function DashboardGeneral() {
  return (
    <div className='flex flex-col h-full max-h-[1600px] justify-center items-center'>
      <h3 className='title'>Próximamente Sección General</h3>
    </div>
    // <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
    //   <div className="flex flex-col max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 py-6 px-4 lg:py-2 text-sm bg-gray-100 dark:bg-[#d3d3df] rounded-md shadow-md transition-all duration-200 ease-in-out">
    //     <div className="flex justify-between m-8">
    //       <h1 className='title flex items-end m-0 font-bold text-black text-4xl'>General</h1>
    //       <div className="flex items-end">
    //         <button className="btn text-base bg-black hover:bg-gray-900 text-white mx-1 my-0 px-4 h-[40px] rounded">
    //           Filter
    //         </button>
    //         <div className="flex flex-col text-black font-semibold">
    //           <h3 className="text-lg">Fecha</h3>
    //           <select
    //             className="px-4 h-[40px] text-base font-semibold bg-[#c0c0c5] text-gray-500 rounded">
    //             <option value="value1">Search</option>
    //           </select>
    //         </div>
    //         <div className="flex flex-col text-black font-semibold">
    //           <h3 className="text-lg">País</h3>
    //           <select
    //             className="px-4 h-[40px] text-base font-semibold bg-[#c0c0c5] text-gray-500 mx-1 rounded">
    //             <option value="value1">Search</option>
    //           </select>
    //         </div>
    //         <div className="flex flex-col text-black font-semibold">
    //           <h3 className="text-lg">Idioma</h3>
    //           <select
    //             className="px-4 h-[40px] text-base font-semibold bg-[#c0c0c5] text-gray-500 rounded">
    //             <option value="value1">Search</option>
    //           </select>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default DashboardGeneral