import React, { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const QuestionItem = ({
    question,
    questionIndex,
    completedQuestionsId,
    handleMultipleAnswers,
    handleAnswer,
    handleMultipleChoiceChange,
    handleTextAnswerChange,
    multipleAnswers,
    questionResultIsLoading,
    answers,
    isSubChallenge,
    ...props
}) => {
    
    const completedQuestion = completedQuestionsId && completedQuestionsId.includes(question.id)
    const questionAnswer = question?.answers?.map(answer => answer.answer)

    const [showHintText, setShowHintText] = useState(false);

    const showHint = (hint) => {
        setShowHintText(hint)
    }

    return (
        <>
            {
                !completedQuestion ? (
                    <div key={questionIndex} className="p-5">
                        <div className="flex justify-between ml-2 md:w-4/4 sm:mt-0 mt-8 mb-5 p-2 overflow-y-auto ">
                            <li className="dark:text-gray-100 text-gray-600 text-md text-left font-medium mt-3 "> {question.question} </li>
                            {
                                (question.hint && !showHintText) ? (
                                    <button
                                        className={`"btn font-bold bg-[#C36B96] min-w-[70px] max-h-[40px] rounded-md`}
                                        onClick={() => showHint(question.hint)}
                                    >
                                        Pista
                                    </button>
                                ) : <></>
                            }
                        </div>
                        <form onSubmit={(e) => {
                            if (question.questionType.type === 'MULTIPLE_CHOICE' || question.questionType.type === 'TRUE_FALSE') {
                                handleMultipleAnswers(question.id, e, questionIndex);
                            } else {
                                handleAnswer(question.id, e, questionIndex)
                            }
                        }}>

                            {
                                (question.questionType.type === 'MULTIPLE_CHOICE' || question.questionType.type === 'TRUE_FALSE') ? (
                                    <div className="bg-gray-50 p-10 dark:bg-[#2c2c2c] shadow-lg dark:shadow-dark rounded-2xl min-w-80 w-148">
                                        {
                                            showHintText ? (
                                                <p className="text-gray-700 text-left dark:text-gray-200 flex justify-start gap-2 mx-6 mb-2">
                                                    Pista: {showHintText}
                                                    <span
                                                        className="text-gray-700 dark:text-gray-200"
                                                        onClick={() => setShowHintText(null)}
                                                        title='Ocultar Pista'
                                                    >
                                                        <VisibilityOffOutlinedIcon
                                                            className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"

                                                        />
                                                    </span>
                                                </p>
                                            ) : <div className='flex min-h-[28px] h-full'>

                                            </div>
                                        }
                                        <ul className={``}>
                                            <fieldset>
                                                {question?.answers?.map((answer, answerIndex) => {
                                                    const isChecked = multipleAnswers?.includes(answer.id);
                                                    const optionId = `q${questionIndex}_o${answerIndex}`;
                                                    return (
                                                        <li key={answerIndex} className="bg-gray-200 text-gray-700 dark:text-gray-200 dark:bg-gray-900 text-lg mx-6 w-auto mb-4 rounded-md flex items-start">
                                                            <label className='flex text-justify px-2 py-1'>
                                                                <input
                                                                    type="radio"
                                                                    value={optionId}
                                                                    checked={isChecked}
                                                                    onChange={(evt) => handleMultipleChoiceChange(evt, answerIndex, answer.id)}
                                                                    className="mr-2"
                                                                />
                                                                {answer.answer}

                                                            </label>
                                                        </li>
                                                    );
                                                })}
                                            </fieldset>
                                        </ul>
                                        <div className="flex flex-row justify-center">

                                            <button className="btn bg-[#C36B96] text-white font-bold w-[100px] h-[36px] mt-2" type='submit'>
                                                {(question.id === questionResultIsLoading) ?
                                                    <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                                                    : "Enviar"}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="bg-gray-50 p-10 dark:bg-[#2c2c2c] shadow-lg dark:shadow-dark rounded-2xl  min-w-80 w-148 row flex-cols-3 mt-2 justify-between">
                                        {
                                            showHintText ? (
                                                <div className="text-gray-700 text-left dark:text-gray-200 flex justify-start gap-2 mx-6">
                                                    <p dangerouslySetInnerHTML={{ __html: `Pista: ${showHintText}` }}></p>
                                                    <span
                                                        className="text-gray-700 dark:text-gray-200"
                                                        onClick={() => setShowHintText(null)}
                                                        title='Ocultar Pista'
                                                    >
                                                        <VisibilityOffOutlinedIcon
                                                            className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                                                        />
                                                    </span>
                                                </div>
                                            ) : <div className='flex min-h-[28px] h-full'>

                                            </div>
                                        }
                                        <input
                                            className={`${!completedQuestion ? "" : "opacity-30"} pl-5 user-input text-lg lg:w-[965px] sm:w-[755px] w-[245px] flex items-center mx-6 mb-4 h-16 rounded-md bg-gray-200 text-gray-700 dark:text-gray-200 dark:bg-gray-900 hover:text-black`}
                                            placeholder="Tu respuesta..."
                                            onChange={(e) => handleTextAnswerChange(questionIndex, e.target.value)}
                                            disabled={completedQuestion}
                                        />
                                        <div className="flex flex-row justify-center">
                                            {completedQuestionsId && completedQuestion ?
                                                <div className='flex'>
                                                    <label className="bg-[#C36B96] font-bold text-white rounded-lg shadow-md m-1 p-2 w-[200px] mt-2 ">
                                                        Respuesta correcta
                                                    </label>
                                                    <img src="/checkmarks/checkmark-done.png" alt="checkmark" width="30px" style={{ paddingTop: '10px', paddingBottom: '10px' }} />
                                                </div>
                                                :
                                                <button className="btn bg-[#C36B96] text-white font-bold w-[100px] h-[36px] mt-2 " type='submit'>
                                                    {(question.id === questionResultIsLoading) ?
                                                        <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                                                        : "Enviar"}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                        <hr className="border-t-2 border-gray-500 mt-10" />
                    </div>

                ) : (
                    <div key={questionIndex} className="p-5 mt-4">
                        {question.questionType.type === 'MULTIPLE_CHOICE' || question.questionType.type === 'TRUE_FALSE' ? (
                            <div className="bg-gray-50 p-10 dark:bg-[#2c2c2c] border-2 border-green-300 shadow-lg dark:shadow-dark rounded-2xl min-w-80 w-148 relative">
                                <p className="dark:text-gray-100 text-gray-600 text-2xl font-bold !my-3">
                                    {question.question}
                                </p>
                                <ul >
                                    {question?.answers?.map((answer, answerIndex) => {

                                        return (
                                            <li
                                                key={answerIndex}
                                                className={`px-5 py-1 text-lg lg:w-[965px] sm:w-[755px] w-[245px] flex items-center mx-6 mb-4 rounded-md bg-gray-300 dark:bg-gray-700 ${answer.isTrueAnswer ? 'border-2 border-green-500' : 'border-2 border-red-500'}`}>
                                                <span
                                                    className='text-gray-600 dark:text-white text-justify'
                                                >
                                                    {answer.answer}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <CheckCircleIcon className="text-green-500 absolute top-4 left-4" />

                            </div>
                        )
                            :
                            <div
                                className="bg-gray-50 p-10 dark:bg-[#2c2c2c] shadow-lg dark:shadow-dark rounded-2xl  min-w-80 w-148 flex-cols-3 mt-6  border-2 border-green-300 relative"
                            >
                                <CheckCircleIcon className="text-green-500 absolute top-4 left-4" />
                                <p className="dark:text-gray-100 text-gray-600 text-2xl font-bold !my-3">
                                    {question.question}
                                </p>
                                <p
                                    className='pl-5 text-lg lg:w-[965px] sm:w-[755px] w-[245px] flex items-center mx-6 mb-4 h-16 rounded-md bg-gray-300 text-gray-600 dark:text-gray-200 dark:bg-gray-700'
                                >
                                    {questionAnswer}
                                </p>

                            </div>
                        }
                    </div>
                )
            }
        </>
    )
}

export default QuestionItem