import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const QuestionsTabs = ({
    selectedQuestion,
    onAnswerChange,
    isEditable = false
}) => {

    const [activeTab, setActiveTab] = useState('tab1');
    const [editableAnswers, setEditableAnswers] = useState(selectedQuestion.answers || []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const addAnswerTab = () => {
        const newAnswers = [...editableAnswers, { answer: '', isTrueAnswer: false }];
        setEditableAnswers(newAnswers);
        // Call the function to update the answers in the parent component
        onAnswerChange(newAnswers);
    };

    const handleAnswerChange = (index, newValue) => {
        const newAnswers = [...editableAnswers];
        newAnswers[index].answer = newValue;
        setEditableAnswers(newAnswers);
        // Call the function to update the answers in the parent component
        onAnswerChange(newAnswers);  
    };

    const checkTrueAnswer = (index) => {
        const newAnswers = [...editableAnswers];
        newAnswers[index].isTrueAnswer = !newAnswers[index].isTrueAnswer;
        setEditableAnswers(newAnswers);
        onAnswerChange(newAnswers);  // Llama a la función de cambio de respuesta pasada como prop
    };

    return (
        <div className="tabs px-2">
            <div className="flex gap-2 border-b border-gray-200">
                {editableAnswers.length > 0 && (
                    <>
                        {editableAnswers.map((answer, index) => (
                            <button
                                key={index}
                                className={`flex gap-2 items-center font-bold rounded-t-md py-2 px-4 ${activeTab === 'tab' + (index + 1) ? 'border-b-2 border-white-500' : ''
                                    } ${answer.isTrueAnswer ? 'bg-green-800 text-white' : 'bg-red-800 text-white'} `}
                                onClick={() => handleTabClick('tab' + (index + 1))}
                            >
                                Respuesta {index + 1}
                                {answer.isTrueAnswer ? <CheckIcon fontSize='small' /> : null}
                            </button>
                        ))}
                        {
                            isEditable && (
                                <>
                                    {
                                        editableAnswers.length < 6 && (
                                            <button
                                                className='flex gap-2 items-center font-bold rounded-t-md py-2 px-4 bg-purple-800 text-white'
                                                onClick={addAnswerTab}
                                            >
                                                <AddIcon />
                                            </button>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )}
            </div>
            <div className="p-4 editor-box rounded-t-none">
                {editableAnswers.length > 0 && (
                    <>
                        {editableAnswers.map((answer, index) => (
                            <div
                                key={index}
                                className={`py-4 text-justify ${activeTab === 'tab' + (index + 1) ? 'block' : 'hidden'
                                    }`}
                            >
                                <p className='font-bold'>Respuesta {index + 1} :</p>
                                {isEditable ? (
                                    <>
                                        <textarea
                                            type="text"
                                            value={answer.answer}
                                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                                            className={`w-full p-2 border border-gray-300 rounded editor-box ${answer.answer.length > 100 ? 'h-[200px]' : 'h-[100px]'} transition-all duration-200 ease-in-out`}
                                        />

                                        <div
                                            className='flex gap-2 items-center'
                                        >
                                            Es correcta:

                                            <button
                                                className={`btn w-[60px] h-[30px] flex items-center justify-center ${answer.isTrueAnswer ? 'bg-green-500' : 'bg-red-500'}`}
                                                onClick={() => checkTrueAnswer(index)}
                                            >
                                                {
                                                    answer.isTrueAnswer ? (
                                                        <span className='text-white flex items-center justify-center gap-1'>
                                                            Si
                                                            <CheckIcon fontSize='small' />
                                                        </span>
                                                    ) : (
                                                        <span className='text-white flex items-center justify-center gap-1'>
                                                            No
                                                            <CloseIcon fontSize='small' />
                                                        </span>
                                                    )
                                                }
                                            </button>
                                        </div>

                                    </>
                                ) : (
                                    <p>{answer.answer}</p>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default QuestionsTabs;
