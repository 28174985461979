import { motion } from 'framer-motion'
import ContributorCard from './contributorCard'
import contributors from './aboutItems'

function About () {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      className='flex justify-center items-start h-full'
    >
      <div className='flex flex-col justify-center items-center text-center min-h-box max-w-[1280px] px-2 py-4 '>
        <h3 className='title'>Sobre Nosotros</h3>
        <div className='flex flex-row justify-center flex-wrap pb-6'>
          {contributors.map((item, index) => {
            return (
              <ContributorCard
                key={index}
                id={index}
                name={item.name}
                desc={item.desc}
                image={item.img}
                thumbnail={item.thumbnail}
                link={item.url}
              />
            )
          })}
        </div>
      </div>
    </motion.div>
  )
}

export default About
