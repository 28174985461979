import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: localStorage.getItem("currentUser") || null,
  userData: JSON.parse(localStorage.getItem("userData")),
  token: localStorage.getItem("token"),
  streak: 0
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthData(state, action) {
      state.currentUser = action.payload;
      localStorage.setItem("currentUser", state.currentUser)
    },

    setUserData(state, action) {
      state.userData = action.payload;
      localStorage.setItem("userData", JSON.stringify(state.userData))
    },

    setToken(state, action) {
      state.token = action.payload;
      localStorage.setItem("token", state.token)
    },
    setStreak(state, action) {
      state.streak = action.payload;
    },
    setReadGuides(state, action) {
      state.readGuides = action.payload;
    },

    resetStore(state) {
      state.currentUser = null
      state.userData = null;
      state.token = null
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
