import { Outlet } from "react-router";
import { useUserVerification } from "../../hooks/userVerification";
import LoadingSpinner from "./loadingSpinner";

function RequiresAuth() {
  const loading = useUserVerification();

  if (loading) {
    return (
      <div>
        <LoadingSpinner size={"300px"}/>
      </div>
    )
  }

  return <Outlet />;
}

export default RequiresAuth;
