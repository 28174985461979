import { React, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import QuestionsTabs from './questionsTabs';

const dinamycSizeDesktop = (selectedQuestion) => {
    if(selectedQuestion.question?.length > 100 || selectedQuestion.hint?.length > 100){
        return "sm:w-3/4"
    }else{
        return "sm:w-1/2"
    }

}

function QuestionModal({ selectedQuestion, index, modal, setModal, questionTypeId }) {

    const variants = {
        opened: {
            opacity: 1,
            display: 'flex'
        },
        closed: {
            opacity: 0,
            transitionEnd: {
                display: 'none'
            }
        }
    }

    const [isQuestionExpanded, setIsQuestionExpanded] = useState(false);
    const [isHintExpanded, setIsHintExpanded] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const toggleQuestionExpansion = () => {
        setIsQuestionExpanded(!isQuestionExpanded);
        if (!isQuestionExpanded) {
            setIsHintExpanded(false);
        }
    };

    const toggleHintExpansion = () => {
        setIsHintExpanded(!isHintExpanded);
        if (!isHintExpanded) {
            setIsQuestionExpanded(false);
        }
    };

    const renderContent = (content, isExpanded, toggleExpansion) => {
        if (content.length <= 150) {
            return content;
        }

        if (isExpanded) {
            return (
                <>
                    {content}
                    <button onClick={toggleExpansion} className="text-blue-500 ml-2">
                        Ver menos
                    </button>
                </>
            );
        }

        return (
            <>
                {content.substring(0, 150)}...
                <button onClick={toggleExpansion} className="text-blue-500 ml-2">
                    Ver más
                </button>
            </>
        );
    };

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-[999]'
        >
            <div className={`absolute p-4 m-2 text-center  bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md w-[99%] ${dinamycSizeDesktop(selectedQuestion)}`}>
                <h1 className='text-xl font-medium my-[20px]' htmlFor='title'>
                    Pregunta {index + 1}
                </h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        setModal(false)
                    }}>
                    <CloseIcon />
                </button>
                <div key={index} className='flex flex-col w-fit mx-auto gap-2'>
                    <div className='flex editor-fields'>
                        <label className="editor-fields-label font-bold flex-none">Pregunta</label>
                        <p className='text-left py-1 w-full text-lg editor-box px-2'>
                            {renderContent(selectedQuestion?.question || '', isQuestionExpanded, toggleQuestionExpansion)}
                        </p>
                    </div>
                    <div className='flex editor-fields'>
                        <label className="editor-fields-label font-bold flex-none">Pista</label>
                        <p className='text-left py-1 w-full text-lg editor-box px-2'>
                            {renderContent(selectedQuestion?.hint || '', isHintExpanded, toggleHintExpansion)}
                        </p>
                    </div>
                    <div className='flex editor-fields'>
                        <label className="editor-fields-label font-bold flex-none">Tipo de Pregunta</label>
                        <p className='flex items-center py-1 w-full text-lg editor-box px-2'>
                            {
                                (questionTypeId === 1) ? "Default"
                                    : (questionTypeId === 2) ? "Multiple Choice"
                                        : "Verdadero y Falso"
                            }
                        </p>
                    </div>

                    {
                        (questionTypeId === 2 || questionTypeId === 3) ? (
                            <QuestionsTabs selectedQuestion={selectedQuestion} />
                        ) : (
                            selectedQuestion.answers?.map((AnswerItem, answerIndex) => (
                                <div
                                    key={answerIndex}
                                    className='flex editor-fields'
                                >
                                    <label
                                        className={`editor-fields-label font-bold flex-none px-2 ${AnswerItem?.isTrueAnswer ? 'bg-green-800' : 'bg-red-800'}`}
                                    >
                                        Respuesta {answerIndex + 1}
                                    </label>
                                    <p
                                        className={`flex items-center gap-2 text-left py-1 w-full text-xl editor-box px-2 ${AnswerItem?.isTrueAnswer ? '!text-green-500' : '!text-red-500'}`}
                                    >
                                        {AnswerItem?.answer}
                                        {AnswerItem?.isTrueAnswer ? <CheckIcon /> : <CloseIcon />}
                                    </p>

                                </div>
                            ))
                        )
                    }



                </div>
            </div>
        </motion.div>
    )
}

export default QuestionModal