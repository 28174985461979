import { useState, useEffect } from "react";
import { addScore, deleteScore, editScore, getDifficultyLevels, getScores, getScoreTypes } from "../../../services/superAdminService";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoaderSpinner from "../../common/loaderSpinner";
import { toast } from "react-toastify";

const AddModal = ({ show, onClose, onAdd, scoreTypes, levels, isLoading }) => {
    if (!show) return null;

    const [scoreType, setScoreType] = useState("");
    const [difficultyLevel, setDifficultyLevel] = useState("");
    const [score, setScore] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await onAdd(scoreType, difficultyLevel, score);
        if (response) {
            onClose();
            setScoreType("");
            setDifficultyLevel("");
            setScore("");
        }
    };



    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white dark:bg-slate-600 px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold dark:text-slate-100">Agrega un tipo de puntaje</h1>

                <form onSubmit={handleSubmit}>
                    <select
                        required
                        className="user-input rounded-md"
                        value={scoreType}
                        onChange={(e) => setScoreType(e.target.value)}
                    >
                        <option value="" disabled>Selecciona un tipo de puntaje</option>
                        {scoreTypes.map((scoreType) => (
                            <option key={scoreType.id} value={scoreType.id}>
                                {scoreType.name}
                            </option>
                        ))}
                    </select>
                    <select
                        required
                        className="user-input rounded-md"
                        value={difficultyLevel}
                        onChange={(e) => setDifficultyLevel(e.target.value)}
                    >
                        <option value="" disabled>Selecciona un nivel</option>
                        {levels.map((level) => (
                            <option key={level.id} value={level.id}>
                                {level.name}
                            </option>
                        ))}
                    </select>
                    <input
                        required
                        type="number"
                        placeholder="Puntaje..."
                        className="user-input"
                        value={score}
                        onChange={(e) => setScore(e.target.value)}
                    />
                    <div className="flex justify-center mt-2">
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <LoaderSpinner /> : "Agregar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const UpdateModal = ({ show, onClose, onUpdate, initialData, scoreTypes, levels, isLoading }) => {

    if (!show) return null;

    const [scoreType, setScoreType] = useState(initialData.scoreType?.id);
    const [difficultyLevel, setDifficultyLevel] = useState(initialData.difficultyLevel?.id);
    const [score, setScore] = useState(initialData.score);

    useEffect(() => {
        setScoreType(initialData.scoreType?.id);
        setDifficultyLevel(initialData.difficultyLevel?.id);
        setScore(initialData.score);
    }, [initialData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await onUpdate(initialData.id, scoreType, difficultyLevel, score);
        if (response) {
            onClose();
            setScoreType("");
            setDifficultyLevel("");
            setScore("");
        }
    };

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-8 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold text-slate-600">Modifica el puntaje</h1>
                <form onSubmit={handleSubmit}>
                    <select
                        required
                        className="user-input rounded-md"
                        value={scoreType}
                        onChange={(e) => setScoreType(e.target.value)}
                    >
                        <option value="" disabled>Selecciona un tipo de puntaje</option>
                        {scoreTypes.map((scoreType) => (
                            <option key={scoreType.id} value={scoreType.id}>
                                {scoreType.name}
                            </option>
                        ))}
                    </select>
                    <select
                        required
                        className="user-input rounded-md"
                        value={difficultyLevel}
                        onChange={(e) => setDifficultyLevel(e.target.value)}
                    >
                        <option value="" disabled>Selecciona un nivel</option>
                        {levels.map((level) => (
                            <option key={level.id} value={level.id}>
                                {level.name}
                            </option>
                        ))}
                    </select>
                    <input
                        required
                        type="number"
                        placeholder="Puntaje..."
                        className="user-input"
                        value={score}
                        onChange={(e) => setScore(e.target.value)}
                    />
                    <div className="flex justify-center mt-2">
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <LoaderSpinner /> : "Modificar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const DeleteModal = ({ show, onClose, onDelete, isLoading }) => {
    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold text-slate-600">¿Estás seguro que deseas eliminar este puntaje?</h1>
                <div className="flex justify-center">
                    <button
                        className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        onClick={onDelete}
                        disabled={isLoading}
                    >
                        {isLoading ? <LoaderSpinner /> : "Eliminar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

function ScoreEditor() {
    const [scores, setScores] = useState([]);
    const [scoreTypes, setScoreTypes] = useState([]);
    const [levels, setLevels] = useState([]);
    const [scoreId, setScoreId] = useState();
    const [addScoreModal, setAddScoreModal] = useState(false);
    const [updateScoreModal, setUpdateScoreModal] = useState(false);
    const [updateScoreData, setUpdateScoreData] = useState({});
    const [deleteScoreModal, setDeleteScoreModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const beginner = '/Michis_Heads_03.png';
    const intermediate = '/Michis_Heads_02.png';
    const advanced = '/Michis_Heads_01.png';

    const fetchScores = async () => {
        const query = {
            cant: 50
        }
        const response = await getScores(query);
        if (response) {
            setScores(response);
        }
    };

    const fetchScoreTypes = async () => {
        const response = await getScoreTypes();
        if (response) {
            setScoreTypes(response);
        }
    };

    const fetchLevels = async () => {
        const response = await getDifficultyLevels();
        if (response) {
            setLevels(response);
        }
    };

    useEffect(() => {
        fetchScores();
        fetchScoreTypes();
        fetchLevels();
    }, []);

    const handleAddScore = async (scoreType, difficultyLevel, score) => {
        setIsLoading(true);
        const payload = { scoreType, difficultyLevel, score };
        const response = await addScore(payload);

        if (response) {
            toast.success("Puntaje agregado con exito");
            setIsLoading(false);
            setAddScoreModal(false);
            await fetchScores();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }

    };

    const handleUpdateScore = async (id, scoreType, difficultyLevel, score) => {
        setIsLoading(true);
        const payload = { scoreType, difficultyLevel, score };
        const response = await editScore(id, payload);

        if (response) {
            toast.success("Puntaje modificado con exito");
            setIsLoading(false);
            setUpdateScoreModal(false);
            await fetchScores();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }

    };

    const handleDeleteScore = async () => {
        setIsLoading(true);

        const response = await deleteScore(scoreId);

        if (response) {
            toast.success("Puntaje eliminado con exito");
            setIsLoading(false);
            setDeleteScoreModal(false);
            fetchScores();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }
    };

    return (
        <>
            <AddModal
                show={addScoreModal}
                onClose={() => setAddScoreModal(false)}
                onAdd={handleAddScore}
                scoreTypes={scoreTypes}
                levels={levels}
                isLoading={isLoading}
            />
            <UpdateModal
                show={updateScoreModal}
                onClose={() => setUpdateScoreModal(false)}
                onUpdate={handleUpdateScore}
                initialData={updateScoreData}
                scoreTypes={scoreTypes}
                levels={levels}
                isLoading={isLoading}
            />
            <DeleteModal
                show={deleteScoreModal}
                onClose={() => setDeleteScoreModal(false)}
                onDelete={handleDeleteScore}
                isLoading={isLoading}
            />
            <div className="flex flex-col min-h-[700px] w-full h-full max-h-[2800px] p-2">
                <div className="overflow-x-auto">
                    <div className="min-w-screen min-h-screen flex justify-center font-sans overflow-hidden">
                        <div className="w-full lg:w-4/6">
                            <div className="bg-white shadow-md rounded my-6">
                                <div className="flex justify-between mr-5">
                                    <h3 className="text-2xl text-gray-800 font-bold ml-5 mt-6">Editor de puntajes</h3>
                                    <button
                                        className="my-5 bg-rose-600 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => setAddScoreModal(true)}
                                    >
                                        + Agregar puntaje
                                    </button>
                                </div>
                                <table className="min-w-max w-full table-auto border border-gray-200">
                                    <thead>
                                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th className="py-3 px-6 text-center border border-gray-300">Nombre</th>
                                            <th className="py-3 px-6 text-center border border-gray-300">Puntaje</th>
                                            <th className="py-3 px-6 text-center border border-gray-300">Nivel</th>
                                            <th className="py-3 px-6 text-center border border-gray-300">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light dark:bg-gray-500 dark:text-gray-200">
                                        {scores?.length ? (
                                            scores.map((score, index) => {
                                                const level = score.difficultyLevel?.id;

                                                const levelIcon = level === 1 ? beginner : level === 2 ? intermediate : advanced;
                                                return (
                                                    <tr key={index}>
                                                        <td className="py-3 px-6 whitespace-nowrap border border-gray-300">
                                                            <div className="flex items-center justify-center">
                                                                {/* {levelIcon && <img src={levelIcon} width='30px' alt="level icon" />} */}
                                                                <span className="font-medium"> {score.scoreType.name} </span>
                                                            </div>
                                                        </td>
                                                        <td className="py-3 px-6 whitespace-nowrap border border-gray-300">
                                                            <div className="flex items-center justify-center">
                                                                {/* {levelIcon && <img src={levelIcon} width='30px' alt="level icon" />} */}
                                                                <span className="font-medium"> {score.score} </span>
                                                            </div>
                                                        </td>
                                                        <td className="py-3 px-6 whitespace-nowrap border border-gray-300">
                                                            <div className="flex gap-2 items-center justify-center">
                                                                {levelIcon && <img src={levelIcon} width='30px' alt="level icon" />}
                                                                <span className="font-medium"> {score.difficultyLevel?.name} </span>
                                                            </div>
                                                        </td>
                                                        <td className="py-3 px-6 text-center border border-gray-300">
                                                            <div className="flex item-center justify-center gap-1">
                                                                <button
                                                                    className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                                    onClick={() => {
                                                                        setUpdateScoreModal(true);
                                                                        setUpdateScoreData(score);
                                                                        setScoreId(score.id);
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon />
                                                                </button>
                                                                <button
                                                                    className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                                    onClick={() => {
                                                                        setDeleteScoreModal(true);
                                                                        setScoreId(score.id);
                                                                    }}
                                                                >
                                                                    <DeleteOutlineOutlinedIcon />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="h-20">
                                                <td
                                                    colSpan={4}
                                                    className='text-lg font-bold mt-5'

                                                >
                                                    <div className="flex justify-center items-center gap-2">
                                                        No hay puntajes creados
                                                        <button
                                                            className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                                            onClick={() => setAddScoreModal(true)}
                                                        >
                                                            Crear puntaje
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScoreEditor;
