import { toast } from 'react-toastify'
import {
  GUIDERATING_URL_CREATE, GUIDE_URL_LIST, GUIDE_URL_CREATE, GUIDE_URL_UPDATE, GUIDE_URL_DELETE, GUIDE_URL_GET, TAG_URL_LIST, DIFFICULTYLEVEL_URL_LIST, LANGUAGE_URL, GUIDEPAGE_URL_CREATE, GUIDEPAGE_URL_UPDATE, GUIDEPAGE_URL_DELETE, GUIDE_OBJECTIVE_URL,
  USERREADGUIDE_URL_CREATE,
  GUIDEOBJECTIVE_URL_DELETE
} from './apiRoutes';
import { makeRequest } from './makeRequest';

import { Guidepage } from '../proto/data_pb.js'

export async function getGuide(guideId) {
  const response = await makeRequest(
    'GET',
    `${GUIDE_URL_GET}/${guideId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function getTagList() {
  const response = await makeRequest(
    'GET',
    `${TAG_URL_LIST}/?paginate=false`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function getLevelList() {

  const response = await makeRequest(
    'GET',
    `${DIFFICULTYLEVEL_URL_LIST}/?paginate=false`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function getLangList(token) {
  const response = await makeRequest(
    'GET',
    `${LANGUAGE_URL}?paginate=false`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function getGuideList() {
  const response = await makeRequest(
    'GET',
    `${GUIDE_URL_LIST}?paginate=false`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};



// guide create
export async function createGuide(guide, token) {

  const response = await makeRequest('POST', `${GUIDE_URL_CREATE}`, {
    payload: guide,
    auth: true
  })

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }

};


export async function createPage(page, token) {
  try {
    const res = await makeRequest('POST', `${GUIDEPAGE_URL_CREATE}`, {
      payload: page,
      auth: true
    })

    if (res && res.status === 200) {
      return res.result
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
    return null
  }

};

// guide update
export async function updateGuide(newFields, guideId) {

  const response = await makeRequest('PUT', `${GUIDE_URL_UPDATE}/${guideId}`, {
    payload: newFields,
    auth: true
  })

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function updateGuidePage(page, pageId, token) {

  console.log("page");
  console.log(page);

  const guidePage = new Guidepage()
  guidePage.setName(page.name)
  guidePage.setContent(page.content)
  guidePage.setIndex(page.index)
  guidePage.setGuideid(page.guideId)

  // Serializes to a UInt8Array
  const serializedData = guidePage.serializeBinary()
  console.log(serializedData);
  const deserializedData = Guidepage.deserializeBinary(serializedData)
  console.log(deserializedData);

  const response = await makeRequest('PUT', `${GUIDEPAGE_URL_UPDATE}/${pageId}`, {
    payload: serializedData,
    auth: true,
    contentType: 'application/octet-stream'
  })

  if (response && response.status === 200) {
    toast.success('Página guardada')
    return response.result
  } else {
    toast.error('No se pudo guardar la página pruebe otra vez')
    return null
  }

};

// guide delete
export async function deleteGuide(guideId, token) {

  const response = await makeRequest('DELETE', `${GUIDE_URL_DELETE}/${guideId}`, {
    auth: true
  })

  if (response && response.status === 200) {
    toast.success('Guía borrada')
    return response.result
  } else {
    toast.error('No se pudo borrar la guía pruebe otra vez')
    return null
  }
};
export async function deleteGuidePage(pageId) {

  const response = await makeRequest('DELETE', `${GUIDEPAGE_URL_DELETE}/${pageId}`, {
    auth: true
  })

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }

};

// guide objectives endpoints
export async function createObjective(objective, token) {

  const response = await makeRequest('POST', `${GUIDE_OBJECTIVE_URL}/create`,
    {
      payload: objective,
      auth: true
    }
  )

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function objectiveCompleted(objectiveId, token) {

  const response = await makeRequest('POST', `${GUIDE_OBJECTIVE_URL}/${objectiveId}/completed`,
    {
      auth: true
    }
  )

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function deleteObjective(objectiveId, token) {

  const response = await makeRequest('DELETE', `${GUIDEOBJECTIVE_URL_DELETE}/${objectiveId}`,
    {
      auth: true
    }
  )

  if (response && response.status === 200) {
    toast.success('Objetivo borrado')
    return response.result
  } else {
    toast.error('No se pudo borrar el objetivo pruebe otra vez')
    return null
  }
};

// guide rating
export async function createGuideRate(rating, review, guideId, userId) {
  const response = await makeRequest('POST', `${GUIDERATING_URL_CREATE}`, {
    auth: true,
    payload: {
      rating,
      review,
      guideId,
      userId
    }
  })

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }

};

export async function userReadedGuide(guideId, userId, token) {
  const response = await makeRequest('POST', `${USERREADGUIDE_URL_CREATE}`, {
    auth: true,
    payload: {
      guideId,
      userId
    }
  })

  if (response && response.status === 200) {
    return response.result
  } else {
    return null
  }
};
