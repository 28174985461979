import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  login,
} from "../../services/userService";
import LoadingSpinner from "../common/loadingSpinner";

import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

function LoginForm() {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [t] = useTranslation("global");
  const captcha = useRef(null);
  const [validCaptcha, setValidCaptcha] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaKey = '6Le1FU4kAAAAAO1zJ1cZnbrkquNmnycFPJCDTYNL';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidCaptcha(true);
    if (captcha.current.getValue()) {
      setIsLoading(true)

      await login(usernameRef.current.value, passwordRef.current.value)
      
      setIsLoading(false)

    } else {
      setValidCaptcha(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
    >
      <h3 className="m-2 my-4 text-2xl md:text-3xl text-white font-bold">
        {t("loginform.login")}
      </h3>
      <div className="form">
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            name="username"
            ref={usernameRef}
            className={`user-input`}
            placeholder="Username"
            type="username"
            autoComplete="username"
          ></input>
          <input
            name="password"
            ref={passwordRef}
            className={`user-input`}
            placeholder={t("loginform.password")}
            type="password"
            autoComplete="off"
          ></input>
          <div className="flex my-2 scale-75">
            <ReCAPTCHA
              ref={captcha}
              sitekey={recaptchaKey}
            />
          </div>
          {isLoading && <LoadingSpinner size={"75px"}/>}
          {validCaptcha === false && <div className="text-red-600">Por favor acepte el captcha</div>}
          <button className="btn mx-auto my-2 w-full" type="submit">
            {t("loginform.login")}
          </button>
          <Link
            to="/forgot-password"
            className="inline-block text-xs xs:text-sm text-gray-700 dark:text-gray-100 hover:text-rose-600 hover:dark:text-rose-600 transition-all ease-in-out duration-200 font-medium"
          >
            {t("loginform.forgot")}
          </Link>
          {/* <div className="mx-4 my-2 border bg-slate-300 dark:bg-hackear-600 dark:border-hackear-600 border-slate-300 rounded-full"></div> */}
        </form>
        {/* <button
          onClick={handleSubmitGoogle}
          className="btn bg-red-600 hover:bg-red-800 mx-auto my-1 w-full"
        >
          <FontAwesomeIcon icon={faGoogle} /> {t("loginform.loginwith")} Google
        </button>
        <button
          onClick={handleSubmitFacebook}
          className="btn mx-auto my-1 w-full bg-blue-700 hover:bg-blue-900"
        >
          <FontAwesomeIcon icon={faFacebook} /> {t("loginform.loginwith")}{" "}
          Facebook
        </button> */}
      </div>
    </motion.div>
  );
}

export default LoginForm;
