import { useState, useEffect } from "react";
import Tinymce from "./tinymce";
import edFunc from "./editorFunctions";
import edChallengeFunc from "./challengeEditorFunctions"
import DeletePageModal from "../common/deletePageModal";
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from "react-router-dom";
import CreateObjectiveModal from "../common/createObjectiveModal";
import TagListModal from "../common/tagListModal";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { settingsActions } from "../../redux/slices/settingsSlice";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { toast } from "react-toastify";

// TODO: REFACTORIZE CODE

const resetFields = {
  id: 0,
  name: "",
  description: "",
  imageThumbnailPath: "",
  videoThumbnailPath: "",
  headerDesktopPath: "",
  headerMobilePath: "",
  difficultyOrder: 1,
  isPublished: false,
  isPremium: false,
  difficultyLevelId: 1,
  tagsId: [],
  guidePage: [],
  challengesId: [],
  guideObjective: [],
};

const resetPageFields = {
  name: "",
  content: "",
  siteUrl: "www.siteUrl.com",
  index: 0,
  guideId: 0,
}


function Editor() {
  const { guideId } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const data = useSelector((state) => state.user.userData);
  const challengeList = useSelector((state) => state.settings.challenges);
  const tagList = useSelector((state) => state.settings.tags);
  const levelList = useSelector((state) => state.settings.levels);
  const hasChanged = useSelector((state) => state.settings.hasChanged);
  const scoreTypesState = useSelector((state) => state.settings.scoreTypes);

  const [pages, setPages] = useState([]);
  const [index, setIndex] = useState(0);
  const [levels, setLevels] = useState(levelList);
  const [tags, setTags] = useState(tagList);
  const [editor, setEditor] = useState(false);
  const [fields, setFields] = useState(resetFields);
  const [pageFields, setPageFields] = useState(resetPageFields);
  const [pageId, setPageId] = useState(0);
  const [modalDeletePage, setModalDeletePage] = useState(false);
  const [modalObjective, setModalObjective] = useState(false);
  const [avoidpage, setAvoidpage] = useState(false);
  const [challenge, setChallenge] = useState(challengeList);
  const [previewPage, setPreviewPage] = useState(true);
  const [tagModal, setTagModal] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!challenge[0] || !tags[0] || !levels[0]) {
        edChallengeFunc.HandleGetChallengeList(setChallenge, setEditor, token)
        edFunc.HandleGetLevelList(setLevels, token);
        edFunc.HandleGetTagList(setTags)
      }
      edFunc.handleGetGuide(guideId, setFields, setPageFields, setEditor, setPages, setPageId, index, token);
    }
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    edFunc.handleGetGuide(guideId, setFields, setPageFields, setEditor, setPages, setPageId, index, token);

  }, [index]);

  useEffect(() => {
    if (!hasChanged) return;
    function handleOnBeforeUnload(event) {
      event.preventDefault();
      return (event.returnValue = '');
    }
    window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
    }
  }, [hasChanged])

  const getGuide = async () => {

    await edFunc.handleGetGuide(guideId, setFields, setPageFields, setEditor, setPages, setPageId, index, token);
  }

  const addNewPage = async () => {

    // eslint-disable-next-line no-unreachable
    const newPage = await edFunc.newPage(fields);
    if (newPage) {
      setIndex(fields.guidePage.length)
      setAvoidpage(false)
      setIndex(Object.keys(pages).length);
      toast.success("Pagina creada con exito")
    } else {
      setAvoidpage(false)
      toast.error("Error al crear la pagina")
    }
  }

  const handleChangePage = (evt) => {
    const { value } = evt.target
    const pageIndex = pages.findIndex((page) => page.id === parseInt(value));

    if (pageIndex !== -1) {
      setIndex(pageIndex);
      setPageFields(pages[pageIndex]);
      setPageId(pages[pageIndex]?.id)
    }
  }

  return (
    <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
      <div className="flex justify-between">
        <div className="flex flex-row py-2 items-center">
          <Link
            className="btn"
            to={`${process.env.PUBLIC_URL}/admin/edition/editor`}
          >
            Volver
          </Link>
        </div>
        <h3 className="title">Editor de guías</h3>
        <div></div>
      </div>
      
      {editor && (
        <div className="mb-2 p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
          <form
            className={`flex flex-col gap-2 ${guideId ? "" : "opacity-50 pointer-events-none"}`}
            onChange={() => dispatch(settingsActions.setHasChanged(true))}
          >
            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="name"
              >
                Titulo:
              </label>
              <input
                className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                id="name"
                onChange={(e) =>
                  edFunc.handleChange(e, "name", setFields, fields)
                }
                disabled={!guideId}
                value={fields.name}
                autoComplete="off"
              />
            </div>

            <div className="editor-fields ">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="description"
              >
                Descripción:
              </label>
              <textarea
                onChange={(e) =>
                  edFunc.handleChange(e, "description", setFields, fields)
                }
                name="description"
                id="description"
                className="p-1 w-full editor-box h-[75px] resize-none transition-all duration-200 ease-in-out"
                value={fields.description}
              ></textarea>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion de la imagen"
                className="editor-fields-label w-[200px]"
                htmlFor="imageThumbnailPath"
              >
                Miniatura imagen:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "imageThumbnailPath", setFields, fields)
                }
                id="imageThumbnailPath"
                type="text"
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                value={fields.imageThumbnailPath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion del video"
                className="editor-fields-label w-[200px]"
                htmlFor="videoThumbnailPath"
              >
                Miniatura video:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "videoThumbnailPath", setFields, fields)
                }
                id="videoThumbnailPath"
                type="url"
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                value={fields.videoThumbnailPath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion de la imagen"
                className="editor-fields-label w-[200px]"
                htmlFor="headerDesktopPath"
              >
                Imagen de cabecera:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "headerDesktopPath", setFields, fields)
                }
                id="headerDesktopPath"
                type="url"
                className="editor-input"
                value={fields.headerDesktopPath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion de la imagen"
                className="editor-fields-label w-[200px]"
                htmlFor="headerMobilePath"
              >
                Imagen de cabecera (móvil):
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "headerMobilePath", setFields, fields)
                }
                id="headerMobilePath"
                type="url"
                className="editor-input"
                value={fields.headerMobilePath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="difficultyOrder"
              >
                Métrica de dificultad:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChangeInt(e, "difficultyOrder", setFields, fields)
                }
                id="difficultyOrder"
                type="number" min="1"
                className="editor-input"
                value={fields.difficultyOrder}
              ></input>
            </div>
            {data && (data.role.name === 'Super Admin' || data.role.name === 'Admin') &&
              <div className="editor-fields">
                <label
                  className="editor-fields-label w-[200px]"
                  htmlFor="isPublished"
                >
                  Publicar:
                </label>
                <select
                  className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                  name="isPublished"
                  id="isPublished"
                  value={fields?.isPublished}
                  onChange={(e) =>
                    edFunc.handleChange(e, "isPublished", setFields, fields)
                  }
                >
                  <option hidden defaultValue="default">-</option>
                  <option value={false}>No</option>
                  <option value={true}>Si</option>
                </select>
              </div>
            }

            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="isPremium"
              >
                Premium:
              </label>
              <select
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                name="isPremium"
                id="isPremium"
                value={
                  fields.isPremium
                    ? fields.isPremium === ""
                      ? "default"
                      : fields.isPremium
                    : "default"
                }
                onChange={(e) =>
                  edFunc.handleChange(e, "isPremium", setFields, fields)
                }
              >
                <option value={false}>No</option>
                <option value={true}>Si</option>
              </select>
            </div>

            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="difficultyLevelId"
              >
                Nivel:
              </label>
              <select
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                name="difficultyLevelId"
                id="difficultyLevelId"
                value={fields?.difficultyLevelId?.id}
                onChange={(e) => {
                  edFunc.handleChangeInt(e, "difficultyLevelId", setFields, fields);
                  setPreviewPage(false);
                }
                }
              >
                <option hidden defaultValue="default"></option>
                {levels?.map((item, index) => {
                  return (
                    <option key={index} value={levels[index].id} className="font-medium">
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="difficultyLevelId"
              >
                Tipo de score:
              </label>
              <select
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                name="scoreType"
                id="scoreTypeId"
                value={fields?.scoreType}
                onChange={(e) => {
                  edFunc.handleChangeInt(e, "scoreType", setFields, fields);
                  setPreviewPage(false);
                }
                }
              >
                <option hidden defaultValue="default"></option>
                {scoreTypesState?.map((item, index) => {
                  return (
                    <option key={index} value={item.id} className="font-medium">
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="editor-fields justify-start">
              <label
                htmlFor="tagsId"
                className="editor-fields-label"
              >
                Etiquetas:
              </label>
              <button className="btn w-[150px] bg-[#3E73B9] hover:bg-blue-900"
                id="tagsId"
                onClick={() => setTagModal(true)}
                type="button">
                Agregar Etiqueta
              </button>
            </div>
            {(fields.tagsId != null) &&
              <div className="editor-fields justify-start">
                {fields.tagsId.map((item, index) => {
                  // using .find to search for the tag objects and obtain the name since the tagId field only save the id number of the tag
                  const tagFind = tags.find(element => element.id === item)
                  return (
                    <div key={index} className="text-lg bg-[#C36B96] px-2 m-2 flex items-center justify-between editor-fields-label rounded-lg w-fit gap-2">
                      <p>{tagFind?.name}</p>
                      <button onClick={(e) => {
                        edFunc.handleChangeArrayRemove(e, "tagsId", setFields, fields, index)
                      }}>
                        <ClearOutlinedIcon
                          className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                        />
                      </button>
                    </div>
                  );
                })}
              </div>}

            <div title="Para editar ir al editor de retos" className="editor-fields justify-start">
              {/* The list of challenge can be viewed but not modified from this editor */}
              <span
                className="editor-fields-label flex-none"
              >
                Retos Asociados:
              </span>
            </div>
            {(fields.challengesId != null) &&
              <div className="editor-fields justify-start">
                {fields.challengesId.map((item, index) => {
                  // same logic as the tags fields
                  const challengeFind = challenge.find(element => element.id === item)
                  return (
                    <label key={index} className="text-xl bg-purple-600 px-2 m-2">
                      {challengeFind?.name}
                    </label>
                  );
                })}
              </div>}
          </form>
        </div>)}

      <div className="flex justify-center items-center">
        <button
          type="submit"
          className={`btn w-40 mx-2 my-4 ${guideId ? "" : "disabled-link"}`}
          onClick={() => {
            dispatch(settingsActions.setHasChanged(false))
            edFunc.HandleUpdateGuide(fields, guideId, token)
          }}
          disabled={!guideId}
          hidden={!guideId}
        >
          Guardar Guía
        </button>
        <button
          type="submit"
          className={`btn w-40 mx-2 my-4 ${guideId ? "" : "disabled-link"}`}
          onClick={() => {
            setModalObjective(true)
          }}
          disabled={!guideId}
          hidden={!guideId}
        >
          Objetivos
        </button>
      </div>

      <div className="flex flex-row flex-wrap max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 justify-between py-6 px-4 lg:py-2 items-center text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
        <div className="flex flex-row w-full ">
          <div className="flex flex-col lg:flex-row justify-between items-center w-full">
            <div className="flex flex-col lg:flex-row items-center">
              <label
                htmlFor="docpage"
                className="p-2 pl-0 text-sm font-medium text-gray-700 dark:text-gray-100"
              >
                Páginas:
              </label>
              <select
                id="docpage"
                className={`max-w-[200px] m-2 px-2 py-1 editor-box transition-all duration-200 ease-in-out`}
                name="docpage"
                value={pageId}
                onChange={handleChangePage}
                disabled={
                  (Object.keys(pages).length === 0)
                }
              >
                <option disabled value="default" className="hidden">
                  Seleccione una página
                </option>
                {pages.map((item, index) => {
                  return (
                    <option key={index} value={item?.id} className="font-medium">
                      {index + 1} : {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="flex flex-row items-center">
              <h3 className="flex items-center relative text-sm font-medium text-gray-700 dark:text-gray-100 transition-all duration-200 ease-in-out ">
                Documento seleccionado:
              </h3>
              <h3 className="text-base font-bold text-gray-700 dark:text-gray-100 m-2 block max-w-[155px] 2xl:max-w-[225px] truncate ...">
                {guideId ? fields.name : "-"}
              </h3>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <h3 className="flex items-center text-sm font-medium text-gray-700 dark:text-gray-100">
              Página:
              <span className="m-2 font-bold text-base block max-w-[155px] 2xl:max-w-[225px] truncate ...">
                {
                  pageId
                    ? pageFields === undefined
                      ? ""
                      : pageFields.name
                    : "-"
                }
              </span>
            </h3>
            <div
              className="flex flex-row items-center"
            >
              <button
                className={`btn w-30 h-9 mx-2 my-4  min-w-[120px] bg-[#3E73B9] hover:bg-blue-900 ${(avoidpage || !editor) ? "disabled-link" : ""}`}
                disabled={!editor}
                onClick={addNewPage}
              >
                {
                  avoidpage ?
                    <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                    : 'Nueva Página'
                }
              </button>
              <button
                disabled={!editor}
                className={`${editor ? "" : "disabled-link"
                  } btn bg-red-600 hover:bg-red-800`}
                onClick={() => setModalDeletePage(true)}
              >
                Borrar Página
              </button>
            </div>
          </div>
        </div>
      </div>
      {editor && (
        <div >
          <div>
            <div className="editor-fields mb-2 p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out !py-4">
              <label
                className="editor-fields-label w-[200px] ml-4 py-2"
                htmlFor="pageTitle"
              >
                Título de Página:
              </label>
              <input
                id="pageTitle"
                className="editor-input"
                onChange={(e) =>
                  edFunc.handleChange(e, "name", setPageFields, pageFields)
                }
                value={
                  pageId
                    ? pageFields === undefined
                      ? ""
                      : pageFields.name
                    : ""
                }
              />
            </div>
          </div>
          <Tinymce
            fields={pageFields}
            avoidpage={setAvoidpage}
            pageId={pageId}
            difficultyLevel={fields.difficultyLevelId}
            previewPage={previewPage}
            setPreviewPage={setPreviewPage}
            token={token}
            getGuide={getGuide}
          />
        </div>
      )}
      {!editor && <div className='p-8 m-2 flex justify-center items-center text-center'>
        <LoadingSpinner size={"300px"} />
      </div>}

      <DeletePageModal
        element={pageFields.name}
        index={index}
        setIndex={setIndex}
        modal={modalDeletePage}
        setModal={setModalDeletePage}
        pages={pages}
        setPages={setPages}
        guideId={guideId}
        pageId={pageId}
        token={token}
      />
      <CreateObjectiveModal
        documentId={guideId}
        modal={modalObjective}
        setModal={setModalObjective}
        fields={fields}
        setFields={setFields}
        element={"guideObjective"}
        objcreateFunc={edFunc.HandleAddGuideObjective}
        objdeleteFunc={edFunc.HandleDeleteGuideObjective}
        token={token}
      />
      <TagListModal
        fields={fields}
        setFields={setFields}
        modal={tagModal}
        setModal={setTagModal}
        token={token}
      />
    </div>
  );
}

export default Editor;
