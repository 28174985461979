import { toast } from 'react-toastify'

import {
  LANGUAGE_URL, TAG_URL_CREATE, TAG_URL_UPDATE, TAG_URL_DELETE, ROLE_URL, DIFFICULTYLEVEL_URL_CREATE, DIFFICULTYLEVEL_URL_UPDATE, DIFFICULTYLEVEL_URL_DELETE, USER_URL_SIGNIN, DASHBOARD_USER_URL,
  ROLE_PERMISSION_URL,
  SCORE_URL_UPDATE,
  ROLE_URL_LIST,
  DIFFICULTYLEVEL_URL_LIST,
  TAG_URL_LIST,
  SCORE_URL_CREATE,
  SCORE_URL_DELETE,
  SCORE_URL_LIST,
  SCORETYPE_URL_LIST
} from './apiRoutes';
import { makeRequest } from './makeRequest';

// Languages services
export async function addLanguage(name, i18nCode) {
  const response = await makeRequest('POST', `${LANGUAGE_URL}`, { 
    auth: true,
    payload: {
      name,
      i18nCode,
    }
  })

  if (response) {
    toast.success('El idioma ha sido agregado.');
    return response.result
  } else {
    toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
    return null
  }


}
export async function editLanguage(id, name, i18nCode, token) {
  const response = await fetch(`${LANGUAGE_URL}/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: token
    },
    body: JSON.stringify({
      name,
      i18nCode,
    })
  })

  if (response.ok) toast.success('El idioma ha sido modificado.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
  const res = response.json()
  return res
}
export async function deleteLanguage(id, token) {

  const response = await makeRequest('DELETE', `${LANGUAGE_URL}/${id}`, {
    auth: true
  })

  if (response) {
    return response.result
  } else {
    return null
  }
}

// Tags services 

export async function getTags(params) {
  const response = await makeRequest('GET', `${TAG_URL_LIST}`, {
    auth: true,
    query: params
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}
export async function addTag(payload) {
  const response = await makeRequest('POST', `${TAG_URL_CREATE}`, {
    payload,
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function editTag(id, name) {
  const response = await makeRequest('PUT', `${TAG_URL_UPDATE}/${id}`, {
    payload: {
      name
    },
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}
export async function deleteTag(id) {
  const response = await makeRequest('DELETE', `${TAG_URL_DELETE}/${id}`, {
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

// Roles services 

export async function getRoles(params) {
  const response = await makeRequest('GET', `${ROLE_URL_LIST}`, {
    auth: true,
    query: params
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function addRole(name, token) {
  const response = await makeRequest('POST', `${ROLE_URL}/create`, {
    payload: {
      name
    },
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}
export async function editRole(id, name) {
  const response = await makeRequest('PUT', `${ROLE_URL}/update/${id}`, {
    payload: {
      name
    },
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}
export async function deleteRole(id) {
  const response = await makeRequest('DELETE', `${ROLE_URL}/delete/${id}`, {
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function addRolePermission(permissions, id, token) {
  const response = await fetch(`${ROLE_PERMISSION_URL}/update/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: token
    },
    body: JSON.stringify({
      rolePermissionIds: permissions,
    })
  })

  if (response.ok) toast.success('El permiso ha sido agregado.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
  return response
}
export async function removeRolePermission(permissions, id, token) {
  const response = await fetch(`${ROLE_URL}/${id}/remove-permissions`, {
    method: 'DELETE',
    headers: {
      Authorization: token
    },
    body: JSON.stringify({
      rolePermissionIds: permissions,
    })
  })

  if (response.ok) toast.success('El permiso ha sido eliminado.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
  return response
}

// Levels services 

export async function getLevels(params) {
  const response = await makeRequest('GET', `${DIFFICULTYLEVEL_URL_LIST}`, {
    auth: true,
    query: params
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function addLevel(payload) {

  const response = await makeRequest('POST', `${DIFFICULTYLEVEL_URL_CREATE}`, {
    payload,
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

}

export async function editLevel(id, payload) {
  const response = await makeRequest('PUT', `${DIFFICULTYLEVEL_URL_UPDATE}/${id}`, {
    payload,
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function deleteLevel(id, token) {

  const response = await makeRequest('DELETE', `${DIFFICULTYLEVEL_URL_DELETE}/${id}`, {
    auth: true,
  })

  if (response) {
    return response.result
  } else {
    return null
  }
}

// Score types 

export async function getScoreTypes(params) {
  const response = await makeRequest('GET', `${SCORETYPE_URL_LIST}`, {
    auth: true,
    query: params
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

// Score levels

export async function getDifficultyLevels(params) {
  const response = await makeRequest('GET', `${DIFFICULTYLEVEL_URL_LIST}`, {
    auth: true,
    query: params
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}


// Scores services 
export async function getScores(params) {
  const response = await makeRequest('GET', `${SCORE_URL_LIST}`, {
    auth: true,
    query: params
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}
export async function addScore(payload) {
  const response = await makeRequest('POST', `${SCORE_URL_CREATE}`, {
    payload,
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }


}

export async function editScore(id, payload) {

  const response = await makeRequest('PUT', `${SCORE_URL_UPDATE}/${id}`, {
    payload,
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

}

export async function deleteScore(id, token) {

  const response = await makeRequest('DELETE', `${SCORE_URL_DELETE}/${id}`, {
    auth: true,
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function getUserDataList(token) {

  const response = await makeRequest('GET', `${DASHBOARD_USER_URL}?paginate=false`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function getUserList() {

  const response = await makeRequest('GET', `${USER_URL_SIGNIN}?paginate=false`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
}

export async function getUserPermissions(roleId) {
  const response = await makeRequest('GET', `${ROLE_PERMISSION_URL}/get/${roleId}`, {
    auth: true
  })

  if (response && response?.status === 200) {

    return response.result
  } else {
    return null
  }

}