import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { changePassword } from "../../services/userService";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../common/loadingSpinner";

function ConfirmPassword() {
  
  const usernameRef = useRef();
  const newPasswordRef = useRef();
  const verificationCodeRef = useRef();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true)
    changePassword(usernameRef.current.value, newPasswordRef.current.value, verificationCodeRef.current.value)
    .then(() => {
      setLoading(false)
      navigate('/')
    })
  };


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
      className="flex flex-col justify-center items-center text-center h-full min-h-[600px] p-4"
    >
      <div className="my-6">
        <h3 className="title font-bold my-2">Cambia tu contraseña</h3>
        <p className="text-base xs:text-lg md:text-xl text-white font-semibold max-w-5xl">
          Ingresa el código que te enviamos a tu mail
        </p>
      </div>
      <form className="form" onSubmit={handleResetPassword}>
        <input
          ref={usernameRef}
          id="username"
          placeholder="Usuario..."
          type="text"
          className="user-input"
        />
        <input
          ref={newPasswordRef}
          placeholder="Nueva contraseña..."
          type="password"
          className="user-input"
        />
        <input
          ref={verificationCodeRef}
          placeholder="Código..."
          type="text"
          className="user-input"
        />
        <button className="btn">Cambiar mi contraseña</button>
        {loading ? <LoadingSpinner size={"150px"}/> : null}
      </form>
    </motion.div>
  );
}

export default ConfirmPassword;