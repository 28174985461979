import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../common/loadingSpinner';
import { getOneSubChallenge } from '../../../services/subChallenge';
import { getClassByLevel } from '../../../utils/assignBgColorByLevel';

const SubChallengeCard = ({
    level,
    showQuestions,
    currentChallenge,
    selectedSubchallengeId,
    subchallenge,
    handleChangeQuestionnaire,
    index,
    setIsSubChallenge
}) => {

    const [subChallengeData, setSubChallengeData] = useState({})

    useEffect(() => {
        if (subchallenge.id) {
            fetchSubchallenge(subchallenge.id)
        }
    }, [])

    const fetchSubchallenge = async (id) => {
        const response = await getOneSubChallenge(id)
        if (response) {
            setSubChallengeData(response)
        }
    }

    const handleOpenQuestionnaire = (subchallenge) => {

        if(selectedSubchallengeId === subchallenge.id){
            handleChangeQuestionnaire(null)
            setIsSubChallenge(false)
            return
        }

        const questions = []
        subchallenge = {
            ...subchallenge,
            quizQuestion: subchallenge?.subChallengeQuestion,
        }

        subchallenge?.subChallengeQuestion?.forEach(question => {
            question.answers = question.subChallengeAnswer
            questions.push(question)
        })

        const aux = {
            ...subchallenge,
            questions
        }

        setIsSubChallenge(true)
        handleChangeQuestionnaire(aux)
    }


    return (
        <div className="flex flex-col justify-start md:min-w-[260px] lg:min-w-[350px] selection-block z-0 pt-0 mt-0">
            <p
                className={`${level === 'Principiante' ? 'bg-[#4379C3]' : level === 'Intermedio' ? 'bg-[#C36B96]' : 'bg-[#662D91]'} py-5 text-lg mt-[-16px] w-[200px] text-white rounded-b-lg `}
            >
                {subchallenge.name}
            </p>
            <img
                src={'/business_assets/Menu-Challenge-Guide-Questionary-Challenge/Challenge-v2.webp'}
                alt="header-img"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                }}
                className="p-2 w-[180px] max-w-[300px] max-h-[150px] mt-4"
            />
            <div
                className='flex flex-col items-center flex-grow relative w-full mt-5'
            >
                {
                    currentChallenge && currentChallenge.imageThumbnailPath && currentChallenge.description ?
                        <>

                            <p>{subchallenge.description}</p>
                            <button
                                className={`btn font-bold text-sm absolute bottom-0 w-[75%] mx-auto ${getClassByLevel(level)}`}
                                onClick={() => handleOpenQuestionnaire(subChallengeData)}
                            >
                                {
                                    selectedSubchallengeId === subchallenge.id ? "Cerrar desafío" : "Comenzar desafío"
                                }
                            </button>
                        </>
                        : <LoadingSpinner size={"150px"} />
                }
            </div>
        </div>
    )
}

export default SubChallengeCard