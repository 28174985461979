import { Player } from '@lottiefiles/react-lottie-player';

function LoadingSpinner(props) {
  const spinner = '/Throbber_Animated_json.json';
  const size = props.size;
  return (
    <div className='flex justify-center items-center text-center'>
      <Player
        autoplay
        loop
        keepLastFrame={true}
        src={spinner}
        style={{ height: size, width: size }} />
    </div>
  )
}

export default LoadingSpinner
