import { getUserDataList, getUserList } from "../../../services/superAdminService";

const HandleGetUserDataList = async (setUsers, setSearchUser, token) => {
  const element = await getUserDataList(token)
  setUsers(element)
  setSearchUser(element)
};
const HandleGetUserList = async (setUsersList, token) => {
  const element = await getUserList(token)
  setUsersList(element)
};

const convertDate = (date) => {
  const day = date[8] + date[9]
  const month = date[5] + date[6]
  const year = date[0] + date[1] + date[2] + date[3]
  const newDate = day + '/' + month + '/' + year
  return newDate
}

const convertDateBack = (date) => {
  const newDate = '20' + date[6] + date[7] + '-' + date[3] + date[4] + '-' + date[0] + date[1]
  return newDate
}

const dashboardFunctions = {
  HandleGetUserDataList,
  convertDate,
  convertDateBack,
  HandleGetUserList
};

export default dashboardFunctions