import { faFileAlt, faBrain, faBookOpen, faCrown, faWrench, faTerminal, faCalculator } from '@fortawesome/free-solid-svg-icons'

export const navbarItems = [
  { 
    route: 'scoreboard',
    icon: faCrown
  },
  { 
    route: 'learningPaths',
    icon: faBookOpen
  },
  {
    route: 'guides',
    icon: faFileAlt
  },
  {
    route: 'challenges',
    icon: faBrain
  },
  {
    route: 'tools',
    icon: faWrench,
    isDropdown: true,
    dropdownItems: [
      {
        key: "tools/bashInterpreter",
        route: "tools/bashInterpreter/index.html",
        icon: faTerminal
      },
      {
        key: "tools/networkCalculator",
        route: "tools/networkCalculator/index.html",
        icon: faCalculator
      },
      {
        key: "glossary",
        route: "https://blog.hackear.com.ar/glosario/",
        icon: faBookOpen,
        isExternal: true
      }
    ]
  }
]

export function variants(isSmall) {
  return isSmall
    ? {
      opened: {
        opacity: 1,
        y: 38,
        transition: {
          ease: 'easeInOut',
          duration: 0.2
        }
      },
      closed: {
        opacity: 0,
        y: 0
      }
    }
    : {
      opened: {
        y: 0,
        opacity: 1,
        transition: {
          ease: 'easeInOut',
          duration: 0
        }
      },
      closed: {
        y: 0,
        opacity: 1,
        transition: {
          ease: 'easeInOut',
          duration: 0.15
        }
      }
    }
}
