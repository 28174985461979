import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../common/loadingSpinner';
import ResizablePanel from '../common/ResizablePanel';
import { verifyAnswer, verifyMultipleAnswers, createChallengeRate, verifyQuizAnswer, verifyQuizMultipleAnswers } from '../../services/challengesService';
import { getChallenges, getCompletedQuestions, getCompletedSubChallegesQuestions } from '../../services/userService';
import { toast } from 'react-toastify';
import useMeasure from "react-use-measure";
import ParticlesBackground from '../main/particlesBackground';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import CompleteQuizModal from './completeQuizModal';
import Rating from '@mui/material/Rating';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Cookies from "js-cookie";
import QuestionList from './components/questionList';
import { calculateLevel } from '../../utils/calculateLevel';
import QuestionnaireCard from './components/questionnaireCard';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SubChallengeCard from './components/subChallengeCard';
import { userCompletedQuizCreate } from '../../services/userCompletedQuiz';
import NotesSidebar from '../common/notesSidebar';
import { getClassByLevel } from '../../utils/assignBgColorByLevel';

const variants = {
  opened: {
    x: 0,
    display: 'block',
  },
  closed: {
    x: '-100%',
    transitionEnd: {
      display: 'none',
    },
  },
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#662D91' : '#662D91',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <div className='flex items-center'>
      <div className='w-full mr-1'>
        <BorderLinearProgress variant="determinate" color="secondary" {...props} />
      </div>
      <div className='min-w-[35px]'>
        <p className='text-white text-lg'>{`${Math.round(props.value)}%`}</p>
      </div>
    </div>
  );
}

const checkReview = (challengeId) => {
  const review = Cookies.get('reviewed');
  if (review) {
    const reviewed = JSON.parse(review);
    if (reviewed.challengeId === challengeId) {
      return true;
    }
    return false;
  } else {
    return false;
  }
}

function ChallengeInfo() {
  const user = useSelector((state) => state.user);
  const { id: challengeId, level } = useParams();
  const [ref, { height }] = useMeasure();
  const { userData } = user;
  const userId = userData.id;
  const [currentChallenge, setCurrentChallenge] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);
  const token = useSelector((state) => state.user.token);
  const [questionResultIsLoading, setQuestionResultIsLoading] = useState(0);
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewed, setReviewed] = useState(checkReview(challengeId));
  const [giveReview, setGiveReview] = useState(false);
  const [openObjectivesMenu, setOpenObjectivesMenu] = useState(false);
  const [openNotesmenu, setOpenNotesmenu] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(null);
  const relatedGuides = currentChallenge?.challengeGuideLink;
  const [answers, setAnswers] = useState([]);
  const [multipleAnswers, setMultipleAnswers] = useState([]);
  const [isSubChallenge, setIsSubChallenge] = useState(false);
  const [completedSubChallengesQuestions, setCompletedSubChallengesQuestions] = useState([]);
  const [challengeObjectives, setChallengeObjectives] = useState([]);

  const completedQuestionsId = completedQuestions && completedQuestions.map(id => id.quizQuestionId);
  const subChallenges = currentChallenge?.subChallenge;

  useEffect(() => {
    fetchChallenge();
    getUserCompletedQuestions();
    getUserSubchallengesAnwersQuestions();
  }, [showQuestions, questionResultIsLoading]);

  const fetchChallenge = async () => {
    const response = await getChallenges(challengeId);
    if (response) {
      setCurrentChallenge(response);
    }
  };

  const getUserSubchallengesAnwersQuestions = async () => {
    // TODO: agregar parametro para traer determinas preguntas respondidas
    const response = await getCompletedSubChallegesQuestions(userId);
    if (response) {
      setCompletedSubChallengesQuestions(response.map(q => q.subChallengeQuestionId));
    }
  };

  const getUserCompletedQuestions = async (checkCompleteQuiz = false) => {
    const response = await getCompletedQuestions(userId);
    if (response) {
      setCompletedQuestions(response);
      if (checkCompleteQuiz) {
        isUserCompletedQuestionnaire(response);
      }
    }
  };

  const isUserCompletedQuestionnaire = async (completedQuestions) => {
    const completedQuestionsId = completedQuestions && completedQuestions.map(id => id.quizQuestionId);
    const progress = verifyProgress(questionnaire, completedQuestionsId);
    if (progress === 100) {
      const payload = {
        userId,
        quizId: questionnaire.id,
      };
      const response = await userCompletedQuizCreate(payload);
      if (response) {
        setQuizCompleted(true);
      }
    }
  };

  const handleChangeQuestionnaire = (selectedQuestionnaire) => {

    if (!selectedQuestionnaire) {
      setAnswers([]);
      setQuestionnaire(null);
      setShowQuestions(false);
      return
    }

    setChallengeObjectives(selectedQuestionnaire.quizObjective);
    setAnswers(Array(selectedQuestionnaire.questions?.length).fill(''));
    setQuestionnaire(selectedQuestionnaire);
    if (questionnaire?.id !== selectedQuestionnaire?.id) {
      setShowQuestions(true);
    } else {
      setShowQuestions(!showQuestions);
    }
  };

  const handleAnswer = async (questionId, e, questionIndex) => {
    e.preventDefault();
    const singleAnswer = answers[questionIndex];
    if (singleAnswer.length === 0) {
      toast.error('Por favor responda la pregunta');
      return;
    }
    setQuestionResultIsLoading(questionId);
    if (isSubChallenge) {
      const response = await verifyAnswer(questionId, singleAnswer);
      if (response === true) {
        await getUserSubchallengesAnwersQuestions();
        toast.success('Respuesta correcta');
      } else if (response === false) {
        toast.error('Respuesta incorrecta');
      } else {
        toast.error('Algo salio mal');
      }
      setQuestionResultIsLoading(0);
    } else {
      const response = await verifyQuizAnswer(questionId, singleAnswer);
      if (response === true) {
        await getUserCompletedQuestions(true);
        toast.success('Respuesta correcta');
      } else if (response === false) {
        toast.error('Respuesta incorrecta');
      } else {
        toast.error('Algo salio mal');
      }
      setQuestionResultIsLoading(0);
    }
  };

  const handleMultipleAnswers = async (questionId, e) => {
    e.preventDefault();
    setQuestionResultIsLoading(questionId);
    const questionAnswered = questionnaire?.questions?.filter((element) => element.id === questionId);
    const questionAnswers = multipleAnswers.filter((element) => questionAnswered[0].answers.find((item) => item.id === element));
    if (questionAnswers.length === 0) {
      setQuestionResultIsLoading(0);
      toast.error('Por favor responda la pregunta');
      return;
    }
    if (isSubChallenge) {
      const response = await verifyMultipleAnswers(questionId, questionAnswers, true);
      if (response === true) {
        await getUserSubchallengesAnwersQuestions();
        toast.success('Respuesta correcta');
      } else if (response === false) {
        toast.error('Respuesta incorrecta');
      } else {
        toast.error('Algo salio mal');
      }
      setQuestionResultIsLoading(0);
    } else {
      const response = await verifyQuizMultipleAnswers(questionId, questionAnswers);
      if (response === true) {
        await getUserCompletedQuestions(true);
        toast.success('Respuesta correcta');
      } else if (response === false) {
        toast.error('Respuesta incorrecta');
      } else {
        toast.error('Algo salio mal');
      }
      setQuestionResultIsLoading(0);
    }
  };

  const handleTextAnswerChange = (index, value) => {
    setAnswers(() => {
      const newAnswers = [...answers];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const handleMultipleChoiceChange = (event, questionIndex, optionId) => {
    setMultipleAnswers([optionId]);
  };

  const verifyProgress = (questionnaire, completedQuestionsId) => {
    let completedQuestion = 0;
    if (questionnaire?.questions?.length === 0) return null;
    if (questionnaire === null) return 0;
    questionnaire?.questions?.forEach((question) => {
      if (completedQuestionsId.includes(question.id)) {
        completedQuestion = completedQuestion + 1;
      }
    });
    const progressPercent = (completedQuestion * 100) / questionnaire.questions.length;
    return progressPercent;
  };

  const calculateItems = () => {
    const relateGuides = 1;
    const questionnaires = currentChallenge.quiz?.length;
    const subchallenges = subChallenges?.length;
    const totalItems = relateGuides + questionnaires + subchallenges;
    if (totalItems > 3) {
      return true;
    } else {
      return false;
    }
  };

  const sendReview = async () => {
    if (review === "") {
      toast.error("Debes escribir una reseña");
      return;
    } else if (review.length < 10) {
      toast.error("La reseña debe tener al menos 10 caracteres");
      return;
    } else if (rating === 0) {
      toast.error("Debes calificar el reto");
      return;
    }
    setReviewed(true);
    const response = await createChallengeRate(rating, review, challengeId, userId, token);
    if (response) {
      setGiveReview(false);
      toast.success("Gracias por tu reseña");
      Cookies.set("reviewed", JSON.stringify({ challengeId, userId }));
    } else {
      toast.error("Algo salio mal");
    }
  };

  const handleOpenObjectives = () => {
    setOpenObjectivesMenu(!openObjectivesMenu);
    setOpenNotesmenu(false);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      className='flex justify-center items-start'
      ref={ref}
    >
      {/* Loading */}
      {!currentChallenge && <LoadingSpinner />}

      {/* Particles */}
      <ParticlesBackground className="z-1" size={height} />

      {/* Progress bar */}
      {(showQuestions && questionnaire.questions.length) && (
        <div className='mx-auto md:max-w-full fixed -left-1 right-1 -bottom-1 z-40'>
          <LinearProgressWithLabel value={verifyProgress(questionnaire, completedQuestionsId)} />
        </div>
      )}

      {currentChallenge && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.2 }}
          className="min-h-box text-center py-4 mx-auto w-full max-w-[1280px]"
        >
          {/* Sidebar Objectives */}
          {showQuestions && (
            <AnimatePresence exitBeforeEnter={true} onExitComplete={() => null}>
              <motion.div
                variants={variants}
                initial={{ display: 'none', x: '-200%' }}
                animate={openObjectivesMenu ? 'opened' : 'closed'}
                exit={'closed'}
                transition={{ ease: 'easeInOut', duration: 0.25 }}
                className={`md:block fixed left-0 bottom-0 top-0 z-20 bg-white dark:bg-neutral-700 w-[320px] shadow-sidemenu overflow-y-scroll level-wrapper`}
              >
                <div className='relative top-12'>
                  <FontAwesomeIcon
                    className='block relative top-2 left-2 text-gray-700 dark:text-gray-100 hover:text-rose-600 hover:dark:text-rose-600 
                            m-2 text-xl xs:text-2xl cursor-pointer select-none transition-all duration-200 ease-in-out'
                    onClick={() => setOpenObjectivesMenu(false)}
                    icon={faTimes}
                  />
                  <h2 className='title text-2xl font-medium'>Objetivos</h2>
                  <ul className='flex flex-col items-center p-2'>
                    {challengeObjectives?.length &&
                      challengeObjectives.map((item, index) => (
                        <div key={index} className='font-medium w-full max-w-[300px] text-sm m-1 bg-gray-300 dark:bg-neutral-800 rounded-md'>
                          <li className="text-gray-700 dark:text-gray-100 p-2 text-left transition-all duration-200 ease-in-out flex items-center">
                            <div className="bg-rose-600 text-gray-700 dark:text-gray-100 rounded-full aspect-square flex justify-center items-center mr-2 text-base font-medium w-[30px]">
                              {index + 1}
                            </div>
                            <span className='w-full'>{item.description}</span>
                          </li>
                        </div>
                      ))}
                  </ul>
                </div>
              </motion.div>
            </AnimatePresence>
          )}

          {/* Sidebar Notes */}
          {
            <NotesSidebar
              opened={openNotesmenu}
              setOpened={setOpenNotesmenu}
              variants={variants}
            />
          }

          {/* Main content */}
          <div className="my-4 mx-2 overflow-x-hidden md:overflow-hidden snap-mandatory snap-x level-wrapper">
            <h3 className="title">{currentChallenge.title}</h3>

            <Swiper
              modules={[Navigation]}
              spaceBetween={0}
              initialSlide={0}
              slidesPerView={3}
              navigation
              onSlideChange={() => console.log('slide change')}
              className={`${calculateItems() ? "" : "center-content"}`}
            >
              {/* Related Guides */}
              {relatedGuides && relatedGuides[0] && (
                <SwiperSlide>
                  <div className="flex flex-col justify-start md:min-w-[260px] lg:min-w-[350px] selection-block z-0 pt-0 mt-0">
                    <p className={`${getClassByLevel(level)} py-5 text-lg mt-[-16px] w-[200px] text-white rounded-b-lg`}>
                      Guías relacionadas
                    </p>
                    {relatedGuides.length ? (
                      <>
                        <img
                          src={'/business_assets/Menu-Challenge-Guide-Questionary-Challenge/Guide-v2.webp'}
                          alt="header-img"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                          }}
                          className="p-2 max-w-[300px] max-h-[150px] mt-4"
                        />
                      </>
                    ) : null}
                    <div className='flex flex-col items-center flex-grow relative w-full'>
                      <ul className='mt-5'>
                        {/* TODO: ADD max 3 elements */}
                        {relatedGuides.length === 1 && !relatedGuides[0].guide ? (
                          <div className='h-full flex justify-center items-center'>
                            <p className="text-center text-xl">
                              No hay guías relacionados
                            </p>
                          </div>
                        ) : (
                          relatedGuides ? relatedGuides.filter(guide => guide.guide).map((guide, index) => {
                            const guideId = guide.guide?.id;
                            const guideLevel = calculateLevel(guide.guide?.difficultyOrder);
                            return (
                              <li key={index} className="py-3">
                                <Link to={`/guides/${guideLevel}/${guideId}`} className={`btn ${getClassByLevel(guideLevel)}`}>
                                  {guide?.guide?.name}
                                </Link>
                              </li>
                            );
                          }) : <LoadingSpinner size={"300px"} />
                        )}
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
              )}

              {currentChallenge?.quiz && currentChallenge?.quiz?.length > 0 && (
                currentChallenge.quiz?.map((quiz, index) => (
                  <SwiperSlide key={index}>
                    <QuestionnaireCard
                      level={level}
                      quizId={quiz?.id}
                      selectedQuestionnaireId={questionnaire?.id}
                      showQuestions={showQuestions}
                      setQuestionnaire={setQuestionnaire}
                      setShowQuestions={setShowQuestions}
                      handleChangeQuestionnaire={handleChangeQuestionnaire}
                      setIsSubChallenge={setIsSubChallenge}
                    />
                  </SwiperSlide>
                ))
              )}

              <div className={`max-h-[465px] z-0 ${subChallenges?.length > 1 ? 'scrollbar-subchallenge overflow-y-scroll' : ''}`}>
                {subChallenges?.length > 0 && (
                  subChallenges.map((subchallenge, index) => (
                    <SwiperSlide key={index}>
                      <SubChallengeCard
                        level={level}
                        currentChallenge={currentChallenge}
                        selectedSubchallengeId={questionnaire?.id}
                        showQuestions={showQuestions}
                        subchallenge={subchallenge}
                        index={index}
                        handleChangeQuestionnaire={handleChangeQuestionnaire}
                        setIsSubChallenge={setIsSubChallenge}
                      />
                    </SwiperSlide>
                  ))
                )}
              </div>
            </Swiper>

            {/* Questionnaire List */}
            <div className="mx-auto mt-8 md:max-w-[1200px]">
              <ResizablePanel showQuestions={showQuestions}>
                {showQuestions && questionnaire && (
                  <motion.div
                    className="sm:mt-0 mt-8 border px-8 pb-8 pt-2 border-gray-600 rounded-xl overflow-y-auto bg-slate-100 dark:bg-[rgb(48,48,48)]"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      scale: [0, 0.5, 1]
                    }}
                    transition={{
                      duration: 0.8,
                      ease: "easeInOut",
                      times: [0, 0.5, 1]
                    }}
                  >
                    <div className="block m-2 p-1 xl:m-1 xl:p-1">
                      <button
                        className={`${openObjectivesMenu
                          ? "text-rose-600 dark:text-rose-600"
                          : "text-gray-700 dark:text-gray-100"
                          } absolute top-0 left-0 text-lg xs:text-xl border-none cursor-pointer select-none transition-all ease-in-out duration-200 p-2 rounded-lg`}
                        onClick={handleOpenObjectives}
                      >
                        <CheckBoxOutlinedIcon fontSize="large" />
                      </button>
                      <button
                        className={`${openNotesmenu
                          ? "text-rose-600 dark:text-rose-600"
                          : "text-gray-700 dark:text-gray-100"
                          } absolute top-0 left-[50px] text-lg xs:text-xl border-none cursor-pointer select-none transition-all ease-in-out duration-200 p-2 rounded-lg`}
                        onClick={() => {
                          setOpenObjectivesMenu(false);
                          setOpenNotesmenu(!openNotesmenu);
                        }}
                      >
                        <EditNoteIcon fontSize="large" />
                      </button>
                    </div>

                    <QuestionList
                      questionnaire={questionnaire}
                      answers={answers}
                      multipleAnswers={multipleAnswers}
                      questions={questionnaire.questions}
                      completedQuestionsId={isSubChallenge ? completedSubChallengesQuestions : completedQuestionsId}
                      questionResultIsLoading={questionResultIsLoading}
                      isSubChallenge={isSubChallenge}
                      handleAnswer={handleAnswer}
                      handleMultipleAnswers={handleMultipleAnswers}
                      handleTextAnswerChange={handleTextAnswerChange}
                      handleMultipleChoiceChange={handleMultipleChoiceChange}
                    />
                  </motion.div>
                )}
              </ResizablePanel>
            </div>

            {/* rating and review feature */}
            <div className={`flex flex-row items-center justify-center mt-6 ${showQuestions ? "" : "hidden"}`}>
              <button
                className={`w-[200px] mx-6 ${reviewed ? "btn-disabled" : "btn"}`}
                onClick={() => setGiveReview(!giveReview)}
                disabled={reviewed}
              >
                {reviewed ? "Muchas gracias por tu opinión!" : "Dejanos tu opinión sobre este reto"}
              </button>
            </div>
            {giveReview && (
              <div className={`flex flex-col items-center justify-center mt-6 ${showQuestions ? "" : "hidden"}`}>
                <h3 className='text-xl font-bold text-white'>Dejanos tu opinión sobre este reto</h3>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                <textarea
                  id="message"
                  rows="4"
                  onChange={(e) => setReview(e.currentTarget.value)}
                  className="block p-2.5  w-1/2 mt-4 text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Comente que le parecio el reto"
                />
                <button className="btn mt-4" onClick={sendReview}>Enviar</button>
              </div>
            )}
          </div>

          {/* Complete quiz modal */}
          <CompleteQuizModal
            title={questionnaire?.name}
            modal={quizCompleted}
            setModal={setQuizCompleted}
          />
        </motion.div>
      )}
    </motion.div>
  )
}

export default ChallengeInfo;
