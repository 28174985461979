import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import LoadingSpinner from "../common/loadingSpinner";
import edFunc from "../admin/editorFunctions"
import VideoComponent from "../common/VideoComponent";

function PathsList() {
  const { id } = useParams();
  const [guides, setGuides] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (guides?.length !== 0) {
        setSpinner(false)
      } else {
        fetchRelatedGuidesAndChallenges()
      }

    }
    return () => {
      isMounted = false;
    }
  }, [])

  const fetchRelatedGuidesAndChallenges = async () => {
    let element = await edFunc.HandleGetRelatedGuidesAndChallenges(id)
    if (element) {
      // Remove duplicated guides by id 

      const ids = new Set();

      const filteredGuides = element?.filter((guide) => {
        if (!ids.has(guide.id)) {
          ids.add(guide.id);
          return true;
        }
        return false;
      });

      if (!filteredGuides && filteredGuides?.length === 0) {
        setGuides(element)
        return
      }

      element = filteredGuides

      setGuides(element)
    } else {
      setGuides([])
    }
    setSpinner(false)
  }

  const beginnerGuides = guides && guides?.filter(guide => guide.difficultyLevel?.internalCode === "L001")
  const intermediateGuides = guides && guides?.filter(guide => guide.difficultyLevel?.internalCode === "L002")
  const advancedGuides = guides && guides?.filter(guide => guide.difficultyLevel?.internalCode === "L003")

  const variants = {
    hover: {
      scale: 1.05,
      transition: {
        ease: "easeInOut",
        duration: 0.15,
        delay: 0,
      },
    },
  };

  return (
    <div className="flex flex-wrap justify-center items-start min-h-box h-full">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        className="min-h-box text-center px-2 py-4"
      >
        {spinner ? (
          <LoadingSpinner size={"300px"} />
        ) : (
          <>

            <h4 className="title mt-10 mb-10 text-red-200">Principiante</h4>
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-2 my-6 max-w-[1280px] mb-20">
              {beginnerGuides?.length > 0 ? beginnerGuides.map((item, index) => {
                return (
                  <div key={index}>
                    <motion.div
                      variants={variants}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          ease: "easeInOut",
                          duration: 0.15,
                          delay: index * 0.05,
                        },
                      }}
                      whileHover={"hover"}
                      key={index}
                      className="container-block-col justify-between max-w-xs min-h-[250px] mx-auto w-full rounded-md shadow-md h-full"
                    >
                      <div>
                        {
                          <VideoComponent
                            imageThumbnailPath={item.imageThumbnailPath}
                            videoThumbnailPath={item.videoThumbnailPath}
                          />
                        }
                      </div>
                      <div className="p-2">
                        <h3 className="m-2 font-semibold text-sm xs:text-base">
                          {
                            item.type === "challenge" ? item?.title : item?.name
                          }
                        </h3>
                        <p className="m-2 font-medium text-xs xs:text-sm text-center">
                          {item.description}
                        </p>
                        <div className="my-4">
                          <Link
                            className="btn"
                            to={`/${item.type === "challenge" ? "challenges" : "guides"}/Principiante/${item.id}`}
                          >
                            {
                              item.type === "challenge" ? "Ver reto" : "Ver guía"
                            }
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                )
              }) : null}
            </div>
            {beginnerGuides.length === 0 && <p className="mb-20 text-slate-300">Actualmente no hay guías para este nivel</p>}
            <hr />
            <h4 className="title mt-10 mb-10 text-red-200">Intermedio</h4>
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-2 my-6 max-w-[1280px] mb-20">
              {intermediateGuides.length > 0 ? intermediateGuides.map((item, index) => {
                return (
                  <div key={index} className="h-full">
                    <motion.div
                      variants={variants}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          ease: "easeInOut",
                          duration: 0.15,
                          delay: index * 0.05,
                        },
                      }}
                      whileHover={"hover"}
                      key={index}
                      className="container-block-col h-full justify-between max-w-xs min-h-[250px] mx-auto w-full rounded-md shadow-md"
                    >
                      <div>
                        <VideoComponent
                          imageThumbnailPath={item.imageThumbnailPath}
                          videoThumbnailPath={item.videoThumbnailPath}
                        />
                      </div>
                      <div className="p-2">
                        <h3 className="m-2 font-semibold text-sm xs:text-base">
                          {item.name || item.title}
                        </h3>
                        <p className="m-2 font-medium text-xs xs:text-sm text-center">
                          {item.description}
                        </p>
                        <div className="my-4">
                          <Link
                            className="btn"
                            to={`/guides/Intermedio/${item.id}`}
                          >
                            Ver guía
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                )
              }) : null}
            </div>
            {intermediateGuides.length === 0 && <p className="mt-[-50px] mb-20 text-slate-300">Actualmente no hay guías para este nivel</p>}
            <hr />
            <h4 className="title mt-10 mb-10 text-red-200">Avanzado</h4>
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-2 my-6 max-w-[1280px]">
              {advancedGuides?.length > 0 ? advancedGuides.map((item, index) => {
                return (
                  <div key={index}>
                    <motion.div
                      variants={variants}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          ease: "easeInOut",
                          duration: 0.15,
                          delay: index * 0.05,
                        },
                      }}
                      whileHover={"hover"}
                      key={index}
                      className="container-block-col justify-between max-w-xs min-h-[250px] mx-auto w-full rounded-md shadow-md h-full"
                    >
                      <div className="flex justify-center">
                        <VideoComponent
                          imageThumbnailPath={item.imageThumbnailPath}
                          videoThumbnailPath={item.videoThumbnailPath}
                        />
                      </div>
                      <div className="p-2">
                        <h3 className="m-2 font-semibold text-sm xs:text-base">
                          {item.name || item.title}
                        </h3>
                        <p className="m-2 font-medium text-xs xs:text-sm text-center">
                          {item.description}
                        </p>
                        <div className="my-4">
                          <Link
                            className="btn"
                            to={`/guides/Avanzado/${item.id}`}
                          >
                            {
                              item.type === "challenge" ? "Ver reto" : "Ver guía"
                            }
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                )
              }) : null}
            </div>
            {advancedGuides.length === 0 && <p className="mb-20 text-slate-300">Actualmente no hay guías para este nivel</p>}
          </>
        )}
      </motion.div>
    </div>
  );
}

export default PathsList;