import { toast } from 'react-toastify';
import {
  getChallenge, createChallenge, updateChallenge, getChallengeList, getLevelList, deleteChallenge, deleteQuiz, createQuiz, updateQuiz, getQuiz, getQuizList, updateQuestion,
  deleteSubChallengeQuestion, createQuizObjective, deleteQuizObjective, getSubChallenge, deleteSubChallenge, getSubChallengeList, getSubChallengeTypeList,
  createSubChallenge, updateSubChallenge, deleteQuizQuestion, createQuestion, createSubchallengeQuestion, updateSubChallengeQuestion
} from '../../services/challengesService'

// challenge functions
const handleGetChallenge = async (fieldId) => {

  const element = await getChallenge(fieldId);
  if (!element) return

  const emptyFields = {
    id: element.id,
    title: element.title,
    description: element.description,
    imageThumbnailPath: element.imageThumbnailPath,
    videoThumbnailPath: element.videoThumbnailPath,
    headerDesktopPath: element.headerDesktopPath,
    headerMobilePath: element.headerMobilePath,
    difficultyOrder: element.difficultyOrder,
    isPublished: element.isPublished,
    isPremium: element.isPremium,
    assignmentTypeId: element.assignmentTypeId,
    difficultyLevelId: element.difficultyLevel.id,
    tagsId: [],
    guidesId: [],
    subChallenge: element.subChallenge,
    quiz: element.quiz,
    scoreType: element.scoreType?.id
  };

  if (element.challengeTagLink?.length) {
    element.challengeTagLink.forEach((element) => {
      if (!element?.tag?.id) return
      emptyFields.tagsId.push(element?.tag?.id)
    })
  }

  if (element.challengeGuideLink?.length) {
    element.challengeGuideLink.forEach((element) => {
      if (!element?.guide?.id) return
      emptyFields.guidesId.push(element?.guide?.id)
    })
  }

  return emptyFields
};

const HandleGetChallengeList = async (setChallenge, setSpinner, token) => {
  const element = await getChallengeList(token)
  if (element) {
    setChallenge(element)
    setSpinner(true)
  }
  else {
    setChallenge([])
    setSpinner(false)
  }
};

const HandleUpdateChallenge = async (fields, challengeId, token) => {
  const newFields = {
    title: fields.title,
    description: fields.description,
    imageThumbnailPath: fields.imageThumbnailPath || "www.imageThumbnailPath.com",
    videoThumbnailPath: fields.videoThumbnailPath || "www.videoThumbnailPath.com",
    headerDesktopPath: fields.headerDesktopPath || "www.headerDesktopPath.com",
    headerMobilePath: fields.headerMobilePath || "www.headerMobilePath.com",
    difficultyOrder: fields.difficultyOrder,
    isPublished: fields.isPublished,
    isPremium: fields.isPremium,
    difficultyLevelId: fields.difficultyLevelId,
    assignmentTypeId: fields.assignmentTypeId,
    guidesId: fields.guidesId,
    tagsId: fields.tagsId
  };
  const updated = await updateChallenge(newFields, challengeId, token)

  if (updated) {
    toast.success("Reto actualizado")
    return updated
  } else {
    toast.error("No se pudo actualizar el Reto, pruebe otra vez")
    return null
  }

};
const HandleCreateChallenge = async (fields) => {
  const challenge = {
    title: fields.title,
    description: fields.description,
    imageThumbnailPath: fields.imageThumbnailPath || "www.imageThumbnailPath.com",
    videoThumbnailPath: fields.videoThumbnailPath || "www.videoThumbnailPath.com",
    headerDesktopPath: fields.headerDesktopPath || "www.headerDesktopPath.com",
    headerMobilePath: fields.headerMobilePath || "www.headerMobilePath.com",
    difficultyOrder: fields.difficultyOrder,
    isPublished: fields.isPublished,
    isPremium: fields.isPremium,
    difficultyLevelId: fields.difficultyLevelId,
    assignmentTypeId: fields.assignmentTypeId,
    guidesId: fields.guidesId,
    tagsId: fields.tagsId,
    scoreType: fields.scoreType
  };

  const newChallenge = await createChallenge(challenge)
  if (newChallenge) {
    toast.success("Reto creado")
    return newChallenge
  } else {
    toast.error("No se pudo crear el Reto, pruebe otra vez")
    return null
  }

};
const handleChangeInt = (e, element, setFields, fields) => {
  const text = e.currentTarget.value;
  const newFields = { ...fields };
  newFields[element] = parseInt(text);
  setFields(newFields);
};
const handleAnswer = (e, setFields, fields, index) => {
  const text = e.currentTarget.value;
  fields[index].answer = text;
  setFields(fields);
};
const handleAnswerFlag = (evt, setFields, fields, index) => {
  const flag = evt.currentTarget.value;
  if (flag === "true") {
    fields[index].isTrueAnswer = true
  } else {
    fields[index].isTrueAnswer = false
  }
  setFields(fields);
};
const HandleGetLevelList = async (setLevels, token) => {
  const element = await getLevelList(token)
  setLevels(element)
};
const handleDelete = async (ChallengeId, quiz, subChallenge, setModal, token) => {
  if (quiz) {
    quiz.forEach((element) => deleteQuiz(element.id))
  }
  if (subChallenge) {
    subChallenge.forEach((element) => deleteSubChallenge(element.id))
  }
  await deleteChallenge(ChallengeId, token)
};

// quiz functions
const handleGetQuiz = async (quizId, setFields) => {
  const element = await getQuiz(quizId)
  const newFields = {
    id: element.id,
    name: element.name,
    description: element.description,
    imgPath: element.imgPath,
    index: element.index,
    challengeId: element.challengeId,
    quizObjective: [...element.quizObjective],
    questions: [],
  };
  element.quizQuestion.forEach((item) => {
    const newQuistion = {
      id: item.id,
      question: item.question,
      hint: item.hint,
      questionTypeId: item.questionType?.id ?? 1,
      scoreTypeId: item.scoreTypeId,
      answers: item.quizAnswer,
    }
    newFields.questions.push(newQuistion)
  })
  setFields(newFields)
}
const handleGetQuizList = async (challengeId, setQuizList, setSpinner, token) => {
  const element = await getQuizList(challengeId, token)
  setQuizList(element)
  setSpinner(false)
}

const handleQuizDelete = async (quizId, token) => {
  const deleted = await deleteQuiz(quizId, token)
  if (deleted) {
    toast.success("Quiz eliminado")
  } else {
    toast.error("No se pudo eliminar el Quiz, pruebe otra vez")
  }
}

const handleUpdateQuiz = async (quiz, token) => {
  const updated = await updateQuiz(quiz, token)
  if (updated) {
    toast.success("Quiz actualizado")
    return updated
  } else {
    toast.error("No se pudo actualizar el Quiz, pruebe otra vez")
    return null
  }
}
const handleNewQuiz = async (quiz, token) => {
  const payload = {
    name: quiz.name,
    description: quiz.description,
    imgPath: quiz.imgPath,
    questions: [],
    challengeId: quiz.challengeId,
    scoreTypeId: quiz.scoreTypeId
  }


  const newQuiz = await createQuiz(payload, token);
  return newQuiz;
}
const HandleAddQuizObjective = async (content, quizId, token) => {
  const objective = {
    description: content,
    quizId
  }
  const newObjective = await createQuizObjective(objective, token)
  return newObjective
};
const HandleDeleteQuizObjective = async (objectiveId) => {
  await deleteQuizObjective(objectiveId)
};
const HandleDeleteQuizQuestion = async (questionId, token) => {
  const res = await deleteQuizQuestion(questionId, token)
  return res
}
const HandleCreateQuizQuestion = async (question, answer, token) => {
  const newQuestion = await createQuestion(question, answer, token)
  return newQuestion
}
const HandleUpdateQuizQuestion = async (question, answer, questionId, documentId) => {
  const newQuestion = await updateQuestion(question, answer, questionId, documentId)
  return newQuestion
}


// subChallenge functions
const handleGetSubChallenge = async (SubChallengeId, setFields) => {
  const element = await getSubChallenge(SubChallengeId)

  if (element) {
    const newFields = {
      id: element.id,
      name: element.name,
      labUrl: element.labUrl,
      imgPath: element.imgPath,
      description: element.description,
      challengeId: element.challengeId,
      subChallengeTypeId: element.subChallengeType?.id,
      instructions: element.instructions,
      questions: [],
    };
    element.subChallengeQuestion?.forEach((item) => {
      const newQuistion = {
        id: item.id,
        question: item.question,
        hint: item.hint,
        subChallengeTypeId: item.questionType?.id,
        scoreTypeId: item.scoreTypeId,
        answers: item.subChallengeAnswer,
      }
      newFields.questions.push(newQuistion)
    })
    setFields(newFields)
  } else {
    setFields(null)
  }

}
const handleGetSubChallengeList = async (challengeId, setSubChallengeList, token) => {
  const element = await getSubChallengeList(challengeId, token)
  
  if(!element) {
    setSubChallengeList([])
    return
  }

  setSubChallengeList(element)
}
const handleSubChallengeDelete = async (subChallengeId) => {
  const response = await deleteSubChallenge(subChallengeId)
  return response
}
const handleUpdateSubChallenge = async (subChallenge) => {
  const response = await updateSubChallenge(subChallenge)
  return response
}
const HandleGetSubChallengeTypeList = async (setSubChallengeTypes) => {
  const response = await getSubChallengeTypeList()
  if (response) {
    setSubChallengeTypes(response)
  } else {
    setSubChallengeTypes([])
  }
};
const handleNewSubChallenge = async (subChallenge) => {
  const newSubChallenge = await createSubChallenge(subChallenge);
  return newSubChallenge;
}
const HandleDeleteSubChallengeQuestion = async (questionId, token) => {
  const response = await deleteSubChallengeQuestion(questionId, token)
  return response
}
const HandleCreateSubChallengeQuestion = async (question, answer) => {
  const newQuestion = await createSubchallengeQuestion(question, answer)
  return newQuestion
}
const HandleUpdateSubChallengeQuestion = async (question, answer, questionId, documentId, token) => {
  const newQuestion = await updateSubChallengeQuestion(question, answer, questionId, documentId, token)
  return newQuestion
}

const handleChangeFile = (e, element, setFields, fields) => {
  const text = e.currentTarget.value;
  const newFields = { ...fields };
  newFields[element] = btoa(text);
  setFields(newFields);
};

const editorFunctions = {
  HandleUpdateChallenge,
  handleChangeInt,
  handleDelete,
  handleGetChallenge,
  HandleGetChallengeList,
  HandleGetLevelList,
  handleAnswer,
  handleAnswerFlag,
  handleNewQuiz,
  handleUpdateQuiz,
  handleGetQuiz,
  handleGetQuizList,
  handleQuizDelete,
  HandleAddQuizObjective,
  HandleDeleteQuizObjective,
  handleGetSubChallenge,
  handleGetSubChallengeList,
  HandleCreateSubChallengeQuestion,
  HandleDeleteSubChallengeQuestion,
  handleSubChallengeDelete,
  HandleGetSubChallengeTypeList,
  handleNewSubChallenge,
  handleUpdateSubChallenge,
  HandleDeleteQuizQuestion,
  HandleCreateQuizQuestion,
  HandleUpdateQuizQuestion,
  handleChangeFile,
  HandleUpdateSubChallengeQuestion,
  HandleCreateChallenge,
};

export default editorFunctions