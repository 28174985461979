import { toast } from 'react-toastify'
import store from '../redux/store';
import { userActions } from "../redux/slices/userSlice";
// import { useSelector } from 'react-redux'
import {
  USER_URL, USER_URL_SIGNIN, USER_URL_GET, COUNTRY_URL_LIST, PROFESSION_URL_LIST, USER_URL_SCOREBOARD, SOCIALMEDIA_URL,
  QUIZ_URL_GET,
  CHALLENGE_URL_GET,
  USER_URL_UPDATE,
  USER_URL_CREATE
} from './apiRoutes';
import Cookies from "js-cookie"
import { makeRequest } from './makeRequest';

const baseUrl = process.env.REACT_APP_BASE_URL_API || 'https://api.hackctf.org'

export async function register(name, username, email, password, countryId, userProfessionId, age, gender) {

  const response = await makeRequest('POST', `${USER_URL_CREATE}`, {
    auth: false,
    payload: {
      name,
      username,
      email,
      password,
      countryId: parseInt(countryId),
      userProfessionId,
      age: parseInt(age),
      gender
    }
  })
  
  if (!response) {
    toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
    return null
  }
  else if (response?.message === "User already exists") {
    toast.error("El usuario ya existe");
    return null
  } else {
    toast.success("Usuario registrado con éxito.");
    return response
  }

}

export function logout() {
  store.dispatch(userActions.resetStore())
}

export async function login(username, password) {

  const response = await makeRequest('POST', `${USER_URL_SIGNIN}`, {
    auth: true,
    payload: {
      username,
      password
    }
  })

  if (!response) {
    return null
  }
  else if (response?.message === "Incorrect password or username") {
    toast.error("Contraseña o usuario incorrecto");
    return null
  } else {
    const threeHours = new Date(new Date().setHours(new Date().getHours() + 3));

    Cookies.set('token', response.result.AccessToken, { expires: threeHours });
    Cookies.set('refreshtoken', response.result.RefreshToken, { expires: threeHours });
    store.dispatch(userActions.setToken(response.result.AccessToken))
    store.dispatch(userActions.setAuthData(username))
    store.dispatch(userActions.setUserData(response.result.user))
  }

  return response

}

export async function confirmVerification(username, code) {

  const response = await fetch(`${baseUrl}/confirm-signup`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      username,
      code,
    })
  })

  if (response.ok) toast.success('Código verificado con éxito.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");

  const res = response.json()
  return res
}

export async function resendCode(username) {

  const response = await fetch(`${baseUrl}/resend-confirmation-code`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      username,
    })
  })

  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");

  const res = response.json()
  return res
}

export async function getNewPasswordCode(username) {
  try {
    const response = await fetch(`${baseUrl}/forgot-password`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        username,
      })
    });

    if (response.ok) {
      toast.success('Te hemos enviado un email con instrucciones.');
      return true;
    } else if (response.status === 403) {
      toast.error('Por favor revisa tu email y verifica tu usuario.');
    } else {
      toast.error('Ha ocurrido un problema. Por favor intente de nuevo.');
    }

    // const data = await response.json();
    return false;

  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}


export async function changePassword(username, newPassword, verificationCode) {

  const response = await fetch(`${baseUrl}/confirm-password`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      username,
      newPassword,
      verificationCode
    })
  })

  if (response.ok) toast.success('Tu contraseña fue actualizada.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");

  const res = response.json()
  return res
}

// Profile services

export async function changePasswordFromProfile(oldPassword, newPassword, token) {

  const response = await fetch(`${baseUrl}/change-password`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      oldPassword,
      newPassword,
      jwt: token,
    })
  })

  if (response.ok) toast.success('Tu contraseña ha sido actualizada.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");

  const res = response.json()
  return res
}

export async function addSocials(data, token) {

  const response = await fetch(`${SOCIALMEDIA_URL}`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  })

  if (response.ok) toast.success('Redes agregadas con éxito.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");

  const res = response.json()
  return res
}

export async function uploadProfilePicture(image, token) {

  const response = await makeRequest('POST', `${baseUrl}/picture`, {
    auth: true,
    payload: {
      file: image
    }
  })

  if (response) {
    toast.success('Foto agregada con éxito.');
    return response.result
  } else {
    toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
    return null
  }
};

export async function updateProfileData(payload, userId) {

  const response = await makeRequest('PUT', `${USER_URL_UPDATE}/${userId}`, {
    auth: true,
    payload
  })

  if (response) {
    toast.success('Datos modificados con éxito.');
    return response.result
  } else {
    toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");
    return null
  }
};

export async function updateSocialMedia(socials, token) {

  const response = await fetch(`${SOCIALMEDIA_URL}`, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "twitter": socials.twitter,
      "github": socials.github,
      "linkedin": socials.linkedin,
      "instagram": socials.instagram,
      "linktree": socials.linktree,
    })
  })

  if (response.ok) toast.success('Redes actualizadas con éxito.');
  if (!response.ok) toast.error("Ha ocurrido un problema. Por favor intente de nuevo.");

  const res = response.json()
  return res
};

export const getChallenges = async (id) => {
  const response = await makeRequest('GET', `${CHALLENGE_URL_GET}/${id}`, {
    auth: true
  })
  
  if (response && response?.status === 200) {
    return response.result
  }

  return null
}

export const getQuiz = async (id) => {
  const response = await makeRequest('GET', `${QUIZ_URL_GET}/${id}`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }

  return null
}

export async function getCompletedQuestions(id) {

  const response = await makeRequest('GET', `${USER_URL_GET}/${id}/completedQuestions`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }
}

export async function getCompletedSubChallegesQuestions(id) {

  const response = await makeRequest('GET', `${USER_URL_GET}/${id}/completedSubchallengeQuestions`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }
}

export async function getCountryList(token) {

  const response = await makeRequest('GET', `${COUNTRY_URL_LIST}?paginate=false`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }
};

export async function getProfessionsList(token) {

  const response = await makeRequest('GET', `${PROFESSION_URL_LIST}?paginate=false`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }


};

export async function getUserStats(id, token) {
  const response = await makeRequest('GET', `${USER_URL_GET}/${id}/stats`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }

};

export async function getUser(id) {

  const response = await makeRequest('GET', `${USER_URL_GET}/${id}`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }

};
export async function getUserStreak(token) {


  const response = await makeRequest('GET', `${USER_URL}/streak`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }
};

export async function getScoreboardList(token) {

  const response = await makeRequest('GET', `${USER_URL_SCOREBOARD}?paginate=false`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }
};

export async function getUserReadGuides(id) {
  const response = await makeRequest('GET', `${USER_URL_GET}/${id}/readGuides`, {
    auth: true
  })
  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }
};
