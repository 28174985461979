import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const variants = {
    opened: {
        opacity: 1,
        display: 'flex'
    },
    closed: {
        opacity: 0,
        transitionEnd: {
            display: 'none'
        }
    }
}

const createObjectiveModal = ({
    documentId,
    modal,
    setModal,
    fields,
    setFields,
    element,
    objcreateFunc,
    objdeleteFunc,
    token
}) => {

    const [input, setInput] = useState("")
    const [objectives, setObjectives] = useState(fields?.guideObjective?.length ? fields?.guideObjective : [])
    const [loadingSpinner, setLoadingSpinner] = useState(false)

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    useEffect(() => {
        if (fields?.guideObjective) {
            setObjectives(fields?.guideObjective)
        }
    }, [fields])

    const handlecreateObjective = async (e) => {
        e.preventDefault();
        if (!input) {
            toast.error("Ingresa un objetivo")
            return
        }

        setLoadingSpinner(true)
        const newObjective = await objcreateFunc(input, documentId, token);
        if (newObjective.id) {
            setLoadingSpinner(false)
            setObjectives([...objectives, newObjective]);
            setInput("")
        }
    }

    const handleDeleteObjective = async (objectiveId) => {
        objdeleteFunc(objectiveId, setObjectives)
        setObjectives(objectives.filter(item => item.id !== objectiveId))
    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
        >
            <div className="absolute p-[40px] bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md w-full sm:w-2/4">
                <h1 className="text-2xl font-medium mb-8">
                    Agregar Objetivo
                </h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        fields[element] = objectives;
                        setFields(fields)
                        setModal(false);
                    }}>
                    <CloseIcon />
                </button>
                <form className='flex' onSubmit={handlecreateObjective}>
                    <input
                        name='newObjective'
                        className='w-full ml-4 my-2 px-2 transition-all editor-box duration-200 ease-in-out text-gray-700'
                        type='text'
                        value={input}
                        placeholder='Nuevo Objetivo'
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button className='btn' type='submit'>{!loadingSpinner ? "Agregar" :
                        <div className='p-1 mx-4 flex justify-center items-center text-center'>
                            <div className='spinner w-5 h-5 rounded-full border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                        </div>
                    }</button>
                </form>
                <div className='flex flex-col gap-2 '>
                    {objectives?.map((item, index) => {

                        return (
                            <div className='flex editor-fields' key={index}>

                                <label className="editor-fields-label flex-none">Objetivo {index + 1}</label>
                                <p className='flex items-center m-2 py-1 w-full'>{item?.description}</p>
                                <button className='btn' onClick={() => handleDeleteObjective(item.id, index)}>Borrar</button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </motion.div>
    )
}

export default createObjectiveModal
