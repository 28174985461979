import { motion, AnimatePresence } from "framer-motion";
import SponsorsSection from "./sponsors";
import ParticlesBackground from "./particlesBackground";
import { useTranslation } from "react-i18next";

function MainContent() {
  const [t] = useTranslation("global");

  const variants = {
    initial: {
      opacity: 0,
      y: -40,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: { ease: "easeInOut", duration: 0.3 },
    },
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="relative flex flex-col justify-center items-center text-center w-full min-h-box h-[95vh] p-2">
        <ParticlesBackground className="z-0" />
        <motion.h3
          variants={variants}
          initial={"initial"}
          animate={"animate"}
          transition={"transition"}
          className="title font-bold"
        >
          {t("main.welcomeText")}
        </motion.h3>
        <motion.p
          variants={variants}
          initial={"initial"}
          animate={"animate"}
          transition={"transition"}
          className="text-base xs:text-lg md:text-xl text-white font-semibold max-w-5xl"
        >
          {t("main.introduction")}
        </motion.p>
      </div>
      <AnimatePresence exitBeforeEnter>
        <SponsorsSection />
      </AnimatePresence>
    </div>
  );
}

export default MainContent;
