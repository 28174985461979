import { motion } from "framer-motion";
import { getChallenge } from "../../services/challengesService";
import { settingsActions } from "../../redux/slices/settingsSlice";
import { useDispatch } from "react-redux";

function DeleteModal(props) {
  const { element, deletefunc, documentId, documents, setDocuments, modal, setModal, mode, token } = props;
  const dispatch = useDispatch();

  const variants = {
    opened: {
      opacity: 1,
      display: "flex",
    },
    closed: {
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
    },
  };

  const handleClickDelete = async () => {
    const newDocumentList = documents.filter(item => item.id !== documentId)
    
    if (mode === "guide") {
      await deletefunc(documentId)
      dispatch(settingsActions.setGuides(newDocumentList))
    } else if (mode === "challenge") {
      const element = getChallenge(documentId, token)
      await deletefunc(documentId, element.quiz, element.subChallenge)
      dispatch(settingsActions.setChallenges(newDocumentList))
    } else {
      await deletefunc(documentId)
      dispatch(settingsActions.setLearningPaths(newDocumentList))
    }

    setDocuments(newDocumentList)
    setModal(false)
  }

  return (
    <motion.div
      variants={variants}
      initial={{ display: "none", opacity: 0 }}
      animate={modal ? "opened" : "closed"}
      transition={{ ease: "easeInOut", duration: 0.1 }}
      className={`${
        /*                 modal ? "flex" : "hidden" */ ""
        } justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30`}
    >
      <div className="p-4 m-2 text-center bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md">
        <h3>
          ¿Está seguro de que desea borrar {mode === "guide" ? "la " : "el "}{mode} &quot;{element}&quot;?
        </h3>
        <p className="my-2">Los cambios son permanentes</p>
        <button
          className="btn m-2 bg-[#C36B96] hover:bg-red-800"
          onClick={() => handleClickDelete()}
        >
          Borrar
        </button>
        <button className="btn m-2 bg-[#3E73B9] hover:bg-blue-800" onClick={() => setModal(false)}>
          Cancelar
        </button>
      </div>
    </motion.div>
  );
}

export default DeleteModal;
