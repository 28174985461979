import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import edFunc from "../admin/editorFunctions";
import edChallFunc from "../admin/challengeEditorFunctions";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const variants = {
    opened: {
        opacity: 1,
        display: 'flex'
    },
    closed: {
        opacity: 0,
        transitionEnd: {
            display: 'none'
        }
    }
}


function CreateQuestionModal({
    documentId,
    modal,
    setModal,
    questCreateFunc,
    questionsList,
    setQuestionsList,
    token,
    getQuizzes,
    setFields,
    scoreTypesState
}) {

    const questionFields = {
        question: {
            question: "",
            hint: "",
            questionTypeId: 0,
            scoreTypeId: 2,
            answers: [],
        },
        scoreTypeId: 2,
        documentId
    }

    const [question, setQuestion] = useState(questionFields)
    const [answer, setAnswer] = useState([])
    const [spinnerEnable, setSpinnerEnable] = useState(false);
    const [optionsMultiples, setOptionsMultiples] = useState([]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])



    const handleChangeQuestionType = (evt) => {
        const { value } = evt.target

        setOptionsMultiples([])

        if (value === "2") {
            setAnswer([])
        }
        else if (value === "3") {
            const aux = [
                {
                    answer: "",
                    isTrueAnswer: false
                },
                {
                    answer: "",
                    isTrueAnswer: false
                }
            ]

            setAnswer(aux)
        } else {
            setAnswer([
                {
                    answer: "",
                    isTrueAnswer: true
                }
            ])
        }

        edFunc.handleChangeInt(evt, "questionTypeId", setQuestion, question)
    }

    const handleCreateQuestion = async () => {

        if (!question.questionTypeId) {
            toast.error("Selecciona un tipo de pregunta")
            setSpinnerEnable(false)
            return false
        }

        if (question.question.question?.trim() === "") {
            toast.error("La pregunta no puede estar vacia")
            setSpinnerEnable(false)
            return false
        }
        else if (question.question.question?.trim()?.length < 4) {
            toast.error("La pregunta debe tener al menos 4 caracteres")
            setSpinnerEnable(false)
            return false
        }
        else if (!question.hint && question.hint?.trim() !== "") {
            toast.error("La pista no puede estar vacia")
            setSpinnerEnable(false)
            return false
        }

        if (question.questionTypeId === 1) {
            createDefaultAnswer()
        }
        else if (question.questionTypeId === 2) {
            createMultipleAnswer()
        }
        else {
            createTrueOrFalseAnswer()
        }

    }

    const createDefaultAnswer = async () => {
        const newAnswer = [...answer];

        if (answer[0] && answer[0].answer?.trim() === "") {
            toast.error("La respuesta no puede estar vacia")
            setSpinnerEnable(false)
            return
        }

        await createQuestion(question, newAnswer, token);
    }

    const createMultipleAnswer = async () => {
        const newAnswer = [...answer];
        
        if (optionsMultiples?.length < 2) {
            toast.error("Debes agregar al menos 2 opciones")
            setSpinnerEnable(false)
            return
        }
        else if (newAnswer.some((item) => item.answer?.trim() === "")) {
            toast.error("Las respuestas no pueden estar vacias")
            setSpinnerEnable(false)
            return
        } else if (!answer.some((item) => item.isTrueAnswer === true)) {
            toast.error("Debe seleccionar una respuesta correcta")
            setSpinnerEnable(false)
            return
        }

        await createQuestion(question, newAnswer, token);
    }

    const createTrueOrFalseAnswer = async () => {
        const newAnswer = [...answer];

        if(newAnswer.some((item) => item.answer?.trim() === "")) {
            toast.error("Las respuestas no pueden estar vacias")
            setSpinnerEnable(false)
            return
        }
        else if (!answer.some((item) => item.isTrueAnswer === true)) {
            toast.error("Debe seleccionar una respuesta correcta")
            setSpinnerEnable(false)
            return
        }

        await createQuestion(question, newAnswer, token);
    }

    const createQuestion = async (question, newAnswer) => {

        const newQuestion = await questCreateFunc(question, newAnswer);
        if (newQuestion) {
            toast.success("Pregunta Creada")
            setQuestionsList([...questionsList, {
                id: newQuestion.id, question: newQuestion.question, questionTypeId: newQuestion.questionTypeId, scoreTypeId: 2, hint: newQuestion.hint, answers: newQuestion.answers
            }]);
            await getQuizzes(documentId, setFields);
            setSpinnerEnable(false)
            setModal(false)
        } else {
            toast.error("Error al crear la pregunta")
            setSpinnerEnable(false)
        }
    }

    const addMultipleOption = () => {

        if (optionsMultiples.length >= 6) {
            toast.error("No puedes agregar mas de 4 opciones")
            return
        }


        setOptionsMultiples([...optionsMultiples, {
            answer: `option${optionsMultiples.length + 1}`,
            isTrueAnswer: false
        }])

        setAnswer([...answer, {
            answer: "",
            isTrueAnswer: false
        }])
    }

    const removeMultipleOption = (index) => {
        setOptionsMultiples(optionsMultiples.filter((_, i) => i !== index))

        setAnswer(answer.filter((_, i) => i !== index))
    }

    const checkTrueAnswer = (index, unique) => {

        if (unique) {
            const newAnswer = [...answer];

            newAnswer?.forEach((answer, i) => {
                if (answer.isTrueAnswer) {
                    newAnswer[i].isTrueAnswer = false
                }
            })

            newAnswer[index].isTrueAnswer = true

            setAnswer(newAnswer)
        } else {

            const newAnswer = [...answer];

            if (newAnswer[index].isTrueAnswer) {
                newAnswer[index].isTrueAnswer = false
            } else {
                newAnswer[index].isTrueAnswer = true
            }
            setAnswer(newAnswer)
        }

    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-[999]'
        >
            <div className={`absolute p-4 m-2 text-center w-full bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md sm:w-3/4`}>
                <div className='flex flex-col gap-2'>
                    <h1 className='text-xl font-medium my-[20px]' htmlFor='title'>
                        Agregar Pregunta
                    </h1>
                    <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                        onClick={() => {
                            setModal(false)
                        }}>
                        <CloseIcon />
                    </button>
                    <div className="editor-fields ">
                        <label
                            className="editor-fields-label"
                            htmlFor="question"
                        >
                            Pregunta:
                        </label>
                        <textarea
                            className={`editor-input ${question.question.length > 180 ? 'h-[160px]' : 'h-[70px]'} transition-all duration-200 ease-in-out`}
                            placeholder='Escriba la pregunta...'
                            id='question'
                            onChange={(e) =>
                                edFunc.handleChange(e, "question", setQuestion, question)
                            }
                        />
                    </div>
                    <div className="editor-fields ">
                        <label
                            className="editor-fields-label"
                            htmlFor="hint"
                        >
                            Hint:
                        </label>
                        <textarea
                            className={`editor-input ${question.hint?.length > 180 ? 'h-[160px]' : 'h-[70px]'} transition-all duration-200 ease-in-out`}
                            placeholder='Escriba la pista...'
                            id='hint'
                            onChange={(e) =>
                                edFunc.handleChange(e, "hint", setQuestion, question)
                            }
                        />
                    </div>
                    <div className="editor-fields">
                        <label
                            htmlFor="scoreTypeId"
                            className="editor-fields-label"
                        >
                            Tipo de Score:
                        </label>
                        <select
                            className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                            name="scoreTypeId"
                            id="scoreTypeId"
                            value={question.scoreTypeId}
                            onChange={(e)=>{
                                edFunc.handleChange(e, "scoreTypeId", setQuestion, question)
                            }}
                        >
                            <option hidden defaultValue="default">Seleccione tipo de score</option>
                            {scoreTypesState?.map((scoreType, index) => (
                                <option key={index} value={scoreType.id}>{scoreType.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="editor-fields">
                        <label
                            htmlFor="questionTypeId"
                            className="editor-fields-label"
                        >
                            Tipo de Pregunta:
                        </label>
                        <select
                            className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                            name="questionTypeId"
                            id="questionTypeId"
                            onChange={handleChangeQuestionType}
                        >
                            <option hidden defaultValue="default">Seleccione el tipo </option>
                            <option value={1}>Única </option>
                            <option value={2}>Multiple Choice</option>
                            <option value={3}>Verdadero y Falso </option>
                            <option value={4}>Regex</option>
                        </select>
                    </div>
                    
                    {
                        (parseInt(question.questionTypeId) === 1 || parseInt(question.questionTypeId) === 4) && (
                            <div className="editor-fields">
                                <label
                                    className="editor-fields-label"
                                    htmlFor="answer"
                                >
                                    Respuesta:
                                </label>
                                <input
                                    onChange={(e) =>
                                        edChallFunc.handleAnswer(e, setAnswer, answer, 0)
                                    }
                                    id="answer"
                                    className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                                    placeholder='Escriba la respuesta...'
                                ></input>
                            </div>
                        )
                    }

                    {
                        (parseInt(question.questionTypeId) === 2 || parseInt(question.questionTypeId) === 3) && (
                            <p>Opciones:</p>
                        )
                    }
                    {
                        (parseInt(question.questionTypeId) === 2 && optionsMultiples.length !== 0) ? (
                            <div
                                className="flex flex-col gap-2"
                            >
                                {
                                    optionsMultiples.map((option, index) => (
                                        <div
                                            key={index}
                                            className='flex  gap-2'
                                        >

                                            <div
                                                className="flex-1 flex flex-col gap-2"
                                            >
                                                <div className="editor-fields">
                                                    <label
                                                        className="editor-fields-label"
                                                        htmlFor="answer"
                                                    >
                                                        Opcion {index + 1}
                                                    </label>
                                                    <input
                                                        onChange={(e) =>
                                                            edChallFunc.handleAnswer(e, setAnswer, answer, index)
                                                        }
                                                        className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                                                        placeholder='Escriba la respuesta...'
                                                    />

                                                </div>

                                                <div className='flex px-2 gap-2 items-center'>
                                                    <label
                                                        className="editor-fields-label"
                                                        htmlFor="isTrueAnswer1"
                                                    >
                                                        Es Correcta:
                                                    </label>
                                                    <button
                                                        className={`btn w-[60px] h-[30px] flex items-center justify-center ${answer[index].isTrueAnswer ? 'bg-green-500' : 'bg-red-500'}`}
                                                        onClick={() => checkTrueAnswer(index)}
                                                    >
                                                        {
                                                            answer[index].isTrueAnswer ? (
                                                                <span className='text-white flex items-center justify-center gap-1'>
                                                                    Si
                                                                    <CheckIcon fontSize='small' />
                                                                </span>
                                                            ) : (
                                                                <span className='text-white flex items-center justify-center gap-1'>
                                                                    No
                                                                    <CloseIcon fontSize='small' />
                                                                </span>
                                                            )
                                                        }
                                                    </button>


                                                </div>
                                            </div>

                                            <button
                                                className='btn h-[30px] w-[30px] m-0 flex items-center justify-center'
                                                onClick={() => removeMultipleOption(index)}
                                                title="Eliminar Opcion"
                                            >
                                                <DeleteIcon fontSize='small' />
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <></>
                        )
                    }

                    {
                        (parseInt(question.questionTypeId) === 2 && optionsMultiples.length < 6) && (
                            <div>
                                <button
                                    className='btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]'
                                    onClick={addMultipleOption}
                                >
                                    Agregar Opcion
                                </button>
                            </div>
                        )
                    }

                    {
                        (parseInt(question.questionTypeId) === 3) && (

                            <div className="flex flex-col gap-2">
                                <div className="editor-fields">
                                    <label className="editor-fields-label" htmlFor="answer3">
                                        Respuesta:
                                    </label>
                                    <input
                                        onChange={(e) => edChallFunc.handleAnswer(e, setAnswer, answer, 0)}
                                        id="answer3"
                                        className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                                        placeholder="Escriba la respuesta..."
                                    />
                                </div>

                                <div className="editor-fields  self-start">
                                    <label htmlFor="isTrueAnswer3" className="editor-fields-label">
                                        Es Correcta:
                                    </label>

                                    <button
                                        className={`btn w-[60px] h-[30px] flex items-center justify-center ${answer[0].isTrueAnswer ? 'bg-green-500' : 'bg-red-500'}`}
                                        onClick={() => checkTrueAnswer(0, true)}
                                    >
                                        {
                                            answer[0].isTrueAnswer ? (
                                                <span className='text-white flex items-center justify-center gap-1'>
                                                    Si
                                                    <CheckIcon fontSize='small' />
                                                </span>
                                            ) : (
                                                <span className='text-white flex items-center justify-center gap-1'>
                                                    No
                                                    <CloseIcon fontSize='small' />
                                                </span>
                                            )
                                        }
                                    </button>

                                </div>

                                <div className="editor-fields">
                                    <label className="editor-fields-label" htmlFor="answer4">
                                        Respuesta:
                                    </label>
                                    <input
                                        onChange={(e) => edChallFunc.handleAnswer(e, setAnswer, answer, 1)}
                                        id="answer4"
                                        className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                                        placeholder="Escriba la respuesta..."
                                    />
                                </div>

                                <div className="editor-fields self-start">
                                    <label htmlFor="isTrueAnswer4" className="editor-fields-label">
                                        Es Correcta:
                                    </label>
                                    <button
                                        className={`btn w-[60px] h-[30px] flex items-center justify-center ${answer[1].isTrueAnswer ? 'bg-green-500' : 'bg-red-500'}`}
                                        onClick={() => checkTrueAnswer(1, true)}
                                    >
                                        {
                                            answer[1].isTrueAnswer ? (
                                                <span className='text-white flex items-center justify-center gap-1'>
                                                    Si
                                                    <CheckIcon fontSize='small' />
                                                </span>
                                            ) : (
                                                <span className='text-white flex items-center justify-center gap-1'>
                                                    No
                                                    <CloseIcon fontSize='small' />
                                                </span>
                                            )
                                        }
                                    </button>

                                </div>
                            </div>

                        )
                    }

                    <div className='flex flex-col xs:flex-row justify-center'>
                        <button
                            className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]`}
                            onClick={() => handleCreateQuestion()}
                        >
                            {spinnerEnable
                                ? (
                                    <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                                )
                                : (
                                    'Crear Pregunta'
                                )}
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default CreateQuestionModal