import { useEffect } from 'react'
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';

function completeQuizModal({title, modal, setModal }) {

    const variants = {
        opened: {
            opacity: 1,
            display: 'flex'
        },
        closed: {
            opacity: 0,
            transitionEnd: {
                display: 'none'
            }
        }
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
        >
            <div className="absolute p-[80px] mt-4 bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-medium">
                ¡Felicitaciones! completaste el cuestionario <br/> &quot;{title}&quot;
                </h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        setModal(false)
                    }}>
                    <CloseIcon />
                </button>
            </div>
        </motion.div>
    )
}

export default completeQuizModal