import { motion } from 'framer-motion'
import { React, useState, useEffect } from 'react'
import edFunc from '../admin/editorFunctions'
import edChallFunc from '../admin/challengeEditorFunctions'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const variants = {
    opened: {
        opacity: 1,
        display: 'flex'
    },
    closed: {
        opacity: 0,
        transitionEnd: {
            display: 'none'
        }
    }
}


function createSubchallengeModal({
    challengeId,
    modal,
    setModal,
    setSubChallengeList,
    ChallengeType,
    token,
    subChallengeList = [],
}) {

    const resetFields = {
        name: "",
        description: "",
        imgPath: "",
        subChallengeTypeId: "",
        labUrl: "",
        challengeId,
    };

    const [fields, setFields] = useState(resetFields);
    const [spinnerEnable, setSpinnerEnable] = useState(false);
    const [subChallengeTypes, setSubChallengeTypes] = useState([]);

    useEffect(() => {

        edChallFunc.HandleGetSubChallengeTypeList(setSubChallengeTypes)

        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleCreateDocument = async () => {
        setSpinnerEnable(true)
        const newSubChallenge = await edChallFunc.handleNewSubChallenge(fields)
        if (newSubChallenge) {
            toast.success("Desafío creado")
            setFields(resetFields)
            setSubChallengeList([...subChallengeList, newSubChallenge]);
            setSpinnerEnable(false)
            setModal(false)
        } else {
            toast.error("No se pudo crear el desafío")
            setSpinnerEnable(false)
        }
    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
        >
            <div className="absolute p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md transition-all duration-200 ease-in-out w-2/3">
                <h1 className="text-4xl font-bold text-gray-100 my-2">
                    Nuevo Desafío
                </h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        setModal(false)
                    }}>
                    <CloseIcon />
                </button>

                <div
                    className='flex flex-col gap-2'
                >
                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="name"
                        >
                            Nombre:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="name"
                            onChange={(e) =>
                                edFunc.handleChange(e, "name", setFields, fields)
                            }
                            value={fields.name}
                            disabled={!challengeId}
                            placeholder='Escriba el nombre'
                        />
                    </div>
                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="description"
                        >
                            Descripción:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="description"
                            onChange={(e) =>
                                edFunc.handleChange(e, "description", setFields, fields)
                            }
                            value={fields.description}
                            disabled={!challengeId}
                            placeholder='Escriba la descripción'
                        />
                    </div>
                    {(ChallengeType === 1) && <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="labUrl"
                        >
                            labUrl:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="labUrl"
                            onChange={(e) =>
                                edFunc.handleChange(e, "labUrl", setFields, fields)
                            }
                            disabled={!challengeId}
                            placeholder='http://labUrl.com'
                        />
                    </div>}
                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="imgPath"
                        >
                            Url imagen:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="imgPath"
                            value={fields.imgPath}
                            onChange={(e) =>
                                edFunc.handleChange(e, "imgPath", setFields, fields)
                            }
                            disabled={!challengeId}
                            placeholder='http://imgpath.com'
                        />
                    </div>
                    {(ChallengeType === 2) && <div className="editor-fields ">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="file"
                        >
                            Archivo:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="file"
                            type='file'
                            onChange={(e) =>
                                edChallFunc.handleChangeFile(e, "file", setFields, fields)
                            }
                            disabled={!challengeId}
                            placeholder='file'
                        />
                    </div>}
                    {(ChallengeType === 2) && <div className="editor-fields justify-start">
                        <label
                            htmlFor="fileType"
                            className="editor-fields-label w-[170px]"
                        >
                            Tipo de Archivo:
                        </label>
                        <select
                            className="editor-input w-1/5 my-2 p-1"
                            name="fileType"
                            id="fileType"
                            onChange={(e) =>
                                edFunc.handleChange(e, "fileType", setFields, fields)
                            }
                        >
                            <option hidden defaultValue="default">Tipo de Archivo</option>
                            <option value={"PDF"} className="font-medium">PDF</option>
                            <option value={"PCAP"} className="font-medium">PCAP</option>
                        </select>
                    </div>}

                    <div className="editor-fields">
                        <label
                            htmlFor="subChallengeTypeId"
                            className="editor-fields-label w-[200px]"
                        >
                            Tipo de desafío:
                        </label>
                        <select
                            id="subChallengeTypeId"
                            name="subChallengeTypeId"
                            className="my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out"
                            value={fields.subChallengeTypeId}
                            onChange={(e) =>
                                edFunc.handleChange(e, "subChallengeTypeId", setFields, fields)
                            }
                        >
                            <option hidden defaultValue="">Seleccione el tipo </option>
                            {
                                subChallengeTypes?.map((subChallengeType, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={subChallengeType.id}
                                        >
                                            {subChallengeType.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="url"
                        >
                            Link:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="labUrl"
                            name="labUrl"
                            onChange={(e) =>
                                edFunc.handleChange(e, "labUrl", setFields, fields)
                            }
                            value={fields.labUrl}
                            disabled={!challengeId}
                            placeholder='Escriba el identificador del desafío'
                        />
                    </div>

                </div>

                <button className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]`}
                    onClick={() => handleCreateDocument()}>
                    {spinnerEnable
                        ? (
                            <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                        )
                        : (
                            'Crear Desafío'
                        )}
                </button>
            </div>
        </motion.div>
    )
}

export default createSubchallengeModal
