import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingSpinner from "./loadingSpinner";
import edFunc from "../admin/editorFunctions"

const defaultImage = '/icons/En contruccion Negro.png'
const beginner = '/Level_Beginner_Animated_json.json'
const intermediate = '/Level_Intermediate_Animated_json.json'
const advanced = '/Level_Advanced_Animated_2_json.json'


const selectionAnimation = (value) => {
  // level === 'Principiante' ? beginner : level === 'Intermedio' ? intermediate : level === 'Avanzado' && advanced
  switch (value) {
    case 'Principiante':
      return beginner
    case 'Intermedio':
      return intermediate
    case 'Avanzado':
      return advanced
    default:
      return defaultImage
  }

}

function LevelSelection({ value }) {
  const [t] = useTranslation("global");
  const levelList = useSelector((state) => state.settings.levels);
  const [levels, setLevels] = useState(levelList || []);


  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!levels[0]) edFunc.HandleGetLevelList(setLevels)
    }
    return () => {
      isMounted = false;
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
      className="min-h-box text-center py-4 mx-auto w-full max-w-[1280px]"
    >
      <h3 className="title">{t(`levelselection.title${value}`)}</h3>
      <div className="my-4 mx-2 overflow-x-scroll md:overflow-auto snap-mandatory snap-x level-wrapper">
        <div className="text-center py-4 w-full max-w-[1280px]">
          <div className="sm:block md:flex flex-row flex-wrap justify-center items-center min-w-[875px] md:min-w-full min-h-[450px]">
            {levels?.length === 0 && <LoadingSpinner size={"300px"} />}
            {levels?.length ? (
              levels.map((item, index) => {
                const level = item.name
                const levelAnimation = selectionAnimation(level);
                return (
                  <div key={index} className="md:min-w-[260px] lg:min-w-[350px]">
                    <Link className="selection-block flex flex-col" to={`/${value}/${item.name}`}>
                      <span className="justify-self-start">{item.name}</span>
                      {
                        levelAnimation.endsWith('png') ? (
                          <div
                            className="w-full h-[300px] bg-center bg-no-repeat"
                          >
                            <img
                              src={levelAnimation}
                              alt="level"
                              className="w-full h-full object-contain"
                            />
                          </div>
                        ) : (
                          <Player
                            hover
                            keepLastFrame={true}
                            src={levelAnimation}

                            style={{ height: '300px', width: '300px' }}
                          />
                        )
                      }
                    </Link>
                  </div>
                )
              })
            ) : null}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default LevelSelection;
