import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import dashFunc from "./dashboardFunctions"
import SearchIcon from '@mui/icons-material/Search';

function DashboardUser() {
  const token = useSelector((state) => state.user.token)

  const resetUser = {
    name: '-',
    username: '-',
    profilePictureUrl: '-',
    gender: '-',
    age: '-',
    email: '-',
    countryId: 0,
    createdAt: 0,
    userStats: {
      completedChallenges: 0,
      readGuides: 0,
      score: 0,
      streak: 0,
    }
  }

  const [userList, setUserList] = useState([])
  const [input, setInput] = useState('')
  const [user, setUser] = useState(resetUser)

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dashFunc.HandleGetUserList(setUserList, token)
    }
    return () => {
      isMounted = false;
    }
  }, []);

  const userSearch = () => {
    const result = userList.filter((userList) => userList.name.toLowerCase() === input.toLowerCase())
    if (result.length) {
      setUser(result[0])
    } else {
      setUser(resetUser)
    }
  }

  return (
    <div className="flex flex-col w-full h-full max-h-[2800px] p-2">
      <div className="flex flex-col max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 py-6 px-4 lg:py-2 text-sm bg-gray-100 dark:bg-[#d3d3df] rounded-md shadow-md transition-all duration-200 ease-in-out">
        <div className='flex justify-end'>
          <div className="bg-[#c0c0c5] w-1/4 m-4 rounded-sm flex">
            <input placeholder="Search by name or e-mail" className="py-2 w-full text-center font-semibold bg-[#c0c0c5]"
              onChange={(e) => setInput(e.currentTarget.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') { userSearch() } }}
            />
            <button className="mx-2" onClick={() => { userSearch() }}><SearchIcon /></button>
          </div>
        </div>
        <div className='flex justify-center items-end text-black'>
          <div className='rounded-full bg-red-400 w-[220px] aspect-square' />
          <div className='flex flex-col mx-[30px]'>
            <h2 className='text-4xl font-semibold text-left my-4'>{user.name}</h2>
            <div className='flex'>
              <h3 className='text-3xl font-semibold bg-[#c0c0c5] p-[20px] mr-4'>Puntaje : {user.userStats.score}</h3>
              <h3 className='text-3xl font-semibold bg-[#c0c0c5] p-[20px] mx-4'>Racha : {user.userStats.streak}</h3>
              <h3 className='text-3xl font-semibold bg-[#c0c0c5] p-[20px] ml-4'>Nivel : 🥇</h3>
            </div>
          </div>
        </div>
        <div className='flex justify-center my-[80px]'>
          <div className='flex-col space-y-2'>
            <h3 className='text-lg font-semibold bg-[#c0c0c5] p-[20px]'>{user.gender}</h3>
            <h3 className='text-lg font-semibold bg-[#c0c0c5] p-[20px]'>{user.email}</h3>
            <h3 className='text-lg font-semibold bg-[#c0c0c5] p-[20px]'>Desde {(user.createdAt) ? dashFunc.convertDate(user.createdAt) : "-"}</h3>
            <h3 className='text-lg font-semibold bg-[#c0c0c5] p-[20px]'>{(user.countryId) ? user.countryId : "-"}</h3>
            <h3 className='text-lg font-semibold bg-[#c0c0c5] p-[20px]'>Español</h3>
            <h3 className='text-lg font-semibold bg-[#c0c0c5] p-[20px]'>Redes</h3>
          </div>
          <div className='text-2xl font-semibold bg-[#c0c0c5] mx-6 w-[300px]'>
            <h3 className='m-4'>Guías Leídas</h3>
            {user.userStats.readGuides}
          </div>
          <div className='text-2xl font-semibold bg-[#c0c0c5] w-[300px]'>
            <h3 className='m-4'>Retos Completados</h3>
            {user.userStats.completedChallenges}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardUser