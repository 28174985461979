import { useEffect, useRef } from 'react'
import { NavLink, useMatch, useNavigate } from 'react-router-dom'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { settingsActions } from "../../redux/slices/settingsSlice"
import LaunchIcon from '@mui/icons-material/Launch';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

const METRICS_URL = "https://manage.app.preset.io/login/?next=https://9614bd89.us1a.app.preset.io/superset/dashboard/12/?native_filters_key=i5y0js95qQk20H75hUj96nI6CMHanrmW7c4QgfThKghDaYOUS3qDoU7MotTDj4fy"

// TODO: REFACTOR THIS CODE
function Sidemenu({ isOpen, closeMenu }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match1 = useMatch({ path: '/admin/dashboard/*', end: true })
  const match2 = useMatch({ path: '/admin/edition/*', end: true })
  const match3 = useMatch({ path: '/admin/edition/editor/*', end: true })
  const match4 = useMatch({ path: '/admin/edition/challengeEditor/*', end: true })
  const match5 = useMatch({ path: '/admin/config/*', end: true })
  const match6 = useMatch({ path: '/admin/config/language-editor', end: true })
  const match7 = useMatch({ path: '/admin/config/tag-editor', end: true })
  const match8 = useMatch({ path: '/admin/config/role-editor', end: true })
  const match9 = useMatch({ path: '/admin/config/level-editor', end: true })
  const match10 = useMatch({ path: '/admin/config/score-editor', end: true })
  const match11 = useMatch({ path: '/admin/creation/*', end: true })
  const match12 = useMatch({ path: '/admin/creation/newChallenge', end: true })
  const match13 = useMatch({ path: '/admin/creation/newGuide', end: true })
  const match14 = useMatch({ path: '/admin/dashboard/data', end: true })
  const match15 = useMatch({ path: '/admin/dashboard/user', end: true })
  const match16 = useMatch({ path: '/admin/dashboard/general', end: true })
  const match17 = useMatch({ path: '/admin/dashboard/guide-challenge', end: true })
  const match18 = useMatch({ path: '/admin/edition/learningPathEditor/*', end: true })
  const match19 = useMatch({ path: '/admin/creation/newLearningPath', end: true })
  const isSmall = useMediaQuery('(max-width: 1023.9px)')
  const menuRef = useRef()
  const data = useSelector((state) => state.user.userData)
  const hasChanged = useSelector((state) => state.settings.hasChanged);

  useEffect(() => {
    const handleClick = (event) => {
      if (!menuRef.current.contains(event.target) && isSmall) {
        closeMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  })

  useEffect(() => {
    if (isSmall) {
      closeMenu(false)
    } else {
      closeMenu(true)
    }
  }, [isSmall, closeMenu])

  const variants = {
    opened: {
      x: 0,
      display: 'block'
    },
    closed: {
      x: '-100%',
      transitionEnd: {
        display: 'none'
      }
    }
  }

  // The purpose of the function is to verify if there was a change in any of the forms and if there is not, it works as a link
  function confirmMessage(route) {
    const text = "¿Estás seguro?\nEs posible que los cambios que implementaste no se puedan guardar."
    if (hasChanged) {
      if (confirm(text) === true) {
        dispatch(settingsActions.setHasChanged(false))
        navigate(route)
      }
    } else {
      navigate(route)
    }
  }

  return (
    <AnimatePresence exitBeforeEnter onExitComplete={() => null}>
      <motion.div
        ref={menuRef}
        variants={variants}
        initial={{ display: 'none', x: '-200%' }}
        animate={isOpen ? 'opened' : 'closed'}
        exit='closed'
        transition={{ ease: 'easeInOut', duration: 0.25 }}
        className={`bg-white dark:bg-neutral-700 text-gray-700 dark:text-gray-100 absolute xl:relative top-0 bottom-0 z-20 w-48 min-w-[155px] shadow-sidemenu`}
      >
        <FontAwesomeIcon
          className='relative top-4 left-3 text-xl block xl:hidden cursor-pointer'
          onClick={() => closeMenu(false)}
          icon={faTimes}
        />
        <ul className='text-center mt-8 top-[40px] xl:top-0 xl:mt-10 mb-4'>

          {
            data && (data.role.name === 'Super Admin' || data.role.name === 'Admin') &&
            <li className='px-2 py-1.5 rounded-md'>
              <div className="group relative">
                <button
                  className={`${(match1) ? ' bg-purple-600' : ''} sidemenu-link w-full hover:bg-purple-700`}
                  onClick={() => { confirmMessage('/admin/dashboard/data') }}>
                  <FontAwesomeIcon className='mr-2 text-base' icon={faHome} />
                  Dashboard
                </button>
                <nav tabIndex="0" className={`${(match1) ? '' : 'hidden'} border-2  border-transparent rounded top-full transition-all`}>
                  <ul className="py-1">
                    {/* TODO: make an array and map the elements */}
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <NavLink
                        className={`${match14 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        to='/admin/dashboard/data'
                      >
                        Data
                      </NavLink>
                    </li>
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <NavLink
                        className={`${match15 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        to='/admin/dashboard/user'
                      >
                        User
                      </NavLink>
                    </li>
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <NavLink
                        className={`${match16 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        to='/admin/dashboard/general'
                      >
                        General
                      </NavLink>
                    </li>
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <NavLink
                        className={`${match17 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        to='/admin/dashboard/guide-challenge'
                      >
                        Guías/Retos
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </li>
          }

          {data && (data.role.name === 'Super Admin' || data.role.name === 'Admin' || data.role.name === 'Content Creator') &&
            <li className='px-2 py-1.5 rounded-md'>
              <div className="group relative">
                <button
                  className={`${(match11) ? 'bg-purple-600' : ''} sidemenu-link w-full hover:bg-purple-700`}
                  onClick={() => { confirmMessage('/admin/creation/newGuide') }}>
                  <FontAwesomeIcon className='mr-2 text-base' icon={faEdit} />
                  Creación
                </button>
                <nav tabIndex="0" className={`${(match11) ? '' : 'hidden'}  border-2 border-transparent rounded top-full transition-all`}>
                  <ul className="py-1">
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <button
                        className={`${match13 ? 'bg-[#ED38C5]' : ''} sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        onClick={() => { confirmMessage('/admin/creation/newGuide') }}>
                        Nueva guía
                      </button>
                    </li>
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <button
                        className={`${match12 ? 'bg-[#ED38C5]' : ''} sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        onClick={() => { confirmMessage('/admin/creation/newChallenge') }}>
                        Nuevo reto
                      </button>
                    </li>
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <button
                        className={`${match19 ? 'bg-[#ED38C5]' : ''} sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        onClick={() => { confirmMessage('/admin/creation/newLearningPath') }}>
                        Nueva Ruta de Aprendizaje
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </li>
          }

          <li className='px-2 py-1.5 rounded-md'>
            <div className="group relative">
              <button
                className={`${(match2) ? 'bg-purple-600' : ''} sidemenu-link w-full hover:bg-purple-700`}
                onClick={() => { confirmMessage('/admin/edition/editor') }}>
                <FontAwesomeIcon className='mr-2 text-base' icon={faEdit} />
                Edición
              </button>
              <nav tabIndex="0" className={`${(match2) ? '' : 'hidden'} border-2 border-transparent rounded top-full transition-all`}>
                <ul className="py-1">
                  <li className='sidemenu-link-sub-item px-2 py-1.5'>
                    <button
                      className={`${match3 ? 'bg-[#ED38C5]' : ''} sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                      onClick={() => { confirmMessage('/admin/edition/editor') }}>
                      Editor de Guías
                    </button>
                  </li>
                  {data && (data.role.name !== 'Guides Moderator') &&
                    <li className='sidemenu-link-sub-item px-2 py-1.5'>
                      <button
                        className={`${match4 ? 'bg-[#ED38C5]' : ''} sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                        onClick={() => { confirmMessage('/admin/edition/challengeEditor') }}>
                        Editor de Retos
                      </button>
                    </li>
                  }
                  <li className='sidemenu-link-sub-item px-2 py-1.5'>
                    <button
                      className={`${match18 ? 'bg-[#ED38C5]' : ''} sidemenu-link hover:bg-[#cc2fa9] text-sm`}
                      onClick={() => { confirmMessage('/admin/edition/learningPathEditor') }}>
                      Editor de Ruta de Aprendizaje
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </li>

          {
            data && (data.role.name === 'Super Admin' || data.role.name === 'Admin') &&
            <li className='px-2 py-1.5 rounded-md'>
              <div className="group relative">

                <button
                  className={`${match5 ? 'bg-purple-600' : ''} sidemenu-link w-full hover:bg-purple-700`}
                  onClick={() => { confirmMessage('/admin/config/language-editor') }}>
                  <FontAwesomeIcon className='mr-2 text-base' icon={faEdit} />
                  Configuración
                </button>
                <nav tabIndex="0" className={`${(match5) ? '' : 'hidden'} border-2 border-transparent rounded top-full transition-all`}>
                  <ul className="py-1">
                    <li className="sidemenu-link-sub-item px-2 py-1.5">
                      <NavLink
                        to="/admin/config/language-editor"
                        className={`${match6 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9]`}
                      >
                        Idiomas
                      </NavLink>
                    </li>
                    <li className="sidemenu-link-sub-item px-2 py-1.5">
                      <NavLink
                        to="/admin/config/tag-editor"
                        className={`${match7 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9]`}
                      >
                        Tags
                      </NavLink>
                    </li>
                    <li className="sidemenu-link-sub-item px-2 py-1.5">
                      <NavLink
                        to="/admin/config/role-editor"
                        className={`${match8 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9]`}
                      >
                        Roles
                      </NavLink>
                    </li>
                    <li className="sidemenu-link-sub-item px-2 py-1.5">
                      <NavLink
                        to="/admin/config/level-editor"
                        className={`${match9 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9]`}
                      >
                        Niveles
                      </NavLink>
                    </li>
                    <li className="sidemenu-link-sub-item px-2 py-1.5">
                      <NavLink
                        to="/admin/config/score-editor"
                        className={`${match10 ? 'bg-[#ED38C5]' : ''
                          } sidemenu-link hover:bg-[#cc2fa9]`}
                      >
                        Puntajes
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </li>
          }

          {
            data && (data.role.name === 'Super Admin' || data.role.name === 'Admin') && (
              <li className='px-2 py-1.5 rounded-md'>
                <a
                  className={`sidemenu-link w-full hover:bg-purple-700 flex items-center cursor-pointer`}
                  href={METRICS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LeaderboardIcon fontSize='small' className='-ml-[1px] text-base mr-[6px]' />
                  <span className='mr-1'>
                    Métricas
                  </span>
                  <LaunchIcon fontSize='small' />
                </a>
              </li>
            )
          }
        </ul>
      </motion.div>
    </AnimatePresence>
  )
}

export default Sidemenu
