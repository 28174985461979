import { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { motion } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LoadingSpinner from "../common/loadingSpinner";
import parse from "html-react-parser";
import Rating from '@mui/material/Rating';
import { createGuideRate, userReadedGuide } from "../../services/guidesService";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { getUserReadGuides } from "../../services/userService";
import { userActions } from "../../redux/slices/userSlice";

function parseHtml(content) {
  const arr = [];

  for (let i = 0; i < Object.keys(content).length; i++) {
    arr.push(parse(true && content[i]));
  }
  return arr;
}

const checkReview = (guideId) => {
  const review = Cookies.get('reviewed-guide')
  if (review) {
    const reviewed = JSON.parse(review)
    if (reviewed.guideId === guideId) {
      return true
    }
    return false
  } else {
    return false
  }
}

function PageInfo({
  pagesList,
  currentpage,
  openmenu,
  menu,
  objectivesMenu,
  openObjectivesMenu,
  notesmenu,
  openNotesmenu,
  guide,
  readGuide,
  setReadGuide
}) {

  const dispatch = useDispatch()
  const { id: guideId, level, pageid: page } = useParams();
  const userId = useSelector((state) => state.user.userData.id);
  const [length, setLength] = useState("");
  const [pages, setPages] = useState("");
  const [giveReview, setGiveReview] = useState(false);
  const nextPage = useRef();
  const previousPage = useRef();

  // These parameters are seated for the purpose of demonstrating operation.
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewed, setReviewed] = useState(checkReview(guideId));

  useEffect(() => {
    if (length && (page >= length || page < 0 || isNaN(page))) {
      window.location.href = process.env.PUBLIC_URL + "/not-found";
    }
    const handleKeyPress = (event) => {
      if (event.key === "ArrowRight") {
        if (page >= 0 && page < length - 1) {
          nextPage.current.click();
        }
      }
      if (event.key === "ArrowLeft") {
        if (page <= length - 1 && page > 0) {
          previousPage.current.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });

  useEffect(() => {
    currentpage(page);
    const handleGuideParsing = () => {
      const currentGuide = guide
      if (currentGuide && currentGuide.guidePage) {
        const currentPageIndex = parseInt(page)
        const currentPage = currentGuide.guidePage.filter((page, index) => index === currentPageIndex)
        const pageContent = currentPage.map(content => content.content)
        const parsedCurrentGuide = parseHtml(pageContent);
        setPages(parsedCurrentGuide);
        setLength(Object.keys(currentGuide.guidePage).length);
      }
    };
    handleGuideParsing();
  }, [guideId, page, currentpage, pagesList]);

  const sendReview = async () => {

    if (review === "") {
      toast.error("Debes escribir una reseña")
      return
    }
    else if (review.length < 10) {
      toast.error("La reseña debe tener al menos 10 caracteres")
      return
    }
    else if (rating === 0) {
      toast.error("Debes calificar el reto")
      return
    }

    const response = await createGuideRate(rating, review, guideId, userId)

    if (response) {
      setGiveReview(false)
      setReviewed(true)
      toast.success("Gracias por tu reseña")
      Cookies.set('reviewed-guide', JSON.stringify({ guideId, userId }))
    } else {
      toast.error("Algo salio mal")
    }
  }

  const handleReadGuide = async () => {
    const response = await userReadedGuide(guideId, userId)
    if (response) {
      const userData = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"))
      if (userData) {
        dispatch(userActions.setReadGuides(await getUserReadGuides(userData?.id)))
      }

      setReadGuide(true)
      toast.success("Has leido esta guía")
    } else {
      toast.error("Algo salio mal")
    }
  }

  return (
    <div>
      {pages === "" ? (
        <LoadingSpinner size={"300px"} />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.2 }}
          className="relative bg-white dark:bg-neutral-700 unreset rounded-md shadow-md 
                            text-gray-700 dark:text-gray-100"
        >

          {
            (page === '0') ? (
              <div className="w-full flex justify-center min-h-[50px]">
                {
                  (guide.headerDesktopPath?.toLowerCase() !== "n/a") ? (
                    <img
                      src={guide.headerDesktopPath}
                      alt="header-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = process.env.PUBLIC_URL + '/inConstruction_night.png';
                      }}
                      className="w-[80%] mb-5 rounded-t-lg"
                    />
                  ):(
                    <div className="h-28 w-full"></div>
                  )
                }
              </div>
            ) : (
              <div className="h-1 w-full"></div>
            )
          }


          <div className={`noPreflight p-4 pt-0 xs:p-8 lg:p-12 xs:pt-0 lg:pt-0 ${page === '0' ? '-mt-10' : '!pt-12'}`}>
            <div className="block m-2 p-1 xl:m-1 xl:p-1">
              <button
                className={`${menu
                  ? "text-rose-600 dark:text-rose-600"
                  : "text-gray-700 dark:text-gray-100"
                  } absolute top-4 left-4 text-lg xs:text-xl border-none cursor-pointer select-none transition-all ease-in-out duration-200 bg-white dark:bg-neutral-700 px-3 rounded-lg`}
                onClick={() => {
                  openmenu(!menu)
                  openObjectivesMenu(false)
                  openNotesmenu(false)
                }}>
                {menu ? <MenuOpenIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
              </button>
              <button
                className={`${objectivesMenu
                  ? "text-rose-600 dark:text-rose-600"
                  : "text-gray-700 dark:text-gray-100"
                  } absolute top-4 left-[60px] text-lg xs:text-xl border-none cursor-pointer select-none transition-all ease-in-out duration-200 bg-white dark:bg-neutral-700 px-3 rounded-lg`}
                onClick={() => {
                  openmenu(false)
                  openObjectivesMenu(!objectivesMenu)
                  openNotesmenu(false)
                }}
              >
                <CheckBoxOutlinedIcon fontSize="large" />
              </button>
              <button
                className={`${notesmenu
                  ? "text-rose-600 dark:text-rose-600"
                  : "text-gray-700 dark:text-gray-100"
                  } absolute top-4 left-[105px] text-lg xs:text-xl border-none cursor-pointer select-none transition-all ease-in-out duration-200 bg-white dark:bg-neutral-700 px-3 rounded-lg`}
                onClick={() => {
                  openmenu(false)
                  openObjectivesMenu(false)
                  openNotesmenu(!notesmenu)
                }}
              >
                <EditNoteIcon fontSize="large" />
              </button>
            </div>
            {pages}
          </div>
          <div className={`flex flex-row items-center justify-center ${parseInt(page) === length - 1 ? "" : "hidden"}`}>
            <button
              className={`btn w-[200px] ${readGuide ? "bg-green-700 hover:bg-green-700 cursor-default" : ""}`}
              onClick={handleReadGuide}
              disabled={readGuide}
            >
              {
                readGuide ? "Guía leída" : "Marcar guía como leida"
              }
            </button>
            <button
              className={`btn w-[200px] mx-6 ${reviewed ? "hidden" : ""}`}
              onClick={() => setGiveReview(!giveReview)}
              disabled={reviewed}
            >
              {
                !reviewed ? "Dejar una reseña" : "Muchas gracias por tu reseña"
              }
            </button>
            <Link className={`btn nav-anchor text-center w-[200px] mx-6 ${guide.challengesId[0] ? "" : "hidden"}`} to={`/challenges/${level}/${guide.challengesId[0]}`}>
              Ir al reto vinculado
            </Link>
          </div>
          {/* TODO: el boton desaparece */}
          {/* rating and review feature */}
          {
            giveReview ?
              <div className={`flex flex-col items-center justify-center ${parseInt(page) === length - 1 ? "" : "hidden"}`}>
                <h3>¿Que te pareció la guía?</h3>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                <textarea id="message" rows="4" value={review} onChange={((e) => setReview(e.currentTarget.value))}
                  className="block p-2.5  w-1/2 mt-4 text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Dejanos tu opinión"></textarea>
                <button className="btn mt-4" onClick={() => sendReview()}>Enviar</button>
              </div>
              : null
          }

          <div className="nav-buttons">
            <Link
              ref={previousPage}
              to={`/guides/${level}/${guideId}/${parseInt(page) - 1}`}
              className={`btn nav-anchor ${parseInt(page) === 0 ? "hidden" : ""
                }`}
            >
              Anterior
            </Link>
            <Link
              ref={nextPage}
              to={`/guides/${level}/${guideId}/${parseInt(page) + 1}`}
              className={`btn nav-anchor align-r ${parseInt(page) === length - 1 ? "hidden" : ""
                }`}
            >
              Siguiente
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default PageInfo;
