import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstanceAuth = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor to add the token to headers
axiosInstanceAuth.interceptors.request.use((config) => {
    const token = Cookies.get('token');
    const refreshtoken = Cookies.get('refreshtoken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (refreshtoken) {
        config.headers.xRefreshToken = `Bearer ${refreshtoken}`;
    }
    return config;
});

const axiosIntance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

export { axiosInstanceAuth, axiosIntance };