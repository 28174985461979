const contributors = [
  {
      name: "Andrés Galeano",
      desc: "Hey Gente! Acá Andrés Galeano alias FunPunch! Fundador de Hackear | Cofundador de HackCTF | Team Leader de Content | Dueño de Lucy, Vel y Blue.",
      img: "/contributors/Andres Galeano.png",
      thumbnail: "/contributors/thumbnail/Andres Galeano.png",
      url: "https://linktr.ee/Hackear",
  },
  {
      name: "Edgardo Krause",
      desc: "Edgardo Krause A.K.A Padawan. Cofundador de HackCTF | Pentester | Consultor de desarrollo | Team Leader del área de Backend.",
      img: "/contributors/Edgardo Krause.png",
      thumbnail: "/contributors/thumbnail/Edgardo Krause.png",
      url: "https://linktr.ee/edgardokrause",
  },
  {
      name: "Martín Frías",
      desc: "Coldd para los panas, tengo 20 años, soy de Venezuela y me especializo en Pentesting. Me encargué de realizar varias de las guías y retos de la plataforma. Actualmente me encuentro como asesor.",
      img: "/contributors/Martin Frias.png",
      thumbnail: "/contributors/thumbnail/Martin Frias.png",
      url: "https://linktr.ee/martinfriasc",
  },
  {
      name: "Juan Kaenel",
      desc: "Soy Juan, tengo 24 años, A.K.A R4z0r. Soy Analista de Sistemas, trabajo de AppSec Engineer y soy estudiante autodidacta de Ciberseguridad. Creador de contenidos en varias plataformas. Amante de los CTFs. Me gusta el Hacking, el Voley y la Calistenia. Colaboré en HackCTF como creador de retos y guías. Actualmente pueden contar conmigo como consultor.",
      img: "/contributors/Juan Kaenel.png",
      thumbnail: "/contributors/thumbnail/Juan Kaenel.png",
      url: "https://linktr.ee/jkaenel",
  },
  {
      name: "Agustín López",
      desc: "Mi nombre es Agustín, tengo 22 años. Soy estudiante de la carrera Ingeniería Informática y me desempeñé en HackCTF como desarrollador FrontEnd.",
      img: "/contributors/Agustin Lopez.png",
      thumbnail: "/contributors/thumbnail/Agustin Lopez.png",
      url: "https://linktr.ee/agustinlopezpiceda",
  },
  {
      name: "Pablo Veliz",
      desc: "Soy Pablo, tengo 35 años. Soy técnico en Linux, apasionado de la CiberSeguridad y el Hardware, estoy ayudando con el Backend. Me gusta estudiar todo lo que es relacionado a Ethical Hacking y Python.",
      img: "/contributors/Pablo Veliz.png",
      thumbnail: "/contributors/thumbnail/Pablo Veliz.png",
      url: "https://linktr.ee/pablitox",
   },
   {
      name: "Fernanda Barrera",
      desc: "Me dicen Cherry y soy ilustradora autodidacta. He hecho cursos en Crehana para aprender a usar la herramienta Adobe Illustrator, aunque mayormente dibujo en Paint Tool Sai. Tengo mis propios proyectos como Ilustradora Freelance y streamer en la plataforma Twitch. En HackCTF colaboro en el concepto estético de la plataforma, así como también me encargo de darle vida a Vel, Lucy y Blue.",
      img: "/contributors/Fernanda Barrera.png",
      thumbnail: "/contributors/thumbnail/Fernanda Barrera.png",
      url: "https://linktr.ee/cherrystaste",
  },
  {
      name: "Isaias Sosa",
      desc: "Tengo 25 años, vivo en Zona Oeste. Maker y fanático de los arcade. Actualmente estudio sistemas y estuve colaborando en guías y retos para este CTF. Además, me encuentro aportando en el Backend.",
      img: "/contributors/Isaias sosa.png",
      thumbnail: "/contributors/thumbnail/Isaias sosa.png",
      url: "https://linktr.ee/isaiassosa",
  },
  {
      name: "Johanna Ponce",
      desc: "Me dicen Joha y soy estudiante universitaria. Soy escritora de novelas románticas y en HackCTF sigo desarrollando mis habilidades generando historias para Blue, Lucy y Vel.",
      img: "/contributors/Johanna Ponce.png",
      thumbnail: "/contributors/thumbnail/Johanna Ponce.png",
      url: "https://linktr.ee/johannaponce0",
  },
  {
      name: "Cristina Romero",
      desc: "Me dicen Pompi y tengo 24 años, vivo en zona Sur. Me especializo en animaciones y efectos especiales. Soy una persona que se considera responsable, empática, versátil y me adapto rápido a los cambios. Colaboré con los efectos especiales y en la animación dentro del proyecto y soy Team Leader del área creativa.",
      img: "/contributors/Cristina Romero.png",
      thumbnail: "/contributors/thumbnail/Cristina Romero.png",
      url: "https://linktr.ee/Cristina155",
  },
  {
      name: "Agustín Sanabria",
      desc: "Soy Agustín y soy tutor de inglés especializado en IT, disfruto de leer y estudiar sobre programación y la seguridad de la información. Disfruto mucho desempeñarme en HackCTF como traductor de inglés y Team Leader en el área de Traducción.",
      img: "/contributors/Agustin Sanabria.png",
      thumbnail: "/contributors/thumbnail/Agustin Sanabria.png",
      url: "https://linktr.ee/sanabriaagustin",
  },
  {
      name: "Soledad Segovia",
      desc: "Me llaman Sol. Soy una entusiasta empedernida, empática y soñadora, eterna aprendiz y amante del poder de las TICS. Abogada especializada en derecho informático y compliance, a diferentes start up y amigos del ecosistema. Colaboro en HackCTF como consultora.",
      img: "/contributors/Soledad Segovia.png",
      thumbnail: "/contributors/thumbnail/Soledad Segovia.png",
      url: "https://linktr.ee/soledadsegovia",
  },
  {
      name: "Adri",
      desc: "Me llaman Adri. Colaboro como Cloud Engineer.",
      img: "/contributors/Adriel Garcia.png",
      thumbnail: "/contributors/thumbnail/Adriel Garcia.png",
      url: "https://linktr.ee/adrielgarcia_",
  },
  {
      name: "Ángeles",
      desc: "Mi nombre es Angie, tengo 35 años. Amo debatir sobre innovación y tecnología. Colaboré en HackCTF como analista SEO y ahora como consultora.",
      img: "/contributors/Angeles.png",
      thumbnail: "/contributors/thumbnail/Angeles.png",
      url: "https://linktr.ee/angelesmaquieira",
  },
  {
      name: "César Arreaza",
      desc: "Soy una persona muy curiosa y constantemente me encuentro desafiado por las nuevas tecnologías. También disfruto que me hablen de nuevos proyectos y visiones. Estoy inmerso en este apasionante mundo de la programación y ciberseguridad. Disfruto colaborar en el área de Frontend del proyecto.",
      img: "/contributors/Cesar Arreaza.png",
      thumbnail: "/contributors/thumbnail/Cesar Arreaza.png",
      url: "https://linktr.ee/arreaza.cesar",
  },
  {
      name: "Angela Brizuela",
      desc: "Me dicen Angie, tengo 20 años y soy de Avellaneda, Buenos Aires. Me encuentro en la mitad de la carrera del profesorado de Educación Superior en Matemática y en HackCTF colaboro como Team Leader de RRHH, así como también en la parte organizacional del proyecto dentro del equipo de Management.",
      img: "/contributors/Angela Brizuela.png",
      thumbnail: "/contributors/thumbnail/Angela Brizuela.png",
      url: "https://linktr.ee/angiebrizuela",
  },
  {
      name: "Agustín Tedone",
      desc: "Estudio Ing. en Informática en la UNLaM. Curso la diplomatura en Seg. Inf. en la UTN. Trabajo como CyberSecurity Dev. en el equipo de WebSec de MercadoLibre, en la BU de MercadoPago. Curso la licencia de PPA (Piloto privado de avión) en la base aérea de Morón. En HackCTF me encuentro colaborando en la creación de contenido.",
      img: "/contributors/Agustin Tedone.png",
      thumbnail: "/contributors/thumbnail/Agustin Tedone.png",
      url: "https://linktr.ee/agustedone",
  },
  {
      name: "Marcos Ariel Trejo",
      desc: "Mi nombre es Marcos, soy de La Matanza y tengo 21 años. Me gusta mucho aprender constantemente y adquirir la mayor cantidad de conocimiento posible. Actualmente me encuentro trabajando como Analista de Matcheo en Mercadolibre y soy Team Leader del soporte técnico en HackCTF.",
      img: "/contributors/Marcos Ariel Trejo.png",
      thumbnail: "/contributors/thumbnail/Marcos Ariel Trejo.png",
      url: "https://linktr.ee/marcostrejo_",
  },
  {
      name: "Diego Osorio",
      desc: "Soy Diego, tengo 18 años, me considero muy autodidacta y me apasiona lo que es el hacking/pentesting. Actualmente estoy trabajando en Hackmetrix como AppSec Engineer, donde gozo de un ambiente enriquecedor en todos los sentidos. Además, colaboro en la creación de contenido en HackCTF.",
      img: "/contributors/Diego Osorio.png",
      thumbnail: "/contributors/thumbnail/Diego Osorio.png",
      url: "https://linktr.ee/sha_16_",
  },
  {
      name: "Cynthia D’Amore",
      desc: "Argentina nacida en Mendoza, resido actualmente en Buenos Aires. Soy licenciada en Curaduría e Historia de las Artes de la Universidad del Museo Social Argentino. Me encuentro colaborando en el área de Recursos Humanos.",
      img: "/contributors/Cynthia DAmore.png",
      thumbnail: "/contributors/thumbnail/Cynthia DAmore.png",
      url: "https://linktr.ee/cyndamore",
  },
  {
      name: "Gisela Morichetti",
      desc: "Soy profesional en diseño gráfico y digital. Tengo conocimiento en fotografía, redes sociales y diseño de interacción. Me encanta el arte, el diseño, la comunicación y la tecnología. Estoy colaborando en la parte creativa del proyecto.",
      img: "/contributors/Gisela Morichetti.png",
      thumbnail: "/contributors/thumbnail/Gisela Morichetti.png",
      url: "https://linktr.ee/giselamorichetti",
  },
  {
      name: "Solange Gladinier",
      desc: "Me llamo Sol y desde el 2020 comencé por mi cuenta, con la ayuda de un conocido, a estudiar programación. Soy una persona determinada y persistente, hasta que no logro el objetivo que me proponga no paro. Sigo aprendiendo todos los días y me encuentro colaborando en el área de Frontend.",
      img: "/contributors/Solange Gladinier.png",
      thumbnail: "/contributors/thumbnail/Solange Gladinier.png",
      url: "https://linktr.ee/solgladinier",
  },
  {
      name: "Sabrina Bonanno",
      desc: "Soy Sabrina y tengo 35 años. Soy profesora y traductora de inglés y fundé mi propio instituto hace doce años. Me gusta enseñar y estoy dispuesta a nuevos desafíos. Actualmente estoy aprendiendo sobre ciberseguridad y aportando en HackCTF en la creación de contenido.",
      img: "/contributors/Sabrina Bonanno.png",
      thumbnail: "/contributors/thumbnail/Sabrina Bonanno.png",
      url: "https://linktr.ee/sabrinasbonanno",
  },
  {
      name: "Evelyn Sanabria",
      desc: "Me llamo Evelyn y estoy cursando la diplomatura de ciberseguridad en la UNSTA, así como también la licenciatura en trabajo social en la Universidad Nacional de Lanús. Además, dicto clases de inglés y estoy en el equipo de traducción donde también ayudo a Agustin Sanabria a organizarse con las clases.",
      img: "/contributors/Evelyn Sanabria.png",
      thumbnail: "/contributors/thumbnail/Evelyn Sanabria.png",
      url: "https://linktr.ee/sanabriaevelyn",
  },
  {
      name: "Pablo Baranovsky",
      desc: "Nací en Punta Alta, una pequeña ciudad. Soy una persona curiosa, sociable y muy responsable. Estudié y me recibí de contador público, pero sigo buscando mi motivación laboral, así que decidí colaborar en el equipo de Backend en HackCTF.",
      img: "/contributors/Pablo Baranovsky.png",
      thumbnail: "/contributors/thumbnail/Pablo Baranovsky.png",
      url: "https://linktr.ee/pablo.baranovsky",
  },
  {
      name: "Chris Anderson",
      desc: "Me llamo Chris, tengo 39 años y soy de Inglaterra. Trabajo como especialista de ciberseguridad en una empresa financiera multinacional. En HackCTF, formo parte del equipo de colaboradores en la creación de contenidos.",
      img: "/contributors/Chris Anderson.png",
      thumbnail: "/contributors/thumbnail/Chris Anderson.png",
      url: "https://linktr.ee/chris.anderson",
  },
  {
      name: "Emmanuel Martínez",
      desc: "Hola mi nombre es Emmanuel, tengo 24 años de edad. Soy co-fundador del proyecto Dirty Code con el cual buscamos apoyar a personas interesadas en temas de programación y tecnología, asimismo con nuestros proyectos buscamos ayudar a la sociedad en general. Soy aficionado al hacking ético y la ciberseguridad. Originario del estado de Oaxaca en México. Colaboro en la sección de creación de contenido para HackCTF.",
      img: "/contributors/Emmanuel Martinez.png",
      thumbnail: "/contributors/thumbnail/Emmanuel Martinez.png",
      url: "https://linktr.ee/dirtycodemex",
  },
  {
      name: "Jeisson Ramírez",
      desc: "Mi nombre es Jeisson, tengo 27 años y vivo en Bogotá, Colombia. Soy diseñador gráfico y estudié matemáticas. Me desempeño en ilustración, animación, pedagogía y programación. También he trabajado en proyectos de realidad aumentada online, redes neuronales para ilustración y animación, así como también en análisis y visualización de datos. Mi sueño es combinar arte y ciencia para construir un entorno sociocultural en donde ellas puedan crecer sanamente y HackCTF es una plataforma que permite este cometido.",
      img: "/contributors/Jeisson Ramirez.png",
      thumbnail: "/contributors/thumbnail/Jeisson Ramirez.png",
      url: "https://linktr.ee/jeidsgn",
  },
  {
      name: "Ariana Lapegna",
      desc: "Mi nombre es Ariana, tengo 20 años y soy de Buenos Aires, Argentina. Actualmente estoy estudiando Bioquímica y tengo conocimientos en ilustración, diseño gráfico, motion graphics e ingeniería de audio. Colaboro en HackCTF como parte del equipo creativo y el equipo de Management.",
      img: "/contributors/Ariana Lapegna.png",
      thumbnail: "/contributors/thumbnail/Ariana Lapegna.png",
      url: "https://linktr.ee/aniiangepal",
  },
  {
      name: "Alexis Hernández",
      desc: "Mi nombre es Alexis Hernández tengo 22 años, cofundador del proyecto Dirty Code el cual busca ser una guía en el camino del desarrollador y ser una alternativa a las necesidades de la sociedad en general. Soy originario de Oaxaca, México. A principios de 2022 fui invitado al proyecto HackCTF en la parte de creación de contenido.",
      img: "/contributors/Alexis Hernandez.png",
      thumbnail: "/contributors/thumbnail/Alexis Hernandez.png",
      url: "https://linktr.ee/dirtycodemex",
  },
  {
      name: "Ignacio Presas",
      desc: "Me encanta la tecnología y desde muy temprana edad me apasiona estudiar sobre seguridad de la información, desarrollo de software, informática, entre otros. Gran parte de ese viaje fue autodidacta, pero también tuve la oportunidad de completar cursos. Actualmente me encuentro aportando en el área de Frontend en HackCTF y gracias a eso, logré conocer personas increíbles de quienes aprendí muchas cosas.",
      img: "/contributors/Ignacio Presas.png",
      thumbnail: "/contributors/thumbnail/Ignacio Presas.png",
      url: "https://linktr.ee/nv.cho",
  },
  {
      name: "Fabián Álvarez",
      desc: "Me llamo Fabián y me desempeño como analista de seguridad informática hace más de 10 años. En HackCTF aporté en la creación de contenido y en este momento me encuentro colaborando en el área de Backend.",
      img: "/contributors/Fabian Alvarez.png",
      thumbnail: "/contributors/thumbnail/Fabian Alvarez.png",
      url: "https://linktr.ee/Fabian.alvarez",
  },
  {
      name: "Evenly Herrera",
      desc: "Me llamo Evenly, soy nacida en Colombia pero actualmente resido en Buenos Aires. Soy ingeniera ambiental y de saneamiento con experiencia en el manejo de la gestión ambiental y en el seguimiento de planes de manejo ambiental. Disfruto de ser parte de HackCTF donde coopero en el equipo de Management.",
      img: "/contributors/Evenly Herrera.png",
      thumbnail: "/contributors/thumbnail/Evenly Herrera.png",
      url: "https://linktr.ee/evenlyherrera",
  },
  {
      name: "Franco Villarreal",
      desc: "Estudiante de la carrera de Informática (Lic. en informática) en la Universidad Nacional de La Plata, Argentina. Manejo con gran eficacia Python, Java, HTML, CSS y Javascript. Colaboro en el Backend de HackCTF.",
      img: "/contributors/Franco Villarreal.png",
      thumbnail: "/contributors/thumbnail/Franco Villarreal.png",
      url: "https://linktr.ee/francovillarreal",
  },
  {
      name: "Selva Mochen",
      desc: "Me llamo Selva, soy Executive Coach y me dedico a la consultoría con procesos de Coaching. A día de hoy contribuyo en HackCTF como miembro del equipo de Recursos Humanos.",
      img: "/contributors/Selva Mochen.png",
      thumbnail: "/contributors/thumbnail/Selva Mochen.png",
      url: "https://linktr.ee/selvamochen",
  },
  {
      name: "Adrià Pérez Montoro",
      desc: "Me llamo Adrià A.K.A (b1n4ri0), soy estudiante de Pentesting de forma autodidacta. Dentro de HackCTF mi rol es crear contenido en las guías y ayudo en algunos retos.",
      img: "/contributors/Adria Perez Montoro.png",
      thumbnail: "/contributors/thumbnail/Adria Perez Montoro.png",
      url: "https://linktr.ee/b1n4ri0",
  },
];


export default contributors;
