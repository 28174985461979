import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import LoadingSpinner from '../common/loadingSpinner'
import { updateSocialMedia, changePasswordFromProfile, updateProfileData, uploadProfilePicture } from '../../services/userService'
import UserDashboard from './userDashboard';
import usersFunctions from './usersFunctions'

const Profile = () => {
  const user = useSelector((state) => state.user);
  const [t] = useTranslation('global')
  const { userData, currentUser, token } = user
  const id = userData.id
  const profilePicture = userData.profilePictureUrl
  const [userInfo, setUserInfo] = useState([]);
  const [twitter, setTwitter] = useState('');
  const [github, setGithub] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linktree, setLinktree] = useState('');
  const [professions, setProfessions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stats, setStats] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [socialsLoading, setSocialsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [uploadImageButton, setUploadImageButton] = useState(false);
  const fullNameRef = useRef();
  const userCountryRef = useRef();
  const userProfessionRef = useRef();
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const [toggleTab, setToggleTab] = useState(1)

  const providerVerification = currentUser.providerData && currentUser.providerData.length > 0
    ? currentUser.providerData[0].providerId
    : null;

  const filterCountry = countries?.length && countries.filter(country => country.id === userData.countryId)
  const userCountry = countries?.length && filterCountry.map(country => country.name)
  const filterProfession = professions?.length && professions.filter(profession => profession.id === userData.userProfessionId)
  const userProfession = professions?.length && filterProfession.filter(profession => profession.name)
  const userTwitter = userInfo && userInfo.userSocialMedia && userInfo.userSocialMedia.twitter
  const userGithub = userInfo && userInfo.userSocialMedia && userInfo.userSocialMedia.github
  const userLinkedin = userInfo && userInfo.userSocialMedia && userInfo.userSocialMedia.linkedin
  const userInstagram = userInfo && userInfo.userSocialMedia && userInfo.userSocialMedia.instagram
  const userLinktree = userInfo && userInfo.userSocialMedia && userInfo.userSocialMedia.linktree

  const handleSocials = (e) => {

    const socialLinks = {
      twitter: twitter.length > 0 ? twitter : userTwitter,
      github: github.length > 0 ? github : userGithub,
      linkedin: linkedin.length > 0 ? linkedin : userLinkedin,
      instagram: instagram.length > 0 ? instagram : userInstagram,
      linktree: linktree.length > 0 ? linktree : userLinktree,
    }

    e.preventDefault();
    setSocialsLoading(true)
    updateSocialMedia(socialLinks, token)
    usersFunctions.HandleGetUser(setUserInfo, setSocialsLoading, id, token)
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      usersFunctions.HandleGetProfessionsList(setProfessions, token)
      usersFunctions.HandleGetCountryList(setCountries, token)
      usersFunctions.HandleGetUserStats(setStats, id, token)
      usersFunctions.HandleGetUser(setUserInfo, setSocialsLoading, id)
    }
    return () => {
      isMounted = false;
    }
  }, [])

  const handleImageToBase64 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setUploadImageButton(true)
    }
  };

  const handleUpload = async () => {
    setIsLoading(true)
    const base64Image = imagePreview.split(',')[1]; // Extract base64 data
    await uploadProfilePicture(base64Image, token)
    setIsLoading(false)
  };

  const handleNewPassword = (e) => {
    e.preventDefault();
    setIsLoading(true)
    changePasswordFromProfile(oldPasswordRef.current.value, newPasswordRef.current.value, token)
      .then(() => setIsLoading(false))
  }

  const handleBioData = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const payload = {
      name: fullNameRef.current.value,
      countryId: userCountryRef.current.value,
      userProfessionId: userProfessionRef.current.value,
    }

    const update = await updateProfileData(payload)
    if (update) {
      await usersFunctions.HandleGetUser(setUserInfo, setSocialsLoading, id, token)
    }
    setIsLoading(false)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      className='flex flex-col h-full w-full min-h-[600px]'
    >

      <div className='flex items-center h-16 p-3 text-xl profile-container w-full'>
        <h2 className='block w-full text-base sm:text-lg lg:text-xl mx-auto max-w-[1920px]'>
          {t('profile.title')},{' '}
          <span className='font-medium'>{userData.name}.</span>
        </h2>
      </div>
      <div className='flex flex-col m-2 w-full max-w-[1500px] lg:m-5 lg:mx-auto px-1'>
        <div className='-mb-2'>
          <button className={`${toggleTab === 1 ? 'bg-[#662D91]' : 'bg-slate-400 dark:bg-[#303030]'} tab-btn`}
            onClick={() => setToggleTab(1)}>Dashboard</button>
          <button className={`${toggleTab === 2 ? 'bg-[#662D91]' : 'bg-slate-400 dark:bg-[#303030]'} tab-btn`}
            onClick={() => setToggleTab(2)}>Perfil</button>
        </div>

        {toggleTab === 1 && <div className='bg-slate-400 dark:bg-[#303030] rounded-lg rounded-tl-none w-full'>
          <UserDashboard stats={stats} />
        </div>}
        {toggleTab === 2 && (
          <div className='bg-slate-400 dark:bg-[#303030] rounded-lg rounded-tl-none'>
            <div className='w-full flex flex-col lg:flex-row'>
              <div className='flex flex-col p-3 profile-container rounded-xl lg:p-5 m-1 lg:w-1/2'>
                <h3 className='pb-3 text-base xs:text-lg font-bold'>
                  {t('profile.information.title')}
                </h3>
                <div className='flex flex-col rounded-sm xl:flex-col w-full'>
                  <div className='flex pb-4 lg:pr-5 lg:shrink-0 flex-col items-center'>
                    <div className="group relative inline-block mb-4">
                      <img
                        height='200px'
                        width='200px'
                        className='w-40 h-40 rounded-full border-double border-4 border-fuchsia-500'
                        src={
                          imagePreview ||
                          profilePicture ||
                          '/blank-profile-picture.webp'
                        }
                        alt='avatar-profile'
                        style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}

                      />
                      <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <p className="bg-white text-gray-800 font-semibold py-2 px-4 rounded-md">
                          Cambiar foto
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageToBase64}
                        className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                    {uploadImageButton ?
                      <>
                        <p>Recuerda que la imagen debe ser 1:1 y de <b>1080x1080</b></p>
                        <button onClick={handleUpload} className='btn mt-5 bg-[#662D91]' disabled={!imagePreview}>
                          Guardar cambios
                        </button>
                      </>
                      : null
                    }
                    {/* {isLoading ? <LoadingSpinner size={"250px"} /> : null} */}
                    {/* <p className='text-sm xs:text-base p-2'>
                    {t('profile.score')}
                  </p>
                  <p className='text-sm xs:text-base font-medium'>
                    {userData && userData.score ? userData.score : ''}{' '}
                    {t('profile.score_cant')}
                  </p> */}
                  </div>
                  <div className='flex flex-col text-sm xs:text-base' style={{ marginTop: '49px' }}>
                    <form action='submit'>
                      <label htmlFor='username' className='font-semibold'>
                        {t('profile.information.username')}
                      </label>
                      <input
                        id='username'
                        name='username'
                        className='user-input w-full mb-3 disabled-input rounded-lg font-normal'
                        type='text'
                        defaultValue={user ? userData.username : ''}
                        placeholder={t(
                          'profile.information.username_placeholder'
                        )}
                        autoComplete="username"
                        disabled
                      />
                      <label htmlFor='email' className='font-semibold'>
                        {t('profile.information.email')}
                      </label>
                      <input
                        id='email'
                        name='email'
                        className={`user-input w-full mb-3 disabled-input rounded-lg font-normal ${providerVerification !== 'password'
                          ? 'disabled-input'
                          : ''
                          }`}
                        type='email'
                        defaultValue={user ? userData.email : ''}
                        placeholder={t('profile.information.email_placeholder')}
                        autoComplete="email"
                        disabled={providerVerification !== 'password'}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-between p-3 profile-container rounded-xl lg:p-5 m-1 lg:w-1/2'>
                <h3 className='pb-3 text-base xs:text-lg font-bold'>
                  {t('profile.social')}
                </h3>
                <form className='text-sm xs:text-base' onSubmit={handleSocials}>

                  <span className='font-semibold'>Twitter</span>
                  <div className='flex flex-row gap-2'>
                    <label htmlFor='twitter' className='dark:text-slate-100 text-black dark:bg-slate-600 font-normal rounded-lg disabled-input user-input max-h-[37px] max-w-[182px]'>
                      https://twitter.com/
                    </label>
                    <input
                      id='twitter'
                      name='twitter'
                      className='user-input w-full mb-3 rounded-lg font-normal'
                      onChange={(e) => {
                        setTwitter(e.target.value)
                      }}
                      type='text'
                      defaultValue={
                        userTwitter
                      }
                      placeholder='Agrega tu perfil de Twitter'
                    />
                  </div>
                  <span className='font-semibold'>Github</span>
                  <div className='flex flex-row gap-2'>
                    <label htmlFor='github' className='dark:text-slate-100 text-black rounded-lg dark:bg-slate-600 font-normal disabled-input user-input max-h-[37px] max-w-[182px]'>
                      https://github.com/
                    </label>
                    <input
                      id='github'
                      name='github'
                      className='user-input w-full mb-3 rounded-lg font-normal'
                      onChange={(e) => {
                        setGithub(e.target.value)
                      }}
                      type='text'
                      defaultValue={
                        userGithub
                      }
                      placeholder='Agrega tu perfil de Github'
                    />
                  </div>
                  <span className='font-semibold'>Linkedin</span>
                  <div className='flex flex-row gap-2'>
                    <label htmlFor='linkedin' className='dark:text-slate-100 text-black rounded-lg dark:bg-slate-600 font-normal disabled-input user-input max-h-[37px] max-w-[182px]'>
                      https://linkedin.com/
                    </label>
                    <input
                      id='linkedin'
                      name='linkedin'
                      className='user-input w-full mb-3 rounded-lg font-normal'
                      onChange={(e) => {
                        setLinkedin(e.target.value)
                      }}
                      type='text'
                      defaultValue={
                        userLinkedin
                      }
                      placeholder='Agrega tu perfil de Linkedin'
                    />
                  </div>
                  <span className='font-semibold'>Instagram</span>
                  <div className='flex flex-row gap-2'>
                    <label htmlFor='linkedin' className='dark:text-slate-100 text-black rounded-lg dark:bg-slate-600 font-normal disabled-input user-input max-h-[37px] max-w-[182px]'>
                      https://instagram.com/
                    </label>
                    <input
                      id='instagram'
                      name='instagram'
                      className='user-input w-full mb-3 rounded-lg font-normal'
                      onChange={(e) => {
                        setInstagram(e.target.value)
                      }}
                      type='text'
                      defaultValue={
                        userInstagram
                      }
                      placeholder='Agrega tu perfil de Instagram'
                    />
                  </div>
                  <span className='font-semibold'>Linktree</span>
                  <div className='flex flex-row gap-2'>
                    <label htmlFor='linktree' className='dark:text-slate-100 rounded-lg text-black dark:bg-slate-600 font-normal disabled-input user-input max-h-[37px] max-w-[182px]'>
                      https://linktr.ee/
                    </label>
                    <input
                      id='linktree'
                      name='linktree'
                      className='user-input w-full mb-3 rounded-lg font-normal'
                      onChange={(e) => {
                        setLinktree(e.target.value)
                      }}
                      type='text'
                      defaultValue={
                        userLinktree
                      }
                      placeholder='Agrega tu Linktree'
                    />
                  </div>
                  <button
                    className='block btn ml-auto'
                    disabled={isLoading}
                    type='submit'
                  >
                    {t('profile.password.save_button')}
                  </button>
                </form>
              </div>
            </div>

            {/* profile sketch design */}
            <div className='flex flex-col lg:flex-row'>
              <div className='flex flex-col justify-between p-3 profile-container rounded-xl lg:p-5 m-1 lg:w-1/2'>
                <h3 className='pb-3 text-base xs:text-lg font-bold'>
                  Biografia
                </h3>
                <form className='text-sm xs:text-base' onSubmit={handleBioData}>
                  <label htmlFor='fullname' className='font-semibold'>
                    {t('profile.information.fullname')}
                  </label>
                  <input
                    id='fullname'
                    name='fullname'
                    className='user-input w-full mb-3 rounded-lg font-normal'
                    type='text'
                    defaultValue={
                      userData ? userData.name : ''
                    }
                    placeholder={t(
                      'profile.information.fullname_placeholder'
                    )}
                    ref={fullNameRef}
                  />
                  <label htmlFor='nationality' className='font-semibold'>Nacionalidad</label>
                  <select id='nationality' className="user-input rounded-lg font-normal" ref={userCountryRef}
                    defaultValue={userCountry && userCountry[0]}
                  >
                    {countries && countries.map((country, index) => {
                      return (
                        <option key={index} value={country.id} >
                          {country.name}
                        </option>
                      );
                    })}
                  </select>

                  <label htmlFor='profession' className='font-semibold'>Profesión</label>
                  <select id='profession' className="user-input rounded-lg font-normal" ref={userProfessionRef}
                    defaultValue={userProfession[0]}
                  >
                    {professions && professions.map((profession, index) => {
                      return (
                        <option key={index} value={profession.id}>
                          {profession.name}
                        </option>
                      );
                    })}
                  </select>

                  <button
                    className='block btn ml-auto'
                    disabled={isLoading}
                    type='submit'
                  >
                    Guardar cambios
                  </button>

                  {/* <button
                  className='block btn ml-auto'
                  disabled={isLoading}
                  type='submit'
                >
                  {t('profile.password.save_button')}
                </button> */}
                  {/* {isLoading ? <LoadingSpinner size={"250px"} /> : null} */}

                  {/* <label htmlFor='biography'>Biografia</label>
                <textarea className='user-input w-full mb-3' placeholder='Escribe algo breve sobre ti...'/> */}
                </form>
              </div>
              <div className='flex flex-col p-3 m-1 profile-container rounded-xl lg:p-5 lg:w-1/2'>
                <div>
                  <h3 className='pb-3 text-base xs:text-lg font-bold'>
                    {t('profile.password.change_pass')}
                  </h3>
                  <form onSubmit={handleNewPassword} className='text-sm xs:text-base'>
                    <label htmlFor='oldpass' className='font-semibold'>
                      {t('profile.password.oldpass')}
                    </label>
                    <input
                      id='oldpass'
                      className='user-input w-full mb-5 text-black rounded-lg font-normal'
                      type='password'
                      placeholder={t('profile.password.oldpass_placeholder')}
                      ref={oldPasswordRef}
                    />
                    <label htmlFor='newpass' className='font-semibold'>
                      {t('profile.password.newpass')}
                    </label>
                    <input
                      id='newpass'
                      className='user-input w-full mb-5 text-black rounded-lg font-normal'
                      type='password'
                      placeholder={t('profile.password.newpass_placeholder')}
                      ref={newPasswordRef}
                    />
                    <label htmlFor='confirmpass' className='font-semibold'>
                      {t('profile.password.confirmpass')}
                    </label>
                    <input
                      id='confirmpass'
                      className='user-input w-full mb-5 text-black rounded-lg font-normal'
                      type='password'
                      placeholder={t(
                        'profile.password.confirmpass_placeholder'
                      )}
                    />

                    <button type='submit' className='block btn ml-auto cursor-pointer'>
                      {t('profile.password.save_button')}
                    </button>
                    {/* {isLoading ? <LoadingSpinner size={"250px"} /> : null} */}
                  </form>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default Profile