import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Main from './components/main/main'
import UserForm from './components/account/userForm'
import ForgotPassword from './components/account/forgotPassword'
import Profile from './components/profile/Profile'
import Scoreboard from './components/scoreboard'
import Admin from './components/admin/admin'
import GuidesList from './components/guides/guidesList'
import ChallengesList from './components/challenges/challengesList'
import About from './components/about/about'
import TermsAndConditions from './components/termsAndConditions'
import UserVerification from './components/account/userVerification'
import Navbar from './components/layout/navbar'
import Footer from './components/layout/footer'
import LevelSelection from './components/common/levelSelection'
import ChallengeInfo from './components/challenges/challengeInfo'
import GuideInfo from './components/guides/guideInfo'
import RequiresAuth from './components/common/requiresAuth'
import RequiresAdmin from './components/common/requiresAdmin'
import RequiresNoUser from './components/common/requiresNoUser'
import NotFound from './components/common/notFound'
import ScrollToTop from './components/common/scrollToTop'
import 'react-toastify/dist/ReactToastify.css'
import { settingsActions } from "./redux/slices/settingsSlice";
import { userActions } from "./redux/slices/userSlice";
import PathSelection from "./components/learningPath/pathSelection";
import PathsList from "./components/learningPath/pathsList";
import ConfirmPassword from "./components/account/confirmPassword";
import { jwtDecode } from "jwt-decode";

import { getGuideList, getLevelList, getTagList } from "./services/guidesService"
import { getUserReadGuides, getUserStreak } from "./services/userService";
import { getChallengeList } from "./services/challengesService";
import { getLearningPathList } from "./services/learningPathService";
import VNCComponent from "./components/vnc/vnc";
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://362785560be562cd0dd521f80421f4e2@o4507794995675136.ingest.us.sentry.io/4507794999738368",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global"); // eslint-disable-line no-unused-vars
  const theme = useSelector((state) => state.theme.value);
  const token = localStorage.getItem("token")
  const Location = useLocation();

  useEffect(() => {
    dispatch(settingsActions.setLanguage(i18n.language));
  }, [dispatch, i18n.language]);

  // When the token is loaded it makes a call to the endpoint to bring the guides, the isMounted constant is only to check whether the component is mounted.
  useEffect(() => {
    if (token) {
      (async () => {
        await getInitialData();
      })();
    }

  }, [token]);

  // When the location changes, if the token expires, it clears the localstorage and reloads the page.
  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      if (Date.now() > (decoded.exp) * 1000) {
        location.reload();
        localStorage.removeItem("currentUser")
        localStorage.removeItem("token")
        localStorage.removeItem("userData")
      }
    }
  }, [Location])

  const getInitialData = async () => {

    const userData = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"))

    dispatch(settingsActions.setGuides(await getGuideList()))
    dispatch(settingsActions.setChallenges(await getChallengeList()))
    dispatch(settingsActions.setLearningPaths(await getLearningPathList()))
    dispatch(settingsActions.setLevels(await getLevelList()))
    dispatch(settingsActions.setTags(await getTagList()))
    dispatch(userActions.setStreak(await getUserStreak(token)))
    dispatch(userActions.setReadGuides(await getUserReadGuides(userData?.id)))
  }

  return (
    (
      <>
        <div className={`App ${theme === 'dark' ? 'dark' : 'light'}`}>
          <ToastContainer theme={theme === 'dark' ? 'dark' : 'light'} />
          <div className={`pt-[44px] flex flex-col min-h-screen  ${Location.pathname?.includes("attacker-vm") ? "pt-[0px] bg-black" : "bg-gradient-to-r from-[rgba(67,121,195,1)] to-[rgba(194,107,150,1)85%]"}`}>
            <Navbar />
            <ScrollToTop>
              <Routes>
                {/* Needs authentication to access these routes */}
                <Route element={<RequiresAuth />}>
                  <Route
                    path='/guides'
                    element={<LevelSelection value='guides' />}
                  />
                  <Route path='/guides/:level/*' element={<GuidesList />} />
                  <Route path='/guides/:level/:id/*' element={<GuideInfo />} />
                  <Route
                    path='/challenges'
                    element={<LevelSelection value='challenges' />}
                  />
                  <Route path='/challenges/:level/*' element={<ChallengesList />} />
                  <Route path='/challenges/:level/:id/*' element={<ChallengeInfo />} />

                  <Route
                    path='/learningPaths'
                    element={<PathSelection />}
                  />
                  <Route path='/learningPaths/:id/*' element={<PathsList />} />

                  <Route path='/profile' element={<Profile />} />

                  <Route
                    path="/user-verification"
                    element={<UserVerification />}
                  />

                  <Route path='/attacker-vm' element={<VNCComponent />} />
                </Route>

                {/* Only admins can access these routes */}
                <Route element={<RequiresAdmin />}>
                  <Route
                    path='/admin'
                    element={<Navigate replace to='/admin/creation/newGuide' />}
                  />
                  <Route path='/admin/*' element={<Admin />} />
                </Route>

                {/* Can't access if you're logged-in */}
                <Route element={<RequiresNoUser />}>
                  <Route path='/login' element={<UserForm login />} />
                  <Route path='/register' element={<UserForm register />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/confirm-password' element={<ConfirmPassword />} />
                </Route>
                <Route path='/scoreboard' element={<Scoreboard />} />
                <Route path='/about-us' element={<About />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/' element={<Main />} />
                <Route path='/not-found' element={<NotFound />} />
                <Route path='*' element={<Navigate replace to='not-found' />} />

              </Routes>
            </ScrollToTop>
            {
              Location.pathname?.includes("attacker-vm") ? null : <Footer />
            }
          </div>
        </div>

      </>
    )
  );
}

export default App
