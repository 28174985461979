import { getCountryList, getProfessionsList, getUserStats, getUser, getScoreboardList } from "../../services/userService"

const HandleGetCountryList = async (setCountries, token) => {
    const element = await getCountryList(token)
    setCountries(element)
};

const HandleGetProfessionsList = async (setProfessions, token) => {
    const element = await getProfessionsList(token)
    if (element)
        setProfessions(element)
    else {
        setProfessions([])
    }
};

const HandleGetUserStats = async (setStats, id, token) => {
    const element = await getUserStats(id, token)
    setStats(element)
};

const HandleGetUser = async (setUserInfo, id) => {
    
    if(typeof id !== 'string') return

    const element = await getUser(id)
    if(element){
        setUserInfo(element)
    }
};

const HandleScoreboard = async (setScoreboardList, setIsLoading, token) => {
    const element = await getScoreboardList(token)
    if (element) {
        setScoreboardList(element)
        setIsLoading(false)
    } else {
        setScoreboardList([])
        setIsLoading(false)
    }

};



const usersFunctions = {
    HandleGetCountryList,
    HandleGetProfessionsList,
    HandleGetUserStats,
    HandleGetUser,
    HandleScoreboard,
};

export default usersFunctions