import { toast } from 'react-toastify'
import {
    GUIDE_URL_LIST, LEARNINGPATH_URL_LIST, LEARNINGPATH_URL_GET, LEARNINGPATH_URL_CREATE, LEARNINGPATH_URL_DELETE, LEARNINGPATH_URL_UPDATE
} from './apiRoutes';
import { makeRequest } from './makeRequest';


export async function createLearningPath(learningPath) {

    const response = await makeRequest(
        "POST",
        `${LEARNINGPATH_URL_CREATE}`,
        {
            payload: learningPath,
            auth: true
        }
    )

    if (response && response?.status === 200) {
        return response.result
    } else {
        return null
    }

};


export async function deleteLearningPath(learningPathId, token) {

    const response = await makeRequest(
        "DELETE",
        `${LEARNINGPATH_URL_DELETE}/${learningPathId}`,
        {
            auth: true
        }
    )

    if (response && response?.status === 200) { 
        toast.success('LearningPath borrado')
        return response.result
    } else {
        toast.error('No se pudo borrar el LearningPath pruebe otra vez')
        return null
    }
};
export async function getLearningPathList() {

    const response = await makeRequest(
        'GET',
        `${LEARNINGPATH_URL_LIST}?paginate=false`,
        {
            auth: true
        }
    )

    if (response && response?.status === 200) {
        return response.result
    } else {
        return null
    }
};
export async function getLearningPath(learningPathId, token) {
    const response = await makeRequest(
        "GET",
        `${LEARNINGPATH_URL_GET}/${learningPathId}`,
        {
            auth: true
        }
    )

    if (response && response?.status === 200) {
        return response.result
    } else {
        return null
    }
};
export async function updateLearningPath(newFields, learningPathId) {
    const response = await makeRequest(
        "PUT",
        `${LEARNINGPATH_URL_UPDATE}/${learningPathId}`,
        {
            payload: newFields,
            auth: true
        }
    )
    if (response && response?.status === 200) {
        return response.result
    } else {
        return null
    }
};
export async function getGuidesList(category, token) {

    const response = await makeRequest(
        "GET",
        `${GUIDE_URL_LIST}?tagId=${category}&sortBy=difficultyOrder%3Aasc`,
        {
            auth: true
        }
    )
    if (response && response?.status === 200) {
        return response.result
    } else {
        return null
    }
};


export const getRelatedGuidesAndChallenges = async (learningPathId) => {

    const response = await makeRequest('GET', `${LEARNINGPATH_URL_GET}/${learningPathId}/contentbytag`, {
        auth: true
    })

    if (response && response?.status === 200) {
        return response.result
    }
    return null
}