import React, { useEffect, useState } from 'react'
import { getQuiz } from '../../../services/userService'
import { getClassByLevel } from '../../../utils/assignBgColorByLevel'

const QuestionnaireCard = ({
    level,
    currentChallenge,
    quizId,
    showQuestions = false,
    selectedQuestionnaireId,
    handleChangeQuestionnaire,
    setIsSubChallenge
}) => {

    // eslint-disable-next-line no-unused-vars
    const [quiz, setQuiz] = useState(null)

    useEffect(() => {
        if (quizId) {
            fetchQuiz()
        }
    }, [currentChallenge])

    const fetchQuiz = async () => {
        const response = await getQuiz(quizId)

        if (response) {
            setQuiz(response)
        }
    }

    const handleOpenQuestionnaire = () => {
        const questions = []

        quiz?.quizQuestion?.forEach(question => {
            question.answers = question.quizAnswer
            questions.push(question)
        })

        const aux = {
            ...quiz,
            questions
        }

        setIsSubChallenge(false)

        handleChangeQuestionnaire(aux)
    }


    return (
        <div className="flex flex-col justify-start md:min-w-[260px] lg:min-w-[350px] selection-block z-0 pt-0 mt-0">
            <p className={`${level === 'Principiante' ? 'bg-[#4379C3]' : level === 'Intermedio' ? 'bg-[#C36B96]' : 'bg-[#662D91]'} 
                  py-5 text-lg mt-[-16px] w-[200px] text-white rounded-b-lg`}>
                {quiz?.name}
            </p>
            {
                (quiz && quiz?.quizQuestion?.length > 0) ?
                    <>
                        <img
                            src={'/business_assets/Menu-Challenge-Guide-Questionary-Challenge/Questionary-v2.webp'}
                            alt="header-img"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                            }}
                            className="p-2 max-w-[300px] max-h-[150px] mt-4"
                        />
                    </>
                    : (
                        <p className="text-center mt-12">
                            No hay preguntas relacionados
                        </p>
                    )
            }

            <div
                className='flex flex-col items-center flex-grow relative w-full mt-5'
            >
                {
                    (quiz && quiz?.quizQuestion?.length > 0) ?
                        <>
                            <p className="">
                                {quiz.description}
                            </p>
                            <button
                                type='button'
                                className={`btn font-bold text-sm absolute bottom-0 w-[75%] mx-auto ${getClassByLevel(level)}`}
                                onClick={handleOpenQuestionnaire}
                            >
                                {(selectedQuestionnaireId === quiz?.id && showQuestions) ? "Cerrar cuestionario" : "Comenzar cuestionario"}
                            </button>
                        </>
                        : null
                }
            </div>
        </div>

    )
}

export default QuestionnaireCard
