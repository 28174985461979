import { useEffect } from 'react'

export default function useClickOutside (ref, secondRef, handleMenu, handleSecondMenu) {
  useEffect(() => {
    const handleClick = (event) => {
      if (!ref.current.contains(event.target) && !secondRef.current.contains(event.target)) {
        handleMenu(false)
        handleSecondMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  })
}
