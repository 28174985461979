function NotFound () {
  return (
    <div className='flex justify-center items-start h-full'>
      <div className='flex flex-col justify-center items-center text-center min-h-[600px] p-2 '>
        <h3 className='title'>404 Not Found</h3>
        <p className='text-xl text-white font-semibold max-w-5xl'>The page you requested was not found.</p>
      </div>
    </div>
  )
}

export default NotFound
