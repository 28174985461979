import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import usersFunctions from './profile/usersFunctions';
import LoadingSpinner from "./common/loadingSpinner";
import { Pagination } from "@mui/material";
import ParticlesBackground from './main/particlesBackground';
// import { useTranslation } from 'react-i18next';

function Scoreboard() {
  const token = useSelector((state) => state.user.token);
  const theme = useSelector((state) => state.theme.value);
  const [scoreboardList, setScoreboardList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const PageSize = 10;

  const paginationStyles = {
    ul: {
      '& .MuiPaginationItem-root': {
        color: theme === 'dark' ? '#f3f4f6' : '#030712'
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (scoreboardList[0]) {
        setIsLoading(true);
      } else {
        usersFunctions.HandleScoreboard(setScoreboardList, setIsLoading, token);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [token, scoreboardList]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return scoreboardList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, scoreboardList]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="flex flex-col w-full p-2 text-gray-700 dark:text-gray-100">
      <ParticlesBackground className="z-1" />
      <h1 className="title flex justify-center my-6">Scoreboard</h1>
      <div className="flex flex-row flex-wrap z-0 max-w-[600px] lg:max-w-full mx-auto justify-center px-4 lg:py-4 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
        <table className="w-full border-collapse">
          <thead className="text-base border-b border-gray-300 dark:border-gray-600">
            <tr>
              <th className='text-xl px-[50px] py-2 border-r border-gray-300 dark:border-gray-600 text-center'>Usuario</th>
              <th className='text-xl px-[50px] py-2 border-r border-gray-300 dark:border-gray-600 text-center'>País</th>
              <th className='text-xl px-[50px] py-2 border-r border-gray-300 dark:border-gray-600 text-center'>Guías Leidas</th>
              <th className='text-xl px-[50px] py-2 border-r border-gray-300 dark:border-gray-600 text-center'>Retos Completados</th>
              <th className='text-xl px-[50px] py-2 text-center'>Puntaje</th>
            </tr>
          </thead>
          <tbody className="font-light">
            {currentTableData[0] ? currentTableData.map((item, index) => (
              <tr key={index} className="border-b border-gray-200 dark:border-gray-700">
                <td className="font-normal text-lg py-3 px-[50px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item?.username}</td>
                <td className="font-normal text-lg py-3 px-[50px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item?.country}</td>
                <td className="font-normal text-lg py-3 px-[50px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item?.readGuides}</td>
                <td className="font-normal text-lg py-3 px-[50px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item?.completedChallenges}</td>
                <td className="font-bold text-lg py-3 px-[50px] text-center align-middle">{item?.score}</td>
              </tr>
            )) : !isLoading ? (
              <tr>
                <td colSpan={5} className='w-30 mt-5'><LoadingSpinner size={"250px"} /></td>
              </tr>
            ) : (
              <tr>
                <td colSpan={5} className='text-lg mt-5 text-center'>No hay Datos</td>
              </tr>
            )}
          </tbody>
        </table>

        {currentTableData[0] && (
          <div className="flex justify-center my-4">
            <Pagination
              sx={paginationStyles}
              count={Math.ceil(scoreboardList.length / PageSize)}
              size="large"
              color="secondary"
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Scoreboard;
