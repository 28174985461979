import { useState, useEffect } from "react";
import edChallFunc from "./challengeEditorFunctions";
import { useSelector } from 'react-redux'
import DeleteDocumentModal from "../common/deleteDocumentModal";
import { useParams, Link } from "react-router-dom";
import LoadingSpinner from "../common/loadingSpinner";
import CreateQuizModal from "../common/createQuizModal";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const QuizList = () => {

    const token = useSelector((state) => state.user.token)
    const scoreTypesState = useSelector((state) => state.settings.scoreTypes);

    const { challengeId } = useParams();
    const [deleteQuizName, setDeleteQuizName] = useState('');
    const [currentQuizId, setCurrentQuizId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false)
    const [quizList, setQuizList] = useState(['']);
    const [spinner, setSpinner] = useState(false)
    const [createQuiz, setCreateQuiz] = useState(false)

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSpinner(true)
            edChallFunc.handleGetQuizList(challengeId, setQuizList, setSpinner, token)
        }
        return () => {
            isMounted = false;
        }
    }, []);

    const convertDate = (date) => {
        const day = date[8] + date[9]
        const month = date[5] + date[6]
        const year = date[0] + date[1] + date[2] + date[3]
        const newDate = day + '/' + month + '/' + year
        return newDate
    }

    return (
        <div className="flex flex-col w-full h-full max-h-[2800px] p-2 text-gray-700 dark:text-gray-100">
            <h1 className="title">Lista de Cuestionarios</h1>
            <div className="flex flex-row py-2 items-center">
                <Link
                    className="btn"
                    to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${challengeId}`}
                >
                    Volver
                </Link>
                <button className='btn w-[200px] my-2'
                    onClick={() => {
                        setCreateQuiz(true)
                    }}>
                    Nuevo Cuestionario
                </button>
            </div>
            <div className="flex flex-row flex-wrap max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 justify-center px-4 lg:py-4 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
                <table className="w-full">
                    <thead className="text-base">
                        <tr>
                            <th>Nombre</th>
                            <th>Fecha de creación</th>
                            <th>Fecha de actualización</th>
                        </tr>
                    </thead>
                    <tbody className="font-light">
                        {quizList[0] ? quizList.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th className="font-normal">{item?.name}</th>
                                    <th className="font-normal">{item.createdAt ? convertDate(item.createdAt) : '-'}</th>
                                    <th className="font-normal">{item.updatedAt ? convertDate(item.updatedAt) : '-'}</th>
                                    <th>
                                        <Link
                                            className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                            to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${challengeId}/Quiz/${item.id}`}
                                        >
                                            <Tooltip title="Editar" arrow>
                                                <EditIcon />
                                            </Tooltip>
                                        </Link>
                                        <button className="btn px-[10px] bg-[#C36B96] hover:bg-red-800"
                                            onClick={() => {
                                                setDeleteQuizName(item?.name)
                                                setCurrentQuizId(item?.id)
                                                setDeleteModal(true)
                                            }}>
                                            <Tooltip title="Borrar" arrow>
                                                <DeleteIcon />
                                            </Tooltip>
                                        </button>
                                    </th>
                                </tr>
                            )
                        }) : spinner ?
                            <tr>
                                <td colSpan={3} className='w-30 mt-5'><LoadingSpinner size={"250px"}/></td>
                            </tr> :
                            <tr>
                                <td colSpan={3} className='text-lg mt-5'>No hay Cuestionarios</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
            <DeleteDocumentModal
                element={deleteQuizName}
                deletefunc={edChallFunc.handleQuizDelete}
                documentId={currentQuizId}
                documents={quizList}
                setDocuments={setQuizList}
                modal={deleteModal}
                setModal={setDeleteModal}
                mode={'Cuestionario'}
                token={token}
            />
            <CreateQuizModal
                challengeId={challengeId}
                modal={createQuiz}
                setModal={setCreateQuiz}
                quizList={quizList}
                setQuizList={setQuizList}
                token={token}
                scoreTypes={scoreTypesState}
            />
        </div>
    )
}

export default QuizList