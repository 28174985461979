import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
// modification of the register functions review changes in userServides
import {
  register,
  // loginWithGoogle,
  // loginWithFacebook,
} from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinner from "../common/loadingSpinner";
import PasswordStrengthBar from 'react-password-strength-bar';
import { PROFESSION_URL_LIST, COUNTRY_URL_LIST } from '../../services/apiRoutes'
import { makeRequest } from "../../services/makeRequest";

function RegisterForm() {
  const [t] = useTranslation("global");
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const usernameRef = useRef();
  const countryRef = useRef();
  const professionRef = useRef();
  const ageRef = useRef();
  const genderRef = useRef();
  const navigate = useNavigate();
  const captcha = useRef(null);
  const [validCaptcha, setValidCaptcha] = useState(null);
  const [professions, setProfessions] = useState();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState('Elige tu país');
  const [selectedProfession, setSelectedProfession] = useState('Elige tu profesión');
  const [selectedAge, setSelectedAge] = useState('Edad');
  const [selectedGender, setSelectedGender] = useState('Género');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const ageNumbers = Array.from({ length: 100 }, (_, index) => index + 1);
  const recaptchaKey = '6Le1FU4kAAAAAO1zJ1cZnbrkquNmnycFPJCDTYNL';

  const fetchUserProfessions = () => {
    makeRequest('GET', PROFESSION_URL_LIST, {}).then(data => setProfessions(data.result)).catch(error => console.log(error))
  }

  const fetchCountryList = () => {
    makeRequest('GET', COUNTRY_URL_LIST, {}).then(data => setCountries(data.result)).catch(error => console.log(error))
  }

  useEffect(() => {
    fetchUserProfessions();
    fetchCountryList();
  }, [])

  const handleSelectedProfession = (event) => {
    setSelectedProfession(event.target.value);
  };

  const handleSelectedCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSelectedAge = (event) => {
    setSelectedAge(event.target.value);
  };

  const handleSelectedGender = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
      if (
        !nameRef.current.value ||
        !usernameRef.current.value ||
        !emailRef.current.value ||
        !passwordRef.current.value ||
        !countryRef.current.value ||
        !professionRef.current.value ||
        !ageRef.current.value ||
        !genderRef.current.value
      ) {
        toast.error(t(`errors.emptyfields`));
      } else {

        const response = await register(
          nameRef.current.value,
          usernameRef.current.value,
          emailRef.current.value,
          passwordRef.current.value,
          countryRef.current.value,
          professionRef.current.value,
          ageRef.current.value,
          genderRef.current.value
        )

        if (response) {
          setIsLoading(false);
          navigate('/user-verification');
        }else{
          setIsLoading(false);
        }

        // await register(
        //   nameRef.current.value,
        //   usernameRef.current.value,
        //   emailRef.current.value,
        //   passwordRef.current.value,
        //   countryRef.current.value,
        //   professionRef.current.value,
        //   ageRef.current.value,
        //   genderRef.current.value
        // ).then(() => {
        //   setIsLoading(false);
        //   navigate('/user-verification');
        // })
      }
    } else {
      setValidCaptcha(false);
    }
  };

  // const handleSubmitGoogle = () => {
  //   loginWithGoogle();
  // };

  // const handleSubmitFacebook = () => {
  //   loginWithFacebook();
  // };

  const handlePasswordChange = () => {
    const passwordValue = passwordRef.current.value;
    const confirmPasswordValue = confirmPasswordRef.current.value;

    // Check if the passwords match as the user types
    setPasswordMatch(passwordValue === confirmPasswordValue);

    setPasswordStrength(passwordValue);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
    >

      <div className="lg:grid lg:min-h-screen lg:grid-cols-12 xl:grid-cols-10 items-start">
        <section
          className="xl:ml-10 lg:ml-10 mt-20 relative flex h-32 items-center justify-center bg-gray-900 lg:col-span-5 lg:h-3/4 xl:col-span-6 rounded-lg"
        >
          <img
            alt="hacker"
            src="/register-hacker-pic.jpg"
            className="absolute inset-0 h-full w-full object-cover opacity-80 rounded-lg"
          />

          <div className="hidden lg:relative lg:block lg:p-12">

            <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
              Bienvenido a HackCTF
            </h2>

            <p className="mt-4 leading-relaxed text-white/90">
              Aprendé sobre ciberseguridad y más con las mejores guías, retos y CTFs.
            </p>
          </div>
        </section>

        <main
          className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-4"
        >
          <div className="max-w-xl lg:max-w-3xl">

            <form onSubmit={(e) => handleSubmit(e)} className="mt-1 grid grid-cols-6 gap-3">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Nombre
                </label>

                <input
                  id="name"
                  type="text"
                  ref={nameRef}
                  className="user-input rounded-md"
                  autoComplete="off"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Nombre de usuario
                </label>

                <input
                  id="username"
                  type="text"
                  ref={usernameRef}
                  className="user-input rounded-md text-black-700"
                  autoComplete="off"
                />
              </div>

              <div className="col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Email
                </label>

                <input
                  id="email"
                  type="email"
                  ref={emailRef}
                  className="user-input rounded-md text-black-700"
                  autoComplete="off"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <select name="country" className="user-input rounded-md" value={selectedCountry} onChange={handleSelectedCountry} ref={countryRef} autoComplete="off">
                  <option hidden disabled>Elige tu país</option>
                  {countries && countries.map((userCountry, index) => {
                    return (
                      <option key={index} value={countries[index].id} >
                        {userCountry.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <select name="profession" className="user-input rounded-md" value={selectedProfession} onChange={handleSelectedProfession} ref={professionRef} autoComplete="off">
                  <option hidden disabled>Elige tu profesión</option>
                  {professions && professions.map((profession, index) => {
                    return (
                      <option key={index} value={professions[index].id}>
                        {profession.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <select name="age" className="user-input rounded-md" value={selectedAge} onChange={handleSelectedAge} ref={ageRef} autoComplete="off">
                  <option hidden disabled>Edad</option>
                  {ageNumbers && ageNumbers.map((number, index) => {
                    return (
                      <option key={index} value={ageNumbers[index]} >
                        {number}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <select name="gender" className="user-input rounded-md" value={selectedGender} onChange={handleSelectedGender} ref={genderRef} autoComplete="off">
                  <option disabled hidden>
                    Género
                  </option>
                  <option value='Masculino'>
                    Masculino
                  </option>
                  <option value='Femenino'>
                    Femenino
                  </option>
                  <option value='Otro'>
                    Otro
                  </option>
                </select>
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Contraseña
                </label>

                <input
                  id="password"
                  type="password"
                  ref={passwordRef}
                  autoComplete="new-password"
                  className="user-input rounded-md text-black-700"
                  onChange={handlePasswordChange}
                />
                {passwordStrength.length > 0 ?

                  <PasswordStrengthBar
                    password={passwordStrength} barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
                    scoreWordStyle={{ color: 'white' }}
                  />
                  : null
                }
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Confirma tu contraseña
                </label>

                <input
                  id="confirmPassword"
                  type="password"
                  ref={confirmPasswordRef}
                  className="user-input rounded-md text-black-700 mb-5"
                  onChange={handlePasswordChange}
                />

                {!passwordMatch && 'Las contraseñas no coinciden'}

              </div>

              <div className="col-span-6">

                <div className="flex justify-center scale-75">
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={recaptchaKey}
                  />
                </div>

                {isLoading && <LoadingSpinner size={"150px"} />}
                {validCaptcha === false && <div className="text-red-600">Por favor acepte el captcha</div>}
                <button className="btn mx-auto mt-10 w-full" type="submit">
                  {t("registerform.register")}
                </button>

                {/* <button
              onClick={handleSubmitGoogle}
              className="btn bg-red-600 hover:bg-red-800 mx-auto my-1 w-full mt-5"
            >
              <FontAwesomeIcon icon={faGoogle} /> {t("registerform.registerwith")}{" "}
              Google
            </button>
            <button
              onClick={handleSubmitFacebook}
              className="btn mx-auto my-1 w-full bg-blue-700 hover:bg-blue-900"
            >
              <FontAwesomeIcon icon={faFacebook} /> {t("registerform.registerwith")}{" "}
              Facebook
            </button> */}
              </div>


              <div className="col-span-6">
                <p className="text-sm text-black">
                  Al crear una cuenta aceptas todos nuestros {" "}
                  <a
                    href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-slate-100 underline ">
                    términos y condiciones {" "}
                  </a>
                  y la {" "}
                  <a
                    href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-slate-100 underline ">
                    política de privacidad
                  </a>
                </p>
              </div>

              <div className="flex justify-center col-span-6 sm:flex sm:items-center sm:gap-4">

                <p className="mt-4 text-sm text-black sm:mt-0">
                  ¿Ya tenés una cuenta? {" "}
                  <a href="/login" className="underline text-slate-100">
                    Inicia sesión
                  </a>
                </p>
              </div>
            </form>
          </div>
        </main>
      </div>

    </motion.div>
  );
}

export default RegisterForm;
