import { useEffect, useState } from "react";
import edFunc from "./editorFunctions";
import learningPathEdFunc from "./learningPathFunctions"
import { useDispatch, useSelector } from 'react-redux'
import TagListModal from "../common/tagListModal";
import CreateDocumentModal from "../common/createDocumentModal";
import { settingsActions } from "../../redux/slices/settingsSlice";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const resetField = {
    name: "Title",
    description: "Description",
    imageThumbnailPath: "www.imageThumbnailPath.com",
    videoThumbnailPath: "www.imageThumbnailPath.com",
    tagsId: [],
}

const NewLearningPath = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token)
    const tagList = useSelector((state) => state.settings.tags);
    const hasChanged = useSelector((state) => state.settings.hasChanged);
    const [newLearningPathId, setNewLearningPathId] = useState(0)
    const [spinnerEnable, setSpinnerEnable] = useState(false);
    const [fields, setFields] = useState(resetField)
    const [modal, setModal] = useState()
    const [tag, setTag] = useState(tagList)
    const [tagModal, setTagModal] = useState(false)

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (tag && !tag[0]) {
                edFunc.HandleGetTagList(setTag, token);
            }
        }
        return () => {
            isMounted = false;
        }
    }, []);

    useEffect(() => {
        if (!hasChanged) return;
        function handleOnBeforeUnload(event) {
            event.preventDefault();
            return (event.returnValue = '');
        }
        window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
        }
    }, [hasChanged])

    const handleLearningPath = async () => {
        setSpinnerEnable(true)
        const element = await learningPathEdFunc.HandleCreateLearningPath(fields, token)
        setNewLearningPathId(element.id)
        setSpinnerEnable(false)
        dispatch(settingsActions.setHasChanged(false))
        setModal(true)
    }

    const handleNewLearningPath = async () => {
        document.getElementById('newLearningPath').reset();
        setFields([])
    }

    return (
        <div className='m-2 pr-3 text-center w-full'>
            <div className="p-4 bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md">
                <h1 className='text-3xl font-medium py-[20px]' htmlFor='title'>Nuevo LearningPath</h1>

                <form
                    id="newLearningPath"
                    className="flex flex-col gap-4"
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleLearningPath()
                    }}
                    onChange={() => dispatch(settingsActions.setHasChanged(true))}
                >

                    <div className="editor-fields ">
                        <label
                            className="editor-fields-label flex-none"
                            htmlFor="name"
                        >
                            Título:
                        </label>
                        <input
                            id='name'
                            placeholder='Escriba aquí el nombre...'
                            className='editor-input'
                            required
                            onChange={(e) =>
                                edFunc.handleChange(e, "name", setFields, fields)
                            }
                            autoComplete="off"
                        />
                    </div>

                    <div className="editor-fields ">
                        <label
                            className="editor-fields-label flex-none"
                            htmlFor="description"
                        >
                            Descripción:
                        </label>
                        <textarea
                            onChange={(e) =>
                                edFunc.handleChange(e, "description", setFields, fields)
                            }
                            name="desc"
                            id="description"
                            required
                            className={`p-1 w-full editor-box h-[75px] resize-none transition-all duration-200 ease-in-out`}
                            placeholder='Escriba aquí la descripción...'
                        ></textarea>
                    </div>

                    <div className="editor-fields">
                        <label
                            title="Ingrese la direccion de la imagen"
                            className="editor-fields-label flex-none"
                            htmlFor="imageThumbnailPath"
                        >
                            Miniatura imagen:
                        </label>
                        <input
                            onChange={(e) =>
                                edFunc.handleChange(e, "imageThumbnailPath", setFields, fields)
                            }
                            id="imageThumbnailPath"
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            placeholder='Ingrese la url de la imagen'
                        ></input>
                    </div>

                    <div className="editor-fields">
                        <label
                            title="Ingrese la direccion del video"
                            className="editor-fields-label flex-none"
                            htmlFor="videoThumbnailPath"
                        >
                            Miniatura video:
                        </label>
                        <input
                            onChange={(e) =>
                                edFunc.handleChange(e, "videoThumbnailPath", setFields, fields)
                            }
                            id="videoThumbnailPath"
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out `}
                            placeholder='Ingrese la url del video'
                        ></input>
                    </div>

                    <div className="editor-fields justify-start">
                        <label
                            htmlFor="tag"
                            className="editor-fields-label flex-none"
                        >
                            Etiquetas:
                        </label>
                        <button className="btn w-[150px] bg-[#3E73B9] hover:bg-blue-900"
                            id="tag"
                            type="button"
                            onClick={() => setTagModal(true)}>
                            Agregar Etiqueta
                        </button>
                    </div>
                    {(fields.tagsId != null) &&
                        <div className="editor-fields justify-start">
                            {fields.tagsId.map((item, index) => {
                                // using .find to search for the tag objects and obtain the name since the tagId field only save the id number of the tag
                                const tagFind = tag.find(element => element.id === item)
                                return (
                                    <div
                                        key={index}
                                        className="text-lg bg-[#C36B96] px-2 m-2 flex items-center justify-between editor-fields-label rounded-lg w-fit gap-2"
                                    >
                                        <p>{tagFind?.name}</p>
                                        <button
                                            onClick={(e) => {
                                                edFunc.handleChangeArrayRemove(e, "tagsId", setFields, fields, index)
                                            }}
                                        >
                                            <ClearOutlinedIcon
                                                className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                                            />
                                        </button>
                                    </div>
                                );
                            })}
                        </div>}

                    <div className='flex flex-col mt-[40px] xs:flex-row justify-center'>
                        <button
                            className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]${spinnerEnable ? 'bg-rose-800 pointer-events-none' : ''} disabled:bg-gray-600 disabled:text-slate-400`}
                            type="submit"
                        >
                            {spinnerEnable
                                ? (
                                    <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                                )
                                : (
                                    'Crear LearningPath'
                                )}
                        </button>
                    </div>
                </form>
            </div>

            <CreateDocumentModal
                modal={modal}
                setModal={setModal}
                type={"learningPath"}
                title={fields.name}
                resetFunc={handleNewLearningPath}
                newDocumentId={newLearningPathId}
            />
            <TagListModal
                fields={fields}
                setFields={setFields}
                modal={tagModal}
                setModal={setTagModal}
                token={token}
            />
        </div>
    )
}

export default NewLearningPath
