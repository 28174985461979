import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import globalEs from "./translations/es/global.json";
import globalEn from "./translations/en/global.json";
import globalPt from "./translations/pt/global.json";
import App from "./App";
import "./index.css";

import { Provider } from "react-redux";
import store from "./redux/store";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lang") ? localStorage.lang : "es",
  resources: {
    es: {
      global: globalEs,
    },
    en: {
      global: globalEn,
    },
    pt: {
      global: globalPt,
    },
  },
  fallbackLng: globalEs,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </BrowserRouter>
);
