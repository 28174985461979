import { useState, useEffect, useMemo } from "react";
import edFunc from "./editorFunctions";
import { useSelector } from 'react-redux';
import DeleteDocumentModal from "../common/deleteDocumentModal";
import { Link } from "react-router-dom";
import { Pagination, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingSpinner from "../common/loadingSpinner";

const GuideList = () => {
    const token = useSelector((state) => state.user.token);
    const theme = useSelector((state) => state.theme.value);
    const data = useSelector((state) => state.user.userData);
    const guideList = useSelector((state) => state.settings.guides);
    const [guides, setGuides] = useState(guideList || []);
    const [deleteGuideName, setDeleteGuideName] = useState('');
    const [currentGuideId, setCurrentGuideId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [spinner, setSpinner] = useState(false);
    const PageSize = 10;

    const paginationStyles = {
        ul: {
            '& .MuiPaginationItem-root': {
                color: theme === 'dark' ? '#f3f4f6' : '#030712'
            }
        }
    };

    useEffect(() => {
        edFunc.HandleGetGuideList(setGuides, setSpinner, token);
    }, [token]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return guides.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, guides]);

    const convertDate = (date) => {
        const day = date.slice(8, 10);
        const month = date.slice(5, 7);
        const year = date.slice(0, 4);
        return `${day}/${month}/${year}`;
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div className="flex flex-col w-full h-full max-h-[2800px] p-2 text-gray-700 dark:text-gray-100">
            <h1 className="title">Lista de Guías</h1>
            <div className="flex flex-row flex-wrap max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 justify-center px-4 lg:py-4 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
                <table className="w-full border-collapse">
                    <thead className="text-base border-b border-gray-300 dark:border-gray-600">
                        <tr>
                            <th className="text-lg px-[20px] py-2 border-r border-gray-300 dark:border-gray-600 text-center">Nombre</th>
                            <th className="text-lg px-[20px] py-2 border-r border-gray-300 dark:border-gray-600 text-center">Formato</th>
                            <th className="text-lg px-[20px] py-2 border-r border-gray-300 dark:border-gray-600 text-center">Estado</th>
                            <th className="text-lg px-[20px] py-2 border-r border-gray-300 dark:border-gray-600 text-center">Dificultad</th>
                            <th className="text-lg px-[20px] py-2 text-center border-r border-gray-300 dark:border-gray-600">Fecha de creación</th>
                            <th className="text-lg px-[20px] py-2 text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="font-light">
                        {currentTableData[0] ? currentTableData.map((item, index) => (
                            <tr key={index} className="border-b border-gray-200 dark:border-gray-700">
                                <td className="font-normal text-lg py-3 px-[20px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item?.name}</td>
                                <td className="font-normal text-lg py-3 px-[20px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item.isPremium ? 'Premium' : 'Free'}</td>
                                <td className="font-normal text-lg py-3 px-[20px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item.isPublished ? 'Publicado' : 'Borrador'}</td>
                                <td className="font-normal text-lg py-3 px-[20px] border-r border-gray-300 dark:border-gray-600 text-center align-middle">{item.difficultyLevel ? item.difficultyLevel.name : '-'}</td>
                                <td className="font-normal text-lg py-3 px-[20px] text-center align-middle border-r border-gray-300 dark:border-gray-600">{item.createdAt ? convertDate(item.createdAt) : '-'}</td>
                                <td className="text-center align-middle">
                                    <Link
                                        className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                        to={`${process.env.PUBLIC_URL}/admin/edition/editor/${item?.id}`}
                                    >
                                        <Tooltip title="Editar" arrow>
                                            <EditIcon />
                                        </Tooltip>
                                    </Link>
                                    <button
                                        className={`btn px-[10px] bg-[#C36B96] hover:bg-red-800 ${data ? (data.role.name === 'Super Admin' || data.role.name === 'Admin') ? '' : 'invisible' : ''}`}
                                        onClick={() => {
                                            setDeleteGuideName(item?.name);
                                            setCurrentGuideId(item?.id);
                                            setDeleteModal(true);
                                        }}
                                    >
                                        <Tooltip title="Borrar" arrow>
                                            <DeleteIcon />
                                        </Tooltip>
                                    </button>
                                </td>
                            </tr>
                        )) : !spinner ? (
                            <tr>
                                <td colSpan={6} className='w-30 mt-5'><LoadingSpinner size={"250px"} /></td>
                            </tr>
                        ) : (
                            <tr className="h-20">
                                <td colSpan={6} className='text-lg mt-5'>
                                    <div className="flex justify-center items-center gap-2">
                                        No hay Guías
                                        <Link
                                            className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                            to={`${process.env.PUBLIC_URL}/admin/creation/newGuide`}
                                        >
                                            Crear Guía
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {currentTableData[0] && (
                    <div className="flex justify-center my-4">
                        <Pagination
                            sx={paginationStyles}
                            count={Math.ceil(guides.length / PageSize)}
                            size="large"
                            color="secondary"
                            showFirstButton
                            showLastButton
                            onChange={handlePageChange}
                        />
                    </div>
                )}
            </div>
            <DeleteDocumentModal
                element={deleteGuideName}
                deletefunc={edFunc.handleDelete}
                documentId={currentGuideId}
                documents={guides}
                setDocuments={setGuides}
                modal={deleteModal}
                setModal={setDeleteModal}
                mode={'guide'}
                token={token}
            />
        </div>
    );
};

export default GuideList;
