import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function useUserVerification() {
  const { currentUser } = useSelector((state) => state.user);
  const isVerified = useSelector((state) => state?.user?.userData?.emailConfirmed);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      // Redirect to the login page if the user is not logged in
      navigate("/login");
      setLoading(false);
    } else if (currentUser && !isVerified) {
      // Redirect to the user verification page if the account is not verified
      navigate("/user-verification");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [currentUser, isVerified, navigate]);

  return loading;
}
