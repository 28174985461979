import { USERCOMPLETEDQUIZ_URL_CREATE } from "./apiRoutes"
import { makeRequest } from "./makeRequest"


export const userCompletedQuizCreate = async (payload) => {

    const response = await makeRequest(
        'POST',
        `${USERCOMPLETEDQUIZ_URL_CREATE}`, 
        {
            auth: true,
            payload
        }
    )

    if (response && response?.status === 200) {
        return response.result
    } else {
        return null
    }

}