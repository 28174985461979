import { toast } from "react-toastify";
import { deleteLearningPath, getLearningPathList, getLearningPath, updateLearningPath, createLearningPath } from "../../services/learningPathService";

const handleDelete = async (learningPathId, token) => {
    await deleteLearningPath(learningPathId, token)
};
const HandleGetLearningPathList = async (setLearningPaths, setSpinner, token) => {
    const element = await getLearningPathList()
    if (element) {
        setLearningPaths(element)
        setSpinner(true)
    } else {
        setLearningPaths([])
        setSpinner(true)
    }
};
const handleGetLearningPath = async (learningPathId, setEditor, token) => {
    const element = await getLearningPath(learningPathId, token);
    const emptyFields = {
        id: element.id,
        name: element.name,
        description: element.description,
        imageThumbnailPath: element.imageThumbnailPath,
        videoThumbnailPath: element.videoThumbnailPath,
        tagsId: [],
    };
    element.learningPathTagLink.forEach((object) => {
        if (!object.tag?.id) return
        emptyFields.tagsId.push(object.tag.id)
    })

    if (element.id) {
        setEditor(true);
    }

    return emptyFields
};
const HandleUpdateLearningPath = async (learningPath, learningPathId, token) => {
    const newFields = {
        name: learningPath.name,
        description: learningPath.description,
        imageThumbnailPath: learningPath.imageThumbnailPath || 'www.imageThumbnailPath.com',
        videoThumbnailPath: learningPath.videoThumbnailPath || "www.videoThumbnailPath.com",
        tagsId: learningPath.tagsId
    };
    const response = await updateLearningPath(newFields, learningPathId)
    if (response) {
        toast.success("Ruta de aprendizaje actualizada")
    } else {
        toast.error("No se pudo actualizar la ruta de aprendizaje")
    }
};
const HandleCreateLearningPath = async (learningPath, token) => {

    const fields = {
        name: learningPath.name,
        description: learningPath.description,
        imageThumbnailPath: learningPath.imageThumbnailPath || 'www.imageThumbnailPath.com',
        videoThumbnailPath: learningPath.videoThumbnailPath || "www.videoThumbnailPath.com",
        tagsId: learningPath.tagsId
    };
    const newLearningPath = await createLearningPath(fields, token)
    return newLearningPath
};

const learningPathFunctions = {
    handleDelete,
    HandleGetLearningPathList,
    handleGetLearningPath,
    HandleUpdateLearningPath,
    HandleCreateLearningPath,
};

export default learningPathFunctions