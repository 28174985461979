import { useEffect, useState, useMemo } from "react";
import { useSelector } from 'react-redux'
import dashFunc from "./dashboardFunctions"
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
// TODO: replace this icon
// import SearchIcon from '@material-ui/icons/Search';
import './dashboard.css'
import { Pagination } from "@mui/material";

function DashboardData() {
  const token = useSelector((state) => state.user.token)
  const orderList = {
    name: 'asc',
    createdAt: 'asc',
    score: 'asc',
    streak: 'asc',
  }
  // const filterList = {
  //   createdAt: '',
  //   country: '',
  //   lastGuide: '',
  //   lastChallenge: '',
  //   score: '',
  //   streak: '',
  // }
  const [userList, setUserList] = useState([])
  const [userSearchList, setUserSearchList] = useState([])
  const [input, setInput] = useState('')
  // const [filter, setFilter] = useState(filterList)
  // const [filterMenu, setFilterMenu] = useState(true)
  const [order, setOrder] = useState(orderList)
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dashFunc.HandleGetUserDataList(setUserList, setUserSearchList, token)
      setCurrentPage(1)
    }
    return () => {
      isMounted = false;
    }
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return userSearchList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, userSearchList]);

  const dashboardSearch = () => {
    const result = userList.filter((userList) => userList.name.includes(input) || userList.email.includes(input))
    // if (!filterMenu) {
    //   let filterResult = result.filter((result) => result.createdAt.includes(filter.createdAt) && result.countryName.includes(filter.country))
    //   if (result.lastGuide && filter.lastGuide) {
    //     filterResult = filterResult.filter((result) => result.lastGuide.name.includes(filter.lastGuide))
    //   }
    //   if (result.lastChallenge && filter.lastChallenge) {
    //     filterResult = filterResult.filter((result) => result.lastChallenge.name.includes(filter.lastChallenge))
    //   }
    //   if (filter.score) {
    //     filterResult = filterResult.filter((result) => result.score === filter.score)
    //   }
    //   if (filter.streak) {
    //     filterResult = filterResult.filter((result) => result.streak === filter.streak)
    //   }
    //   setUserSearchList(filterResult)
    // } else {
    setUserSearchList(result)
    // }
    setCurrentPage(1)
  }

  const dashboardSort = (param) => {
    const orderedList = [...userSearchList]
    if (order[param] === 'desc') {
      orderList[param] = "asc"
      setOrder(orderList)
      orderedList.sort(function (a, b) {
        if (a[param] > b[param]) {
          return 1;
        }
        if (a[param] < b[param]) {
          return -1;
        }
        return 0;
      })
      setUserSearchList(orderedList)
    } else {
      orderList[param] = "desc"
      setOrder(orderList)
      orderedList.sort(function (a, b) {
        if (a[param] < b[param]) {
          return 1;
        }
        if (a[param] > b[param]) {
          return -1;
        }
        return 0;
      })
      setUserSearchList(orderedList)
    }
  }

  // const dashboardFilter = (param, e) => {
  //   if (param === 'createdAt') {
  //     filterList[param] = dashFunc.convertDateBack(e.currentTarget.value)
  //   } else {
  //     filterList[param] = e.currentTarget.value
  //   }
  //   setFilter(filterList)
  // }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  }

  return (
    <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
      <div className="flex flex-col max-w-full mx-auto lg:mx-0 mb-4 py-6 px-4 lg:py-2 text-sm bg-gray-100 dark:bg-[#d3d3df] rounded-md shadow-md transition-all duration-200 ease-in-out">
        <div className="flex justify-between m-4">
          <h1 className='title flex items-end m-0 font-bold text-black'>DATA</h1>
          <button className="btn w-[200px] p-6 text-base item-center bg-black hover:bg-gray-900 text-white">
            Descarga CSV
          </button>
        </div>

        <div className="mx-4 flex">
          <div className="bg-[#c0c0c5] w-1/4 flex rounded-sm">
            <input placeholder="Buscar por Nombre o Email" className="py-2 w-full text-center font-semibold bg-[#c0c0c5] outline-none"
              onChange={(e) => setInput(e.currentTarget.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }}
            />
            <button className="mx-2" onClick={() => { dashboardSearch() }}>
              {/* <SearchIcon /> */}
            </button>
          </div>
          {/* <button className="mx-2 w-[150px] flex justify-center items-center bg-black text-white font-bold" onClick={() => { filterMenu ? setFilterMenu(false) : setFilterMenu(true) }}>Filtrar</button> */}
        </div>

        {/* filter Menu in development */}
        {/* <div className={`transition-opacity delay-150 duration-700 ${filterMenu ? 'opacity-0' : "opacity-100"}`}>
          <div className={`${filterMenu ? 'hidden' : ""}`}>
            <div className="grid grid-cols-3">
              <div className="flex my-[20px]">
                <label className="w-[150px] mx-[10px] flex justify-center items-center bg-black text-white font-bold">Registro</label>
                <input placeholder="dd/mm/yy" className="dashboard-table-input" onChange={(e) => { dashboardFilter('createdAt', e) }} onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }} />
              </div>
              <div className="flex my-[20px]">
                <label className="w-[150px] mx-[10px] flex justify-center items-center bg-black text-white font-bold">Puntaje</label>
                <input placeholder="Search" className="dashboard-table-input" onChange={(e) => { dashboardFilter('score', e) }} onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }} />
              </div>
              <div className="flex my-[20px]">
                <label className="w-[150px] mx-[10px] flex justify-center items-center bg-black text-white font-bold">Guia</label>
                <input placeholder="Search" className="dashboard-table-input" onChange={(e) => { dashboardFilter('lastGuide', e) }} onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }} />
              </div>
              <div className="flex my-[20px]">
                <label className="w-[150px] mx-[10px] flex justify-center items-center bg-black text-white font-bold">Pais</label>
                <input placeholder="Search" className="dashboard-table-input" onChange={(e) => { dashboardFilter('country', e) }} onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }} />
              </div>
              <div className="flex my-[20px]">
                <label className="w-[150px] mx-[10px] flex justify-center items-center bg-black text-white font-bold">Racha</label>
                <input placeholder="Search" className="dashboard-table-input" onChange={(e) => { dashboardFilter('streak', e) }} onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }} />
              </div>
              <div className="flex my-[20px]">
                <label className="w-[150px] mx-[10px] flex justify-center items-center bg-black text-white font-bold">Reto</label>
                <input placeholder="Search" className="dashboard-table-input" onChange={(e) => { dashboardFilter('lastChallenge', e) }} onKeyDown={(e) => { if (e.key === 'Enter') { dashboardSearch() } }} />
              </div>
            </div>
          </div>
        </div> */}

        <table className="border-none my-8">
          <thead className="border-none text-black">
            <tr className="border-none">
              <th className="text-lg flex justify-between px-1">
                Nombre<button onClick={() => { dashboardSort('name') }}>{order.name === "asc" ? <NorthIcon fontSize="small" /> : <SouthIcon fontSize="small" />}</button>
              </th>
              <th className="text-lg px-2">Correo </th>
              <th className="text-lg flex justify-between px-1">
                Registro<button onClick={() => { dashboardSort('createdAt') }}>{order.createdAt === "asc" ? <NorthIcon fontSize="small" /> : <SouthIcon fontSize="small" />}</button>
              </th>
              <th className="text-lg px-2">País</th>
              <th className="text-lg px-2">Nivel</th>
              <th className="text-lg px-2">Guía</th>
              <th className="text-lg px-2">Reto</th>
              <th className="text-lg"><div className="flex justify-between px-1">
                Puntaje<button onClick={() => { dashboardSort('score') }}>{order.score === "asc" ? <NorthIcon fontSize="small" /> : <SouthIcon fontSize="small" />}</button></div>
              </th>
              <th className="text-lg"><div className="flex justify-between px-1">
                Racha <button className="pl-4" onClick={() => { dashboardSort('streak') }}>{order.streak === "asc" ? <NorthIcon fontSize="small" /> : <SouthIcon fontSize="small" />}</button></div>
              </th>
            </tr>
          </thead>
          <tbody className="border-none lg:text-sm text-xs">
            {
              currentTableData?.map((item, index) => {
                return (
                  <tr key={index} className="bg-[#c0c0c5] border-y-8 dark:border-[#d3d3df] border-gray-100 font-semibold">
                    <td className="h-[60px]">{item.name}</td>
                    <td>{item.email ? item.email : '-'}</td>
                    <td className="px-2">{item.createdAt ? dashFunc.convertDate(item.createdAt) : '-'}</td>
                    <td>{item.countryName ? item.countryName : '-'}</td>
                    <td>-</td>
                    <td>{item.lastGuide ? item.lastGuide.name : '-'}</td>
                    <td>{item.lastChallenge ? item.lastChallenge.name : '-'}</td>
                    <td>{item.score ? item.score : '-'}</td>
                    <td>{item.streak ? item.streak : '-'}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className="flex justify-center mb-4">
          <Pagination count={Math.ceil((userSearchList?.length) / PageSize)} size="large" showFirstButton showLastButton onChange={handlePageChange} />
        </div>
      </div>
    </div>
  )
}

export default DashboardData
