import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';
import { addRolePermission, getUserPermissions, removeRolePermission } from '../../services/superAdminService';
import LoadingSpinner from './loadingSpinner';

const PermissionsListModal = ({ roleId, modal, setModal, token, permissions }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCalculating, setIsCalculating] = useState(true);
    const [userPermissions, setUserPermissions] = useState([]);

    const variants = {
        opened: {
            opacity: 1,
            display: 'flex'
        },
        closed: {
            opacity: 0,
            transitionEnd: {
                display: 'none'
            }
        }
    }

    const fetchUserPermissions = async () => {
        try {
            const response = await getUserPermissions(roleId)
            const data = await response.json();
           if(data.result?.rolePermissionLink?.length > 0){
               const permissionsId = await data?.result?.rolePermissionLink?.map(link => link.rolePermissionId);
               setUserPermissions(permissionsId)
               return userPermissions
               
            }else{
                setUserPermissions([])
            }
        } catch (error) {
            console.error(error);
            return error
        }
    }
    const loadUserPermissions = () => setIsCalculating(false);

    // to close modal when user press 'ESC'
    useEffect(() => {
        fetchUserPermissions().then(() => setIsCalculating(false))
        setTimeout(loadUserPermissions, 1500)
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    const toggleAdding = async (item) => {
        // setIsLoading(true);
        const addedPermissions = []
        if (!userPermissions.includes(item.id)) {
            addedPermissions.push(item.id);
            await addRolePermission(addedPermissions, roleId, token);
            fetchUserPermissions();
        }
        else {
            const deletedPermissions = []
            deletedPermissions.push(item.id);
            await removeRolePermission(deletedPermissions, roleId, token)
            fetchUserPermissions();
        }
        setIsLoading(false);
    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
        >
            <div className="h-[85vh] p-4 overflow-y-auto overflow-x-hidden absolute mt-4 bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md custom-scrollbar">
                {isCalculating ?
                    <div className="block">
                        <p className="text-lg font-bold">Cargando permisos del rol...</p>
                        <LoadingSpinner size={"300px"} />
                    </div>
                    :
                    <>

                        <div>
                            <h1 className="title mt-0 text-gray-700 dark:text-gray-100">Lista de Permisos</h1>
                            <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                                onClick={() => {
                                    setModal(false)
                                }}>
                                <CloseIcon />
                            </button>
                            <div className='grid grid-cols-2 gap-2'>
                                {permissions?.map((item, index) => {
                                    return (
                                        <div key={index} className='flex justify-between mx-4'>
                                            <p className={`m-1 p-2 text-lg`}>
                                                {item?.name}
                                            </p>
                                            <button
                                                className={`btn w-[70px] ${userPermissions?.includes(item.id) ? '' : 'bg-[#3E73B9] hover:bg-blue-900'}`
                                                }
                                                onClick={(_) => toggleAdding(item)}
                                            >
                                                {
                                                    isLoading ? (
                                                        <LoadingSpinner size={"25px"} />
                                                    ) : (
                                                        <>
                                                            {!isCalculating ? userPermissions?.includes(item.id) ? "Quitar" : "Agregar" : null}
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </>
                }
            </div>

        </motion.div>
    )
}

export default PermissionsListModal
