function ContributorCard (props) {
  const { name, desc, image, thumbnail, id, link } = props

  function handleLoad () {
    const thumb = document.querySelector(`#thumbnail-${id}`)
    const picture = document.querySelector(`#picture-${id}`)
    thumb.classList.replace('block', 'hidden')
    picture.classList.replace('hidden', 'block')
  }

  return (
    <div className='container-block-col justify-between items-center m-3 w-64 lg:w-72 min-h-[300px] px-4 py-6 rounded-md shadow-md'>
      <div className="container-block-col justify-between items-center">
        <div className='w-[125px] min-h-[125px] relative mb-4'>
          <img
            className='block top-0 blur-[2px] w-[125px] rounded-full pointer-events-none'
            src={process.env.PUBLIC_URL + thumbnail}
            alt={name}
            id={`thumbnail-${id}`}
          />
          <img
            className='w-[125px] hidden rounded-full pointer-events-none'
            onLoad={() => handleLoad()}
            src={process.env.PUBLIC_URL + image}
            alt={name}
            id={`picture-${id}`}
          />
        </div>
        <h4 className='text-base text-gray-700 dark:text-gray-100 font-semibold'>{name}</h4>
      </div>
      <p className='text-sm m-1 text-gray-700 dark:text-gray-100 font-medium'>{desc}</p>
      <a className='btn my-2' href={link}>
        Linktree
      </a>
    </div>
  )
}

export default ContributorCard
