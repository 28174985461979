import { motion } from "framer-motion";
import edFunc from "../admin/editorFunctions";
import { toast } from "react-toastify";

const variants = {
  opened: {
    opacity: 1,
    display: "flex",
  },
  closed: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
};

function DeleteModal(props) {
  const { element, setIndex, modal, setModal, pages, setPages, guideId, pageId } = props;

  const handleDeletePage = async() => {

    if (guideId && Object.keys(pages).length === 1) {
      toast.error("No puede borrar la unica página existente")
      return
    }
    
    const response = await edFunc.handlePageDelete(pageId)

    if (response) {
      setPages(pages.filter((page) => page.id !== pageId))
      setIndex(0)
      setModal(false)
      toast.success("Pagina borrada con exito")
    } else {
      toast.error("Error al borrar la pagina")
    }
  };

  return (
    <motion.div
      variants={variants}
      initial={{ display: "none", opacity: 0 }}
      animate={modal ? "opened" : "closed"}
      transition={{ ease: "easeInOut", duration: 0.1 }}
      className={`${modal ? "flex" : "hidden"
        } justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30`}
    >
      <div className="p-4 m-2 text-center bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md">
        <h3>
          ¿Está seguro de que desea borrar la página &quot;{element}&quot;?
        </h3>
        <p className="my-2">Los cambios son permanentes</p>
        <button
          className="btn m-2 bg-red-600 hover:bg-red-800"
          onClick={handleDeletePage}
        >
          Borrar
        </button>
        <button className="btn m-2" onClick={() => setModal(false)}>
          Cancelar
        </button>
      </div>
    </motion.div>
  );
}

export default DeleteModal;
