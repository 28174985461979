import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

const AttackerVMModal = ({
    showVMModal,
    setShowVMModal,
    runVirtualMachine
}) => {

    const location = useLocation()

    const [isVmRunning, setIsVmRunning] = useState(localStorage.getItem("vmRunning") === "true");
    const [loading, setLoading] = useState(false);

    const handleTurnOff = () => {
        setLoading(true);

        setTimeout(() => {
            localStorage.setItem("vmRunning", "false");
            runVirtualMachine("stop");
            setIsVmRunning(false);
            setLoading(false);
            setShowVMModal(false);
            
            if(location.pathname?.includes('attacker-vm')){
                window.close();
            }

        }, 1500);
    }

    return (
        <>
            {
                showVMModal && (
                    <div
                        className='bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-lg shadow-md flex mx-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-1/3 py-10 flex-col items-center justify-center'
                    >
                        <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                            onClick={() => {
                                setShowVMModal(false);
                            }}>
                            <CloseIcon />
                        </button>

                        <div>
                            {
                                isVmRunning ? (
                                    <div
                                        className='flex flex-col items-center justify-center'
                                    >
                                        <p
                                            className='text-2xl w-5/6 text-center'
                                        >
                                            Estas seguro de apagar la maquina virtual atacante?
                                        </p>
                                        <button
                                            className='btn text-white border border-[#662D91] bg-[#662D91] hover:bg-purple-900 hover:border-purple-900 rounded-lg shadow-md px-4 py-2 mt-2'
                                            onClick={handleTurnOff}
                                        >
                                            {
                                                loading ? (
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={faCircleNotch}
                                                            className='mr-2 text-sm xs:text-base'
                                                            color={"white"}
                                                            spin
                                                        />
                                                        Apagando...
                                                    </>
                                                ) : (
                                                    "Apagar"
                                                )
                                            }
                                        </button>
                                    </div>
                                ) : (
                                    <></>
                                )

                            }
                        </div>

                    </div>
                )
            }
        </>
    )
}

export default AttackerVMModal