import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import LoadingSpinner from "../common/loadingSpinner";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import edFunc from "../admin/editorFunctions"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoComponent from "../common/VideoComponent";

const variants = {
  hover: {
    scale: 1.05,
    transition: {
      ease: "easeInOut",
      duration: 0.15,
      delay: 0,
    },
  },
};

const formatReadGuides = (readGuides) => {

  if (!readGuides) return

  const readGuidesList = [];
  readGuides.forEach((guide) => {

    readGuidesList.push(guide.guide?.id)
  })
  return readGuidesList
}

const filterGuidesByLevel = (guides, level) => {
  const filteredGuides = guides.filter((guide) => guide?.difficultyLevel?.name === level)

  // Order by difficultyOrder, if difficultyOrder is the same, order by alphabetically by name
  filteredGuides.sort((a, b) => {
    if (a.difficultyOrder === b.difficultyOrder) {
      return a.name.localeCompare(b.name);
    } else {
      return a.difficultyOrder - b.difficultyOrder;
    }
  })

  return filteredGuides
}


function GuidesList() {
  const { level } = useParams();
  const [t] = useTranslation("global");
  const token = useSelector((state) => state.user.token)
  const guideList = useSelector((state) => state.settings.guides);
  const readGuidesList = useSelector((state) => state.user.readGuides);
  const [guides, setGuides] = useState(guideList);
  const [filteredGuides, setFilteredGuides] = useState([]);
  const [spinner, setSpinner] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [readGuides, setReadGuides] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (guides[0]) {
        setSpinner(true)
      } else {
        edFunc.HandleGetGuideList(setGuides, setSpinner, token)
      }
    }
    return () => {
      isMounted = false;
    }
  }, [])

  useEffect(() => {
    if (guides[0]) {
      setFilteredGuides(filterGuidesByLevel(guides, level));
    }
    if (readGuidesList) {
      setReadGuides(formatReadGuides(readGuidesList))
    }
  }, [guides, readGuidesList])

  return (
    <div className="flex flex-wrap justify-center items-start min-h-box h-full">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        className="min-h-box text-center px-2 py-4"
      >
        <h3 className="title">{t("guides.title")}</h3>
        <p className="title text-base xs:text-lg md:text-xl">
          {t("guides.info")}
        </p>
        {!spinner ? (
          <LoadingSpinner size={"300px"} />
        ) : (
          <>
            {
              filteredGuides.length === 0 ? (
                <div className="text-center py-4 mx-auto w-full max-w-[1280px]">
                  <h3 className="title text-base text-center">
                    No hay guías disponibles
                  </h3>
                </div>
              ) : (
                <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-2 my-6 max-w-[1280px]">
                  {filteredGuides.map((item, index) => {
                    return item.difficultyLevel.name === level ? (
                      <motion.div
                        variants={variants}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: {
                            ease: "easeInOut",
                            duration: 0.15,
                            delay: index * 0.05,
                          },
                        }}
                        whileHover={"hover"}
                        key={index}
                        className={`relative flex flex-col justify-between max-w-xs min-h-[250px] mx-auto w-full bg-white dark:bg-neutral-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md ${readGuides?.includes(item.id) && "border-[2.5px] border-green-600"}`}
                      >
                        <Link
                          to={`/guides/${level}/${item.id}`}
                        >
                          {
                            readGuides?.includes(item.id) ? (
                              <div title="Guía leída" className="absolute top-0 right-0 p-2">
                                <CheckCircleIcon className="text-green-500" />
                              </div>
                            ) : null
                          }

                          {
                            item?.isPublished ? null : (
                              <div className="absolute top-0 left-0 p-2">
                                <span className="bg-rose-600 text-white text-xs px-2 py-1 rounded-md font-bold">No publicada</span>
                              </div>
                            )
                          }

                          <div>
                            {item.videoThumbnailPath?.endsWith('.webm') ? (
                              <VideoComponent
                                imageThumbnailPath={item.imageThumbnailPath}
                                videoThumbnailPath={item.videoThumbnailPath}
                              />
                            ) : (
                              <img
                                className="mx-auto my-4 mt-10"
                                width="75%"
                                src={item.imageThumbnailPath}
                                alt="Thumbnail"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                                }}
                              />
                            )}
                          </div>
                          <div className="px-2 pb-2">
                            <h3 className="m-2 font-semibold text-sm xs:text-base">
                              {item.name}
                            </h3>
                            <p className="m-2 font-medium text-xs xs:text-sm text-center">
                              {item.description}
                            </p>
                          </div>
                        </Link>
                      </motion.div>
                    ) : null;
                  })}
                </div>
              )
            }

          </>
        )}
      </motion.div>
    </div>
  );
}

export default GuidesList;
