import { useEffect, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import medals from '../medals/medals.json'
import LoadingSpinner from '../common/loadingSpinner'

const userDashboard = (props) => {
    const [showMedals, setShowMedals] = useState(false);
    const stats = props.stats

    useEffect(() => {
        medals.forEach((img) => {
            const newImg = new Image();
            newImg.src = img.src;
        });
    }, []);

    const MedalsModal = () => {
        return (
            <div className="bg-slate-900 bg-opacity-0 flex mx-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <div className="dark:bg-[#2c2c2c] bg-[#2c2c2c] px-7 py-7 rounded-md text-center w-[1000px] shadow-2xl">
                    <h1 className="mb-10 font-bold text-slate-100 text-xl">Badges</h1>
                    <div className='grid grid-cols-3' >
                        <div>
                            <h3 className='text-black dark:text-white'>Principiante</h3>
                            <div className='flex flex-wrap justify-center'>
                                {medals.map((img, index) => {
                                    return (
                                        <div key={index}>
                                            {img.level === 1 && <img
                                                src={img.src}
                                                style={{ height: '80px' }}
                                                className={`${img.hasIt ? '' : 'opacity-25'}`}
                                            />}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <h3 className='text-black dark:text-white'>Intermedio</h3>
                            <div className='flex flex-wrap justify-center'>
                                {medals.map((img, index) => {
                                    return (
                                        <div key={index} >
                                            {img.level === 2 && <img
                                                src={img.src}
                                                style={{ height: '80px' }}
                                                className={`${img.hasIt ? '' : 'opacity-25'}`}
                                            />}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div >
                            <h3 className='text-black dark:text-white'>Avanzado</h3>
                            <div className='flex flex-wrap justify-center'>
                                {medals.map((img, index) => {
                                    return (
                                        <div key={index}>
                                            {img.level === 3 && <img
                                                src={img.src}
                                                style={{ height: '80px' }}
                                                className={`${img.hasIt ? '' : 'opacity-25'}`}
                                            />}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn px-4 py-2 rounded-md text-md text-white block ml-auto"
                        onClick={() => setShowMedals(false)}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className='bg-slate-400 dark:bg-[#303030] rounded-lg rounded-tl-none'>
            <div className='flex flex-col lg:flex-row'>
                { /* the statistics part is under development */}
                <div className='flex flex-col p-3 profile-container rounded-xl lg:p-5 m-1 lg:w-1/2'>
                    <h3 className='text-base xs:text-lg font-bold'>
                        Estadisticas
                    </h3>
                    <div className='text-sm xs:text-base mt-5'>
                        {
                            stats ?
                                <div className='flex flex-col gap-6'>
                                    <span className='font-semibold'>Cantidad de guias leidas: {stats.readGuides} </span>
                                    <span className='font-semibold'>Cantidad de Retos resueltos: {stats.completedChallenges} </span>
                                    <span className='font-semibold'>Cantidad de Desafíos resueltos: {stats.completedSubChallenges} </span>
                                    <span className='font-semibold'>Cantidad de cuestionarios resueltos: {stats.completedQuizzes} </span>
                                    <span className='font-semibold'>Cantidad de preguntas respondidas: {stats.completedQuestions} </span>
                                    <span className='font-semibold'>Dias conectado: {stats.streak} </span>
                                </div>
                                : <LoadingSpinner size={"250px"} />
                        }
                    </div>
                </div>
                <div className='p-3 profile-container justify-center rounded-xl lg:p-5 m-1 lg:w-1/2 min-h-[325px]'>
                    <h3 className='pb-3 text-base xs:text-lg font-bold'>
                        Badges
                    </h3>
                    <div className='flex flex-row flex-wrap m-2 py-6 justify-evenly'>
                        <Player
                            className='w-[130px] xs:w-[150px] xl:w-[170px] m-1'
                            src={'/medals/lottie/Medal_Assembler_01_Animated_json.json'}
                            alt='Blue medal'
                            autoplay
                            loop
                        />
                        <Player
                            className='w-[130px] xs:w-[150px] xl:w-[170px] m-1 opacity-50'
                            src={'/medals/lottie/Medal_Burpsuite_01_Animated_json.json'}
                            alt='Vel medal'
                            autoplay
                            loop
                        />
                        <Player
                            className='w-[130px] xs:w-[150px] xl:w-[170px] m-1 opacity-50'
                            src={'/medals/lottie/Medal_Cookies_01_Animated_json.json'}
                            alt='Lucy medal'
                            autoplay
                            loop
                        />
                    </div>
                    <button
                        className='block btn ml-auto mt-11'
                        onClick={() => setShowMedals(true)}
                    >
                        Ver todas
                    </button>
                </div>
                {showMedals ? <MedalsModal /> : null}
            </div>
        </div>
    )
}

export default userDashboard
