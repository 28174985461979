import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const NotesSidebar = ({
    variants,
    opened,
    setOpened
}) => {
    const [notes, setNotes] = useState(localStorage.getItem("notes") || "");
    const [warningShowed, setWarningShowed] = useState(false);
    const [warningTimeout, setWarningTimeout] = useState(null);
    const [rows, setRows] = useState(27);
    const sidebarRef = useRef(null);

    useEffect(() => {
        customRows();
        checkLimit();
    }, [notes]);

    const checkLimit = () => {
        if (notes.length > 3000) {
            if (!warningShowed) {
                setWarningShowed(true);
                toast.warn("No se puede guardar más de 3000 caracteres");
                const timeout = setTimeout(() => {
                    setWarningShowed(false);
                    clearTimeout(timeout);
                    setWarningTimeout(null);
                }, 3000);

                setWarningTimeout(timeout);
            }
        } else {
            localStorage.setItem("notes", notes);
            if (warningShowed) {
                setWarningShowed(false);
                if (warningTimeout) {
                    clearTimeout(warningTimeout);
                    setWarningTimeout(null);
                }
            }
        }
    };

    const handleNoteChange = (e) => {
        const newNotes = e.target.value;
        if (newNotes.length <= 3000) {
            setNotes(newNotes);
        } else {
            toast.warn("No se puede guardar más de 3000 caracteres");
        }
    };

    const customRows = () => {
        if(window.innerWidth < 1024){   
            setRows(15);
        } else if(window.innerWidth < 1280){
            setRows(20);
        } else if(window.innerWidth < 1536){
            setRows(26);
        } else{
            setRows(30);
        }
    }

    return (
        <AnimatePresence exitBeforeEnter={true} onExitComplete={() => null}>
            {opened && (
                <motion.div
                    ref={sidebarRef}
                    variants={variants}
                    initial={{ display: 'none', x: '-200%' }}
                    animate={opened ? 'opened' : 'closed'}
                    exit={'closed'}
                    transition={{ ease: 'easeInOut', duration: 0.25 }}
                    className={`md:block fixed left-0 bottom-0 top-0 z-50 bg-white dark:bg-neutral-700 w-[340px] shadow-sidemenu level-wrapper p-2 `}
                >
                    <div className='relative top-12 h-fit flex flex-col'>
                        <FontAwesomeIcon
                            className='block relative top-2 left-2 text-gray-700 dark:text-gray-100 hover:text-rose-600 hover:dark:text-rose-600 
                                m-2 text-xl xs:text-2xl cursor-pointer select-none transition-all duration-200 ease-in-out'
                            onClick={() => setOpened(false)}
                            icon={faTimes}
                        />
                        <h2 className='title text-2xl font-medium'>Notas</h2>
                        <div className='font-medium w-full h-full text-sm bg-gray-300 dark:bg-neutral-800 rounded-md flex-1'>
                            <textarea
                                className="text-gray-700 dark:text-gray-100 bg-gray-300 dark:bg-neutral-800 resize-none w-[98%] focus:outline-none h-full"
                                value={notes}
                                onChange={handleNoteChange}
                                rows={rows}
                            />
                        </div>
                        <small className='text-xs text-center text-gray-700 dark:text-gray-100'>
                            Caracteres restantes: {3000 - notes.length}.
                        </small>
                        <small className='text-xs text-center text-gray-700 dark:text-gray-100'>
                        Esta información se guarda de manera local.
                        </small>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default NotesSidebar;
