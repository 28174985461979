import { React, useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import edChallFunc from './challengeEditorFunctions'
import edFunc from './editorFunctions'
// import CreateObjectiveModal from '../common/createObjectiveModal';
import CreateQuestionModal from '../common/createQuestionModal';
import EditQuestionModal from '../common/editQuestionModal';
import QuestionModal from '../common/QuestionModal'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import { truncateContent } from '../../utils/truncateText';

// TODO: REFACTORIZE CODE

const questionLabel = [
  {
    id: 1,
    label: "Pregunta Única",
  },
  {
    id: 2,
    label: "Pregunta Multiple",
  },
  {
    id: 3,
    label: "Pregunta Abierta",
  },
]

const SubChallengeEditor = () => {
  const token = useSelector((state) => state.user.token)
  const params = useParams();

  const [fields, setFields] = useState({});
  const [challengeFields, setChallengeFields] = useState({})
  const [ChallengeType, setChallengeType] = useState()
  const [questionModal, setQuestionModal] = useState(false)
  const [editQuestionModal, setEditQuestionModal] = useState(false)
  const [seeQuestionModal, setSeeQuestionModal] = useState(false)
  // const [modalObjective, setModalObjective] = useState(false);
  const [questionsList, setQuestionsList] = useState(fields.questions)
  const [subChallengeTypes, setSubChallengeTypes] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [index, setIndex] = useState(0)
  const [editor, setEditor] = useState(true)

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      edChallFunc.HandleGetSubChallengeTypeList(setSubChallengeTypes, token)
      edChallFunc.handleGetChallenge(params.challengeId, setChallengeFields, setEditor, token);
      edChallFunc.handleGetSubChallenge(params.subChallengeId, setFields, token)
      setChallengeType(challengeFields.assignmentTypeId)
    }
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    setChallengeType(challengeFields.assignmentTypeId)
  }, [challengeFields])

  useEffect(() => {
    setQuestionsList(fields.questions)
  }, [fields.questions]);

  const renderQuestionType = (subChallengeTypeId) => {
    const find = questionLabel.find(item => item.id === subChallengeTypeId)
    if (find) {
      return find.label
    }
  }

  const handleCreate = async (question, newAnswer) => {
    const response = await edChallFunc.HandleCreateSubChallengeQuestion(question, newAnswer)
    if (response) {
      return response
    } else {
      return null
    }
  }

  const handleUpdate = async () => {
    const response = await edChallFunc.handleUpdateSubChallenge(fields)
    if (response) {
      toast.success("Desafío actualizado correctamente")
    } else {
      toast.error("Error al actualizar desafío")
    }

  }

  const handleDeleteQuestion = async (questionId) => {
    const response = await edChallFunc.HandleDeleteSubChallengeQuestion(questionId)

    if (response) {
      toast.success("Pregunta eliminada correctamente")
      setQuestionsList(questionsList.filter(item => item.id !== questionId))
    } else {
      toast.error("Error al eliminar pregunta")
    }

  }

  return (
    <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
      <h3 className="title">Editor de Desafíos</h3>
      <div className="flex flex-row py-2 items-center">
        <Link
          className="btn"
          to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${params.challengeId}/SubChallenge`}
        >
          Volver
        </Link>
        <button className={`btn bg-purple-600 hover:bg-purple-700 w-[200px] my-2 ${!fields.id ? "hidden" : ''}`}
          onClick={() => { handleUpdate() }}>
          Guardar Cambios
        </button>
      </div>

      <div className="mb-2 p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md transition-all duration-200 ease-in-out flex flex-col gap-2">
        <div className="editor-fields">
          <label
            className="editor-fields-label w-[200px]"
            htmlFor="name"
          >
            Nombre:
          </label>
          <input
            className={`my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out`}
            id="name"
            onChange={(e) =>
              edFunc.handleChange(e, "name", setFields, fields)
            }
            value={!fields.id ? 'cargando...' : fields.name}
            disabled={!editor}
            placeholder='Escriba la descripción'
          />
        </div>

        <div className="editor-fields">
          <label
            className="editor-fields-label w-[200px]"
            htmlFor="description"
          >
            Descripción:
          </label>
          <input
            className={`my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out`}
            id="description"
            onChange={(e) =>
              edFunc.handleChange(e, "description", setFields, fields)
            }
            disabled={!editor}
            value={!fields.id ? 'cargando...' : fields.description}
          />
        </div>

        {(ChallengeType === 1) && <div className="editor-fields">
          <label
            className="editor-fields-label w-[200px]"
            htmlFor="labUrl"
          >
            LabUrl:
          </label>
          <input
            className={`my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out`}
            id="labUrl"
            onChange={(e) =>
              edFunc.handleChange(e, "labUrl", setFields, fields)
            }
            disabled={!editor}
            value={!fields.id ? 'cargando...' : fields.labUrl}
          />
        </div>}

        <div className="editor-fields">
          <label
            className="editor-fields-label w-[200px]"
            htmlFor="imgPath"
          >
            Url imagen:
          </label>
          <input
            className={`my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out`}
            id="imgPath"
            onChange={(e) =>
              edFunc.handleChange(e, "imgPath", setFields, fields)
            }
            disabled={!editor}
            value={!fields.id ? 'cargando...' : fields.imgPath}
          />
        </div>

        <div className="editor-fields">
          <label
            htmlFor="subChallengeTypeId"
            className="editor-fields-label w-[200px]"
          >
            Tipo de desafío:
          </label>
          <select
            className="my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out"
            name="subChallengeTypeId"
            id="subChallengeTypeId"
            onChange={(e) =>
              edFunc.handleChangeInt(e, "subChallengeTypeId", setFields, fields)
            }
            value={fields.subChallengeTypeId}
          >
            <option hidden defaultValue="default">Seleccione el tipo </option>
            {subChallengeTypes?.map((item, index) => {
              return (
                <option key={index} value={subChallengeTypes[index].id} className="font-medium">
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>


        <div className="editor-fields">
          <label
            className="editor-fields-label w-[200px]"
            htmlFor="labUrl"
          >
            Link
          </label>
          <input
            className={`my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out`}
            id="labUrl"
            name="labUrl"
            onChange={(e) =>
              edFunc.handleChange(e, "labUrl", setFields, fields)
            }
            value={fields?.labUrl}
            disabled={!editor}
            placeholder='Link de Lab/Docker/Attachment'
          />
        </div>

        <div className="editor-fields">
          <label
            className="editor-fields-label w-[200px]"
            htmlFor="subChallengeCode"
          >
            Instrucciones
          </label>
          <textarea
            className={`my-1 p-1 w-full editor-box transition-all duration-200 ease-in-out`}
            id="instructions"
            onChange={(e) =>
              edFunc.handleChange(e, "instructions", setFields, fields)
            }
            value={fields?.instructions}
            disabled={!editor}
            placeholder='Instrucciones'
          />
        </div>

        <h1 className={`text-xl font-medium my-[20px] ${!fields.id ? 'hidden' : ''}`}>Lista de Preguntas</h1>
        <p className={`text-base font-medium my-[20px] ${!fields.id ? 'hidden' : questionsList?.length ? 'hidden' : ''}`}> No hay Preguntas</p>

        {questionsList?.map((item, index) => {

          return (
            <div key={index} className='flex flex-col items-start'>
              <div className='flex editor-fields'>
                <div className="editor-fields-label flex flex-col">
                  <label
                    className='font-bold'
                  >
                    Pregunta {index + 1}
                  </label>
                  <span
                    className='text-xs font-medium'
                  >
                    {
                      renderQuestionType(item?.subChallengeTypeId)
                    }
                  </span>
                </div>
                <Tooltip
                  title={item?.question}
                  arrow
                >
                  <p className='flex items-center text-left m-2 py-1'>
                    {truncateContent(item?.question, 50)}
                  </p>
                </Tooltip>

                <div className='flex-none'>
                  <button className='btn bg-purple-600 hover:bg-purple-700 px-[10px]'
                    onClick={() => {
                      setSelectedQuestion(item)
                      setIndex(index)
                      setSeeQuestionModal(true)
                    }}>
                    <Tooltip title="Ver Pregunta" arrow>
                      <VisibilityIcon />
                    </Tooltip>
                  </button>
                  <button className='btn bg-[#3E73B9] hover:bg-blue-800 px-[10px]'
                    onClick={() => {
                      setSelectedQuestion(item)
                      setIndex(index)
                      setEditQuestionModal(true)
                    }}>
                    <Tooltip title="Editar" arrow>
                      <EditIcon />
                    </Tooltip>
                  </button>
                  {/* TODO: Agregar un modal para borrar */}
                  <button
                    className='btn px-[10px] bg-[#C36B96] hover:bg-red-800'
                    onClick={() => handleDeleteQuestion(item?.id, index)}
                  >
                    <Tooltip title="Borrar" arrow>
                      <DeleteIcon />
                    </Tooltip>
                  </button>
                </div>

              </div>
            </div>
          )
        })}

        <button className={`btn bg-purple-600 hover:bg-purple-700 w-[200px] my-2 ${!fields.id ? "hidden" : ''}`}
          onClick={() => setQuestionModal(true)}>
          Agregar Pregunta
        </button>
      </div>

      {seeQuestionModal && <QuestionModal
        selectedQuestion={selectedQuestion}
        index={index}
        modal={seeQuestionModal}
        questionTypeId={selectedQuestion?.subChallengeTypeId}
        setModal={setSeeQuestionModal}
        token={token}
      />}

      {questionModal && <CreateQuestionModal
        documentId={fields.id}
        modal={questionModal}
        setModal={setQuestionModal}
        questCreateFunc={handleCreate}
        questionsList={questionsList}
        setQuestionsList={setQuestionsList}
        getQuizzes={edChallFunc.handleGetSubChallenge}
        setFields={setFields}
        token={token}
      />}

      {editQuestionModal && <EditQuestionModal
        documentId={params.subChallengeId}
        selectedQuestion={selectedQuestion}
        index={index}
        questUpdateFunc={edChallFunc.HandleUpdateSubChallengeQuestion}
        questionsList={questionsList}
        setQuestionsList={setQuestionsList}
        modal={editQuestionModal}
        getQuizzes={edChallFunc.handleGetSubChallenge}
        setModal={setEditQuestionModal}
        token={token}
        setFields={setFields}
      />}

      { /* CreateObjectiveModal for is in development */}
      {/* {modalObjective && <CreateObjectiveModal
        documentId={fields.id}
        modal={modalObjective}
        setModal={setModalObjective}
        fields={fields}
        setFields={setFields}
        element={"quizObjective"}
        objcreateFunc={edChallFunc.HandleAddQuizObjective}
        objdeleteFunc={edChallFunc.HandleDeleteQuizObjective}
        token={token}
      />} */}
    </div>
  )
}

export default SubChallengeEditor
