import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { addLevel, deleteLevel, editLevel, getLevels } from "../../../services/superAdminService";
import { toast } from "react-toastify";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoaderSpinner from "../../common/loaderSpinner";

const AddModal = ({ show, onClose, onAdd, isLoading }) => {

    const [formData, setFormData] = useState({
        name: '',
        internalCode: '',
        imageThumbnailPath: '',
        videoThumbnailPath: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await onAdd(formData);
        if (res) {
            setFormData({
                name: '',
                internalCode: '',
                imageThumbnailPath: '',
                videoThumbnailPath: ''
            });
        }
    };

    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="dark:bg-slate-600 bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold dark:text-slate-100">Agrega un nivel</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        required
                        name="name"
                        placeholder="Nivel..."
                        className="user-input"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        required
                        name="internalCode"
                        placeholder="Código interno..."
                        className="user-input"
                        value={formData.internalCode}
                        onChange={handleChange}
                    />
                    <input
                        name="imageThumbnailPath"
                        placeholder="Thumbnail img..."
                        className="user-input"
                        value={formData.imageThumbnailPath}
                        onChange={handleChange}
                    />
                    <input
                        name="videoThumbnailPath"
                        placeholder="Thumbnail video..."
                        className="user-input"
                        value={formData.videoThumbnailPath}
                        onChange={handleChange}
                    />
                    <div
                        className="flex justify-center mt-2"
                    >
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {
                                isLoading ? <LoaderSpinner /> : "Agregar"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const UpdateModal = ({ show, onClose, onUpdate, initialData, isLoading }) => {
    const [formData, setFormData] = useState(initialData);

    useEffect(() => {
        setFormData(initialData);
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onUpdate(formData);
    };

    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold text-slate-600">Modifica el nivel</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        required
                        name="name"
                        placeholder="Nivel..."
                        className="user-input"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        required
                        name="imageThumbnailPath"
                        placeholder="Thumbnail img..."
                        className="user-input"
                        value={formData.imageThumbnailPath}
                        onChange={handleChange}
                    />
                    <input
                        required
                        name="videoThumbnailPath"
                        placeholder="Thumbnail video..."
                        className="user-input"
                        value={formData.videoThumbnailPath}
                        onChange={handleChange}
                    />
                    <div
                        className="flex justify-center mt-2"
                    >
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                        >
                            {
                                isLoading ? <LoaderSpinner /> : " Modificar"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const DeleteModal = ({ show, onClose, onDelete, isLoading }) => {
    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1
                    className="mb-10 font-bold text-slate-600"
                >
                    ¿Estás seguro que deseas eliminar este nivel?
                </h1>
                <div className="flex justify-center">
                    <button
                        className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        onClick={onDelete}
                        disabled={isLoading}
                    >
                        {
                            isLoading ? <LoaderSpinner /> : "Eliminar"
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

function LevelEditor() {
    const token = useSelector((state) => state.user.token);
    const [levels, setLevels] = useState([]);
    const [levelId, setLevelId] = useState();
    const [addLevelModal, setAddLevelModal] = useState(false);
    const [updateLevelModal, setUpdateLevelModal] = useState(false);
    const [deleteLevelModal, setDeleteLevelModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateLevelData, setUpdateLevelData] = useState({
        name: '',
        internalCode: '',
        imageThumbnailPath: '',
        videoThumbnailPath: ''
    });

    useEffect(() => {
        fetchLevels();
    }, []);

    const fetchLevels = async () => {
        const query = {
            cant: 50,
        }
        const response = await getLevels(query);

        if (response) {
            setLevels(response);
        }

    };

    const handleAddLevel = async (formData) => {
        setIsLoading(true);
        const res = await addLevel(formData);
        if (res) {
            toast.success('Nivel agregado con éxito');
            setIsLoading(false);
            setAddLevelModal(false);
            await fetchLevels();
            return true;
        } else {
            setIsLoading(false);
            toast.error('Ha ocurrido un error. Por favor intente de nuevo');
            return false;
        }
    };

    const handleUpdateLevel = async (formData) => {
        setIsLoading(true);
        const res = await editLevel(levelId, formData);
        if (res) {
            toast.success('Nivel actualizado con éxito');
            setIsLoading(false);
            setUpdateLevelModal(false);
            await fetchLevels();
        } else {
            setIsLoading(false);
            toast.error('Ha ocurrido un error. Por favor intente de nuevo');
        }
    };

    const handleDeleteLevel = async () => {
        setIsLoading(true);
        await deleteLevel(levelId, token);
        setIsLoading(false);
        setDeleteLevelModal(false);
        toast.success('El nivel ha sido eliminado.');
        await fetchLevels();
    };

    return (
        <div className="flex flex-col min-h-[700px] w-full h-full max-h-[2800px] p-2">
            <AddModal
                show={addLevelModal}
                onClose={() => setAddLevelModal(false)}
                onAdd={handleAddLevel}
                isLoading={isLoading}
            />
            <UpdateModal
                show={updateLevelModal}
                onClose={() => setUpdateLevelModal(false)}
                onUpdate={handleUpdateLevel}
                initialData={updateLevelData}
                isLoading={isLoading}
            />
            <DeleteModal
                show={deleteLevelModal}
                onClose={() => setDeleteLevelModal(false)}
                onDelete={handleDeleteLevel}
                isLoading={isLoading}
            />

            <div className="overflow-x-auto">
                <div className="min-w-screen min-h-screen flex justify-center font-sans overflow-hidden">
                    <div className="w-full lg:w-4/6">
                        <div className="bg-white shadow-md rounded my-6">
                            <div className="flex justify-between mr-5">
                                <h3 className="text-2xl text-gray-800 font-bold ml-5 mt-6">Editor de niveles</h3>
                                <button
                                    className="my-5 bg-rose-600 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => setAddLevelModal(true)}
                                >
                                    + Agregar nivel
                                </button>
                            </div>
                            <table className="min-w-max w-full table-auto border border-gray-200">
                                <thead>
                                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                        <th className="py-3 px-6 text-center border border-gray-300">Niveles</th>
                                        <th className="py-3 px-6 text-center border border-gray-300"> Código interno</th>
                                        <th className="py-3 px-6 text-center border border-gray-300">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 text-sm font-light dark:bg-gray-500 dark:text-gray-200">
                                    {levels?.map((level, index) => (
                                        <tr key={index}>
                                            <td className="py-3 px-6 whitespace-nowrap border border-gray-300">
                                                <div className="flex items-center justify-center">
                                                    <img src='/Michis_Heads_03.png' width='30px' alt="Thumbnail" />
                                                    <span className="font-medium ml-2">{level.name}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-center border border-gray-300">
                                                <div className="flex items-center justify-center">
                                                    <span className="font-medium ml-2">{level.internalCode}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-center border border-gray-300">
                                                <div className="flex item-center justify-center gap-1">
                                                    <button
                                                        className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                        onClick={() => {
                                                            setUpdateLevelModal(true);
                                                            setUpdateLevelData(level);
                                                            setLevelId(level.id);
                                                        }}
                                                    >
                                                        <EditOutlinedIcon />
                                                    </button>
                                                    <button
                                                        className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                        onClick={() => {
                                                            setDeleteLevelModal(true);
                                                            setLevelId(level.id);
                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {levels?.length === 0 && (
                                        <tr className="h-20">
                                            <td
                                                colSpan={2}
                                                className='text-lg font-bold mt-5'

                                            >
                                                <div className="flex justify-center items-center gap-2">
                                                    No hay niveles creados
                                                    <button
                                                        className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                                        onClick={() => setAddLevelModal(true)}
                                                    >
                                                        Crear nivel
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LevelEditor;
