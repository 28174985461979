import { useState, useEffect } from "react";
import edFunc from "./editorFunctions";
import edChallengeFunc from "./challengeEditorFunctions";
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from "react-router-dom";
import LoadingSpinner from "../common/loadingSpinner"
import TagListModal from "../common/tagListModal";
import GuideListModal from "../common/guideListModal"
import { settingsActions } from "../../redux/slices/settingsSlice";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { getScoreTypes } from "../../services/superAdminService";

// const resetFields = {
//   title: "Title",
//   description: "Description",
//   imageThumbnailPath: "www.imageThumbnailPath.com",
//   videoThumbnailPath: "www.videoThumbnailPath.com",
//   headerDesktopPath: "www.headerDesktopPath.com",
//   headerMobilePath: "www.headerMobilePath.com",
//   difficultyMetric: 1,
//   isPublished: false,
//   isPremium: false,
//   difficultyLevelId: 1,
//   assignmentTypeId: 1,
//   guidesId: [],
//   tagsId: [],
//   subChallenge: [],
//   quiz: []
// };

function ChallengeEditor() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token)
  const userData = useSelector((state) => state.user.userData)
  const guideList = useSelector((state) => state.settings.guides);
  const tagList = useSelector((state) => state.settings.tags);
  const levelList = useSelector((state) => state.settings.levels)
  const hasChanged = useSelector((state) => state.settings.hasChanged);
  const scoreTypesState = useSelector((state) => state.settings.scoreTypes);

  const { challengeId } = useParams();
  const [fields, setFields] = useState({});
  const [editor, setEditor] = useState(false);
  const [levels, setLevels] = useState(levelList);
  const [tag, setTag] = useState(tagList);
  const [guide, setGuide] = useState(guideList);
  const [tagModal, setTagModal] = useState(false)
  const [guideModal, setGuideModal] = useState(false)
  const [scoreTypes, setScoreTypes] = useState(scoreTypesState);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getChallenge();
      getInitialData()
    }
    return () => {
      setEditor(false);
      setFields({});
      isMounted = false;
    }
  }, [challengeId]);

  useEffect(() => {
    if (!hasChanged) return;
    function handleOnBeforeUnload(event) {
      event.preventDefault();
      return (event.returnValue = '');
    }
    window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
    }
  }, [hasChanged])

  const getInitialData = async () => {
    if ((!guide && !guide?.length) || (!tag && !tag?.length) || (!levels && !levels.length)) {
      edFunc.HandleGetLevelList(setLevels, token);
      edFunc.HandleGetTagList(setTag, token);
      edFunc.HandleGetGuideList(setGuide, setEditor, token)
    }

    if (scoreTypesState.length > 0) {
      setScoreTypes(scoreTypesState)
    } else {
      const response = await getScoreTypes();
      if (response) {
        setScoreTypes(response)
        dispatch(settingsActions.setScoreTypes(response))
      }
    }
  }

  const getChallenge = async () => {
    const element = await edChallengeFunc.handleGetChallenge(challengeId);
    if (!element) return
    setFields(element);
    setEditor(true);
  }

  return (
    <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
      <h3 className="title">Editor de Retos</h3>
      <div className="flex flex-row py-2 items-center">
        <Link
          className="btn"
          to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor`}
        >
          Volver
        </Link>
      </div>
      <div className="flex flex-row flex-wrap max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 justify-between py-6 px-4 lg:py-2 items-center text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
        <div className="flex flex-col lg:flex-row justify-between items-center w-full">
          <div className="flex flex-col ">

            <div className="flex flex-row py-2 items-center">
              <span className="flex items-center mx-2 relative text-sm font-medium text-gray-700 dark:text-gray-100 transition-all duration-200 ease-in-out ">
                Reto seleccionado :
              </span>
              <span className="text-base font-bold text-gray-700 dark:text-gray-100 m-2 block max-w-[155px] 2xl:max-w-[225px] truncate ...">
                {challengeId ?
                  editor ? fields.title : "-"
                  : "-"}
              </span>
            </div>

            <div className="flex flex-row py-2 items-center">
              <h3 className="flex items-center mx-2 relative text-sm font-medium text-gray-700 dark:text-gray-100 transition-all duration-200 ease-in-out ">
                Tipo de Asignación :
              </h3>
              <h3 className="text-base font-bold text-gray-700 dark:text-gray-100 m-2 block max-w-[155px] 2xl:max-w-[225px] truncate ...">
                {challengeId ?
                  editor ?
                    (fields.assignmentTypeId === 1) ? "Aleatorio" : "Único"
                    : "-"
                  : "-"}
              </h3>
            </div>
          </div>
        </div>
      </div>

      {editor && (
        <div className="mb-2 p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
          <form
            className={`flex flex-col gap-2 ${challengeId ? "" : "opacity-50 pointer-events-none"}`}
            onChange={() => dispatch(settingsActions.setHasChanged(true))}
          >
            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="title"
              >
                Título:
              </label>
              <input
                className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                id="title"
                onChange={(e) =>
                  edFunc.handleChange(e, "title", setFields, fields)
                }
                placeholder="Título"
                value={fields.title}
                autoComplete="off"
              />
            </div>

            <div className="editor-fields ">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="description"
              >
                Descripción:
              </label>
              <textarea
                onChange={(e) =>
                  edFunc.handleChange(e, "description", setFields, fields)
                }
                name="description"
                id="description"
                placeholder="Descripción"
                className="p-1 w-full editor-box h-[75px] resize-none transition-all duration-200 ease-in-out"
                value={fields.description}
              ></textarea>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion de la imagen"
                className="editor-fields-label w-[200px]"
                htmlFor="imageThumbnailPath"
              >
                Miniatura imagen:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "imageThumbnailPath", setFields, fields)
                }
                id="imageThumbnailPath"
                type="text"
                placeholder="Miniatura imagen"
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                value={fields.imageThumbnailPath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion del video"
                className="editor-fields-label w-[200px]"
                htmlFor="videoThumbnailPath"
              >
                Miniatura video:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "videoThumbnailPath", setFields, fields)
                }
                id="videoThumbnailPath"
                type="url"
                placeholder="Miniatura video"
                className="my-2 p-1 w-full editor-box transition-all duration-200 ease-in-out"
                value={fields.videoThumbnailPath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion de la imagen"
                className="editor-fields-label w-[200px]"
                htmlFor="headerDesktopPath"
              >
                Imagen de cabecera:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "headerDesktopPath", setFields, fields)
                }
                id="headerDesktopPath"
                placeholder="Imagen de cabecera"
                type="url"
                className="editor-input"
                value={fields.headerDesktopPath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                title="Ingrese la direccion de la imagen"
                className="editor-fields-label w-[200px]"
                htmlFor="headerMobilePath"
              >
                Imagen de cabecera (móvil):
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChange(e, "headerMobilePath", setFields, fields)
                }
                id="headerMobilePath"
                type="url"
                placeholder="Imagen de cabecera (móvil)"
                className="editor-input"
                value={fields.headerMobilePath}
              ></input>
            </div>

            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="difficultyOrder"
              >
                Métrica de dificultad:
              </label>
              <input
                onChange={(e) =>
                  edFunc.handleChangeInt(e, "difficultyOrder", setFields, fields)
                }
                id="difficultyOrder"
                type="number"
                min="1"
                placeholder="Métrica de dificultad"
                className="editor-input"
                value={fields.difficultyOrder}
              ></input>
            </div>

            {userData && (userData.role.name === 'Super Admin' || userData.role.name === 'Admin') &&
              <div className="editor-fields">
                <label
                  className="editor-fields-label w-[200px]"
                  htmlFor="isPublished"
                >
                  Publicar:
                </label>
                <select
                  className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                  name="isPublished"
                  id="isPublished"
                  value={fields.isPublished || false}
                  onChange={(e) =>
                    edFunc.handleChange(e, "isPublished", setFields, fields)
                  }
                >
                  <option hidden defaultValue="default">-</option>
                  <option value={false}>No</option>
                  <option value={true}>Si</option>
                </select>
              </div>
            }
            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="isPremium"
              >
                Premium:
              </label>
              <select
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                name="isPremium"
                id="isPremium"
                value={fields.isPremium || false}
                onChange={(e) =>
                  edFunc.handleChange(e, "isPremium", setFields, fields)
                }
              >
                <option value={false}>No</option>
                <option value={true}>Si</option>
              </select>
            </div>

            <div className="editor-fields">
              <label
                className="editor-fields-label w-[200px]"
                htmlFor="difficultyLevelId"
              >
                Nivel:
              </label>
              <select
                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                name="difficultyLevelId"
                id="difficultyLevelId"
                value={
                  fields.difficultyLevelId
                    ? fields.difficultyLevelId === ""
                      ? "default"
                      : fields.difficultyLevelId
                    : "default"
                }
                onChange={(e) =>
                  edFunc.handleChangeInt(e, "difficultyLevelId", setFields, fields)
                }
              >
                <option hidden defaultValue="default"></option>
                {levels?.length > 0 && levels?.map((item, index) => {
                  return (
                    <option key={index} value={levels[index]?.id} className="font-medium">
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="editor-fields">
              <label
                htmlFor="difficultyLevel"
                className="editor-fields-label"
              >
                Tipo de score:
              </label>
              <select
                className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                name="scoreTypeId"
                id="scoreTypeId"
                value={fields.scoreType}
                required
                onChange={(e) =>
                  edFunc.handleChangeInt(e, "scoreType", setFields, fields)
                }
              >
                <option hidden defaultValue="default">Seleccione tipo de score</option>
                {scoreTypes?.map((item, index) => {
                  return (
                    <option key={index} value={item.id} className="font-medium">
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="editor-fields justify-start">
              <label
                htmlFor="tag"
                className="editor-fields-label flex-none"
              >
                Etiquetas:
              </label>
              <button className="btn btn w-[150px] bg-[#3E73B9] hover:bg-blue-800"
                id="tag"
                onClick={() => setTagModal(true)}
                type="button">
                Agregar Etiqueta
              </button>
            </div>
            {(fields.tagsId != null) &&
              <div className="editor-fields justify-start">
                {fields.tagsId.map((item, index) => {
                  // using .find to search for the tag objects and obtain the name since the tagId field only save the id number of the tag
                  const tagFind = tag.find(element => element.id === item)
                  return (
                    <div key={index} className="text-lg bg-[#C36B96] px-2 m-2 flex items-center justify-between editor-fields-label rounded-lg w-fit gap-2">
                      <p>{tagFind?.name}</p>
                      <button onClick={(e) => {
                        edFunc.handleChangeArrayRemove(e, "tagsId", setFields, fields, index)
                      }}>
                        <ClearOutlinedIcon
                          className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                        />
                      </button>
                    </div>
                  );
                })}
              </div>}

            <div className="editor-fields justify-start">
              <label
                htmlFor="guidesId"
                className="editor-fields-label flex-none"
              >
                Guía:
              </label>
              <button className="btn btn w-[150px] bg-[#3E73B9] hover:bg-blue-800"
                id="guidesId"
                onClick={() => setGuideModal(true)}
                type="button">
                Agregar Guía
              </button>
            </div>
            {(fields.guidesId != null) &&
              <div className="editor-fields justify-start">
                {
                  guide?.length > 0 && (
                    fields.guidesId.map((item, index) => {
                      // same logic as the tags fields
                      const guideFind = guide.find(element => element.id === item)
                      return (
                        <div key={index} className="text-lg bg-[#C36B96] px-2 m-2 flex items-center justify-between editor-fields-label rounded-lg w-fit gap-2">
                          <p>{guideFind?.name}</p>
                          <button onClick={(e) => {
                            edFunc.handleChangeArrayRemove(e, "guidesId", setFields, fields, index)
                          }}>
                            <ClearOutlinedIcon
                              className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                            />
                          </button>
                        </div>
                      );
                    })
                  )
                }
              </div>}
          </form>
        </div>
      )}

      {!editor && <div className='p-8 m-2 flex justify-center items-center text-center'>
        <LoadingSpinner size={"200px"} />
      </div>}

      <div className="flex justify-center items-center">
        <button
          type="submit"
          className={`btn w-40 mx-2 my-4 ${editor ? "" : "disabled-link"}`}
          onClick={() => {
            dispatch(settingsActions.setHasChanged(false))
            edChallengeFunc.HandleUpdateChallenge(fields, challengeId, token)
          }}
          disabled={!editor}
          hidden={!editor}
        >
          Guardar Reto
        </button>
        <Link
          className={`btn w-40 mx-2 my-4 ${editor ? "" : "disabled-link"}`}
          disabled={!editor}
          hidden={!editor}
          to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${challengeId}/Quiz`}
        >
          Ver Cuestionarios
        </Link>
        <Link
          className={`btn w-40 mx-2 my-4 ${editor ? "" : "disabled-link"}`}
          disabled={!editor}
          hidden={!editor}
          to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${challengeId}/SubChallenge`}
        >
          Ver Desafíos
        </Link>

        <TagListModal
          fields={fields}
          setFields={setFields}
          modal={tagModal}
          setModal={setTagModal}
          token={token}
        />
        <GuideListModal
          fields={fields}
          setFields={setFields}
          modal={guideModal}
          setModal={setGuideModal}
          token={token}
        />
      </div>
    </div>
  );
}

export default ChallengeEditor;
