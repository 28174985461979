import React from 'react'
import QuestionItem from './questionItem';
import ExternalElementButton from './externalElementButton';

const QuestionList = ({
  questionnaire,
  answers,
  questions,
  multipleAnswers,
  completedQuestionsId,
  questionResultIsLoading,
  handleAnswer,
  handleMultipleAnswers,
  handleMultipleChoiceChange,
  handleTextAnswerChange,
  isSubChallenge,
}) => {

  return (
    <ul>
      <div>
        {
          isSubChallenge && (
            <>
              <div
                className='w-full flex justify-between items-center mt-12 relative'
              >
                <div>
                  <h3 className='text-white text-3xl font-bold text-left'>
                    Instrucciones
                  </h3>

                  <p className='py-6 pt-8 text-white text-base text-left'>
                    {questionnaire?.instructions}
                  </p>
                </div>

                {
                  questionnaire?.labUrl && (
                    <ExternalElementButton
                      url={questionnaire?.labUrl}
                      questionType={questionnaire?.subChallengeType}
                    />
                  )
                }

              </div>

              <hr className='my-3 mb-8' />

              <h3 className='text-white text-3xl font-bold text-center'>
                Cuestionario
              </h3>
            </>
          )
        }

        {(questions && questions.length > 0) ?

          questions.map((question, questionIndex) => {

            return (
              <QuestionItem
                key={question.id}
                question={question}
                questionIndex={questionIndex}
                completedQuestionsId={completedQuestionsId}
                handleMultipleAnswers={handleMultipleAnswers}
                handleAnswer={handleAnswer}
                handleMultipleChoiceChange={handleMultipleChoiceChange}
                handleTextAnswerChange={handleTextAnswerChange}
                multipleAnswers={multipleAnswers}
                questionResultIsLoading={questionResultIsLoading}
                answers={answers}
                isSubChallenge={isSubChallenge}
              />
            )
          }) : (
            <div>
              <p className='py-6 pt-8 text-white text-center text-2xl'>No hay preguntas relacionadas</p>
            </div>
          )}
      </div>
    </ul>
  )
}

export default QuestionList