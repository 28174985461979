import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import learningPathFunctions from "../admin/learningPathFunctions";

import LoadingSpinner from "../common/loadingSpinner";
import VideoComponent from "../common/VideoComponent";

function PathSelection() {
  const [t] = useTranslation("global");
  const token = useSelector((state) => state.user.token)
  const pathList = useSelector((state) => state.settings.learningPaths);
  const [paths, setPaths] = useState(pathList);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (paths && paths[0]) {
        setSpinner(true)
      } else {
        learningPathFunctions.HandleGetLearningPathList(setPaths, setSpinner, token)
      }
    }
    return () => {
      isMounted = false;
    }
  }, [])

  const variants = {
    hover: {
      scale: 1.05,
      transition: {
        ease: "easeInOut",
        duration: 0.15,
        delay: 0,
      },
    },
  };

  return (
    <div className="flex flex-wrap justify-center items-start min-h-box h-full">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        className="min-h-box text-center px-2 py-4"
      >
        <h3 className="title">{t("learningPath.title")}</h3>
        <p className="title text-base xs:text-lg md:text-xl">
          {t("learningPath.info")}
        </p>
        {!spinner ? (
          <LoadingSpinner size={"300px"} />
        ) : (
          <>
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 m-auto max-w-[1280px]">

              {
                paths.length ? paths.map((item, index) => {

                  return (
                    <Link key={index} to={`/learningPaths/${item.id}`}>
                      <motion.div
                        variants={variants}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: {
                            ease: "easeInOut",
                            duration: 0.15,
                            delay: index * 0.05,
                          },
                        }}
                        whileHover={"hover"}
                        className="flex flex-col justify-between max-w-xs min-h-[350px] min-w-[300px] mt-2 mx-auto w-full bg-white dark:bg-neutral-800 text-gray-700 dark:text-gray-100 rounded-md shadow-md"
                      >
                        {
                          item?.videoThumbnailPath.endsWith(".webm") ? (
                            <VideoComponent
                              imageThumbnailPath={item.imageThumbnailPath}
                              videoThumbnailPath={item.videoThumbnailPath}
                            />
                          ) :
                            <img
                              className="mx-auto my-4 mt-10"
                              src={item.imageThumbnailPath}
                              alt="image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                              }}
                            />
                        }
                        <div className="px-2 pb-2">
                          <h3 className="m-6 font-semibold text-sm xs:text-base">
                            {item.name}
                          </h3>
                          <p className="m-6 text-sm xs:text-base">
                            {item.description}
                          </p>
                        </div>
                      </motion.div>
                    </Link>


                  )
                }) : (
                  <div
                    className="flex flex-col items-center text-center py-4 mx-auto w-full max-w-[1280px]"
                  >
                    <h3 className="title text-xl text-center">
                      No hay rutas de aprendizaje disponibles
                    </h3>
                  </div>
                )
              }
            </div>
          </>
        )
        }
      </motion.div >
    </div >
  );
}

export default PathSelection;
