import './sponsorsAnimation.css'

function SponsorsSection () {
  return (
    <div className='flex w-full justify-center bg-gray-700 dark:bg-hackear-700 z-10'>
      <div className='slider m-2'>
        <div className='slide-track h-full'>
          <div className='slide'>
            <img
              className='m-4 px-2 w-48 xs:w-48 sm:w-52 xl:w-56'
              alt='google.png'
              src={process.env.PUBLIC_URL + '/sponsors/google.png'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-52 xs:w-56 sm:w-60 xl:w-64'
              alt='hackear.png'
              src={process.env.PUBLIC_URL + '/sponsors/hackear.png'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-40 xs:w-36 sm:w-44 xl:w-48'
              alt='ekoparty.webp'
              src={process.env.PUBLIC_URL + '/sponsors/ekoparty.webp'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-48 xs:w-52 sm:w-56 xl:w-60'
              alt='notpinkcon.png'
              src={process.env.PUBLIC_URL + '/sponsors/notpinkcon.png'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-48 xs:w-48 sm:w-52 xl:w-56'
              alt='google.png'
              src={process.env.PUBLIC_URL + '/sponsors/google.png'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-52 xs:w-56 sm:w-60 xl:w-64'
              alt='hackear.png'
              src={process.env.PUBLIC_URL + '/sponsors/hackear.png'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-40 xs:w-36 sm:w-44 xl:w-48'
              alt='ekoparty.webp'
              src={process.env.PUBLIC_URL + '/sponsors/ekoparty.webp'}
            />
          </div>
          <div className='slide'>
            <img
              className='m-4 px-2 w-48 xs:w-52 sm:w-56 xl:w-60'
              alt='notpinkcon.png'
              src={process.env.PUBLIC_URL + '/sponsors/notpinkcon.png'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SponsorsSection

/* AOS.init({ once: true });
return (
    <div className="flex w-full bg-gray-700 dark:bg-hackear-700 z-10">
        <div className="flex flex-row flex-wrap justify-center mx-auto my-12 sm:my-8 items-center text-center min-h-[300px] p-2 ">
            <div className="flex flex-col xs:flex-row items-center justify-center">
                <img
                    data-aos="fade-zoom-in"
                    data-aos-anchor-placement="center-bottom"
                    className="m-4 w-48 xs:w-48 sm:w-52 xl:w-56"
                    alt={"google.png"}
                    src={process.env.PUBLIC_URL + "/sponsors/google.png"}
                />
                <img
                    data-aos="fade-zoom-in"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="50"
                    className="m-4 w-52 xs:w-56 sm:w-60 xl:w-64"
                    alt={"hackear.png"}
                    src={process.env.PUBLIC_URL + "/sponsors/hackear.png"}
                />
            </div>
            <div className="flex flex-col xs:flex-row items-center justify-center">
                <img
                    data-aos="fade-zoom-in"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="100"
                    className="m-4 w-40 xs:w-36 sm:w-44 xl:w-48"
                    alt={"ekoparty.webp"}
                    src={process.env.PUBLIC_URL + "/sponsors/ekoparty.webp"}
                />
                <img
                    data-aos="fade-zoom-in"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="150"
                    className="m-4 w-48 xs:w-52 sm:w-56 xl:w-60"
                    alt={"notpinkcon.png"}
                    src={process.env.PUBLIC_URL + "/sponsors/notpinkcon.png"}
                />
            </div>
        </div>
    </div>
); */
