import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

function CreateDocumentModal({ modal, setModal, type, title, resetFunc, newDocumentId }) {

  const variants = {
    opened: {
      opacity: 1,
      display: 'flex'
    },
    closed: {
      opacity: 0,
      transitionEnd: {
        display: 'none'
      }
    }
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setModal(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <motion.div
      variants={variants}
      initial={{ display: 'none', opacity: 0 }}
      animate={modal ? 'opened' : 'closed'}
      transition={{ ease: 'easeInOut', duration: 0.1 }}
      className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
    >
      <div className="absolute p-[40px] mt-4 bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-lg shadow-md px-12">
        <h1 className="text-4xl font-medium">
          !Felicitaciones!<br /> 
          <span className='text-2xl'>Ya has creado {type === "guide" ? "la guía:" : type === "challenge" ? "el reto:" : "el learningPath:"} &quot;{title}&quot;</span>
        </h1>
        <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
          onClick={() => {
            resetFunc()
            setModal(false)
          }}>
          <CloseIcon />
        </button>
        <div className='flex flex-col xs:flex-row justify-center mt-[20px]'>
          <button
            className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]`}
            onClick={() => {
              setModal(false)
              resetFunc()
            }}
          >
            Crear {type === "guide" ? "Nueva guía" : "Nuevo reto"}
          </button>
          {(type === "guide") ?
            <Link
              className='btn mx-auto my-2 xs:m-2 min-w-[125px] max-w-[200px]'
              to={`${process.env.PUBLIC_URL}/admin/edition/editor/${newDocumentId}`}
            >
              Editar Guia
            </Link>
            : (type === "challenge") ?
              <Link
                className='btn mx-auto my-2 xs:m-2 min-w-[125px] max-w-[200px]'
                to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${newDocumentId}`}
              >
                Editar Reto
              </Link>
              : <Link
                className='btn mx-auto my-2 xs:m-2 min-w-[125px] max-w-[200px]'
                to={`${process.env.PUBLIC_URL}/admin/edition/learningPathEditor/${newDocumentId}`}
              >
                Editar LearningPath
              </Link>}
        </div>
      </div>
    </motion.div>
  )
}

export default CreateDocumentModal
