export const getClassByLevel = (level) => {

    let bgColor = '';
    switch (level) {
        case 'Principiante':
            bgColor = 'bg-[#4379C3]';
            break;
        case 'Intermedio':
            bgColor = 'bg-[#C36B96]';
            break;
        default:
            bgColor = 'bg-[#662D91]';
    }

    return `${bgColor}`;
};