import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import edFunc from "../admin/editorFunctions";
import edChallFunc from "../admin/challengeEditorFunctions";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import QuestionsTabs from './questionsTabs';

const dinamycSizeDesktop = (selectedQuestion) => {
    if (selectedQuestion.question?.length > 100 || selectedQuestion.hint?.length > 100) {
        return "sm:w-3/4"
    } else {
        return "sm:w-3/4"
    }
}

const variants = {
    opened: {
        opacity: 1,
        display: 'flex'
    },
    closed: {
        opacity: 0,
        transitionEnd: {
            display: 'none'
        }
    }
}

function editQuestionModal({
    documentId,
    selectedQuestion,
    index,
    questUpdateFunc,
    questionsList,
    setQuestionsList,
    modal,
    setModal,
    token,
    getQuizzes,
    setFields
}) {

    const questionFields = {
        question: selectedQuestion.question,
        hint: selectedQuestion.hint,
        index,
        questionTypeId: selectedQuestion.questionTypeId ?? selectedQuestion?.subChallengeTypeId,
        scoreTypeId: 2,
        answers: selectedQuestion.answers,
        id:selectedQuestion?.id
    }

    const [question, setQuestion] = useState(questionFields)
    const [answer, setAnswer] = useState(selectedQuestion.answers);
    const [spinnerEnable, setSpinnerEnable] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleUpdateQuestion = async () => {
        setSpinnerEnable(true)
        const newAnswer = [...answer];

        // Llamar a la función correspondiente según el tipo de pregunta
        await handleCreateQuestion(newAnswer);

        setSpinnerEnable(false)
    }

    const handleCreateQuestion = async (newAnswer) => {

        if (question.questionTypeId === 1) {
            await createDefaultAnswer(newAnswer);
        } else if (question.questionTypeId === 2) {
            await createMultipleAnswer(newAnswer);
        } else {
            await createTrueOrFalseAnswer(newAnswer);
        }
    }

    const createDefaultAnswer = async (newAnswer) => {
        if (answer[0] && answer[0].answer?.trim() === "") {
            toast.error("La respuesta no puede estar vacía")
            setSpinnerEnable(false)
            return
        }

        await updateQuestion(question, newAnswer, token);
    }

    const createMultipleAnswer = async (newAnswer) => {
        if (newAnswer.length < 2) {
            toast.error("Debes agregar al menos 2 opciones")
            setSpinnerEnable(false)
            return
        } else if (newAnswer.some((item) => item.answer?.trim() === "")) {
            toast.error("Las respuestas no pueden estar vacías")
            setSpinnerEnable(false)
            return
        } else if (!newAnswer.some((item) => item.isTrueAnswer === true)) {
            toast.error("Debe seleccionar una respuesta correcta")
            setSpinnerEnable(false)
            return
        }

        await updateQuestion(question, newAnswer, token);
    }

    const createTrueOrFalseAnswer = async (newAnswer) => {
        if (newAnswer.some((item) => item.answer?.trim() === "")) {
            toast.error("Las respuestas no pueden estar vacías")
            setSpinnerEnable(false)
            return
        } else if (!newAnswer.some((item) => item.isTrueAnswer === true)) {
            toast.error("Debe seleccionar una respuesta correcta")
            setSpinnerEnable(false)
            return
        }

        await updateQuestion(question, newAnswer, token);
    }

    const updateQuestion = async (question, newAnswer) => {

        const updatedQuestion = await questUpdateFunc(question, newAnswer, question.id, documentId);

        if (updatedQuestion) {
            toast.success("Pregunta actualizada")
            questionsList[index] = { id: selectedQuestion.id, question: updatedQuestion.question, questionTypeId: updatedQuestion.questionTypeId, scoreTypeId: 2, hint: updatedQuestion.hint, answers: newAnswer }
            await getQuizzes(documentId, setFields)
            setQuestionsList(questionsList)
            setSpinnerEnable(false)
            setModal(false)
        } else {
            toast.error("Error al actualizar la pregunta")
            setSpinnerEnable(false)
        }
    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-[999]'
        >
            <div className={`absolute p-4 m-2 text-center bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md w-full ${dinamycSizeDesktop(selectedQuestion)}`}>
                <h1 className='text-xl font-medium my-[20px]' htmlFor='title'>
                    Editar Pregunta
                </h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        setModal(false)
                    }}>
                    <CloseIcon />
                </button>
                <div className='flex flex-col gap-2'>
                    <div className="editor-fields ">
                        <label
                            className="editor-fields-label"
                            htmlFor="question"
                        >
                            Pregunta:
                        </label>
                        <textarea
                            className={`editor-input ${question.question?.length > 180 ? 'h-[160px]' : 'h-[70px]'} transition-all duration-200 ease-in-out`}
                            value={question.question}
                            placeholder='Escriba la pregunta...'
                            id='question'
                            onChange={(e) => edFunc.handleChange(e, "question", setQuestion, question)}
                        />
                    </div>
                    <div className="editor-fields ">
                        <label
                            className="editor-fields-label"
                            htmlFor="hint"
                        >
                            Hint:
                        </label>
                        <textarea
                            className={`editor-input ${question.hint?.length > 180 ? 'h-[160px]' : 'h-[70px]'} transition-all duration-200 ease-in-out`}
                            value={question.hint}
                            placeholder='Escriba la pista...'
                            id='hint'
                            onChange={(e) => edFunc.handleChange(e, "hint", setQuestion, question)}
                        />
                    </div>
                    <div className="editor-fields">
                        <label
                            htmlFor="questionTypeId"
                            className="editor-fields-label"
                        >
                            Tipo de Pregunta:
                        </label>
                        <p className='flex items-center m-2 py-1 w-full'>
                            {
                                (question?.questionTypeId === 1) ? "Default"
                                    : (question?.questionTypeId === 2) ? "Multiple Choice"
                                        : "Verdadero y Falso"
                            }
                        </p>
                    </div>

                    {
                        parseInt(question.questionTypeId) === 1 && (
                            <div className="editor-fields">
                                <label
                                    className="editor-fields-label"
                                    htmlFor="answer"
                                >
                                    Respuesta:
                                </label>
                                <textarea
                                    onChange={(e) =>
                                        edChallFunc.handleAnswer(e, setAnswer, answer, 0)
                                    }
                                    id="answer"
                                    className={`p-1 w-full editor-box transition-all duration-200 ease-in-out ${answer[0].answer?.length > 140 ? 'h-[160px]' : 'h-[70px]'}`}
                                    placeholder='Escriba la respuesta...'
                                    defaultValue={answer[0].answer}
                                />

                            </div>
                        )
                    }

                    {
                        (parseInt(question.questionTypeId) === 2 || parseInt(question.questionTypeId) === 3) && (
                            <p>Opciones:</p>
                        )
                    }

                    {
                        (parseInt(question.questionTypeId) === 2 || parseInt(question.questionTypeId) === 3) ? (
                            <QuestionsTabs
                                selectedQuestion={selectedQuestion}
                                isEditable={true}
                                onAnswerChange={setAnswer}
                            />
                        ) : (
                            <></>
                        )
                    }

                    <div className='flex flex-col xs:flex-row justify-center'>
                        <button
                            className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]`}
                            onClick={() => handleUpdateQuestion()}
                        >
                            {spinnerEnable
                                ? (
                                    <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                                )
                                : (
                                    'Guardar Cambios'
                                )}
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default editQuestionModal
