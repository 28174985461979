import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function RequiresAdmin() {
  const { currentUser } = useSelector((state) => state.user);
  const data = useSelector((state) => state.user.userData)

  return currentUser && (data.role.name === "Full Moderator" || data.role.name === "Content Creator" || data.role.name === "Guides Moderator" ||
    data.role.name === "Super Admin" || data.role.name === "Admin") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}

export default RequiresAdmin;
