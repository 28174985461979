import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guides: [],
  challenges: [],
  learningPaths: [],
  levels: [],
  tags: [],
  language: "es",
  url: "",
  hasChanged: false,
  scoreTypes: [],
  atackerVmRunning: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setGuides(state, action) {
      state.guides = action.payload;
    },
    setChallenges(state, action) {
      state.challenges = action.payload;
    },
    setLearningPaths(state, action) {
      state.learningPaths = action.payload;
    },
    setLevels(state, action) {
      state.levels = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setUrl(state, action) {
      state.url = action.payload;
    },
    setHasChanged(state, action) {
      state.hasChanged = action.payload;
    },
    setScoreTypes(state, action) {
      state.scoreTypes = action.payload;
    },
    setAtackerVmRunning(state, action) {
      state.atackerVmRunning = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export default settingsSlice.reducer;
