import { motion } from 'framer-motion';
import React, { useState, useEffect, useMemo } from "react";
import edFunc from "../admin/editorFunctions";
import { Pagination } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// TODO: replace this icon
// import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from "react-redux";

const TagListModal = ({ fields, setFields, modal, setModal }) => {
    const variants = {
        opened: {
            opacity: 1,
            display: 'flex'
        },
        closed: {
            opacity: 0,
            transitionEnd: {
                display: 'none'
            }
        }
    }

    const theme = useSelector((state) => state.theme.value);
    const [tags, setTags] = useState(['']);
    const [currentPage, setCurrentPage] = useState(1);
    const [input, setInput] = useState('');
    const [tagsSearchList, setTagsSearchList] = useState([]);
    const PageSize = 10;

    const paginationStyles = {
        ul: {
            '& .MuiPaginationItem-root': {
                color: theme === 'dark' ? '#f3f4f6' : '#030712'
            }
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            edFunc.HandleGetTagList(setTags);
        }
        return () => {
            isMounted = false;
        }
    }, []);

    // to close modal when user press 'ESC'
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false);
            }
        }
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [setModal]);

    useEffect(() => {
        setTagsSearchList(tags);
    }, [tags]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return tagsSearchList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, tagsSearchList]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const toggleAdding = async (item) => {
        if (!fields.tagsId?.includes(item.id)) {
            if (fields.tagsId) {
                fields.tagsId.push(item.id);
            } else {
                fields.tagsId = [item.id];
            }
        } else {
            const index = fields.tagsId.indexOf(item.id);
            fields.tagsId.splice(index, 1);
        }
        setFields(fields);
        setModal(false);
    }

    const tagSearch = () => {
        // first convert the tag names to lowercase then filter the tags whose name includes the input also in lowercase
        const result = tags.filter((tags) => tags.name.toLowerCase().includes(input.toLowerCase()));
        setTagsSearchList(result);
        setCurrentPage(1);
    }

    const handleKeydown = (e) => {
        if (e.key === 'Enter') {
            tagSearch();
        }
    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
        >
            <div className="absolute px-[50px] py-[20px] mt-4 bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md">
                <h1 className="title mt-0 text-gray-700 dark:text-gray-100">Lista de Etiquetas</h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        setModal(false);
                    }}>
                    <CloseIcon />
                </button>
                <div className='flex my-4 rounded-lg bg-gray-300 dark:bg-hackear-800'>
                    <input name='search' placeholder="Buscar por Nombre" className="p-2 w-full text-left font-semibold rounded-lg bg-gray-300 dark:bg-hackear-800 outline-none"
                        onChange={(e) => setInput(e.currentTarget.value)}
                        onKeyDown={handleKeydown}
                    />
                    <button className="mx-2" onClick={tagSearch}>
                        {/* <SearchIcon /> */}
                    </button>
                </div>
                <div className='grid grid-cols-2 gap-2'>
                    {currentTableData[0] ? currentTableData.map((item, index) => (
                        <div key={index} className='flex justify-between mx-4'>
                            <p className={`m-1 p-2 text-lg rounded-lg ${fields.tagsId?.includes(item.id) ? 'bg-purple-600' : ''}`}>
                                {item?.name}
                            </p>
                            <button className={`btn w-[80px] ${fields.tagsId?.includes(item.id) ? '' : 'bg-[#3E73B9] hover:bg-blue-900'}`}
                                onClick={() => toggleAdding(item)}>
                                {fields.tagsId?.includes(item.id) ? "Quitar" : "Agregar"}
                            </button>
                        </div>
                    )) : input ? (
                        <p colSpan={3} className='text-lg mt-5'>No se encontraron resultados</p>
                    ) : (
                        <p colSpan={3} className='text-lg mt-5'>Cargando...</p>
                    )}
                </div>
                {!currentTableData[0] && tagsSearchList[0] ? (
                    <div className='p-8 m-2 flex justify-center items-center text-center'>
                        <div className='spinner w-20 h-20 xs:w-20 xs:h-20 rounded-full border-2 border-white border-l-2 border-l-purple-600 animate-spin' />
                    </div>
                ) : (
                    <div className="flex justify-center mb-4 mt-4">
                        <Pagination
                            sx={paginationStyles}
                            count={Math.ceil(tagsSearchList.length / PageSize)}
                            size="large"
                            color="secondary"
                            showFirstButton
                            showLastButton
                            onChange={handlePageChange}
                        />
                    </div>
                )}
            </div>
        </motion.div>
    )
}

export default TagListModal;
