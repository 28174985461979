import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: localStorage.getItem("theme") || "dark",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme(state) {
      if (state.value === "dark") {
        state.value = "light";
      } else if (state.value === "light") {
        state.value = "dark";
      }
      localStorage.setItem("theme", state.value)
    },
  },
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
