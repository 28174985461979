import { useState, useEffect } from "react";
import edFunc from "./editorFunctions";
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from "react-router-dom";
import TagListModal from "../common/tagListModal";
import learningPathFunctions from "./learningPathFunctions";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { settingsActions } from "../../redux/slices/settingsSlice";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

// const resetFields = {
//     id: 0,
//     name: "Title",
//     description: "Description",
//     imageThumbnailPath: "www.imageThumbnailPath.com",
//     videoThumbnailPath: "www.videoThumbnailPath.com",
//     tagsId: [],
// };

const LearningPathEditor = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token)
    const { learningPathId } = useParams();
    const tagList = useSelector((state) => state.settings.tags);
    const hasChanged = useSelector((state) => state.settings.hasChanged);
    const [fields, setFields] = useState({});
    const [editor, setEditor] = useState(false);
    const [tags, setTags] = useState(tagList);
    const [tagModal, setTagModal] = useState(false)

    useEffect(() => {
        setEditor(false)
        let isMounted = true;
        if (isMounted) {
            if (!tags[0]) {
                edFunc.HandleGetTagList(setTags, token)
            }

            getLearningPath()
        }
        return () => {
            isMounted = false;
            setFields({})
            setEditor(false)
        }
    }, [learningPathId]);

    useEffect(() => {
        if (!hasChanged) return;
        function handleOnBeforeUnload(event) {
            event.preventDefault();
            return (event.returnValue = '');
        }
        window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
        }
    }, [hasChanged])

    const getLearningPath = async () => {
        const res = await learningPathFunctions.handleGetLearningPath(learningPathId, setFields, setEditor, token)
        setFields(res)
        setEditor(true)
    }


    return (
        <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
            <h3 className="title">Editor de Ruta de Aprendizaje</h3>
            <div className="flex flex-row py-2 items-center">
                <Link
                    className="btn"
                    to={`${process.env.PUBLIC_URL}/admin/edition/learningPathEditor`}
                >
                    Volver
                </Link>
            </div>
            <div className="flex flex-row flex-wrap max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 justify-between py-6 px-4 lg:py-2 items-center text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
                <div className="flex flex-col justify-between w-full">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="flex flex-row items-center">
                            <h3 className="flex items-center relative text-sm font-medium text-gray-700 dark:text-gray-100 transition-all duration-200 ease-in-out ">
                                Documento seleccionado:
                            </h3>
                            <h3 className="text-base font-bold text-gray-700 dark:text-gray-100 m-2 block max-w-[155px] 2xl:max-w-[225px] truncate ...">
                                {learningPathId ? editor ? fields.name : "-" : "-"}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            {editor && (
                <div className="mb-2 p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
                    <form
                        className={`flex flex-col gap-2 ${learningPathId ? "" : "opacity-50 pointer-events-none"}`}
                        onChange={() => dispatch(settingsActions.setHasChanged(true))}
                    >
                        <div className="editor-fields">
                            <label
                                className="editor-fields-label w-[200px]"
                                htmlFor="name"
                            >
                                Titulo:
                            </label>
                            <input
                                className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                                id="name"
                                onChange={(e) =>
                                    edFunc.handleChange(e, "name", setFields, fields)
                                }
                                placeholder="Escriba aquí el nombre..."
                                disabled={!learningPathId}
                                value={fields.name}
                                autoComplete="off"
                            />
                        </div>

                        <div className="editor-fields ">
                            <label
                                className="editor-fields-label w-[200px]"
                                htmlFor="description"
                            >
                                Descripción:
                            </label>
                            <textarea
                                onChange={(e) =>
                                    edFunc.handleChange(e, "description", setFields, fields)
                                }
                                placeholder="Escriba aquí la descripción..."
                                name="description"
                                id="description"
                                className="p-1 w-full editor-box h-[75px] resize-none transition-all duration-200 ease-in-out"
                                value={fields.description}
                            ></textarea>
                        </div>

                        <div className="editor-fields">
                            <label
                                title="Ingrese la direccion de la imagen"
                                className="editor-fields-label w-[200px]"
                                htmlFor="imageThumbnailPath"
                            >
                                Miniatura imagen:
                            </label>
                            <input
                                onChange={(e) =>
                                    edFunc.handleChange(e, "imageThumbnailPath", setFields, fields)
                                }
                                id="imageThumbnailPath"
                                placeholder="Ingrese la url de la imagen..."
                                type="text"
                                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                                value={fields.imageThumbnailPath}
                            ></input>
                        </div>

                        <div className="editor-fields">
                            <label
                                title="Ingrese la direccion del video"
                                className="editor-fields-label w-[200px]"
                                htmlFor="videoThumbnailPath"
                            >
                                Miniatura video:
                            </label>
                            <input
                                onChange={(e) =>
                                    edFunc.handleChange(e, "videoThumbnailPath", setFields, fields)
                                }
                                placeholder="Ingrese la url del video..."
                                id="videoThumbnailPath"
                                type="url"
                                className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
                                value={fields.videoThumbnailPath}
                            ></input>
                        </div>

                        <div className="editor-fields justify-start">
                            <label
                                htmlFor="tagsId"
                                className="editor-fields-label"
                            >
                                Etiquetas:
                            </label>
                            <button
                                className="btn w-[175px] bg-[#3E73B9] hover:bg-blue-900"
                                id="tagsId"
                                onClick={() => setTagModal(true)}
                                type="button"
                            >
                                Agregar Etiqueta
                            </button>
                        </div>

                        {(fields.tagsId != null) &&
                            <div className="editor-fields justify-start">
                                {fields.tagsId.map((item, index) => {
                                    // using .find to search for the tag objects and obtain the name since the tagId field only save the id number of the tag
                                    const tagFind = tags.find(element => element.id === item)
                                    return (
                                        <div
                                            key={index}
                                            className="text-lg bg-[#C36B96] px-2 m-2 flex items-center justify-between editor-fields-label rounded-lg w-fit gap-2"
                                        >
                                            <p>{tagFind?.name}</p>
                                            <button onClick={(e) => {
                                                edFunc.handleChangeArrayRemove(e, "tagsId", setFields, fields, index)
                                            }}>
                                                <ClearOutlinedIcon
                                                    className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                                                />
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>}
                    </form>
                </div>)}

            <div className="flex justify-center items-center">
                <button
                    type="submit"
                    className={`btn w-40 mx-2 my-4 ${learningPathId ? "" : "disabled-link"}`}
                    onClick={() => {
                        dispatch(settingsActions.setHasChanged(false))
                        learningPathFunctions.HandleUpdateLearningPath(fields, learningPathId, token)
                    }}
                    disabled={!learningPathId}
                    hidden={!learningPathId}
                >
                    Guardar LearningPath
                </button>
            </div>
            {!editor && <div className='p-8 m-2 flex justify-center items-center text-center'>
                <LoadingSpinner size={"300px"} />
            </div>}
            <TagListModal
                fields={fields}
                setFields={setFields}
                modal={tagModal}
                setModal={setTagModal}
                token={token}
            />
        </div>
    )
}

export default LearningPathEditor
