import { toast } from 'react-toastify'
import { getGuide, getTagList, getLevelList, getGuideList, createPage, deleteGuide, deleteGuidePage, updateGuide, createGuide, createObjective, deleteObjective } from '../../services/guidesService'
import { getRelatedGuidesAndChallenges } from '../../services/learningPathService';

const newPage = async (fields) => {
  const page = {
    name: `Nueva Pagina ${fields.guidePage.length + 1}`,
    content: "Nueva descripcion",
    guideId: fields.id
  };
  const res = await createPage(page)
  if (res) {
    return res
  } else {
    return null
  }
};

const handleGetGuide = async (guideId, setFields, setPageFields, setEditor, setPages, setPageId, ind, token) => {
  const element = await getGuide(guideId);
  const emptyFields = {
    id: element.id,
    name: element.name,
    description: element.description,
    imageThumbnailPath: element.imageThumbnailPath,
    videoThumbnailPath: element.videoThumbnailPath,
    headerDesktopPath: element.headerDesktopPath,
    headerMobilePath: element.headerMobilePath,
    difficultyOrder: element.difficultyOrder,
    isPublished: element.isPublished,
    isPremium: element.isPremium,
    difficultyLevelId: element.difficultyLevel,
    guidePage: element.guidePage,
    tagsId: [],
    challengesId: [],
    guideObjective: element.guideObjective || null,
    scoreType: element?.scoreType?.id || 1
  };

  if (element.guideTagLink[0]) {
    element.guideTagLink.forEach(object => {
      emptyFields.tagsId.push(object.tag?.id)
    })
  };

  if (element.challengeGuideLink[0]) {
    element.challengeGuideLink.forEach(object => {
      emptyFields.challengesId.push(object.challenge?.id)
    })
  };

  const emptyPageFields = {
    name: element.guidePage[ind]?.name,
    content: element.guidePage[ind]?.content,
    index: element.guidePage[ind]?.index,
    guideId: element.id,
  };

  setPages(element.guidePage)
  setPageId(element.guidePage[ind]?.id)
  setFields(emptyFields)
  setPageFields(emptyPageFields)
  setEditor(true);

};

const GetGuideForUser = async (guideId, setGuide, setSpinner, setPages, token) => {
  const element = await getGuide(guideId, token);
  const emptyFields = {
    id: element.id,
    name: element.name,
    description: element.description,
    imageThumbnailPath: element.imageThumbnailPath,
    videoThumbnailPath: element.videoThumbnailPath,
    headerDesktopPath: element.headerDesktopPath,
    headerMobilePath: element.headerMobilePath,
    difficultyOrder: element.difficultyOrder,
    isPublished: element.isPublished,
    isPremium: element.isPremium,
    difficultyLevelId: element.difficultyLevelId,
    guidePage: element.guidePage,
    tagsId: [],
    challengesId: [],
    guideObjective: element.guideObjective || null,
  };
  if (element.guideTagLink[0]) {
    element.guideTagLink.forEach(object => {
      emptyFields.tagsId.push(object.tag?.id)
    })
  };
  if (element.challengeGuideLink[0]) {
    element.challengeGuideLink.forEach(object => {
      emptyFields.challengesId.push(object.challenge?.id)
    })
  };
  setPages(element.guidePage)
  setGuide(emptyFields)
  setSpinner(false)
};

const handleChange = (e, element, setFields, fields) => {
  const text = e.currentTarget.value;
  const newFields = { ...fields };
  if (element === "isPublished" || element === "isPremium") {
    if (text === "true") {
      newFields[element] = true
    } else {
      newFields[element] = false
    }
  } else {
    newFields[element] = text;
  }
  setFields(newFields);
};

const handleChangeInt = (e, element, setFields, fields) => {
  const text = e.currentTarget.value;
  const newFields = { ...fields };
  newFields[element] = parseInt(text);
  setFields(newFields);
};

const handleChangeArray = (e, element, setFields, fields) => {
  const newId = e.currentTarget.value;
  const newFields = { ...fields };
  newFields[element].push(newId);
  setFields(newFields);
};
const handleChangeArrayRemove = (e, element, setFields, fields, index) => {
  e.preventDefault()
  const newFields = { ...fields };
  newFields[element].splice(index, 1);
  setFields(newFields);
};
const HandleGetGuideList = async (setGuides, setSpinner) => {
  const element = await getGuideList()
  if (element) {
    setGuides(element)
    setSpinner(true)
  } else {
    setGuides([])
    setSpinner(true)
  }
};
const HandleGetLevelList = async (setLevels) => {
  const element = await getLevelList()
  if (element) {
    setLevels(element)
  } else {
    setLevels([])
  }
};
const HandleGetTagList = async (setTag) => {
  const element = await getTagList()
  if (element) {
    setTag(element)
  } else {
    setTag([])
  }
};
const handleDelete = async (guideId, token) => {
  await deleteGuide(guideId, token)
};

const handlePageDelete = async (pageId) => {
  const response = await deleteGuidePage(pageId)
  if (response) {
    return response
  } else {
    return null
  }
};

const handleModal = (fieldId, setModal) => {
  if (fieldId) {
    setModal(true);
  } else {
    toast.error("No ha seleccionado un documento");
  }
};
const HandleUpdateGuide = async (guide, guideId, token) => {
  const newFields = {
    name: guide.name,
    description: guide.description,
    imageThumbnailPath: guide.imageThumbnailPath || 'www.imageThumbnailPath.com',
    videoThumbnailPath: guide.videoThumbnailPath || "www.videoThumbnailPath.com",
    headerDesktopPath: guide.headerDesktopPath || "www.headerDesktopPath.com",
    headerMobilePath: guide.headerMobilePath || "www.headerMobilePath.com",
    difficultyOrder: guide.difficultyOrder,
    isPublished: guide.isPublished,
    isPremium: guide.isPremium,
    difficultyLevelId: guide.difficultyLevelId,
    tagsId: guide.tagsId,
    scoreType: guide.scoreType,
  };
  const update = await updateGuide(newFields, guideId, token)
  if (update) {
    toast.success("Guía actualizada")
    return update
  } else {
    toast.error("No se pudo actualizar la guía, pruebe otra vez")
    return null
  }

};
const HandleCreateGuide = async (guide) => {
  const newFields = {
    name: guide.name,
    description: guide.description,
    imageThumbnailPath: guide.imageThumbnailPath || 'www.imageThumbnailPath.com',
    videoThumbnailPath: guide.videoThumbnailPath || "www.videoThumbnailPath.com",
    headerDesktopPath: guide.headerDesktopPath || "www.headerDesktopPath.com",
    headerMobilePath: guide.headerMobilePath || "www.headerMobilePath.com",
    difficultyOrder: guide.difficultyOrder,
    isPublished: guide.isPublished || false,
    isPremium: guide.isPremium,
    difficultyLevelId: guide.difficultyLevelId,
    tagsId: guide.tagsId,
    scoreType: guide.scoreType,
    guidePage: [{
      "name": guide.guidePage,
      "content": "Empiece a escribir el contenido aquí",
      "index": 0
    }],
  };

  const newguide = await createGuide(newFields)
  return newguide
};
const HandleAddGuideObjective = async (content, guideId, token) => {
  const objective = {
    description: content,
    guideId
  }
  const newObjective = await createObjective(objective, token)
  if (newObjective.id) {
    toast.success("Objetivo creado con exito")
  } else {
    toast.error("No se pudo crear el objetivo")
  }
  return newObjective
};
const HandleDeleteGuideObjective = async (objectiveId) => {
  await deleteObjective(objectiveId)
};

const HandleGetRelatedGuidesAndChallenges = async (learningPathId) => {
  const element = await getRelatedGuidesAndChallenges(learningPathId)
  if (element) {
      return element
  }
}

const editorFunctions = {
  handleModal,
  handleDelete,
  handlePageDelete,
  handleChange,
  handleChangeInt,
  handleChangeArray,
  handleChangeArrayRemove,
  handleGetGuide,
  newPage,
  HandleGetTagList,
  HandleGetLevelList,
  HandleGetGuideList,
  HandleCreateGuide,
  HandleUpdateGuide,
  HandleAddGuideObjective,
  HandleDeleteGuideObjective,
  GetGuideForUser,
  HandleGetRelatedGuidesAndChallenges
};

export default editorFunctions
