import { useState, useEffect } from "react";
import edChallFunc from "./challengeEditorFunctions";
import { useSelector } from 'react-redux'
import DeleteDocumentModal from "../common/deleteDocumentModal";
import { useParams, Link } from "react-router-dom";
// import LoadingSpinner from "../common/loadingSpinner";
import CreateSubchallengeModal from "../common/CreateSubchallengeModal";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";

const SubChallengeList = () => {

    const token = useSelector((state) => state.user.token)
    const { challengeId } = useParams();
    const [fields, setFields] = useState({});
    const [currentSubChallengeId, setCurrentSubChallengeId] = useState(0);
    const [deleteSubChallengeName, setDeleteSubChallengeName] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [subChallengeList, setSubChallengeList] = useState([]);
    const [createSubChallenge, setCreateSubChallenge] = useState(false);
    const [challengeType, setChallengeType] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSpinner(false)
            edChallFunc.handleGetChallenge(challengeId, setFields, setSpinner, token);
            edChallFunc.handleGetSubChallengeList(challengeId, setSubChallengeList, token)
        }
        return () => {
            isMounted = false;
        }
    }, []);

    const convertDate = (date) => {
        const day = date[8] + date[9]
        const month = date[5] + date[6]
        const year = date[0] + date[1] + date[2] + date[3]
        const newDate = day + '/' + month + '/' + year
        return newDate
    }

    const handleDeleteSubChallenge = async (subChallengeId) => {
        const response = await edChallFunc.handleSubChallengeDelete(subChallengeId)
        if (response) {
            toast.success('Desafío eliminado')    
        }else{
            toast.error('Error al eliminar el desafío')
        }
    }

    return (
        <div className="flex flex-col w-full h-full max-h-[2800px] p-2 text-gray-700 dark:text-gray-100">
            <h1 className="title">Lista de Desafíos</h1>
            <div className="flex flex-row py-2 items-center">
                <Link
                    className="btn"
                    to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${challengeId}`}
                >
                    Volver
                </Link>
                <button className='btn bg-purple-600 hover:bg-purple-700 w-[200px] my-2'
                    onClick={() => {
                        setChallengeType(fields.assignmentTypeId)
                        setCreateSubChallenge(true)
                    }}>
                    Nuevo Desafío
                </button>
            </div>
            <div className="flex flex-row flex-wrap max-w-[600px] lg:max-w-full mx-auto lg:mx-0 mb-4 justify-center px-4 lg:py-4 text-sm bg-gray-100 dark:bg-neutral-700 rounded-md shadow-md transition-all duration-200 ease-in-out">
                <table className="w-full">
                    <thead className="text-base">
                        <tr>
                            <th>Nombre</th>
                            <th>Fecha de creación</th>
                            <th>Fecha de actualización</th>
                        </tr>
                    </thead>
                    <tbody className="font-light">
                        {(subChallengeList?.length && subChallengeList[0]) ? subChallengeList.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th className="font-normal">{item?.name}</th>
                                    <th className="font-normal">{item.createdAt ? convertDate(item.createdAt) : '-'}</th>
                                    <th className="font-normal">{item.updatedAt ? convertDate(item.updatedAt) : '-'}</th>
                                    <th>
                                        <Link
                                            className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                            to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${challengeId}/SubChallenge/${item.id}`}
                                        >
                                            <Tooltip title="Editar" arrow>
                                                <EditIcon />
                                            </Tooltip>
                                        </Link>
                                        <button className="btn px-[10px] bg-[#C36B96] hover:bg-red-800"
                                            onClick={() => {
                                                setDeleteSubChallengeName(item?.name)
                                                setCurrentSubChallengeId(item?.id)
                                                setDeleteModal(true)
                                            }}>
                                            <Tooltip title="Borrar" arrow>
                                                <DeleteIcon />
                                            </Tooltip>
                                        </button>
                                    </th>
                                </tr>
                            )
                        }) : (
                            <tr>
                                <td colSpan={3} className='text-2xl !py-5'>No hay Desafíos</td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
            </div>
            <CreateSubchallengeModal
                challengeId={challengeId}
                modal={createSubChallenge}
                setModal={setCreateSubChallenge}
                subChallengeList={subChallengeList}
                setSubChallengeList={setSubChallengeList}
                ChallengeType={challengeType}
                token={token}
            />
            <DeleteDocumentModal
                element={deleteSubChallengeName}
                deletefunc={handleDeleteSubChallenge}
                documentId={currentSubChallengeId}
                documents={subChallengeList}
                setDocuments={setSubChallengeList}
                modal={deleteModal}
                setModal={setDeleteModal}
                mode={'Desafío'}
                token={token}
            />
            
        </div>
    )
}

export default SubChallengeList