import { useEffect, useState, useRef } from 'react'
import { Link, Route, Routes, useLocation, useParams, useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import ScrollToTop from '../common/scrollToTop'
import PageInfo from './pageInfo'
import './reset.css'
import guideFunctions from '../admin/editorFunctions'
import LoadingSpinner from "../common/loadingSpinner";

import { useDispatch, useSelector } from 'react-redux'
import { settingsActions } from '../../redux/slices/settingsSlice'
import { objectiveCompleted } from '../../services/guidesService'
import NotesSidebar from '../common/notesSidebar'

const variants = {
  opened: {
    x: 0,
    display: 'block',
  },
  closed: {
    x: '-100%',
    transitionEnd: {
      display: 'none',
    },
  },
}

const isReadGuide = (readGuides, guideId) => {
  if (!readGuides) return false
  let isRead = false
  readGuides.forEach((readGuide) => {
    console.log(readGuide.guide?.id === guideId);
    if (readGuide.guide?.id === guideId) {
      isRead = true
    }
  })
  return isRead
}

function GuideInfo() {
  const { id: guideId, level } = useParams()

  const menuRef = useRef()
  const loc = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const isSmall = useMediaQuery('(max-width: 1023.9px)')

  const token = useSelector((state) => state.user.token);
  const readGuides = useSelector((state) => state.user.readGuides);

  const [guide, setGuide] = useState();
  const [pages, setPages] = useState('')
  const [currentpage, setCurrentpage] = useState(0)
  const [openmenu, setOpenmenu] = useState(isSmall)
  const [openObjectivesMenu, setOpenObjectivesMenu] = useState(isSmall)
  const [openNotesmenu, setOpenNotesmenu] = useState(isSmall)
  const [spinner, setSpinner] = useState(true)
  const [readGuide, setReadGuide] = useState(false)


  useEffect(() => {
    guideFunctions.GetGuideForUser(guideId, setGuide, setSpinner, setPages, token)
    if (readGuides) {
      setReadGuide(isReadGuide(readGuides, guideId))
    }
  }, [])

  useEffect(() => {
    if (!spinner) navigate(`/guides/${level}/${guideId}/0`)
  }, [spinner])

  useEffect(() => {
    const handleClick = (event) => {
      if (!menuRef.current?.contains(event.target) && isSmall) {
        setOpenmenu(false)
        setOpenObjectivesMenu(false)
        setOpenNotesmenu(false)
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  })

  useEffect(() => {
    dispatch(settingsActions.setUrl(loc.pathname))
  }, [guideId, loc.pathname, dispatch])

  useEffect(() => {
    if (isSmall) {
      setOpenmenu(false)
    } else {
      setOpenmenu(true)
    }
  }, [isSmall])


  const markObjectiveCompleted = (objectiveId) => {
    objectiveCompleted(objectiveId, token)
  }

  return (
    <div>
      {spinner && <LoadingSpinner size={"300px"} />}
      {!spinner && <div className='relative flex flex-row'>
        <AnimatePresence exitBeforeEnter={true} onExitComplete={() => null}>
          <motion.div
            variants={variants}
            initial={{ display: 'none', x: '-200%' }}
            animate={openmenu ? 'opened' : 'closed'}
            exit={'closed'}
            ref={menuRef}
            transition={{ ease: 'easeInOut', duration: 0.25 }}
            className={`md:block fixed bottom-0 top-0 z-20 bg-white dark:bg-neutral-700 w-[320px] shadow-sidemenu overflow-y-scroll level-wrapper`}
          >
            <div className='relative top-12'>
              <FontAwesomeIcon
                className='block lg:hidden relative top-2 left-2 text-gray-700 dark:text-gray-100 hover:text-rose-600 hover:dark:text-rose-600 
                            m-2 text-xl xs:text-2xl cursor-pointer select-none transition-all duration-200 ease-in-out'
                onClick={() => setOpenmenu(false)}
                icon={faTimes}
              />
              <ul className='flex flex-col items-center p-2'>
                <Link
                  className='mt-0 mb-2 lg:mt-1 lg:mb-2 bg-gray-300 dark:bg-neutral-800 py-1.5 px-2.5 group rounded-full'
                  to={`/guides/${level}`}
                >
                  <li className='text-gray-700 dark:text-gray-100 group-hover:text-rose-600 text-md text-center transition-all duration-200 ease-in-out'>
                    <FontAwesomeIcon icon={faHome} />
                  </li>
                </Link>
                {pages &&
                  pages.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        className='font-medium w-full max-w-[300px] text-sm m-1 bg-gray-300 dark:bg-neutral-800 rounded-md'
                        to={`/guides/${level}/${guideId}/${index}`}
                      >
                        <li
                          className={`${index === currentpage - 0
                            ? 'text-rose-600 dark:text-rose-600 font-extrabold text-[15px]'
                            : index <= currentpage
                              ? 'text-rose-400 dark:text-rose-500'
                              : 'text-gray-700 dark:text-gray-100'
                            }   p-2 text-left transition-all duration-200 ease-in-out flex items-center`}
                        >
                          <div className={`bg-rose-600 text-gray-700 dark:text-gray-100 rounded-full aspect-square flex justify-center items-center mr-2 
                        ${index === currentpage - 0
                              ? 'text-[17px] font-medium w-[36px]'
                              : 'text-base font-medium w-[30px]'
                            }`}
                          >
                            {index + 1}
                          </div>
                          {item.name}
                        </li>
                      </Link>
                    )
                  })}
              </ul>
            </div>
          </motion.div>
        </AnimatePresence>

        {/* objectives sideMenu */}
        <AnimatePresence exitBeforeEnter={true} onExitComplete={() => null}>
          <motion.div
            variants={variants}
            initial={{ display: 'none', x: '-200%' }}
            animate={openObjectivesMenu ? 'opened' : 'closed'}
            exit={'closed'}

            transition={{ ease: 'easeInOut', duration: 0.25 }}
            className={`md:block fixed bottom-0 top-0 z-20 bg-white dark:bg-neutral-700 w-[320px] shadow-sidemenu overflow-y-scroll level-wrapper`}
          >
            <div className='relative top-12'>
              <FontAwesomeIcon
                className='block relative top-2 left-2 text-gray-700 dark:text-gray-100 hover:text-rose-600 hover:dark:text-rose-600 
                            m-2 text-xl xs:text-2xl cursor-pointer select-none transition-all duration-200 ease-in-out'
                onClick={() => setOpenObjectivesMenu(false)}
                icon={faTimes}
              />
              <h2 className='title text-center text-2xl font-medium'>Objetivos</h2>
              <ul className='flex flex-col items-center p-2'>
                {guide.guideObjective &&
                  guide.guideObjective.map((item, index) => {
                    return (
                      <div key={index} className='font-medium w-full max-w-[300px] text-sm m-1 bg-gray-300 dark:bg-neutral-800 rounded-md'>
                        <li className="text-gray-700 dark:text-gray-100 p-2 text-left transition-all duration-200 ease-in-out flex items-center">
                          <label className='mx-0'>
                            <input
                              type="checkbox"
                              id="objective"
                              className='mx-2'
                              disabled={item.userCompletedGuideObjective?.includes(item.id)}
                              onClick={() => { markObjectiveCompleted(item.id) }}
                            />
                            {item.description}
                          </label>
                        </li>
                      </div>
                    )
                  })}
              </ul>
            </div>
          </motion.div>
        </AnimatePresence>

        {/* notes sideMenu */}
        <NotesSidebar
          opened={openNotesmenu}
          setOpened={setOpenNotesmenu}
          variants={variants}
        />

        <div
          className={
            `flex flex-row justify-center w-full items-center min-h-box ml-0 ${(openmenu || openObjectivesMenu || openNotesmenu) ? 'lg:ml-[320px]' : 'lg:ml-0'}`
          }
        >
          <div className='relative m-2 min-h-screen w-full my-8'>
            <ScrollToTop>
              <AnimatePresence>
                <Routes>
                  <Route
                    path=':pageid'
                    element={
                      <PageInfo
                        pagesList={pages}
                        menu={openmenu}
                        openmenu={setOpenmenu}
                        objectivesMenu={openObjectivesMenu}
                        openObjectivesMenu={setOpenObjectivesMenu}
                        notesmenu={openNotesmenu}
                        openNotesmenu={setOpenNotesmenu}
                        currentpage={setCurrentpage}
                        guide={guide}
                        readGuide={readGuide}
                        setReadGuide={setReadGuide}
                      />
                    }
                  />
                </Routes>
              </AnimatePresence>
            </ScrollToTop>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default GuideInfo
