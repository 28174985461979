import { axiosInstanceAuth, axiosIntance } from './axiosInstance';
import Cookies from 'js-cookie';

export async function makeRequest(
    method,
    url,
    {
        query = {},
        payload = {},
        headers = {},
        auth = true,
        contentType = null
    } = {}
) {
    const axiosInstance = auth ? axiosInstanceAuth : axiosIntance;

    const language = localStorage.getItem('lang') || 'es';

    try {
        const config = {
            method,
            url,
            params: {
                ...query,
                language
            },
            headers,
            data: JSON.stringify(payload),
            paramsSerializer: (params) => {
                return new URLSearchParams(params).toString();
            },
        }

        if (contentType) {
            config.data = payload
            config.headers = {
                'Content-Type': contentType
            }
        }

        const response = await axiosInstance(config);

        // TODO: Añadir error de autorizacion 
        if (response?.status >= 200 && response?.status <= 400) {
            return response.data;
        }
        else if (response?.status === 403) {
            Cookies.remove('token');
            Cookies.remove('refreshToken');
            window.location.href = process.env.PUBLIC_URL + "/login";
        }
        else {
            return null;
        }

    } catch (error) {
        console.error('Request failed', error);
        return null;
    }
}
