import { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import ScrollToTop from '../common/scrollToTop'
import Editor from './editor'
import ChallengeEditor from './challengeEditor'
import LearningPathEditor from './learningPathEditor'
import Sidemenu from './sidemenu'
import DashboardData from './dashboard/dashboardData'
import DashboardGeneral from './dashboard/dashboardGeneral'
import DashboardUser from './dashboard/dashboardUser'
import DashboardGuideChallenge from './dashboard/dashboardGuideChallenge'
import LanguageEditor from './config/languageEditor'
import RoleEditor from './config/roleEditor'
import TagEditor from './config/tagEditor'
import LevelEditor from './config/levelEditor'
import ScoreEditor from './config/scoreEditor'
import NewChallenge from './newChallenge'
import NewGuide from './newGuide'
import NewLearningPath from './newLearningPath'
import GuideList from './guideList'
import ChallengeList from './challengeList'
import LearningPathList from './learningPathList'
import QuizList from './quizList'
import QuizEditor from './quizEditor'
import SubChallengeList from './subChallengeList'
import SubChallengeEditor from './subChallengeEditor'
import { useDispatch } from 'react-redux'
import { settingsActions } from '../../redux/slices/settingsSlice'
import { getScoreTypes } from '../../services/superAdminService'

function Admin() {
  const dispatch = useDispatch()
  const isSmall = useMediaQuery('(max-width: 1279.9px)')
  const [menu, setMenu] = useState(!isSmall)

  useEffect(() => {
    fetchScoreTypes()
    if (isSmall) {
      setMenu(false)
    } else {
      setMenu(true)
    }
  }, [isSmall])

  const fetchScoreTypes = async () => {
    dispatch(settingsActions.setScoreTypes(await getScoreTypes()))
  }

  return (
    <div className='relative flex min-h-screen'>
      <Sidemenu isOpen={menu} closeMenu={setMenu} />
      <div className='p-2 flex flex-col w-full text-center min-h-box'>
        <FontAwesomeIcon
          icon={faBars}
          onClick={() => setMenu(true)}
          className={`absolute top-[20px] left-4 text-white bg-hackear-700 text-xl p-1.5 rounded-sm cursor-pointer ${menu || !isSmall ? 'hidden' : 'block'
            }`}
        />
        <ScrollToTop>
          <Routes>
            <Route path='dashboard/data' element={<DashboardData />} />
            <Route path='dashboard/user' element={<DashboardUser />} />
            <Route path='dashboard/general' element={<DashboardGeneral />} />
            <Route path='dashboard/guide-challenge' element={<DashboardGuideChallenge />} />
            <Route path='edition/editor' element={<GuideList />} />
            <Route path='edition/editor/:guideId/*' element={<Editor />} />
            <Route path='edition/challengeEditor' element={<ChallengeList />} />
            <Route path='edition/challengeEditor/:challengeId' element={<ChallengeEditor />} />
            <Route path='edition/learningPathEditor' element={<LearningPathList />} />
            <Route path='edition/learningPathEditor/:learningPathId' element={<LearningPathEditor />} />
            <Route path='edition/challengeEditor/:challengeId/Quiz' element={<QuizList />} />
            <Route path='edition/challengeEditor/:challengeId/Quiz/:quizId' element={<QuizEditor />} />
            <Route path='edition/challengeEditor/:challengeId/SubChallenge' element={<SubChallengeList />} />
            <Route path='edition/challengeEditor/:challengeId/SubChallenge/:subChallengeId' element={<SubChallengeEditor />} />
            <Route path='creation/newGuide' element={<NewGuide />} />
            <Route path='creation/newChallenge' element={<NewChallenge />} />
            <Route path='creation/newLearningPath' element={<NewLearningPath />} />
            <Route path='config/language-editor' element={<LanguageEditor />} />
            <Route path='config/tag-editor' element={<TagEditor />} />
            <Route path='config/role-editor' element={<RoleEditor />} />
            <Route path='config/level-editor' element={<LevelEditor />} />
            <Route path='config/score-editor' element={<ScoreEditor />} />
          </Routes>
        </ScrollToTop>
      </div>
    </div>
  )
}

export default Admin
