import Particles from 'react-tsparticles'

const ParticlesBackground = (props) => {
  const size = props.size
  return (
    <Particles
      id='tsparticles'
      options={{
        style: {
          position: 'absolute',
          height: `${size}px`
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: 'push'
            },
            onHover: {
              enable: false,
              mode: 'repulse'
            },
            resize: true
          },
          modes: {
            bubble: {
              distance: 800,
              duration: 2,
              opacity: 0.8,
              size: 40
            },
            push: {
              quantity: 4
            },
            repulse: {
              distance: 200,
              duration: 0.4
            }
          }
        },
        particles: {
          color: {
            value: '#ffffff'
          },
          links: {
            enable: false
          },
          collisions: {
            enable: false
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 0.25,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 200,
              factor: 1750
            },
            value: 6
          },
          opacity: {
            value: {
              min: 0,
              max: 0.15
            },
            animation: {
              enable: true,
              speed: 0.075,
              sync: true
            }
          },
          shape: {
            type: 'circle'
          },
          size: {
            random: { enable: true, minimumValue: 5 },
            value: 14
          }
        },
        detectRetina: true
      }}
    />
  )
}

export default ParticlesBackground
