import {
  CHALLENGE_URL, SUBCHALLENGE_VERIFY_URL, CHALLENGE_URL_DELETE, CHALLENGE_URL_UPDATE, CHALLENGE_URL_CREATE, CHALLENGE_URL_LIST, CHALLENGE_URL_GET, DIFFICULTYLEVEL_URL_LIST, QUIZQUESTION_URL_CREATE, QUIZQUESTION_URL_DELETE, QUIZQUESTION_URL_UPDATE, QUIZ_URL_CREATE, QUIZ_URL_GET, QUIZ_URL_UPDATE, QUIZ_URL_DELETE, SUBCHALLENGE_URL, CHALLENGE_QUESTION_URL,
  CHALLENGE_ANSWER_URL, QUIZ_OBJECTIVE_URL, QUIZOBJECTIVE_URL_DELETE, QUIZOBJECTIVE_URL_CREATE,
  SUBCHALLENGE_URL_CREATE,
  CHALLENGE_RATE_URL,
  SUBCHALLENGEQUESTION_URL_CREATE,
  SUBCHALLENGETYPE_URL_LIST,
  SUBCHALLENGE_URL_UPDATE,
  SUBCHALLENGE_URL_DELETE,
  SUBCHALLENGEQUESTION_URL_UPDATE,
  SUBCHALLENGEQUESTION_URL_DELETE,
  QUIZ_VERIFY_URL
} from './apiRoutes';
import { makeRequest } from './makeRequest';

export async function getChallenge(challengeId, token) {
  const response = await makeRequest(
    'GET',
    `${CHALLENGE_URL_GET}/${challengeId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};


export async function getLevelList(token) {
  const response = await makeRequest('GET', `${DIFFICULTYLEVEL_URL_LIST}?paginate=false`, {
    auth: true
  })

  if (response && response?.status === 200) {
    return response.result
  }
  else {
    return null
  }

};
export async function getChallengeList() {
  const response = await makeRequest(
    'GET',
    `${CHALLENGE_URL_LIST}?paginate=false`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};


// create challenges
export async function createChallenge(challenge) {

  const response = await makeRequest('POST', `${CHALLENGE_URL_CREATE}`, {
    auth: true,
    payload: challenge
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

// update challenge
export async function updateChallenge(newFields, challengeId, token) {

  const response = await makeRequest('PUT', `${CHALLENGE_URL_UPDATE}/${challengeId}`, {
    auth: true,
    payload: newFields
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function updateChallengeQuestions(newQuest, challengeId, questionId) {

  const response = await makeRequest('PUT', `${CHALLENGE_QUESTION_URL}/${questionId}`, {
    auth: true,
    payload: {
      question: newQuest.question,
      challengeId: parseInt(challengeId),
      challengeQuestionTypeId: parseInt(newQuest.challengeQuestionTypeId)
    }
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function updateChallengeAnswers(newAnswer, newState, questionId, answerId, token) {
  let isTrue = false
  if (newState === 'true') {
    isTrue = true
  } else {
    isTrue = false
  }

  const response = await makeRequest('PUT', `${CHALLENGE_ANSWER_URL}/${answerId}`, {
    auth: true,
    payload: {
      answer: newAnswer,
      isTrueAnswer: isTrue,
      challengeQuestionId: parseInt(questionId),
    }
  })

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

// delete challenge
export async function deleteChallenge(challengeId) {

  const response = await makeRequest(
    'DELETE',
    `${CHALLENGE_URL_DELETE}/${challengeId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};

// Quiz create/delete/update
export async function getQuiz(quizId) {

  const response = await makeRequest(
    "GET",
    `${QUIZ_URL_GET}/${quizId}`,
    {
      auth: true
    }
  )

  if (response) {
    return response.result
  } else {
    return null
  }

};
export async function getQuizList(challengeId, token) {

  const response = await makeRequest(
    "GET",
    `${CHALLENGE_URL_GET}/${challengeId}/quizes?paginate=false`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function createQuiz(quiz, token) {
  const response = await makeRequest(
    "POST",
    `${QUIZ_URL_CREATE}`,
    {
      payload: quiz,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};

export async function updateQuiz(quiz) {

  const response = await makeRequest(
    "PUT",
    `${QUIZ_URL_UPDATE}/${quiz.id}`,
    {
      payload: quiz,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function deleteQuiz(quizId, token) {

  const response = await makeRequest(
    "DELETE",
    `${QUIZ_URL_DELETE}/${quizId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

// quiz question/answer create/update/delete
export async function createQuestion(newQuestion, answer) {

  const payload = {
    question: {
      question: newQuestion.question,
      hint: newQuestion.hint,
      questionTypeId: newQuestion.questionTypeId,
      scoreTypeId: 2,
      answers: [...answer],
    },
    quizId: newQuestion.documentId
  }

  const response = await makeRequest(
    "POST",
    `${QUIZQUESTION_URL_CREATE}`,
    {
      payload,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function updateQuestion(newQuestion, answer, questionId, documentId) {

  const payload = {
    question: {
      question: newQuestion.question,
      hint: newQuestion.hint,
      index: newQuestion.index,
      scoreTypeId: 2,
      answer,
    },
    quizId: documentId
  }

  const response = await makeRequest(
    "PUT",
    `${QUIZQUESTION_URL_UPDATE}/${questionId}`,
    {
      payload,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function deleteQuizQuestion(quizQuestionId, token) {

  const response = await makeRequest(
    "DELETE",
    `${QUIZQUESTION_URL_DELETE}/${quizQuestionId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

// quiz objectives endpoints
export async function createQuizObjective(objective, token) {
  const response = await makeRequest(
    "POST",
    `${QUIZOBJECTIVE_URL_CREATE}`,
    {
      payload: objective,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};

export async function QuizObjectiveCompleted(objectiveId, token) {

  const response = await makeRequest(
    "POST",
    `${QUIZ_OBJECTIVE_URL}/${objectiveId}/completed`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

export async function deleteQuizObjective(objectiveId) {

  const response = await makeRequest(
    "DELETE",
    `${QUIZOBJECTIVE_URL_DELETE}/${objectiveId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

// subChallenge create/delete/update
export async function getSubChallenge(subChallengeId) {
  const response = await makeRequest(
    "GET",
    `${SUBCHALLENGE_URL}/get/${subChallengeId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};

export async function getSubChallengeList(challengeId) {
  const response = await makeRequest(
    "GET",
    `${CHALLENGE_URL}/get/${challengeId}/subchallenges`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};

export async function createSubChallenge(subChallenge) {

  const response = await makeRequest(
    "POST",
    `${SUBCHALLENGE_URL_CREATE}`,
    {
      payload: subChallenge,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function updateSubChallenge(subChallenge) {
  const response = await makeRequest(
    "PUT",
    `${SUBCHALLENGE_URL_UPDATE}/${subChallenge.id}`,
    {
      payload: subChallenge,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function deleteSubChallenge(subChallengeId) {

  const response = await makeRequest(
    "DELETE",
    `${SUBCHALLENGE_URL_DELETE}/${subChallengeId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function getSubChallengeTypeList() {

  const response = await makeRequest(
    "GET",
    `${SUBCHALLENGETYPE_URL_LIST}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};

// subChallenge question/answer create/update/delete
export async function createSubchallengeQuestion(newQuestion, answer) {
  const response = await makeRequest(
    "POST",
    `${SUBCHALLENGEQUESTION_URL_CREATE}`,
    {
      payload: {
        question: {
          question: newQuestion.question,
          hint: newQuestion.hint,
          questionTypeId: newQuestion.questionTypeId,
          scoreTypeId: 2,
          answers: [...answer],
        },
        subChallengeId: newQuestion.documentId
      },
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};
export async function deleteSubChallengeQuestion(subChallengeQuestionId, token) {

  const response = await makeRequest(
    "DELETE",
    `${SUBCHALLENGEQUESTION_URL_DELETE}/${subChallengeQuestionId}`,
    {
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }

};
export async function updateSubChallengeQuestion(newQuestion, answer, questionId, documentId) {
  const response = await makeRequest(
    "PUT",
    `${SUBCHALLENGEQUESTION_URL_UPDATE}/${questionId}`,
    {
      payload: {
        question: {
          question: newQuestion.question,
          hint: newQuestion.hint,
          index: newQuestion.index,
          scoreTypeId: 2,
          answer,
        },
        subChallengeId: documentId
      },
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};


export async function verifyAnswer(id, answer) {
  const response = await makeRequest(
    'POST',
    `${SUBCHALLENGE_VERIFY_URL}/${id}`,
    {
      payload: { answerValue: answer.toString() },
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return true
  }
  else if (response && response?.status === 400) {
    return false
  }
  else {
    return null
  }
};

export async function verifyQuizAnswer(id, answer) {
  const response = await makeRequest(
    'POST',
    `${QUIZ_VERIFY_URL}/${id}`,
    {
      payload: { answerValue: answer.toString() },
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return true
  }
  else if (response && response?.status === 400) {
    return false
  }
  else {
    return null
  }

};

export async function verifyMultipleAnswers(id, answers, isSubChallenge) {

  const payload = {
    answerIds: answers
  }

  if (isSubChallenge) {
    delete payload.answerIds

    payload.subChallengeAnswerIds = answers
  }

  const response = await makeRequest(
    'POST',
    `${SUBCHALLENGE_VERIFY_URL}/${id}`,
    {
      payload,
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return true
  }
  else if (response && response?.status === 400) {
    return false
  }
  else {
    return null
  }
};

export async function verifyQuizMultipleAnswers(id, answers, token) {

  const response = await makeRequest(
    'POST',
    `${QUIZ_VERIFY_URL}/${id}`,
    {
      payload: { answerIds: answers },
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return true
  }
  else if (response && response?.status === 400) {
    return false
  }
  else {
    return null
  }
};

export async function createChallengeRate(rating, review, challengeId, userId) {
  const response = await makeRequest(
    'POST',
    `${CHALLENGE_RATE_URL}`,
    {
      payload: {
        rating,
        review,
        challengeId,
        userId
      },
      auth: true
    }
  )

  if (response && response?.status === 200) {
    return response.result
  } else {
    return null
  }
};