import React, { useRef } from 'react'

const VideoComponent = ({
    imageThumbnailPath,
    videoThumbnailPath
}) => {

    const videoRef = useRef(null);

    const handleMouseOver = () => {
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                console.error("Error playing video:", error);
            });
        }
    };

    const handleMouseOut = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setTimeout(() => {
                videoRef.current.load(); // Recargar el video para que vuelva a mostrar el póster
            }, 100); // Agregar un pequeño retraso
        }
    };


    if (
        (!imageThumbnailPath || imageThumbnailPath.toLowerCase() === "www.imagethumbnailpath.com") && 
        (!videoThumbnailPath || videoThumbnailPath.toLowerCase() === "www.videothumbnailpath.com")
    ) {
        return (
            <img
                src={'/icons/En contruccion Negro.png'}
                alt="header-img"
                className="p-2 max-w-[300px] max-h-[150px] mt-4"
            />
        )
    }

    return (
        <video
            ref={videoRef}
            className="mx-auto my-4 mt-10"
            width="75%"
            muted
            loop
            controls={false}
            poster={imageThumbnailPath}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <source src={videoThumbnailPath} type="video/webm" />
        </video>
    )
}

export default VideoComponent