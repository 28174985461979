/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { VncScreen } from 'react-vnc'
import { useQuery } from '../../hooks/useQuery';
import { useDispatch } from 'react-redux';
import { settingsActions } from '../../redux/slices/settingsSlice';

const VNCComponent = () => {

  const ref = useRef();
  const query = useQuery()
  const dispatch = useDispatch()
  const url = query.get('url')
  const password = query.get('password')

  return (
    <div
      className='flex justify-center items-center w-full mt-[55px]'
    >
      <VncScreen
        url={url}
        scaleViewport
        background="#000000"
        style={{
          width: '100%',
          height: '92vh',
        }}
        resizeSession={true}
        ref={ref}
        rfbOptions={{
          credentials:{
            password,
          }
        }}
      />

    </div>
  )
}

export default VNCComponent