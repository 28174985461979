import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import LoadingSpinner from "../common/loadingSpinner";
import { useSelector } from "react-redux";
import edFunc from "../admin/challengeEditorFunctions"
import VideoComponent from "../common/VideoComponent";
function ChallengesList() {
  const { level } = useParams();
  const token = useSelector((state) => state.user.token)
  const challengeList = useSelector((state) => state.settings.challenges);
  const [Challenges, setChallenges] = useState(challengeList);
  const [filteredChallenges, setFilteredChallenges] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (Challenges[0]) {
        setSpinner(true)
      } else {
        edFunc.HandleGetChallengeList(setChallenges, setSpinner, token)
      }
    }
    return () => {
      isMounted = false;
    }
  }, [])

  useEffect(() => {
    if (Challenges[0]) {
      setFilteredChallenges(Challenges.filter((Challenge) => Challenge?.difficultyLevel?.name === level));
    }
  }, [Challenges])

  const variants = {
    hover: {
      scale: 1.05,
      transition: {
        ease: "easeInOut",
        duration: 0.15,
        delay: 0,
      },
    },
  };
  return (
    <div className="flex flex-wrap justify-center items-start min-h-box h-full">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        className="min-h-box text-center px-2 py-4"
      >
        <h3 className="title">Retos</h3>
        {!spinner ? (
          <LoadingSpinner size={"300px"} />
        ) : (
          <>
            {
              filteredChallenges.length === 0 ? (
                <div className="text-center py-4 mx-auto w-full max-w-[1280px]">
                  <h3 className="title text-center">
                    No hay retos disponibles
                  </h3>
                </div>
              ) : (
                <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-2 my-6 max-w-[1280px]">
                  {
                    filteredChallenges.map((item, index) => {
                      return item.difficultyLevel.name === level ? (
                        <motion.div
                          variants={variants}
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: {
                              ease: "easeInOut",
                              duration: 0.15,
                              delay: index * 0.05,
                            },
                          }}
                          whileHover={"hover"}
                          key={index}
                          className="container-block-col justify-between max-w-xs min-h-[250px] mx-auto w-full rounded-md shadow-md"
                        >
                          <Link
                            to={`/challenges/${level}/${item.id}`}
                          >
                            <div>
                              {/* 
                              
                              
                              */}
                              {
                                item?.videoThumbnailPath?.endsWith(".webm") ? (
                                  <VideoComponent
                                    imageThumbnailPath={item.imageThumbnailPath}
                                    videoThumbnailPath={item.videoThumbnailPath}
                                  />

                                ) : (
                                  <img
                                    src={item.imageThumbnailPath}
                                    className="w-[75%] mx-auto px-2 pt-3 pb-1 pointer-events-none"
                                    alt={"thumbnail-" + index}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                                    }}
                                  />
                                )
                              }

                              {/* <img
                                src={item.imageThumbnailPath}
                                className="w-[75%] mx-auto px-2 pt-3 pb-1 pointer-events-none"
                                alt={"thumbnail-" + index}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = process.env.PUBLIC_URL + '/icons/En contruccion Negro.png';
                                }}
                              /> */}
                            </div>
                            <div className="p-2">
                              <h3 className="m-2 font-semibold text-sm xs:text-base">
                                {item.title}
                              </h3>
                              <p className="m-2 font-medium text-xs xs:text-sm text-center">
                                {item.description}
                              </p>
                              <div className="my-4">
                              </div>
                            </div>
                          </Link>
                        </motion.div>
                      ) : null;
                    })
                  }
                </div>
              )

            }

          </>
        )}
      </motion.div>
    </div>
  );
}

export default ChallengesList;
