export const CHALLENGE_URL = `/challenge`
export const CHALLENGE_QUESTION_URL = `/challenge/challenge-question`
export const CHALLENGE_ANSWER_URL = `/challenge/challenge-answer`

export const SUBCHALLENGE_URL = `/subchallenge`
export const SUBCHALLENGE_QUESTION_URL = `/subchallenge/question`

export const QUIZ_URL = `/quiz`
export const QUIZ_QUESTION_URL = `/quiz/question`
export const QUIZ_OBJECTIVE_URL = `/quiz/objective`
export const QUIZ_VERIFY_URL = `/quiz/verifyAnswer`
export const SUBCHALLENGE_VERIFY_URL = `/subchallenge/verifyAnswer`

export const GUIDE_URL = `/guide`
export const GUIDE_PAGE_URL = `/guide-page`
export const GUIDE_OBJECTIVE_URL = `/guideobjective`

export const USER_URL = `/user`
export const DASHBOARD_USER_URL = `/dashboard/users`

export const LANGUAGE_URL = `/language`
export const DIFFICULTY_URL = `/difficulty-level`
export const TAG_URL = `/tag`
export const ROLE_URL = `/role`
export const ROLE_PERMISSION_URL = `/rolepermission`
export const SCORE_URL = `/score`
export const COUNTRY_URL = `/country`
export const SCOREBOARD_URL = `/scoreboard`
export const SOCIALMEDIA_URL = `/social-media`

export const ASSIGNMENTTYPE_URL_CREATE = `/assignmenttype/create`  // post
export const ASSIGNMENTTYPE_URL_DELETE = `/assignmenttype/delete`  // delete
export const ASSIGNMENTTYPE_URL_GET = `/assignmenttype/get`  // get
export const ASSIGNMENTTYPE_URL_LIST = `/assignmenttype/list`  // get
export const ASSIGNMENTTYPE_URL_RESTORE = `/assignmenttype/restore`  // put
export const ASSIGNMENTTYPE_URL_UPDATE = `/assignmenttype/update`  // put
export const BADGE_URL_CREATE = `/badge/create`  // post
export const BADGE_URL_DELETE = `/badge/delete`  // delete
export const BADGE_URL_GET = `/badge/get`  // get
export const BADGE_URL_LIST = `/badge/list`  // get
export const BADGE_URL_RESTORE = `/badge/restore`  // put
export const BADGE_URL_UPDATE = `/badge/update`  // put
export const CHALLENGEGUIDELINK_URL_CREATE = `/challengeguidelink/create`  // post
export const CHALLENGEGUIDELINK_URL_DELETE = `/challengeguidelink/delete`  // delete
export const CHALLENGEGUIDELINK_URL_GET = `/challengeguidelink/get`  // get
export const CHALLENGEGUIDELINK_URL_LIST = `/challengeguidelink/list`  // get
export const CHALLENGEGUIDELINK_URL_RESTORE = `/challengeguidelink/restore`  // put
export const CHALLENGEGUIDELINK_URL_UPDATE = `/challengeguidelink/update`  // put
export const CHALLENGERATING_URL_CREATE = `/challengerating/create`  // post
export const CHALLENGERATING_URL_DELETE = `/challengerating/delete`  // delete
export const CHALLENGERATING_URL_GET = `/challengerating/get`  // get
export const CHALLENGERATING_URL_LIST = `/challengerating/list`  // get
export const CHALLENGERATING_URL_RESTORE = `/challengerating/restore`  // put
export const CHALLENGERATING_URL_UPDATE = `/challengerating/update`  // put
export const CHALLENGETAGLINK_URL_CREATE = `/challengetaglink/create`  // post
export const CHALLENGETAGLINK_URL_DELETE = `/challengetaglink/delete`  // delete
export const CHALLENGETAGLINK_URL_GET = `/challengetaglink/get`  // get
export const CHALLENGETAGLINK_URL_LIST = `/challengetaglink/list`  // get
export const CHALLENGETAGLINK_URL_RESTORE = `/challengetaglink/restore`  // put
export const CHALLENGETAGLINK_URL_UPDATE = `/challengetaglink/update`  // put
export const CHALLENGE_URL_CREATE = `/challenge/create`  // post
export const CHALLENGE_URL_DELETE = `/challenge/delete`  // delete
export const CHALLENGE_URL_GET = `/challenge/get`  // get
export const CHALLENGE_URL_LIST = `/challenge/list`  // get
export const CHALLENGE_URL_RESTORE = `/challenge/restore`  // put
export const CHALLENGE_URL_UPDATE = `/challenge/update`  // put
export const CHALLENGE_RATE_URL = `/challengerating/create`
export const COUNTRY_URL_CREATE = `/country/create`  // post
export const COUNTRY_URL_DELETE = `/country/delete`  // delete
export const COUNTRY_URL_GET = `/country/get`  // get
export const COUNTRY_URL_LIST = `/country/list`  // get
export const COUNTRY_URL_RESTORE = `/country/restore`  // put
export const COUNTRY_URL_UPDATE = `/country/update`  // put
export const DIFFICULTYLEVEL_URL_CREATE = `/difficultylevel/create`  // post
export const DIFFICULTYLEVEL_URL_DELETE = `/difficultylevel/delete`  // delete
export const DIFFICULTYLEVEL_URL_GET = `/difficultylevel/get`  // get
export const DIFFICULTYLEVEL_URL_LIST = `/difficultylevel/list`  // get
export const DIFFICULTYLEVEL_URL_RESTORE = `/difficultylevel/restore`  // put
export const DIFFICULTYLEVEL_URL_UPDATE = `/difficultylevel/update`  // put
export const GUIDEOBJECTIVE_URL_CREATE = `/guideobjective/create`  // post
export const GUIDEOBJECTIVE_URL_DELETE = `/guideobjective/delete`  // delete
export const GUIDEOBJECTIVE_URL_GET = `/guideobjective/get`  // get
export const GUIDEOBJECTIVE_URL_LIST = `/guideobjective/list`  // get
export const GUIDEOBJECTIVE_URL_RESTORE = `/guideobjective/restore`  // put
export const GUIDEOBJECTIVE_URL_UPDATE = `/guideobjective/update`  // put
export const GUIDEPAGE_URL_CREATE = `/guidepage/create`  // post
export const GUIDEPAGE_URL_DELETE = `/guidepage/delete`  // delete
export const GUIDEPAGE_URL_GET = `/guidepage/get`  // get
export const GUIDEPAGE_URL_LIST = `/guidepage/list`  // get
export const GUIDEPAGE_URL_RESTORE = `/guidepage/restore`  // put
export const GUIDEPAGE_URL_UPDATE = `/guidepage/update`  // put
export const GUIDERATING_URL_CREATE = `/guiderating/create`  // post
export const GUIDERATING_URL_DELETE = `/guiderating/delete`  // delete
export const GUIDERATING_URL_GET = `/guiderating/get`  // get
export const GUIDERATING_URL_LIST = `/guiderating/list`  // get
export const GUIDERATING_URL_RESTORE = `/guiderating/restore`  // put
export const GUIDERATING_URL_UPDATE = `/guiderating/update`  // put
export const GUIDETAGLINK_URL_CREATE = `/guidetaglink/create`  // post
export const GUIDETAGLINK_URL_DELETE = `/guidetaglink/delete`  // delete
export const GUIDETAGLINK_URL_GET = `/guidetaglink/get`  // get
export const GUIDETAGLINK_URL_LIST = `/guidetaglink/list`  // get
export const GUIDETAGLINK_URL_RESTORE = `/guidetaglink/restore`  // put
export const GUIDETAGLINK_URL_UPDATE = `/guidetaglink/update`  // put
export const GUIDE_URL_CREATE = `/guide/create`  // post
export const GUIDE_URL_DELETE = `/guide/delete`  // delete
export const GUIDE_URL_GET = `/guide/get`  // get
export const GUIDE_URL_LIST = `/guide/list`  // get
export const GUIDE_URL_RESTORE = `/guide/restore`  // put
export const GUIDE_URL_UPDATE = `/guide/update`  // put
export const LEARNINGPATHCHALLENGELINK_URL_CREATE = `/learningpathchallengelink/create`  // post
export const LEARNINGPATHCHALLENGELINK_URL_DELETE = `/learningpathchallengelink/delete`  // delete
export const LEARNINGPATHCHALLENGELINK_URL_GET = `/learningpathchallengelink/get`  // get
export const LEARNINGPATHCHALLENGELINK_URL_LIST = `/learningpathchallengelink/list`  // get
export const LEARNINGPATHCHALLENGELINK_URL_RESTORE = `/learningpathchallengelink/restore`  // put
export const LEARNINGPATHCHALLENGELINK_URL_UPDATE = `/learningpathchallengelink/update`  // put
export const LEARNINGPATHGUIDELINK_URL_CREATE = `/learningpathguidelink/create`  // post
export const LEARNINGPATHGUIDELINK_URL_DELETE = `/learningpathguidelink/delete`  // delete
export const LEARNINGPATHGUIDELINK_URL_GET = `/learningpathguidelink/get`  // get
export const LEARNINGPATHGUIDELINK_URL_LIST = `/learningpathguidelink/list`  // get
export const LEARNINGPATHGUIDELINK_URL_RESTORE = `/learningpathguidelink/restore`  // put
export const LEARNINGPATHGUIDELINK_URL_UPDATE = `/learningpathguidelink/update`  // put
export const LEARNINGPATHTAGLINK_URL_CREATE = `/learningpathtaglink/create`  // post
export const LEARNINGPATHTAGLINK_URL_DELETE = `/learningpathtaglink/delete`  // delete
export const LEARNINGPATHTAGLINK_URL_GET = `/learningpathtaglink/get`  // get
export const LEARNINGPATHTAGLINK_URL_LIST = `/learningpathtaglink/list`  // get
export const LEARNINGPATHTAGLINK_URL_RESTORE = `/learningpathtaglink/restore`  // put
export const LEARNINGPATHTAGLINK_URL_UPDATE = `/learningpathtaglink/update`  // put
export const LEARNINGPATH_URL_CREATE = `/learningpath/create`  // post
export const LEARNINGPATH_URL_DELETE = `/learningpath/delete`  // delete
export const LEARNINGPATH_URL_GET = `/learningpath/get`  // get
export const LEARNINGPATH_URL_LIST = `/learningpath/list`  // get
export const LEARNINGPATH_URL_RESTORE = `/learningpath/restore`  // put
export const LEARNINGPATH_URL_UPDATE = `/learningpath/update`  // put
export const PROFESSION_URL_CREATE = `/profession/create`  // post
export const PROFESSION_URL_DELETE = `/profession/delete`  // delete
export const PROFESSION_URL_GET = `/profession/get`  // get
export const PROFESSION_URL_LIST = `/profession/list`  // get
export const PROFESSION_URL_RESTORE = `/profession/restore`  // put
export const PROFESSION_URL_UPDATE = `/profession/update`  // put
export const QUESTIONTYPE_URL_CREATE = `/questiontype/create`  // post
export const QUESTIONTYPE_URL_DELETE = `/questiontype/delete`  // delete
export const QUESTIONTYPE_URL_GET = `/questiontype/get`  // get
export const QUESTIONTYPE_URL_LIST = `/questiontype/list`  // get
export const QUESTIONTYPE_URL_RESTORE = `/questiontype/restore`  // put
export const QUESTIONTYPE_URL_UPDATE = `/questiontype/update`  // put
export const QUIZANSWER_URL_CREATE = `/quizanswer/create`  // post
export const QUIZANSWER_URL_DELETE = `/quizanswer/delete`  // delete
export const QUIZANSWER_URL_GET = `/quizanswer/get`  // get
export const QUIZANSWER_URL_LIST = `/quizanswer/list`  // get
export const QUIZANSWER_URL_RESTORE = `/quizanswer/restore`  // put
export const QUIZANSWER_URL_UPDATE = `/quizanswer/update`  // put
export const QUIZOBJECTIVE_URL_CREATE = `/quizobjective/create`  // post
export const QUIZOBJECTIVE_URL_DELETE = `/quizobjective/delete`  // delete
export const QUIZOBJECTIVE_URL_GET = `/quizobjective/get`  // get
export const QUIZOBJECTIVE_URL_LIST = `/quizobjective/list`  // get
export const QUIZOBJECTIVE_URL_RESTORE = `/quizobjective/restore`  // put
export const QUIZOBJECTIVE_URL_UPDATE = `/quizobjective/update`  // put
export const QUIZQUESTION_URL_CREATE = `/quizquestion/create`  // post
export const QUIZQUESTION_URL_DELETE = `/quizquestion/delete`  // delete
export const QUIZQUESTION_URL_GET = `/quizquestion/get`  // get
export const QUIZQUESTION_URL_LIST = `/quizquestion/list`  // get
export const QUIZQUESTION_URL_RESTORE = `/quizquestion/restore`  // put
export const QUIZQUESTION_URL_UPDATE = `/quizquestion/update`  // put
export const QUIZ_URL_CREATE = `/quiz/create`  // post
export const QUIZ_URL_DELETE = `/quiz/delete`  // delete
export const QUIZ_URL_GET = `/quiz/get`  // get
export const QUIZ_URL_LIST = `/quiz/list`  // get
export const QUIZ_URL_RESTORE = `/quiz/restore`  // put
export const QUIZ_URL_UPDATE = `/quiz/update`  // put
export const ROLEPERMISSIONLINK_URL_CREATE = `/rolepermissionlink/create`  // post
export const ROLEPERMISSIONLINK_URL_DELETE = `/rolepermissionlink/delete`  // delete
export const ROLEPERMISSIONLINK_URL_GET = `/rolepermissionlink/get`  // get
export const ROLEPERMISSIONLINK_URL_LIST = `/rolepermissionlink/list`  // get
export const ROLEPERMISSIONLINK_URL_RESTORE = `/rolepermissionlink/restore`  // put
export const ROLEPERMISSIONLINK_URL_UPDATE = `/rolepermissionlink/update`  // put
export const ROLEPERMISSION_URL_CREATE = `/rolepermission/create`  // post
export const ROLEPERMISSION_URL_DELETE = `/rolepermission/delete`  // delete
export const ROLEPERMISSION_URL_GET = `/rolepermission/get`  // get
export const ROLEPERMISSION_URL_LIST = `/rolepermission/list`  // get
export const ROLEPERMISSION_URL_RESTORE = `/rolepermission/restore`  // put
export const ROLEPERMISSION_URL_UPDATE = `/rolepermission/update`  // put
export const ROLE_URL_CREATE = `/role/create`  // post
export const ROLE_URL_DELETE = `/role/delete`  // delete
export const ROLE_URL_GET = `/role/get`  // get
export const ROLE_URL_LIST = `/role/list`  // get
export const ROLE_URL_RESTORE = `/role/restore`  // put
export const ROLE_URL_UPDATE = `/role/update`  // put
export const SCORETYPE_URL_CREATE = `/scoretype/create`  // post
export const SCORETYPE_URL_DELETE = `/scoretype/delete`  // delete
export const SCORETYPE_URL_GET = `/scoretype/get`  // get
export const SCORETYPE_URL_LIST = `/scoretype/list`  // get
export const SCORETYPE_URL_RESTORE = `/scoretype/restore`  // put
export const SCORETYPE_URL_UPDATE = `/scoretype/update`  // put
export const SCORE_URL_CREATE = `/score/create`  // post
export const SCORE_URL_DELETE = `/score/delete`  // delete
export const SCORE_URL_GET = `/score/get`  // get
export const SCORE_URL_LIST = `/score/list`  // get
export const SCORE_URL_RESTORE = `/score/restore`  // put
export const SCORE_URL_UPDATE = "/score/update" // put
export const SUBCHALLENGEANSWER_URL_CREATE = `/subchallengeanswer/create`  // post
export const SUBCHALLENGEANSWER_URL_DELETE = `/subchallengeanswer/delete`  // delete
export const SUBCHALLENGEANSWER_URL_GET = `/subchallengeanswer/get`  // get
export const SUBCHALLENGEANSWER_URL_LIST = `/subchallengeanswer/list`  // get
export const SUBCHALLENGEANSWER_URL_RESTORE = `/subchallengeanswer/restore`  // put
export const SUBCHALLENGEANSWER_URL_UPDATE = `/subchallengeanswer/update`  // put
export const SUBCHALLENGEFILE_URL_CREATE = `/subchallengefile/create`  // post
export const SUBCHALLENGEFILE_URL_DELETE = `/subchallengefile/delete`  // delete
export const SUBCHALLENGEFILE_URL_GET = `/subchallengefile/get`  // get
export const SUBCHALLENGEFILE_URL_LIST = `/subchallengefile/list`  // get
export const SUBCHALLENGEFILE_URL_RESTORE = `/subchallengefile/restore`  // put
export const SUBCHALLENGEFILE_URL_UPDATE = `/subchallengefile/update`  // put
export const SUBCHALLENGEOBJECTIVE_URL_CREATE = `/subchallengeobjective/create`  // post
export const SUBCHALLENGEOBJECTIVE_URL_DELETE = `/subchallengeobjective/delete`  // delete
export const SUBCHALLENGEOBJECTIVE_URL_GET = `/subchallengeobjective/get`  // get
export const SUBCHALLENGEOBJECTIVE_URL_LIST = `/subchallengeobjective/list`  // get
export const SUBCHALLENGEOBJECTIVE_URL_RESTORE = `/subchallengeobjective/restore`  // put
export const SUBCHALLENGEOBJECTIVE_URL_UPDATE = `/subchallengeobjective/update`  // put
export const SUBCHALLENGEQUESTION_URL_CREATE = `/subchallengequestion/create`  // post
export const SUBCHALLENGEQUESTION_URL_DELETE = `/subchallengequestion/delete`  // delete
export const SUBCHALLENGEQUESTION_URL_GET = `/subchallengequestion/get`  // get
export const SUBCHALLENGEQUESTION_URL_LIST = `/subchallengequestion/list`  // get
export const SUBCHALLENGEQUESTION_URL_RESTORE = `/subchallengequestion/restore`  // put
export const SUBCHALLENGEQUESTION_URL_UPDATE = `/subchallengequestion/update`  // put
export const SUBCHALLENGETYPE_URL_CREATE = `/subchallengetype/create`  // post
export const SUBCHALLENGETYPE_URL_DELETE = `/subchallengetype/delete`  // delete
export const SUBCHALLENGETYPE_URL_GET = `/subchallengetype/get`  // get
export const SUBCHALLENGETYPE_URL_LIST = `/subchallengetype/list`  // get
export const SUBCHALLENGETYPE_URL_RESTORE = `/subchallengetype/restore`  // put
export const SUBCHALLENGETYPE_URL_UPDATE = `/subchallengetype/update`  // put
export const SUBCHALLENGE_URL_CREATE = `/subchallenge/create`  // post
export const SUBCHALLENGE_URL_DELETE = `/subchallenge/delete`  // delete
export const SUBCHALLENGE_URL_GET = `/subchallenge/get`  // get
export const SUBCHALLENGE_URL_LIST = `/subchallenge/list`  // get
export const SUBCHALLENGE_URL_RESTORE = `/subchallenge/restore`  // put
export const SUBCHALLENGE_URL_UPDATE = `/subchallenge/update`  // put
export const TAG_URL_CREATE = `/tag/create`  // post
export const TAG_URL_DELETE = `/tag/delete`  // delete
export const TAG_URL_GET = `/tag/get`  // get
export const TAG_URL_LIST = `/tag/list`  // get
export const TAG_URL_RESTORE = `/tag/restore`  // put
export const TAG_URL_UPDATE = `/tag/update`  // put
export const USERBADGE_URL_CREATE = `/userbadge/create`  // post
export const USERBADGE_URL_DELETE = `/userbadge/delete`  // delete
export const USERBADGE_URL_GET = `/userbadge/get`  // get
export const USERBADGE_URL_LIST = `/userbadge/list`  // get
export const USERBADGE_URL_RESTORE = `/userbadge/restore`  // put
export const USERBADGE_URL_UPDATE = `/userbadge/update`  // put
export const USERCOMPLETEDCHALLENGE_URL_CREATE = `/usercompletedchallenge/create`  // post
export const USERCOMPLETEDCHALLENGE_URL_DELETE = `/usercompletedchallenge/delete`  // delete
export const USERCOMPLETEDCHALLENGE_URL_GET = `/usercompletedchallenge/get`  // get
export const USERCOMPLETEDCHALLENGE_URL_LIST = `/usercompletedchallenge/list`  // get
export const USERCOMPLETEDCHALLENGE_URL_RESTORE = `/usercompletedchallenge/restore`  // put
export const USERCOMPLETEDCHALLENGE_URL_UPDATE = `/usercompletedchallenge/update`  // put
export const USERCOMPLETEDDIFFICULTYLEVEL_URL_CREATE = `/usercompleteddifficultylevel/create`  // post
export const USERCOMPLETEDDIFFICULTYLEVEL_URL_DELETE = `/usercompleteddifficultylevel/delete`  // delete
export const USERCOMPLETEDDIFFICULTYLEVEL_URL_GET = `/usercompleteddifficultylevel/get`  // get
export const USERCOMPLETEDDIFFICULTYLEVEL_URL_LIST = `/usercompleteddifficultylevel/list`  // get
export const USERCOMPLETEDDIFFICULTYLEVEL_URL_RESTORE = `/usercompleteddifficultylevel/restore`  // put
export const USERCOMPLETEDDIFFICULTYLEVEL_URL_UPDATE = `/usercompleteddifficultylevel/update`  // put
export const USERCOMPLETEDGUIDEOBJECTIVE_URL_CREATE = `/usercompletedguideobjective/create`  // post
export const USERCOMPLETEDGUIDEOBJECTIVE_URL_DELETE = `/usercompletedguideobjective/delete`  // delete
export const USERCOMPLETEDGUIDEOBJECTIVE_URL_GET = `/usercompletedguideobjective/get`  // get
export const USERCOMPLETEDGUIDEOBJECTIVE_URL_LIST = `/usercompletedguideobjective/list`  // get
export const USERCOMPLETEDGUIDEOBJECTIVE_URL_RESTORE = `/usercompletedguideobjective/restore`  // put
export const USERCOMPLETEDGUIDEOBJECTIVE_URL_UPDATE = `/usercompletedguideobjective/update`  // put
export const USERCOMPLETEDLEARNINGPATH_URL_CREATE = `/usercompletedlearningpath/create`  // post
export const USERCOMPLETEDLEARNINGPATH_URL_DELETE = `/usercompletedlearningpath/delete`  // delete
export const USERCOMPLETEDLEARNINGPATH_URL_GET = `/usercompletedlearningpath/get`  // get
export const USERCOMPLETEDLEARNINGPATH_URL_LIST = `/usercompletedlearningpath/list`  // get
export const USERCOMPLETEDLEARNINGPATH_URL_RESTORE = `/usercompletedlearningpath/restore`  // put
export const USERCOMPLETEDLEARNINGPATH_URL_UPDATE = `/usercompletedlearningpath/update`  // put
export const USERCOMPLETEDQUESTION_URL_CREATE = `/usercompletedquestion/create`  // post
export const USERCOMPLETEDQUESTION_URL_DELETE = `/usercompletedquestion/delete`  // delete
export const USERCOMPLETEDQUESTION_URL_GET = `/usercompletedquestion/get`  // get
export const USERCOMPLETEDQUESTION_URL_LIST = `/usercompletedquestion/list`  // get
export const USERCOMPLETEDQUESTION_URL_RESTORE = `/usercompletedquestion/restore`  // put
export const USERCOMPLETEDQUESTION_URL_UPDATE = `/usercompletedquestion/update`  // put
export const USERCOMPLETEDQUIZOBJECTIVE_URL_CREATE = `/usercompletedquizobjective/create`  // post
export const USERCOMPLETEDQUIZOBJECTIVE_URL_DELETE = `/usercompletedquizobjective/delete`  // delete
export const USERCOMPLETEDQUIZOBJECTIVE_URL_GET = `/usercompletedquizobjective/get`  // get
export const USERCOMPLETEDQUIZOBJECTIVE_URL_LIST = `/usercompletedquizobjective/list`  // get
export const USERCOMPLETEDQUIZOBJECTIVE_URL_RESTORE = `/usercompletedquizobjective/restore`  // put
export const USERCOMPLETEDQUIZOBJECTIVE_URL_UPDATE = `/usercompletedquizobjective/update`  // put
export const USERCOMPLETEDQUIZ_URL_CREATE = `/usercompletedquiz/create`  // post
export const USERCOMPLETEDQUIZ_URL_DELETE = `/usercompletedquiz/delete`  // delete
export const USERCOMPLETEDQUIZ_URL_GET = `/usercompletedquiz/get`  // get
export const USERCOMPLETEDQUIZ_URL_LIST = `/usercompletedquiz/list`  // get
export const USERCOMPLETEDQUIZ_URL_RESTORE = `/usercompletedquiz/restore`  // put
export const USERCOMPLETEDQUIZ_URL_UPDATE = `/usercompletedquiz/update`  // put
export const USERCOMPLETEDSUBCHALLENGEOBJECTIVE_URL_CREATE = `/usercompletedsubchallengeobjective/create`  // post
export const USERCOMPLETEDSUBCHALLENGEOBJECTIVE_URL_DELETE = `/usercompletedsubchallengeobjective/delete`  // delete
export const USERCOMPLETEDSUBCHALLENGEOBJECTIVE_URL_GET = `/usercompletedsubchallengeobjective/get`  // get
export const USERCOMPLETEDSUBCHALLENGEOBJECTIVE_URL_LIST = `/usercompletedsubchallengeobjective/list`  // get
export const USERCOMPLETEDSUBCHALLENGEOBJECTIVE_URL_RESTORE = `/usercompletedsubchallengeobjective/restore`  // put
export const USERCOMPLETEDSUBCHALLENGEOBJECTIVE_URL_UPDATE = `/usercompletedsubchallengeobjective/update`  // put
export const USERCOMPLETEDSUBCHALLENGEQUESTION_URL_CREATE = `/usercompletedsubchallengequestion/create`  // post
export const USERCOMPLETEDSUBCHALLENGEQUESTION_URL_DELETE = `/usercompletedsubchallengequestion/delete`  // delete
export const USERCOMPLETEDSUBCHALLENGEQUESTION_URL_GET = `/usercompletedsubchallengequestion/get`  // get
export const USERCOMPLETEDSUBCHALLENGEQUESTION_URL_LIST = `/usercompletedsubchallengequestion/list`  // get
export const USERCOMPLETEDSUBCHALLENGEQUESTION_URL_RESTORE = `/usercompletedsubchallengequestion/restore`  // put
export const USERCOMPLETEDSUBCHALLENGEQUESTION_URL_UPDATE = `/usercompletedsubchallengequestion/update`  // put
export const USERCOMPLETEDSUBCHALLENGE_URL_CREATE = `/usercompletedsubchallenge/create`  // post
export const USERCOMPLETEDSUBCHALLENGE_URL_DELETE = `/usercompletedsubchallenge/delete`  // delete
export const USERCOMPLETEDSUBCHALLENGE_URL_GET = `/usercompletedsubchallenge/get`  // get
export const USERCOMPLETEDSUBCHALLENGE_URL_LIST = `/usercompletedsubchallenge/list`  // get
export const USERCOMPLETEDSUBCHALLENGE_URL_RESTORE = `/usercompletedsubchallenge/restore`  // put
export const USERCOMPLETEDSUBCHALLENGE_URL_UPDATE = `/usercompletedsubchallenge/update`  // put
export const USERPERSONALINFORMATION_URL_CREATE = `/userpersonalinformation/create`  // post
export const USERPERSONALINFORMATION_URL_DELETE = `/userpersonalinformation/delete`  // delete
export const USERPERSONALINFORMATION_URL_GET = `/userpersonalinformation/get`  // get
export const USERPERSONALINFORMATION_URL_LIST = `/userpersonalinformation/list`  // get
export const USERPERSONALINFORMATION_URL_RESTORE = `/userpersonalinformation/restore`  // put
export const USERPERSONALINFORMATION_URL_UPDATE = `/userpersonalinformation/update`  // put
export const USERREADGUIDE_URL_CREATE = `/userreadguide/create`  // post
export const USERREADGUIDE_URL_DELETE = `/userreadguide/delete`  // delete
export const USERREADGUIDE_URL_GET = `/userreadguide/get`  // get
export const USERREADGUIDE_URL_LIST = `/userreadguide/list`  // get
export const USERREADGUIDE_URL_RESTORE = `/userreadguide/restore`  // put
export const USERREADGUIDE_URL_UPDATE = `/userreadguide/update`  // put
export const USERSOCIALMEDIA_URL_CREATE = `/usersocialmedia/create`  // post
export const USERSOCIALMEDIA_URL_DELETE = `/usersocialmedia/delete`  // delete
export const USERSOCIALMEDIA_URL_GET = `/usersocialmedia/get`  // get
export const USERSOCIALMEDIA_URL_LIST = `/usersocialmedia/list`  // get
export const USERSOCIALMEDIA_URL_RESTORE = `/usersocialmedia/restore`  // put
export const USERSOCIALMEDIA_URL_UPDATE = `/usersocialmedia/update`  // put
export const USERSTATS_URL_CREATE = `/userstats/create`  // post
export const USERSTATS_URL_DELETE = `/userstats/delete`  // delete
export const USERSTATS_URL_GET = `/userstats/get`  // get
export const USERSTATS_URL_LIST = `/userstats/list`  // get
export const USERSTATS_URL_RESTORE = `/userstats/restore`  // put
export const USERSTATS_URL_UPDATE = `/userstats/update`  // put
export const USERSUBCHALLENGE_URL_CREATE = `/usersubchallenge/create`  // post
export const USERSUBCHALLENGE_URL_DELETE = `/usersubchallenge/delete`  // delete
export const USERSUBCHALLENGE_URL_GET = `/usersubchallenge/get`  // get
export const USERSUBCHALLENGE_URL_LIST = `/usersubchallenge/list`  // get
export const USERSUBCHALLENGE_URL_RESTORE = `/usersubchallenge/restore`  // put
export const USERSUBCHALLENGE_URL_UPDATE = `/usersubchallenge/update`  // put
export const USER_URL_CREATE = `/user/create`  // post
export const USER_URL_DELETE = `/user/delete`  // delete
export const USER_URL_GET = `/user/get`  // get
export const USER_URL_LIST = `/user/list`  // get
export const USER_URL_RESTORE = `/user/restore`  // put
export const USER_URL_UPDATE = `/user/update`  // put
export const USER_URL_SIGNIN = `/user/signin`  // put
export const USER_URL_SCOREBOARD = `/user/scoreboard`  // put