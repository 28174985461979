import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { addTag, deleteTag, editTag, getTags } from "../../../services/superAdminService";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { toast } from "react-toastify";
import LoaderSpinner from "../../common/loaderSpinner";
import { settingsActions } from "../../../redux/slices/settingsSlice";
import { getTagList } from "../../../services/guidesService";

const AddModal = ({ show, onClose, onAdd, isLoading }) => {
    const [tagName, setTagName] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onAdd(tagName);
        setTagName("");
    };

    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="dark:bg-slate-600 bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold dark:text-slate-100">Agrega un tag</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        required
                        placeholder="Tag..."
                        className="user-input"
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                    />
                    <div className="flex justify-center mt-2">
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <LoaderSpinner /> : "Agregar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const UpdateModal = ({ show, onClose, onUpdate, initialData, isLoading }) => {
    const [tagName, setTagName] = useState(initialData.name);

    useEffect(() => {
        setTagName(initialData.name);
    }, [initialData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onUpdate(initialData.id, tagName);
    };

    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold text-slate-600">Modifica el tag</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        required
                        placeholder="Tag..."
                        className="user-input"
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                    />
                    <div className="flex justify-center mt-2">
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <LoaderSpinner /> : "Modificar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const DeleteModal = ({ show, onClose, onDelete, isLoading }) => {
    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold text-slate-600">¿Estás seguro que deseas eliminar este tag?</h1>
                <div className="flex justify-center">
                    <button
                        className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        onClick={onDelete}
                        disabled={isLoading}
                    >
                        {isLoading ? <LoaderSpinner /> : "Eliminar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

function TagEditor() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const [tags, setTags] = useState([]);
    const [tagId, setTagId] = useState();
    const [addTagModal, setAddTagModal] = useState(false);
    const [updateTagModal, setUpdateTagModal] = useState(false);
    const [updateTagData, setUpdateTagData] = useState({});
    const [deleteTagModal, setDeleteTagModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTags = async () => {
        const query = {
            paginate: false
        }

        const response = await getTags(query);

        if (response) {
            setTags(response);
        }
    };

    useEffect(() => {
        fetchTags();
    }, []);

    const handleAddTag = async (tagName) => {
        setIsLoading(true);
        const payload = { name: tagName };
        const res = await addTag(payload, token);
        if (res) {
            // Obtain all tags to save in redux
            dispatch(settingsActions.setTags(await getTagList()))
            toast.success("Tag agregado con éxito");
            setIsLoading(false);
            setAddTagModal(false);
            fetchTags();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }
    };

    const handleUpdateTag = async (id, tagName) => {
        setIsLoading(true);
        const res = await editTag(id, tagName);
        if (res) {
            toast.success("Tag modificado con éxito");
            setIsLoading(false);
            setUpdateTagModal(false);
            await fetchTags();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }
    };

    const handleDeleteTag = async () => {
        setIsLoading(true);
        const res = await deleteTag(tagId, token);
        if (res) {
            toast.success("Tag eliminado con éxito");
            setIsLoading(false);
            setDeleteTagModal(false);
            fetchTags();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }
    };

    return (
        <>
            <AddModal
                show={addTagModal}
                onClose={() => setAddTagModal(false)}
                onAdd={handleAddTag}
                isLoading={isLoading}
            />
            <UpdateModal
                show={updateTagModal}
                onClose={() => setUpdateTagModal(false)}
                onUpdate={handleUpdateTag}
                initialData={updateTagData}
                isLoading={isLoading}
            />
            <DeleteModal
                show={deleteTagModal}
                onClose={() => setDeleteTagModal(false)}
                onDelete={handleDeleteTag}
                isLoading={isLoading}
            />
            <div className="flex flex-col min-h-[700px] w-full h-full max-h-[2800px] p-2">
                <div className="overflow-x-auto">
                    <div className="min-w-screen min-h-screen flex justify-center font-sans overflow-hidden">
                        <div className="w-full lg:w-4/6">
                            <div className="bg-white shadow-md rounded my-6">
                                <div className="flex justify-between mr-5">
                                    <h3 className="text-2xl text-gray-800 font-bold ml-5 mt-6">Editor de tags</h3>
                                    <button
                                        className="my-5 bg-rose-600 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => setAddTagModal(true)}
                                    >
                                        + Agregar tag
                                    </button>
                                </div>
                                <table className="min-w-max w-full table-auto border border-gray-200">
                                    <thead>
                                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th className="py-3 px-6 text-center border border-gray-300">Tags</th>
                                            <th className="py-3 px-6 text-center border border-gray-300">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light dark:bg-gray-500 dark:text-gray-200">
                                        {tags.map((tag, index) => (
                                            <tr key={index}>
                                                <td className="py-3 px-6 whitespace-nowrap border border-gray-300">
                                                    <div className="flex items-center justify-center">
                                                        <img src='/Michis_Heads_03.png' width='30px' alt="tag" />
                                                        <span className="font-medium ml-2"> {tag.name} </span>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-6 text-center border border-gray-300">
                                                    <div className="flex item-center justify-center gap-1">
                                                        <button
                                                            className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setUpdateTagModal(true);
                                                                setUpdateTagData(tag);
                                                                setTagId(tag.id);
                                                            }}
                                                        >
                                                            <EditOutlinedIcon />
                                                        </button>
                                                        <button
                                                            className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setDeleteTagModal(true);
                                                                setTagId(tag.id);
                                                            }}
                                                        >
                                                            <DeleteOutlineOutlinedIcon />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {tags.length === 0 && (
                                            <tr className="h-20">
                                                <td
                                                    colSpan={2}
                                                    className='text-lg font-bold mt-5'

                                                >
                                                    <div className="flex justify-center items-center gap-2">
                                                        No hay tags
                                                        <button
                                                            className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                                            onClick={() => setAddTagModal(true)}
                                                        >
                                                            Crear tag
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TagEditor;
