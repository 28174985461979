import { React, useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import edChallFunc from './challengeEditorFunctions'
import edFunc from './editorFunctions'
import CreateObjectiveModal from '../common/createObjectiveModal';
import CreateQuestionModal from '../common/createQuestionModal';
import EditQuestionModal from '../common/editQuestionModal';
import QuestionModal from '../common/QuestionModal'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import { truncateContent } from '../../utils/truncateText';

const questionLabel = [
    {
        id: 1,
        label: "Pregunta Única",
    },
    {
        id: 2,
        label: "Pregunta Multiple",
    },
    {
        id: 3,
        label: "Pregunta Abierta",
    },
]
const quizEditor = () => {

    const params = useParams();
    const token = useSelector((state) => state.user.token)
    const scoreTypesState = useSelector((state) => state.settings.scoreTypes);

    const resetFields = {
        id: 0,
        name: "name",
        description: "description",
        imgPath: "http://imgPath.com",
        index: 0,
        questions: [],
        quizObjective: [],
        challengeId: params.challengeId,
        scoreTypeId: 5
    };

    const [fields, setFields] = useState(resetFields);
    const [questionModal, setQuestionModal] = useState(false)
    const [editQuestionModal, setEditQuestionModal] = useState(false)
    const [seeQuestionModal, setSeeQuestionModal] = useState(false)
    const [modalObjective, setModalObjective] = useState(false);
    const [questionsList, setQuestionsList] = useState(fields.questions)
    const [selectedQuestion, setSelectedQuestion] = useState({})
    const [index, setIndex] = useState(0)
    const [loadingSpinner, setLoadingSpinner] = useState(false)

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            edChallFunc.handleGetQuiz(params.quizId, setFields, token)
        }
        return () => {
            isMounted = false;
        }
    }, []);

    useEffect(() => {
        setQuestionsList(fields.questions)
    }, [fields.questions]);

    const handleUpdate = async () => {
        setLoadingSpinner(true)
        edChallFunc.handleUpdateQuiz(fields, token)
        setTimeout(() => {
            setLoadingSpinner(false)
        }, "2000");
    }

    const handleDeleteQuestion = async (questionId) => {
        const res = await edChallFunc.HandleDeleteQuizQuestion(questionId, token)
        if (res) {
            setQuestionsList(questionsList.filter(item => item.id !== questionId))
            toast.success("Pregunta Eliminada")
        } else {
            toast.error("Error al Eliminar Pregunta")
        }
    }

    const renderQuestionType = (questionTypeId) => {
        const find = questionLabel.find(item => item.id === questionTypeId)
        if (find) {
            return find.label
        }
    }

    return (
        <div className="flex flex-col min-h-[1280px] w-full h-full max-h-[2800px] p-2">
            <h3 className="title">Editor de Cuestionarios</h3>
            <div className="flex flex-row py-2 items-center">
                <Link
                    className="btn"
                    to={`${process.env.PUBLIC_URL}/admin/edition/challengeEditor/${params.challengeId}/Quiz`}
                >
                    Volver
                </Link>
                <button className={`btn w-[200px] my-2 ${!fields.id ? "hidden" : ''}`}
                    onClick={() => { handleUpdate() }}>
                    {loadingSpinner ?
                        <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                        : "Guardar Cambios"}
                </button>
                <button
                    type="submit"
                    className={`btn w-40 my-4 ${!fields.id ? "hidden" : ''}`}
                    onClick={() => {
                        setModalObjective(true)
                    }}
                    disabled={!fields.id}
                    hidden={!fields.id}
                >
                    Objetivos
                </button>
            </div>
            <div
                className="flex flex-col gap-2 mb-2 p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md transition-all duration-200 ease-in-out"

            >
                <div className="editor-fields">
                    <label
                        className="editor-fields-label w-[200px]"
                        htmlFor="name"
                    >
                        Título:
                    </label>
                    <input
                        className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                        id="name"
                        onChange={(e) =>
                            edFunc.handleChange(e, "name", setFields, fields)
                        }
                        disabled={!fields.id}
                        value={!fields.id ? 'cargando...' : fields.name}
                    />
                </div>

                <div className="editor-fields">
                    <label
                        className="editor-fields-label w-[200px]"
                        htmlFor="description"
                    >
                        Descripción:
                    </label>
                    <input
                        className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                        id="description"
                        onChange={(e) =>
                            edFunc.handleChange(e, "description", setFields, fields)
                        }
                        disabled={!fields.id}
                        value={!fields.id ? 'cargando...' : fields.description}
                    />
                </div>

                <div className="editor-fields">
                    <label
                        className="editor-fields-label w-[200px]"
                        htmlFor="imgPath"
                    >
                        Url imagen:
                    </label>
                    <input
                        className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                        id="imgPath"
                        onChange={(e) => {
                            edFunc.handleChange(e, "imgPath", setFields, fields)
                        }
                        }
                        disabled={!fields.id}
                        value={!fields.id ? 'cargando...' : fields.imgPath}
                    />
                </div>

                <div className="editor-fields">
                    <label
                        htmlFor="scoreTypeId"
                        className="editor-fields-label w-[200px]"
                    >
                        Tipo de score:
                    </label>
                    <select
                        className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                        name="scoreTypeId"
                        id="scoreTypeId"
                        required
                        value={fields.scoreTypeId}
                        onChange={(e) =>
                            edFunc.handleChange(e, "scoreTypeId", setFields, fields)
                        }
                    >
                        <option hidden defaultValue="default">Seleccione tipo de score</option>
                        {scoreTypesState?.map((item, index) => {
                            return (
                                <option key={index} value={item.id} className="font-medium">
                                    {item.name}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <h1
                    className={`text-xl font-medium my-[20px] ${!fields.id ? 'hidden' : ''}`}
                >
                    Lista de Preguntas
                </h1>

                <p
                    className={`text-base font-medium my-[20px] ${!fields.id ? 'hidden' : questionsList[0] ? 'hidden' : ''}`}
                >
                    No hay Preguntas
                </p>

                {questionsList?.map((item, index) => (
                    <div key={index} className='flex flex-col items-start'>
                        <div className='flex editor-fields'>
                            <div className="editor-fields-label flex flex-col">
                                <label
                                    className='font-bold'
                                >
                                    Pregunta {index + 1}
                                </label>
                                <span
                                    className='text-xs font-medium'
                                >
                                    {
                                        renderQuestionType(item?.questionTypeId)
                                    }
                                </span>
                            </div>
                            <Tooltip
                                title={item?.question}
                                arrow
                            >
                                <p className='flex items-center text-left m-2 py-1'>
                                    {truncateContent(item?.question, 50)}
                                </p>
                            </Tooltip>

                            <div className='flex-none'>
                                <button className='btn bg-purple-600 hover:bg-purple-700 px-[10px]'
                                    onClick={() => {
                                        setSelectedQuestion(item)
                                        setIndex(index)
                                        setSeeQuestionModal(true)
                                    }}>
                                    <Tooltip title="Ver Pregunta" arrow>
                                        <VisibilityIcon />
                                    </Tooltip>
                                </button>
                                <button className='btn bg-[#3E73B9] hover:bg-blue-800 px-[10px]'
                                    onClick={() => {
                                        setSelectedQuestion(item)
                                        setIndex(index)
                                        setEditQuestionModal(true)
                                    }}>
                                    <Tooltip title="Editar" arrow>
                                        <EditIcon />
                                    </Tooltip>
                                </button>
                                <button
                                    className='btn px-[10px] bg-[#C36B96] hover:bg-red-800'
                                    onClick={() => handleDeleteQuestion(item?.id, index)}
                                >
                                    <Tooltip title="Borrar" arrow>
                                        <DeleteIcon />
                                    </Tooltip>
                                </button>
                            </div>

                        </div>
                    </div>
                ))}

                <button className={`btn w-[200px] my-2 ${!fields.id ? "hidden" : ''}`}
                    onClick={() => setQuestionModal(true)}>
                    Agregar Pregunta
                </button>


            </div>

            {seeQuestionModal && <QuestionModal
                selectedQuestion={selectedQuestion}
                questionTypeId={selectedQuestion?.questionTypeId}
                index={index}
                modal={seeQuestionModal}
                setModal={setSeeQuestionModal}
                token={token}
            />}
            {questionModal && <CreateQuestionModal
                documentId={fields.id}
                modal={questionModal}
                setModal={setQuestionModal}
                questCreateFunc={edChallFunc.HandleCreateQuizQuestion}
                questionsList={questionsList}
                setQuestionsList={setQuestionsList}
                token={token}
                getQuizzes={edChallFunc.handleGetQuiz}
                setFields={setFields}
                scoreTypesState={scoreTypesState}
            />}
            {editQuestionModal && <EditQuestionModal
                documentId={params.quizId}
                selectedQuestion={selectedQuestion}
                index={index}
                questUpdateFunc={edChallFunc.HandleUpdateQuizQuestion}
                questionsList={questionsList}
                setQuestionsList={setQuestionsList}
                modal={editQuestionModal}
                setModal={setEditQuestionModal}
                token={token}
                getQuizzes={edChallFunc.handleGetQuiz}
                setFields={setFields}
            />}

            {modalObjective && <CreateObjectiveModal
                documentId={fields.id}
                modal={modalObjective}
                setModal={setModalObjective}
                fields={fields}
                setFields={setFields}
                element={"quizObjective"}
                objcreateFunc={edChallFunc.HandleAddQuizObjective}
                objdeleteFunc={edChallFunc.HandleDeleteQuizObjective}
                token={token}
            />}
        </div>
    )
}

export default quizEditor
