import { useState, useEffect, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTwitter, } from '@fortawesome/free-brands-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { settingsActions } from '../../redux/slices/settingsSlice'

function Footer() {
  const [t] = useTranslation('global')
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const date = new Date();
  const [hide, setHide] = useState()
  const url = useSelector((state) => state.settings.url)
  const theme = useSelector((state) => state.theme.value);
  const hasChanged = useSelector((state) => state.settings.hasChanged);
  const currentLocation = useLocation()
  const [fullFooter, setFullFooter] = useState(false)

  
  const footerSocials = useMemo(
    () => [
      {
        title: 'Linkedin',
        icon: faLinkedin,
        url: 'https://www.linkedin.com/company/hackctf',
      },
      {
        title: 'Instagram',
        icon: faInstagram,
        url: 'https://www.instagram.com/hackctf.ok/',
      },
      {
        title: 'Facebook',
        icon: faFacebook,
        url: 'https://www.facebook.com/HackearARG/',
      },
      {
        title: 'Twitter',
        icon: faTwitter,
        url: 'https://twitter.com/hackctf',
      },
    ],
    []
  )

  useEffect(() => {
    const path = currentLocation.pathname
    const cutRoute = path.substring(0, path.length - 1).concat('0')

    if (path === "/") {
      setFullFooter(true)
    } else {
      setFullFooter(false)
    }

    if (cutRoute === url) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [currentLocation.pathname, url])

  function confirmMessage(route) {
    const text = "¿Estás seguro?\nEs posible que los cambios que implementaste no se puedan guardar."
    if (hasChanged) {
      if (confirm(text) === true) {
        dispatch(settingsActions.setHasChanged(false))
        navigate(route)
      }
    } else {
      navigate(route)
    }
  }

  return (
    <footer
      className={`${hide ? 'hidden' : ''
        } navigation-styles mt-auto shadow-footer z-20`}
    >
      {
        fullFooter ? (
          <div className='flex flex-col sm:flex-row m-auto text-center sm:text-left items-center sm:items-start sm:justify-around  max-w-[1080px] text-gray-700 dark:text-gray-100'>
            <div className='flex flex-col justify-start px-2 pt-4 pb-2 sm:py-4'>
              <Link
                to='/'
              >
                <img
                  className='w-32 mt-2'
                  src={
                    process.env.PUBLIC_URL +
                    (theme === 'dark' ? '/Logo_Dark.png' : '/Logo_Light.png')
                  }
                  alt='Logo_HackCTF'
                />
              </Link>
            </div>
            <div className='px-2 py-0 sm:py-4 w-full sm:w-52 text-center sm:text-left'>
              <h4 className='m-2 font-bold text-sm xs:text-base'>
                {t('footer.interest.title')}
              </h4>
              <ul>
                <li className='footer-link' >
                  <a className='hover:underline' href="https://blog.hackear.com.ar/" target='_blank' rel="noreferrer">
                    Blog
                  </a>
                </li>
                <li className='footer-link cursor-pointer'>
                  <a className='hover:underline' target='_blank' href="mailto:info@hackear.com.ar" rel="noreferrer">{t('footer.interest.contact')}</a>
                </li>
                <li className='footer-link'>
                  <button className='hover:underline font-semibold' onClick={() => { confirmMessage('/terms-and-conditions') }}>
                    {t('footer.interest.terms')}
                  </button>
                </li>
                <li className='footer-link'>
                  <button className='hover:underline font-semibold' onClick={() => { confirmMessage('/about-us') }}>
                    {t('footer.interest.about')}
                  </button>
                </li>
              </ul>
            </div>
            <div className='px-2 pt-0 pb-4 sm:py-4 w-full sm:w-60 text-center sm:text-left'>
              <h4 className='m-2 font-bold text-sm xs:text-base'>
                {t('footer.social')}
              </h4>
              <ul>
                {footerSocials.map((item, index) => {
                  return (
                    <li key={index} className='footer-link'>
                      <a
                        href={item.url}
                        rel='noreferrer'
                        className='hover:underline'
                        target='_blank'
                      >
                        <FontAwesomeIcon
                          className='mr-2 text-base hover:text-[#cc2fa9]'
                          icon={item.icon}
                        />
                        {item.title}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        ) : (
          <div
            className='flex flex-row justify-between items-center text-gray-700 dark:text-gray-100 px-6'
          >

            <div className='flex flex-col justify-center px-2 pt-4 pb-2 sm:py-4'>
              <Link
                to='/'
              >
                <img
                  className='w-32'
                  src={
                    process.env.PUBLIC_URL +
                    (theme === 'dark' ? '/Logo_Dark.png' : '/Logo_Light.png')
                  }
                  alt='Logo_HackCTF'
                />
              </Link>
            </div>
            <div className='flex items-center h-full'>
              <ul className='flex items-center justify-between'>
                <li className='footer-link'>
                  <a className='hover:underline' href="https://blog.hackear.com.ar/" target='_blank' rel="noreferrer">
                    Blog
                  </a>
                </li>
                <li className='footer-link cursor-pointer'>
                  <a className='hover:underline' target='_blank' href="mailto:info@hackear.com.ar" rel="noreferrer">{t('footer.interest.contact')}</a>
                </li>
                <li className='footer-link'>
                  <button className='hover:underline font-semibold' onClick={() => { confirmMessage('/terms-and-conditions') }}>
                    {t('footer.interest.terms')}
                  </button>
                </li>
                <li className='footer-link'>
                  <button className='hover:underline font-semibold' onClick={() => { confirmMessage('/about-us') }}>
                    {t('footer.interest.about')}
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <ul className='flex items-center'>
                {footerSocials.map((item, index) => {
                  return (
                    <li key={index} className='footer-link'>
                      <a
                        href={item.url}
                        rel='noreferrer'
                        className='hover:underline'
                        target='_blank'
                      >
                        <FontAwesomeIcon
                          className='text-base hover:text-[#cc2fa9]'
                          icon={item.icon}
                        />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )
      }


      <div className='bg-white dark:bg-neutral-800 shadow-footer'>
        <h4 className='px-2 py-4 sm:py-6 text-center font-medium text-xs xs:text-sm'>
          Copyright © Hackear {date.getFullYear()} - {t('footer.copyright')}
        </h4>
      </div>
    </footer>
  )
}

export default Footer
