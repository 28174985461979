import { motion } from 'framer-motion'
import { useState, useRef, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon as emptyFaMoon } from '@fortawesome/free-regular-svg-icons'
import { faMoon as solidFaMoon, faBars, faTimes, faUserTie, faFire, faCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { navbarItems, variants } from './navbarItems'
import { logout } from '../../services/userService'
import useClickOutside from './../../hooks/useClickOutside'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { themeActions } from '../../redux/slices/themeSlice'
import { settingsActions } from '../../redux/slices/settingsSlice'
import Cookies from "js-cookie"
import AttackerVMModal from '../common/modals/AttackerVMModal'

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const navRef = useRef()
  const toggleRef = useRef()
  const toolsRef = useRef()

  const [menu, setMenu] = useState(false)
  const [langmenu, setLangMenu] = useState(false)
  // const [dropdowns, setDropdowns] = useState({})
  const [showDropdown, setShowDropdown] = useState(false)


  const theme = useSelector((state) => state.theme.value)
  const currentUser = useSelector((state) => state.user.currentUser)
  const data = useSelector((state) => state.user.userData)
  const hasChanged = useSelector((state) => state.settings.hasChanged);
  // TODO: implement streak
  // const userStreak = useSelector((state) => state.user.streak);

  const [vmRunning, setVmRunning] = useState(localStorage.getItem("vmRunning") === "true")
  const [loading, setLoading] = useState(false)
  const [showVMModal, setShowVMModal] = useState(false)
  const [t, i18n] = useTranslation('global')
  const [ipAttackerVM] = useState(localStorage.getItem("ipAttackerVM") || "172.17.0.2")
  const isSmall = useMediaQuery('(max-width: 1023.9px)')
  const animationVariants = variants(isSmall)

  useClickOutside(navRef, toggleRef, setMenu, setLangMenu)

  useEffect(() => {

    if (localStorage.getItem("vmRunning") === "true") {
      setVmRunning(true)
    }

    const handleClick = (event) => {
      if (!toolsRef.current?.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }

  }, [])

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem("lang", lang)
    dispatch(settingsActions.setLanguage(lang))

    window.location.reload()
  }

  const themeHandler = () => {
    dispatch(themeActions.toggleTheme())
  }

  const handleLogOut = () => {
    localStorage.removeItem("currentUser")
    localStorage.removeItem("token")
    localStorage.removeItem("userData")
    Cookies.remove("token")
    logout()
    setMenu(false)
    setLangMenu(false)
  }

  function confirmMessage(route) {
    const text = "¿Estás seguro?\nEs posible que los cambios que implementaste no se puedan guardar."
    if (hasChanged) {
      if (confirm(text) === true) {
        dispatch(settingsActions.setHasChanged(false))
        navigate(route)
      }
    } else {
      navigate(route)
    }
  }

  const toggleDropdown = (index) => {
    // setDropdowns(prev => ({ ...prev, [index]: !prev[index] }));
    setShowDropdown(!showDropdown)
  }

  const openVMModal = () => {
    if (!vmRunning || localStorage.getItem("vmRunning") !== "true") {
      runVirtualMachine("start")
    } else {
      setShowVMModal(!showVMModal)
    }
  }

  const runVirtualMachine = (action) => {

    if (!vmRunning || action === "start") {
      localStorage.setItem("vmRunning", true)
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
        setVmRunning(true)
        window.open(`/attacker-vm?url=ws://hackctf.net.ar:6080/&password=3x@mpl3_C0mpl3x_P@ssw0rd_with_24+_chars!!`, '_blank')
      }, 1500)
    } else {
      localStorage.setItem("vmRunning", false)
      setVmRunning(false)
      setLoading(false)
    }
  }

  return (
    <>
      <header className='fixed left-0 top-0 right-0 navigation-styles z-[99]'>
        <div className='relative flex flex-row mx-auto py-1 justify-between max-w-[1600px] h-[55px]'>
          <h1 className='flex items-center mx-2 font-bold text-lg xs:text-xl'>
            <button
              onClick={() => {
                confirmMessage('/')
                setMenu(false)
                setLangMenu(false)
              }}
            >
              <img
                className='w-36'
                src={
                  process.env.PUBLIC_URL +
                  (theme === 'dark' ? '/Logo_Dark.png' : '/Logo_Light.png')
                }
                alt='Logo_HackCTF'
              />
            </button>
          </h1>
          <div className='flex items-center'>
            <motion.ul
              ref={navRef}
              variants={animationVariants}
              animate={menu ? 'opened' : 'closed'}
              transition={'transition'}
              className={`${menu ? 'enabled' : 'disabled'} navbar-container`}
            >
              <ul className='flex items-center'>
                {
                  currentUser && (
                    <button
                      title={vmRunning ? 'Detener MV Atacante' : 'Encender MV Atacante'}
                      className='my-3 lg:my-auto bg-[#C36B96] rounded-md p-1 px-2 flex items-center mr-2'
                      onClick={openVMModal}
                    >
                      {
                        loading ? (
                          <FontAwesomeIcon
                            icon={faCircleNotch}
                            className='mr-2 text-sm xs:text-base'
                            color={"white"}
                            spin
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className='mr-2 text-sm xs:text-base'
                            color={vmRunning ? '#86EFAC' : '#9F1239'}
                          />
                        )
                      }
                      <span className='text-xs'>
                        {
                          vmRunning ? ipAttackerVM : "MV Atacante"
                        }
                        </span>
                    </button>
                  )
                }
                {navbarItems.map((item, index) => {
                  return (
                    currentUser && (
                      <li ref={item.isDropdown ? toolsRef : null} key={index} className='my-3 lg:my-auto relative'>
                        {item.isDropdown ? (
                          <>
                            <button
                              onClick={() => toggleDropdown(index)}
                              className={`navbar-link py-0`}>
                              <FontAwesomeIcon
                                className='mr-2 text-sm xs:text-base'
                                icon={item.icon}
                              />
                              {t(`navbar.${item.route}`)}
                            </button>
                            {(showDropdown) && (
                              <ul className="flex flex-col gap-1 absolute right-1 py-2 px-0 bg-slate-200 dark:bg-hackear-600 rounded-md w-52">
                                {item.dropdownItems.map((subItem, subIndex) => (
                                  <li key={subIndex} className='flex justify-center'>
                                    {
                                      subItem.isExternal ? (
                                        <Link
                                          onClick={() => {
                                            setMenu(false)
                                            setLangMenu(false)
                                            toggleDropdown(index)
                                          }}
                                          to={subItem.route}
                                          target='_blank'
                                          className={`navbar-link`}
                                        >
                                          <FontAwesomeIcon
                                            className='mr-2 text-sm xs:text-base'
                                            icon={subItem.icon}
                                          />
                                          {t(`navbar.toolsSubItems.${subItem.key}`)}
                                        </Link>
                                      ) : (
                                        <NavLink
                                          onClick={() => {
                                            setMenu(false)
                                            setLangMenu(false)
                                            toggleDropdown(index)
                                          }}
                                          to={`/${subItem.route}`}
                                          target='_blank'
                                          className={`navbar-link`}
                                        >
                                          <FontAwesomeIcon
                                            className='mr-2 text-sm xs:text-base'
                                            icon={subItem.icon}
                                          />
                                          {t(`navbar.toolsSubItems.${subItem.key}`)}
                                        </NavLink>
                                      )
                                    }
                                  </li>
                                ))}
                              </ul>
                            )}
                          </>
                        ) : (
                          hasChanged ? (
                            <button
                              onClick={() => {
                                confirmMessage(item.route)
                                setMenu(false)
                                setLangMenu(false)
                              }}
                              className={`navbar-link py-0`}>
                              <FontAwesomeIcon
                                className='mr-2 text-sm xs:text-base'
                                icon={item.icon}
                              />
                              {t(`navbar.${item.route}`)}
                            </button>
                          ) : (
                            <NavLink
                              onClick={() => {
                                setMenu(false)
                                setLangMenu(false)
                              }}
                              to={`/${item.route}`}
                              className={`${(nav) =>
                                nav.isActive ? 'active' : ''} navbar-link`}
                            >
                              <FontAwesomeIcon
                                className='mr-2 text-sm xs:text-base'
                                icon={item.icon}
                              />
                              {t(`navbar.${item.route}`)}
                            </NavLink>
                          )
                        )}
                      </li>
                    )
                  )
                })}
              </ul>
              {((data !== 'null' && data !== null) && (data.role.name === "Full Moderator" || data.role.name === "Content Creator" || data.role.name === "Guides Moderator" ||
                data.role.name === "Super Admin" || data.role.name === "Admin")) && (
                  <li className='my-3 lg:my-auto'>
                    {hasChanged ?
                      <button
                        onClick={() => {
                          confirmMessage((data.role.name === "Super Admin" || data.role.name === "Admin") ? `/admin/creation/newGuide` : `/admin/edition/editor`)
                          setMenu(false)
                          setLangMenu(false)
                        }}
                        className={`navbar-link py-0`}>
                        <FontAwesomeIcon
                          className='mr-2 text-sm xs:text-base'
                          icon={faUserTie}
                        />
                        Admin
                      </button>
                      : <NavLink
                        onClick={() => {
                          setMenu(false)
                          setLangMenu(false)
                        }}
                        to={(data.role.name === "Super Admin" || data.role.name === "Admin") ? `/admin/creation/newGuide` : `/admin/edition/editor`}
                        className={`${(nav) =>
                          nav.isActive ? 'active' : ''} navbar-link`}
                      >
                        <FontAwesomeIcon
                          className='mr-2 text-sm xs:text-base'
                          icon={faUserTie}
                        />
                        Admin
                      </NavLink>}
                  </li>
                )}
              {currentUser && (
                <li className='my-3 lg:my-auto text-labelColor'>
                  <span
                    className={`py-0 text-xs xs:text-sm mx-0.5 lg:mx-1 px-2 lg:py-2 h-full font-semibold transition-all duration-200 ease-in-out flex`}>
                    <FontAwesomeIcon className='mr-2 text-sm xs:text-base' icon={faFire} />
                    <span className='text-white'>32</span>
                  </span>
                </li>
              )}
              {!currentUser ? (
                <div className='flex flex-col lg:flex-row items-center py-2 lg:py-0'>
                  <li className='my-2 lg:my-auto'>
                    <Link
                      onClick={() => {
                        setMenu(false)
                        setLangMenu(false)
                      }}
                      className='btn border border-[#662D91] text-[#662D91] rounded-sm bg-transparent hover:bg-purple-900 hover:text-white '
                      to='/login'
                    >
                      {t('navbar.login')}
                    </Link>
                  </li>
                  <li className='my-3 lg:my-auto'>
                    <Link
                      onClick={() => {
                        setMenu(false)
                        setLangMenu(false)
                      }}
                      className='btn text-white bg-[#662D91] border border-[#662D91] hover:bg-purple-900 hover:border-purple-900 rounded-sm shadow-md'
                      to='/register'
                    >
                      {t('navbar.register')}
                    </Link>
                  </li>
                </div>
              ) : (
                <div className='flex flex-col lg:flex-row items-center py-1 lg:py-0'>
                  <li className='my-2 lg:my-auto'>
                    <button
                      onClick={() => {
                        confirmMessage('/profile')
                        setMenu(false)
                        setLangMenu(false)
                      }}
                      className='btn border border-[#8A96B2] text-[#8A96B2] rounded-sm bg-transparent hover:bg-slate-600 hover:text-white '
                    >
                      <span className='inline-block text-center max-w-[125px] truncate align-middle'>
                        {currentUser
                          && currentUser
                        }
                        {!currentUser && 'Cargando...'}
                      </span>
                    </button>
                  </li>
                  <li className='my-3 lg:my-auto'>
                    <Link
                      onClick={() => handleLogOut()}
                      className='btn text-white border border-[#662D91] bg-[#662D91] hover:bg-purple-900 hover:border-purple-900 rounded-sm shadow-md'
                      to='/'
                    >
                      {t('navbar.logout')}
                    </Link>
                  </li>
                </div>
              )}
              <li
                className='mt-1 mb-3 lg:my-0 lg:mx-2 cursor-pointer flex items-center'
                onClick={themeHandler}
              >
                <FontAwesomeIcon
                  onClick={() => {
                    setMenu(false)
                    setLangMenu(false)
                  }}
                  className='text-base xs:text-xl'
                  icon={theme === 'light' ? emptyFaMoon : solidFaMoon}
                />
              </li>
              <div
                onClick={() => setLangMenu(!langmenu)}
                className='flex flex-col relative p-2 my-1 mb-2 lg:my-0 mx-1 rounded-md cursor-pointer bg-slate-200 dark:bg-hackear-600 font-semibold text-xs xs:text-sm outline-none focus:ring-2 ring-blue-500 dark:ring-white'
              >
                <img
                  alt='lang'
                  className='block w-8 h-5 select-none'
                  src={process.env.PUBLIC_URL + `/${i18n.language}.svg`}
                />
                <ul
                  className={`${langmenu ? 'block' : 'hidden'
                    } absolute top-[60px] lg:top-[50px] right-0 p-2 w-14 bg-slate-200 dark:bg-hackear-600 rounded-md`}
                >
                  <li
                    onClick={() => handleChangeLanguage('es')}
                    className={`py-2 px-1 select-none ${langmenu ? `block` : `hidden`
                      }`}
                  >
                    <img alt='es' src={process.env.PUBLIC_URL + '/es.svg'} />
                  </li>
                  <li
                    onClick={() => handleChangeLanguage('en')}
                    className={`py-2 px-1 select-none ${langmenu ? `block` : `hidden`
                      }`}
                  >
                    <img alt='en' src={process.env.PUBLIC_URL + '/en.svg'} />
                  </li>
                  <li
                    onClick={() => handleChangeLanguage('pt')}
                    className={`py-2 px-1 select-none ${langmenu ? `block` : `hidden`
                      }`}
                  >
                    <img alt='pt' src={process.env.PUBLIC_URL + '/pt.svg'} />
                  </li>
                </ul>
              </div>
            </motion.ul>
            <div ref={toggleRef}>
              <FontAwesomeIcon
                className={`lg:hidden mt-[7px] xs:mt-[5px] mr-2 text-xl xs:text-2xl cursor-pointer select-none`}
                onClick={() => {
                  setMenu(!menu)
                  setLangMenu(false)
                }}
                icon={menu ? faTimes : faBars}
              />
            </div>
          </div>
        </div>
      </header>

      {
        showVMModal && (
          <AttackerVMModal
            showVMModal={showVMModal}
            setShowVMModal={setShowVMModal}
            runVirtualMachine={runVirtualMachine}
          />
        )
      }
    </>
  )
}

export default Navbar
