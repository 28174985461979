import React, { useState } from 'react'
import LoaderSpinner from '../../common/loaderSpinner'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
const ExternalElementButton = ({
    url,
    questionType
}) => {
    if (questionType.name === "LAB") {
        return (
            <div className='flex items-center'>
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn px-4 py-2 font-semibold bg-[#C36B96] min-w-[70px] max-h-[40px] rounded-md text-lg text-white absolute top-0 right-0"

                >
                    Acceder al Lab
                </a>

            </div>
        )
    }

    if (questionType.name === "EXTERNAL_ATTACHMENT") {
        return (
            <div className='flex items-center'>
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn px-4 py-2 font-semibold bg-[#C36B96] min-w-[70px] max-h-[40px] rounded-md text-lg text-white absolute top-0 right-0"

                >
                    Descargar
                </a>
            </div>
        )
    }

    const [loading, setLoading] = useState(false)
    const [vmRunning, setVmRunning] = useState(false)

    const runVirtualMachine = () => {
        if (!vmRunning) {
            setLoading(true)

            setTimeout(() => {
                setLoading(false)
                setVmRunning(true)
                // window.open(`/vnc?url=${url}&password=3x@mpl3_C0mpl3x_P@ssw0rd_with_24+_chars!!`, '_blank')
            }, 3000)
        } else {
            setLoading(true)

            setTimeout(() => {
                setLoading(false)
                setVmRunning(false)
            }, 3000)
        }
    }


    return (
        <div className='flex items-center'>
            
            <button
                className={`btn px-4 py-2 font-semibold min-w-[70px] max-h-[40px] rounded-md text-lg text-white absolute top-0 right-12 ${vmRunning ? ' bg-green-300 hover:bg-green-300' : 'bg-[#C36B96]'}`}
                onClick={runVirtualMachine}
                title={vmRunning ? "Detener MV Victima" : "Iniciar MV Victima"}
            >
                {
                    loading ? (
                        <span className='flex items-center gap-2'>
                            {
                                vmRunning ? "Deteniendo..." : "Lanzando..."
                            }
                            <LoaderSpinner />
                        </span>
                    ) : (
                        <>
                            {
                                vmRunning ? `IP: ${url}` : "Encender MV Victima"
                            }
                        </>
                    )
                }
            </button>
            {
                vmRunning && (
                   <div 
                   title='Copiar IP'
                   className='absolute top-3 right-4 cursor-pointer'
                   onClick={() => navigator.clipboard.writeText(url)}
                   >
                      <ContentCopyOutlinedIcon className="text-white" />
                   </div>
                )
            }
        </div>
    )
}

export default ExternalElementButton