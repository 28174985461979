import { Outlet, Navigate } from "react-router";
import { useSelector } from "react-redux";

function RequiresNoUser() {
  const { currentUser } = useSelector((state) => state.user);

  if (!currentUser) {
    return <Outlet />;
  } else {
    return <Navigate to="/profile" />;
  }
}

export default RequiresNoUser;
