import { motion } from 'framer-motion'
import { React, useState, useEffect } from 'react'
import edFunc from '../admin/editorFunctions'
import edChallFunc from '../admin/challengeEditorFunctions'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const variants = {
    opened: {
        opacity: 1,
        display: 'flex'
    },
    closed: {
        opacity: 0,
        transitionEnd: {
            display: 'none'
        }
    }
}

const createQuizModal = ({ challengeId, modal, setModal, quizList, setQuizList, token, scoreTypes }) => {

    const resetFields = {
        id: 0,
        name: "nombre",
        description: "descripcion",
        imgPath: "http://imgPath.com",
        questions: [],
        quizObjective: [],
        challengeId,
        scoreTypeId: 5
    };

    const [fields, setFields] = useState(resetFields);
    const [spinnerEnable, setSpinnerEnable] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleDocument = async () => {
        setSpinnerEnable(true)
        const newQuiz = await edChallFunc.handleNewQuiz(fields, token)
        if (newQuiz) {
            toast.success("Cuestionario creado")
            setQuizList([...quizList, newQuiz]);
            setSpinnerEnable(false)
            setModal(false)
            setFields(resetFields)
        } else {
            toast.error("No se pudo crear el cuestionario")
            setSpinnerEnable(false)
        }
    }

    return (
        <motion.div
            variants={variants}
            initial={{ display: 'none', opacity: 0 }}
            animate={modal ? 'opened' : 'closed'}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            className='justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-30'
        >
            <div className="absolute p-4 lg:p-2 text-sm bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md transition-all duration-200 ease-in-out w-4/5">
                <h1 className="text-4xl font-bold text-gray-100 my-2">
                    Nuevo Cuestionario
                </h1>
                <button className='btn absolute top-0 right-0 rounded-full aspect-square w-[40px] p-0'
                    onClick={() => {
                        setModal(false)
                    }}>
                    <CloseIcon />
                </button>

                <div className='flex flex-col gap-2'>
                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="name"
                        >
                            Título:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="name"
                            onChange={(e) =>
                                edFunc.handleChange(e, "name", setFields, fields)
                            }
                            disabled={!challengeId}
                            placeholder='Escriba el nombre del cuestionario'
                        />
                    </div>
                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="description"
                        >
                            Descripción:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="description"
                            onChange={(e) =>
                                edFunc.handleChange(e, "description", setFields, fields)
                            }
                            disabled={!challengeId}
                            placeholder='Escriba la descripción'
                        />
                    </div>
                    <div className="editor-fields">
                        <label
                            className="editor-fields-label w-[200px]"
                            htmlFor="imgPath"
                        >
                            Url imagen:
                        </label>
                        <input
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            id="imgPath"
                            onChange={(e) =>
                                edFunc.handleChange(e, "imgPath", setFields, fields)
                            }
                            disabled={!challengeId}
                            placeholder='http://imgpath.com'
                        />
                    </div>
                    <div className="editor-fields">
                        <label
                            htmlFor="scoreTypeId"
                            className="editor-fields-label w-[200px]"
                        >
                            Tipo de score:
                        </label>
                        <select
                            className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
                            name="scoreTypeId"
                            id="scoreTypeId"
                            required
                            value={fields.scoreTypeId}
                            onChange={(e) =>
                                edFunc.handleChangeInt(e, "scoreTypeId", setFields, fields)
                            }
                        >
                            <option hidden defaultValue="default">Seleccione tipo de score</option>
                            {scoreTypes?.map((item, index) => {
                                return (
                                    <option key={index} value={item.id} className="font-medium">
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>


                <button className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]`}
                    onClick={() => handleDocument()}>
                    {spinnerEnable
                        ? (
                            <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                        )
                        : (
                            'Crear Cuestionario'
                        )}
                </button>
            </div>
        </motion.div>
    )
}

export default createQuizModal
