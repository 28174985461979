import { SUBCHALLENGE_URL_GET } from "./apiRoutes"
import { makeRequest } from "./makeRequest"


export const getOneSubChallenge = async (id,) => {
    const res = await makeRequest('GET', `${SUBCHALLENGE_URL_GET}/${id}`, {
        auth: true
    })

    if (res && res?.status === 200) {
        return res.result
    } else {
        return null
    }
}