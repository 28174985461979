import { motion } from 'framer-motion'

function TermsAndConditions() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
            className='flex justify-center items-start h-full'
        >
            <div className='container-block-col justify-center text-center min-h-box max-w-[1280px] p-4 m-6 rounded-md shadow-md'>
                <h2 className='title'>Política de Privacidad</h2>
                <h3 className='font-semibold text-xl'>HackCTF</h3>
                <p className='text-left p-6'>
                    <strong>Empresa registrada en la República Argentina</strong><br /><br />
                    <strong>Introducción</strong><br />
                    <ol>
                        <li>1.	La privacidad de los visitantes de nuestro sitio web es muy importante y estamos comprometidos a protegerla.
                            Esta política explica qué haremos con su información personal.</li>
                        <li>2.	Cuando usted nos visita por primera vez y da su consentimiento para el uso que le damos a las cookies, de conformidad con los términos de esta política,
                            usted nos da permiso para usar cookies cada vez que visita nuestro sitio web.</li>
                    </ol>
                    <br />
                    <strong>Recopilacion de informacion personal</strong><br />
                    Los siguientes tipos de información personal pueden ser recopilados, almacenados y usados:<br /><br />
                    <ol>
                        <li>1.	Información sobre su ordenador, incluyendo su dirección IP, ubicación geográfica, tipo y versión de navegador, y sistema operativo;</li>
                        <li>2.	Información sobre sus visitas y uso de este sitio web, incluidas las fuentes de referencia, duración de la visita, visitas de la página y
                            rutas de navegación del sitio web;</li>
                        <li>3.	Información que introduzca al crear una cuenta en nuestro sitio web, como la dirección de correo electrónico;</li>
                        <li>4.  Información que introduzca al crear un perfil en nuestro sitio web</li>
                        <li>5.	Información que introduzca para suscribirse a nuestros correos y boletines, como su nombre y dirección de correo electrónico;</li>
                        <li>6.	Información que introduzca mientras usa los servicios en nuestro sitio web;</li>
                        <li>7.	Información que se genera mientras usa nuestro sitio web, incluido cuándo, qué tan a menudo y bajo qué circunstancias lo use;</li>
                        <li>8.	Información sobre cualquier aspecto relacionado con su compra, servicios que use o transacciones que haga a través del sitio web, incluido su nombre,
                            dirección, número telefónico, y dirección de correo electrónico</li>
                        <li>9.	Información que publique en nuestro sitio web con la intención de que sea publicada en Internet, lo que incluye su nombre de usuario, fotos de perfil
                            y contenido de sus publicaciones;</li>
                        <li>10.	Información contenida en cualquiera de las comunicaciones que nos envía a través de correo electrónico o de nuestro sitio web, incluido el contenido de la
                            comunicación y metadatos;</li>
                        <li>11.	Cualquier otra información personal que nos envíe.</li>
                    </ol><br />
                    Antes de divulgarnos la información personal de otra persona, primero debe obtener el consentimiento de esa persona, tanto para la divulgación como para el procesamiento de esa información personal de acuerdo con esta política;<br /><br />
                    <strong>Uso su información personal</strong><br />
                    La información personal que nos envíe a través de nuestro sitio web será usada con los fines especificados en esta política o en las páginas relevantes
                    del sitio web. Podemos usar su información personal para los siguientes fines:<br /><br />
                    <ol>
                        <li>1.	Administrar nuestro sitio web y negocio;</li>
                        <li>2.	Personalizar nuestro sitio web para usted;</li>
                        <li>3.	Activar el uso de servicios disponibles en nuestro sitio web;</li>
                        <li>4.	Enviarle artículos comprados a través de nuestro sitio web;</li>
                        <li>5.	Suministrar servicios comprados a través de nuestro sitio web;</li>
                        <li>6.	Enviar extractos, facturas y recordatorios de pago y recopilar datos suyos;</li>
                        <li>7.	Enviar comunicaciones comerciales (no de marketing);</li>
                        <li>8.	Enviar notificaciones de correo electrónico que ha solicitado específicamente;</li>
                        <li>9.	Enviar un boletín de correo electrónico, si usted lo ha solicitado (puede informarnos en cualquier momento si no desea seguir suscrito al boletín);</li>
                        <li>10.	Enviar comunicaciones de marketing relacionadas con nuestro negocio, o los negocios de terceros cuidadosamente seleccionados, que consideramos que serán
                            de su interés, por correo postal o donde haya aceptado específicamente a esto, por correo electrónico o tecnologías similares
                            (nos puede informar en cualquier momento si no desea seguir recibiendo las comunicaciones de marketing);</li>
                        <li>11.	Dar información estadística a terceros sobre nuestros usuarios (pero esos terceros no podrán identificar a ningún usuario individual con esa información):</li>
                        <li>12.	Dar respuesta a las preguntas y quejas suyas o sobre usted, relacionadas con nuestro sitio web;</li>
                        <li>13.	Mantener protegido el sitio web y evitar el fraude;</li>
                        <li>14.	Verificar el cumplimiento de los términos y condiciones que rigen sobre el uso de nuestro sitio web (incluido la monitorización de mensajes privados a través
                            del servicio de mensajería privada de nuestro sitio web); y</li>
                        <li>15.	Otros usos.</li>
                    </ol>
                    <br />
                    Si usted envía información personal para su publicación en nuestro sitio web, publicaremos y usaremos esa información de conformidad con la licencia que usted nos confiere.
                    Sus ajustes de privacidad pueden usarse para limitar la publicación de nuestra información en nuestro sitio web y puede ajustarse usando controles de privacidad en el sitio web.
                    Sin su consentimiento explícito no proporcionaremos su información personal a ningún tercero para su marketing directo o el de otro tercero.<br /><br />
                    <strong>Divulgación de información personal</strong><br />
                    Podremos divulgar su información personal a cualquiera de nuestros empleados, oficiales, aseguradores, consejeros profesionales, agentes, proveedores o contratistas, como sea razonablemente necesario para los fines descritos en esta política.
                    Podremos divulgar su información personal a cualquier miembro de nuestro grupo de empresas (esto incluye subsidiarios, nuestro grupo y todas sus subsidiarias), como sea razonablemente necesario para los fines descritos en esta política.
                    Podemos divulgar su información personal:<br /><br />
                    <ol>
                        <li>1.	Hasta lo que sea requerido por la ley;</li>
                        <li>2.	En relación con cualquier procedimiento legal actual o prospectivo;</li>
                        <li>3.	Para establecer, ejercer o defender nuestros derechos legales (incluido proporcionar información personal a otros con el fin de evitar fraudes y reducir
                            el riesgo crediticio);</li>
                        <li>4.	Al comprador (o comprador prospectivo) de cualquier negocio o activo que estemos vendiendo o estemos contemplando vender; y</li>
                        <li>5.	A cualquier persona que creamos razonablemente que podrá aplicar a una corte o a otra autoridad competente para solicitar la divulgación de esa información personal, y que, bajo nuestra opinión razonable, dicha corte o autoridad tendrá una probabilidad razonable de ordenar la divulgación de dicha información personal.
                            Con excepción de lo establecido por la ley, no proporcionaremos su información personal a terceros.</li>
                    </ol>
                    <br />
                    <strong>Transferencia de Datos Internacionales</strong><br />
                    <ol>
                        <li>1.	La información que recopilamos puede ser almacenada, procesada y transferida entre cualquiera de los países en que operamos, con el fin de permitirnos usar
                            la información de conformidad con esta política.</li>
                        <li>2.	La información personal que publique en nuestro sitio web o envíe para su publicación en nuestro sitio web, puede estar disponible a través de Internet,
                            en todo el mundo. No podemos evitar el uso o mal uso de dicha información por parte de otros.</li>
                        <li>3.	Usted acepta explícitamente las transferencias de información personal descritas en esta sección.</li>
                    </ol>
                    <br />
                    <strong>Conservación de información personal</strong><br />
                    <ol>
                        <li>1.  Esta sección especifica nuestras políticas y procedimientos de conservación de información personal, diseñadas para ayudar a garantizar que cumplimos con nuestras obligaciones legales con respecto a la conservación y eliminación de información personal.</li>
                        <li>2.  La información personal que procesamos para cualquier fin o fines no debe ser almacenada más tiempo de lo necesario para ese fin o fines.</li>
                        <li>3.  Conservaremos los documentos (incluidos documentos electrónicos) que contengan datos personales:
                            <ol className='pl-8 pt-2'>
                                <li>1.  En medida de lo que sea requerido por la ley;</li>
                                <li>2.  Si creemos que los documentos pueden ser relevantes para cualquier procedimiento legal actual o prospectivo; y</li>
                                <li>3.  Para establecer, ejercer o defender nuestros derechos legales (incluido proporcionar información personal a otros con el fin de evitar fraudes y reducir el riesgo crediticio).</li>
                            </ol></li>
                    </ol>
                    <br />
                    <strong>Seguridad de su información personal</strong><br />
                    <ol>
                        <li>1.	Tomaremos precauciones razonables técnicas y organizacionales para evitar la pérdida, mal uso o alteración de su información personal.</li>
                        <li>2.	Almacenaremos toda la información personal que nos dé en nuestros servidores seguros.</li>
                        <li>3.	Todas las transacciones financieras electrónicas realizadas a través de nuestro sitio web estarán protegidas por tecnología de cifrado de procesadores
                            de tarjetas de crédito.</li>
                        <li>4.	Usted acepta que la transmisión de información en Internet es inherentemente insegura y que no podemos garantizar la seguridad de los datos enviados
                            a través de Internet.</li>
                        <li>5.	Usted es responsable de mantener de forma confidencial la contraseña que use para acceder a nuestro sitio web, y nosotros no le pediremos
                            su contraseña (exceptuando para iniciar sesión en nuestro sitio web).</li>
                    </ol>
                    <br />
                    <strong>Enmiendas</strong><br />
                    Es posible que actualicemos esta política de vez en cuando al publicar una nueva versión en nuestro sitio web. Usted debe comprobar ocasionalmente esta página para asegurarse de que entiende cualquier cambio a esta política. Es posible que le notifiquemos de cambios a esta política a través de correo electrónico o a través del sistema de mensajería privada de nuestro sitio web.<br /><br />
                    <strong>Sus derechos</strong><br />
                    Usted puede solicitarnos que le proporcionemos cualquier información personal que tengamos de usted; la entrega de dicha información estará sujeta a que usted presente evidencia apropiada sobre su identidad; para este fin, usualmente aceptaremos una fotocopia del documento de identidad o pasaporte certificada por un notario y un comprobante de domicilio. Nos puede contactar en help@hackear.com.ar
                    Podemos retener información personal que usted nos solicite en la medida de lo permitido por la ley.
                    Usted puede solicitarnos en cualquier momento que no procesemos su información personal para fines de marketing.
                    En la práctica, usted usualmente aceptará explícitamente de antemano que usemos su información personal para fines de marketing, o le daremos la oportunidad de que decida que no se use su información personal para fines de marketing.<br /><br />
                    <strong>Sitios web de terceros</strong><br />
                    Nuestro sitio web incluye hiperenlaces a, e información de sitios web de terceros. No tenemos control ni somos responsables por las políticas de privacidad y prácticas de terceros.<br /><br />
                    <strong>Actualizar información</strong><br />
                    Por favor, infórmenos si la información personal que tenemos de usted necesita ser corregida o actualizada.<br /><br />
                    <strong>Cookies</strong><br />
                    Nuestro sitio web usa cookies. Una cookie es un fichero que contiene un identificador (una cadena de letras y números) que se envía desde un servidor web a un navegador web y se almacena en el navegador. El identificador es enviado de vuelta al servidor cada vez que el navegador solicita una página del servidor. Las cookies pueden ser “persistentes” o de “sesión”. Una cookie persistente será almacenada por una navegador web y permanecerá válida hasta su fecha de caducidad, a menos que el usuario la elimine antes de la fecha de caducidad. Una cookie de sesión caducará al final de la sesión del usuario, cuando se cierre el navegador. Las cookies típicamente no contienen ninguna información que identifique personalmente al usuario sino información personal que almacenamos sobre usted que puede relacionarse con la información almacenada y obtenida de las cookies.<br /><br />
                </p>

            </div>
        </motion.div>
    )
}

export default TermsAndConditions