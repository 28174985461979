import { useEffect, useState } from "react";
import edFunc from "./editorFunctions";
import { useDispatch, useSelector } from 'react-redux'
import CreateDocumentModal from "../common/createDocumentModal";
import TagListModal from "../common/tagListModal";
import { settingsActions } from "../../redux/slices/settingsSlice";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { toast } from "react-toastify";
import { getGuideList } from "../../services/guidesService";
import { getScoreTypes } from "../../services/superAdminService";

const resetField = {
  name: "Title",
  description: "Description",
  imageThumbnailPath: "www.imageThumbnailPath.com",
  videoThumbnailPath: "www.videoThumbnailPath.com",
  headerDesktopPath: "www.headerDesktopPath.com",
  headerMobilePath: "www.headerMobilePath.com",
  difficultyOrder: 1,
  isPublished: false,
  isPremium: false,
  difficultyLevelId: 1,
  tagsId: [],
  guidePage: [],
  scoreType: 1,
}

function NewGuide() {
  const dispatch = useDispatch();
  const hasChanged = useSelector((state) => state.settings.hasChanged);
  const scoreTypesState = useSelector((state) => state.settings.scoreTypes);
  const [newGuideId, setNewGuideId] = useState(0)
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [fields, setFields] = useState(resetField)
  const [modal, setModal] = useState()
  const [levels, setLevels] = useState([])
  const [tags, setTags] = useState([])
  const [tagModal, setTagModal] = useState(false)
  const [scoreTypes, setScoreTypes] = useState(scoreTypesState)

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!tags[0] || !levels[0]) {
        edFunc.HandleGetLevelList(setLevels);
        edFunc.HandleGetTagList(setTags);
      }
    }

    return () => {
      setTags([])
      setFields({})
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    // Get score types
    fetchScoreTypes();

    if (!hasChanged) return;
    function handleOnBeforeUnload(event) {
      event.preventDefault();
      return (event.returnValue = '');
    }
    window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
    }

  }, [hasChanged]);

  const fetchScoreTypes = async () => {
    if (scoreTypes.length > 0) {
      setScoreTypes(scoreTypesState)
    } else {
      const response = await getScoreTypes();
      if(response){
        setScoreTypes(response)
        dispatch(settingsActions.setScoreTypes(response))
      }
    }
  }

  const handleGuide = async (evt) => {
    evt.preventDefault();
    const element = await edFunc.HandleCreateGuide(fields)
    if (element) {
      dispatch(settingsActions.setGuides(await getGuideList()))
      toast.success("Guía creada con exito")
      setSpinnerEnable(true)
      setNewGuideId(element.id)
      setSpinnerEnable(false)
      dispatch(settingsActions.setHasChanged(false))
      setModal(true)
    } else {
      toast.error("Error al crear la guía")
      setSpinnerEnable(false)
    }
  }

  const handleNewGuide = async () => {
    document.getElementById('newGuide').reset();
  }

  return (
    <div className='m-2 pr-3 text-center w-full'>
      <div className="p-4 bg-gray-100 dark:bg-hackear-700 text-gray-700 dark:text-gray-100 rounded-md shadow-md">
        <h1 className='text-3xl font-medium py-[20px]' htmlFor='title'>Nueva Guía</h1>

        <form
          id="newGuide"
          className="flex flex-col gap-2"
          onSubmit={handleGuide}
        // onChange={() => dispatch(settingsActions.setHasChanged(true))}
        >

          <div className="editor-fields ">
            <label
              className="editor-fields-label"
              htmlFor="name"
            >
              Título:
            </label>
            <input
              id='name'
              placeholder='Escriba aquí el nombre...'
              className='editor-input'
              required
              onChange={(e) =>
                edFunc.handleChange(e, "name", setFields, fields)
              }
              autoComplete="off"
            />
          </div>

          <div className="editor-fields">
            <label
              className="editor-fields-label"
              htmlFor="description"
            >
              Descripción:
            </label>
            <textarea
              onChange={(e) =>
                edFunc.handleChange(e, "description", setFields, fields)
              }
              name="desc"
              id="description"
              required
              className={`p-1 w-full editor-box h-[75px] resize-none transition-all duration-200 ease-in-out`}
              placeholder='Escriba aquí la descripción...'
            ></textarea>
          </div>

          <div className="editor-fields">
            <label
              title="Ingrese la direccion de la imagen"
              className="editor-fields-label"
              htmlFor="imageThumbnailPath"
            >
              Miniatura imagen:
            </label>
            <input
              onChange={(e) =>
                edFunc.handleChange(e, "imageThumbnailPath", setFields, fields)
              }
              id="imageThumbnailPath"
              className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
              placeholder='Ingrese la url de la imagen'
            ></input>
          </div>

          <div className="editor-fields">
            <label
              title="Ingrese la direccion del video"
              className="editor-fields-label"
              htmlFor="videoThumbnailPath"
            >
              Miniatura video:
            </label>
            <input
              onChange={(e) =>
                edFunc.handleChange(e, "videoThumbnailPath", setFields, fields)
              }
              id="videoThumbnailPath"
              className={`p-1 w-full editor-box transition-all duration-200 ease-in-out `}
              placeholder='Ingrese la url del video'
            ></input>
          </div>

          <div className="editor-fields">
            <label
              title="Ingrese la direccion de la imagen"
              className="editor-fields-label"
              htmlFor="headerDesktop"
            >
              Imagen de cabecera:
            </label>
            <input
              onChange={(e) =>
                edFunc.handleChange(e, "headerDesktopPath", setFields, fields)
              }
              id="headerDesktop"
              className={`editor-input`}
              placeholder='Ingrese la url de la imagen'
            ></input>
          </div>

          <div className="editor-fields">
            <label
              title="Ingrese la direccion de la imagen"
              className="editor-fields-label"
              htmlFor="headerMobile"
            >
              Imagen de cabecera (móvil):
            </label>
            <input
              onChange={(e) =>
                edFunc.handleChange(e, "headerMobilePath", setFields, fields)
              }
              id="headerMobile"
              className={`editor-input`}
              placeholder='Ingrese la url de la imagen'
            ></input>
          </div>

          <div className="editor-fields">
            <label
              className="editor-fields-label"
              htmlFor="difficultyOrder"
            >
              Métrica de dificultad:
            </label>
            <input
              onChange={(e) =>
                edFunc.handleChangeInt(e, "difficultyOrder", setFields, fields)
              }
              id="difficultyOrder"
              type="number" min="1"
              className={`editor-input `}
              placeholder='Ingrese dificultad numérica...'
            ></input>
          </div>

          <div className="editor-fields">
            <label
              className="editor-fields-label"
              htmlFor="isPremium"
            >
              Premium:
            </label>
            <select
              className="p-1 w-full editor-box transition-all duration-200 ease-in-out"
              name="isPremium"
              id="isPremium"
              onChange={(e) =>
                edFunc.handleChange(e, "isPremium", setFields, fields)
              }
            >
              <option hidden defaultValue="default">-</option>
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </select>
          </div>

          <div className="editor-fields">
            <label
              htmlFor="difficultyLevel"
              className="editor-fields-label"
            >
              Nivel:
            </label>
            <select
              className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
              name="difficultyLevel"
              id="difficultyLevel"
              required
              onChange={(e) =>
                edFunc.handleChangeInt(e, "difficultyLevelId", setFields, fields)
              }
            >
              <option hidden defaultValue="default">Seleccione dificultad</option>
              {levels?.map((item, index) => {
                return (
                  <option key={index} value={levels[index].id} className="font-medium">
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="editor-fields">
            <label
              htmlFor="difficultyLevel"
              className="editor-fields-label"
            >
              Tipo de score:
            </label>
            <select
              className={`p-1 w-full editor-box transition-all duration-200 ease-in-out`}
              name="scoreTypeId"
              id="scoreTypeId"
              required
              value={fields.scoreType}
              onChange={(e) =>
                edFunc.handleChangeInt(e, "scoreType", setFields, fields)
              }
            >
              <option hidden defaultValue="default">Seleccione tipo de score</option>
              {scoreTypes?.map((item, index) => {
                return (
                  <option key={index} value={item.id} className="font-medium">
                     {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="editor-fields justify-start">
            <label
              htmlFor="tagsId"
              className="editor-fields-label"
            >
              Etiquetas:
            </label>
            <button className="btn w-[150px] bg-[#3E73B9] hover:bg-blue-900"
              id="tagsId"
              onClick={() => setTagModal(true)}
              type="button">
              Agregar Etiqueta
            </button>
          </div>

          {(fields.tagsId != null) &&
            <div className="editor-fields justify-start">
              {fields.tagsId.map((item, index) => {
                // using .find to search for the tag objects and obtain the name since the tagId field only save the id number of the tag
                const tagFind = tags.find(element => element.id === item)
                return (
                  <div key={index} className="text-lg bg-[#C36B96] px-2 m-2 flex items-center justify-between editor-fields-label rounded-lg w-fit gap-2">
                    <p>{tagFind?.name}</p>
                    <button
                      className=""
                      onClick={(e) => {
                        edFunc.handleChangeArrayRemove(e, "tagsId", setFields, fields, index)
                      }}
                    >
                      <ClearOutlinedIcon
                        className="mb-1 hover:text-red-500 hover:scale-110 cursor-pointer"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          }

          <div className="editor-fields">
            <label
              className="editor-fields-label flex-none"
              htmlFor="guidePage"
            >
              Nombre 1era página:
            </label>
            <input
              id="guidePage"
              required
              className={`editor-input`}
              placeholder='Escriba título de la primera página...'
              onChange={(e) => { setFields({ ...fields, guidePage: e.target.value }) }}
            ></input>
          </div>

          <div className='flex flex-col mt-[40px] xs:flex-row justify-center'>
            <button
              className={`btn mx-auto my-2 xs:m-2 h-full min-w-[125px] max-w-[200px]${spinnerEnable ? 'bg-rose-800 pointer-events-none' : ''} disabled:bg-gray-600 disabled:text-slate-400`}
              type="submit"
            >
              {spinnerEnable
                ? (
                  <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
                )
                : (
                  'Crear Guía'
                )}
            </button>
          </div>
        </form>
      </div>

      {modal && <CreateDocumentModal
        modal={modal}
        setModal={setModal}
        type={"guide"}
        title={fields.name}
        resetFunc={handleNewGuide}
        newDocumentId={newGuideId}
      />}
      <TagListModal
        fields={fields}
        setFields={setFields}
        modal={tagModal}
        setModal={setTagModal}
      />
    </div>
  )
}

export default NewGuide