import React, { useState } from 'react'
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { resendCode } from '../../services/userService';
import LoadingSpinner from '../common/loadingSpinner';
import { useSelector } from "react-redux";


const UserVerification = () => {

  const { currentUser } = useSelector((state) => state.user);
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState();

  const handleResendVerification = async () => {
    setIsLoading(true)
    
      if (
        !currentUser
      ) {
        toast.error('Por favor inicia sesión.');
        setIsLoading(false)
      } else {
        
        await resendCode(
         currentUser,
        )
        .then(() => { 
          setIsLoading(false);
          toast.success('El link de verificación ha sido reenviado.')
        })
      }
  };
  

  return (
    <>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
            className="flex flex-col justify-center items-center text-center h-full min-h-[600px] p-4"
        >
            <h3 className="title font-bold">Parece que todavía no te has verificado</h3>
            <p className="text-base xs:text-lg md:text-xl text-white font-semibold max-w-5xl">
                {t("userverification.message")}
            </p>
           
            <button className="btn mt-5" onClick={() => handleResendVerification()}>
              Reenviar link de verificación
            </button>
            {isLoading ? <LoadingSpinner size={"150px"}/> : null}
        </motion.div>  
    </>
  )
}

export default UserVerification