import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";

function UserForm(props) {
  return (
    <div className="flex flex-col justify-center items-center text-center h-full min-h-box p-2 my-4 xs:my-0">
      {props.login && <LoginForm />}
      {props.register && <RegisterForm />}
    </div>
  );
}

export default UserForm;
