import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { addRole, deleteRole, editRole, getRoles } from "../../../services/superAdminService";
import PermissionsListModal from "../../common/permissionsModal";
import { ROLEPERMISSION_URL_LIST } from '../../../services/apiRoutes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { toast } from "react-toastify";
import LoaderSpinner from "../../common/loaderSpinner";

const AddModal = ({ show, onClose, onAdd, isLoading }) => {
    const [roleName, setRoleName] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onAdd(roleName);
        setRoleName("");
    };

    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="dark:bg-slate-600 bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold dark:text-slate-100">Agrega un rol</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        required
                        placeholder="Rol..."
                        className="user-input"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                    />
                    <div
                        className="flex justify-center mt-2"
                    >
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <LoaderSpinner /> : "Agregar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const UpdateModal = ({ show, onClose, onUpdate, initialData, isLoading }) => {
    const [roleName, setRoleName] = useState(initialData.name);

    useEffect(() => {
        setRoleName(initialData.name);
    }, [initialData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onUpdate(initialData.id, roleName);
    };

    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
            <div className="bg-white px-16 py-14 rounded-md text-center w-full sm:w-1/3">
                <h1 className="mb-10 font-bold text-slate-600">Modifica el nombre del rol</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        required
                        placeholder="Rol..."
                        className="user-input"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                    />
                    <div
                        className="flex justify-center mt-2"
                    >
                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                            type="submit"
                        >
                            {isLoading ? <LoaderSpinner /> : "Modificar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const DeleteModal = ({ show, onClose, onDelete, isLoading }) => {
    if (!show) return null;

    return (
        <div className="bg-black bg-opacity-50 flex fixed top-0 left-0 w-full h-full z-50">
            <div className="bg-white px-16 py-14 rounded-md text-center m-auto">
                <h1 className="mb-10 font-bold text-slate-600">¿Estás seguro que deseas eliminar este rol?</h1>
                <div
                    className="flex justify-center"
                >
                    <button
                        className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className={`bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        onClick={onDelete}
                        disabled={isLoading}
                    >
                        {isLoading ? <LoaderSpinner /> : "Eliminar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

function RoleEditor() {
    const token = useSelector((state) => state.user.token);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState();
    const [roleId, setRoleId] = useState();
    const [addRoleModal, setAddRoleModal] = useState(false);
    const [permissionsModal, setPermissionsModal] = useState(false);
    const [updateRoleModal, setUpdateRoleModal] = useState(false);
    const [updateRoleData, setUpdateRoleData] = useState({});
    const [deleteRoleModal, setDeleteRoleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchRoles = async () => {
        const queryParams = {
            cant: 50,
            paginate: false
        };
        const response = await getRoles(queryParams);
        if(response) {
            setRoles(response);
        }else{
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
            setRoles([]);
        }
    };

    const fetchPermissions = () => {
        fetch(`${ROLEPERMISSION_URL_LIST}`, {
            headers: {
                Authorization: token
            }
        })
            .then(response => response.json())
            .then(data => setPermissions(data.result))
            .catch(error => console.log(error));
    };

    useEffect(() => {
        fetchRoles();
        fetchPermissions();
    }, []);

    const handleAddRole = async (roleName) => {
        setIsLoading(true);
        const response = await addRole(roleName, token);
        if (response) {
            toast.success("Rol agregado con exito");
            setIsLoading(false);
            setAddRoleModal(false);
            fetchRoles();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
        }
    };

    const handleUpdateRole = async (id, roleName) => {
        setIsLoading(true);
        const response = await editRole(id, roleName, token);
        if (response) {
            toast.success("Rol actualizado con exito");
            setIsLoading(false);
            setUpdateRoleModal(false);
            await fetchRoles();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
            setUpdateRoleModal(false);
        }
    };

    const handleDeleteRole = async () => {
        setIsLoading(true);
        const response = await deleteRole(roleId, token);
        if (response) {
            toast.success("Rol eliminado con exito");
            setIsLoading(false);
            setDeleteRoleModal(false);
            await fetchRoles();
        } else {
            toast.error("Ha ocurrido un error. Por favor intente de nuevo");
            setIsLoading(false);
            setDeleteRoleModal(false);
        }
    };

    return (
        <>
            <AddModal
                show={addRoleModal}
                onClose={() => setAddRoleModal(false)}
                onAdd={handleAddRole}
                isLoading={isLoading}
            />
            <UpdateModal
                show={updateRoleModal}
                onClose={() => setUpdateRoleModal(false)}
                onUpdate={handleUpdateRole}
                initialData={updateRoleData}
                isLoading={isLoading}
            />
            <DeleteModal
                show={deleteRoleModal}
                onClose={() => setDeleteRoleModal(false)}
                onDelete={handleDeleteRole}
                isLoading={isLoading}
            />
            {permissionsModal && (
                <PermissionsListModal
                    permissions={permissions}
                    modal={permissionsModal}
                    setModal={setPermissionsModal}
                    roleId={roleId}
                    token={token}
                />
            )}
            <div className="flex flex-col min-h-[700px] w-full h-full max-h-[2800px] p-2">
                <div className="overflow-x-auto">
                    <div className="min-w-screen min-h-screen flex justify-center font-sans overflow-hidden">
                        <div className="w-full lg:w-4/6">
                            <div className="bg-white shadow-md rounded my-6">
                                <div className="flex justify-between mr-5">
                                    <h3 className="text-2xl text-gray-800 font-bold ml-5 mt-6">Editor de roles</h3>
                                    <button
                                        className="my-5 bg-rose-600 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => setAddRoleModal(true)}
                                    >
                                        + Agregar rol
                                    </button>
                                </div>
                                <table className="min-w-max w-full table-auto border border-gray-200">
                                    <thead>
                                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th className="py-3 px-6 text-center border border-gray-300">Roles</th>
                                            <th className="py-3 px-6 text-center border border-gray-300">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light dark:bg-gray-500 dark:text-gray-200">
                                        {roles.map((role, index) => (
                                            <tr key={index}>
                                                <td className="whitespace-nowrap border border-gray-300 text-center">
                                                    <div className="flex items-center justify-center">
                                                        <img src='/Michis_Heads_03.png' width='30px' className="inline-block" alt="role" />
                                                        <span className="font-medium ml-2"> {role.name} </span>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-6 text-center border border-gray-300">
                                                    <div className="flex item-center justify-center gap-1">
                                                        <button
                                                            className="transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setPermissionsModal(true);
                                                                setRoleId(role.id);
                                                            }}
                                                        >
                                                            <ManageAccountsIcon />
                                                        </button>
                                                        <button
                                                            className="transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setUpdateRoleModal(true);
                                                                setUpdateRoleData(role);
                                                                setRoleId(role.id);
                                                            }}
                                                        >
                                                            <EditOutlinedIcon />
                                                        </button>
                                                        <button
                                                            className="transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setDeleteRoleModal(true);
                                                                setRoleId(role.id);
                                                            }}
                                                        >
                                                            <DeleteOutlineOutlinedIcon />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {roles.length === 0 && (
                                            <tr className="h-20">
                                                <td
                                                    colSpan={2}
                                                    className='text-lg font-bold mt-5'

                                                >
                                                    <div className="flex justify-center items-center gap-2">
                                                        No hay roles
                                                        <button
                                                            className="btn p-[10px] bg-[#3E73B9] hover:bg-blue-800"
                                                            onClick={() => setAddRoleModal(true)}
                                                        >
                                                            Crear rol
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoleEditor;
