import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import { updateGuidePage } from "../../services/guidesService";

export default function Tinymce(props) {
  const [spinnerEnable, setSpinnerEnable] = useState(false);

  const { fields, avoidpage, pageId, difficultyLevel, previewPage, setPreviewPage, token, getGuide } = props;
  const { name, content, siteUrl, index, guideId } = fields;
  const editorRef = useRef(null);
  const theme = useSelector((state) => state.theme.value);

  const handlePutGuide = async (e, name, content, index, guideId, token) => {
    e.preventDefault();
    const newContent = {
      name,
      content: editorRef.current.getContent(),
      siteUrl,
      index,
      guideId
    };
    setSpinnerEnable(true)
    const response = await updateGuidePage(newContent, pageId, token)
    if (response?.result) {
      await getGuide()
    }

    setTimeout(async () => {
      setSpinnerEnable(false)
      setPreviewPage(true);
    }, "2000");
  };

  const handleImageUpload = (cb, value, meta) => {

  };

  return (
    <form
      className="flex flex-col h-screen justify-center items-center"
      onSubmit={(e) =>
        handlePutGuide(
          e,
          name,
          content,
          index,
          guideId,
          token
        )
      }
    >
      <>
        <TinyMCE
          apiKey='0hs89ru4n63gm0rwv4crhp0e4p1qtrrh4vc8s16lshb9gww5'
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={
            content === undefined
              ? "Empiece a escribir el contenido aqui"
              : content
          }
          init={{
            paste_data_images: true,
            min_height: 800,
            max_height: 2500,
            width: "100%",
            menubar: "file edit insert table tc help",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code codesample pagebreak emoticons",
              "insertdatetime media table paste code help wordcount",
            ],
            skin: theme === "dark" ? "oxide-dark" : "oxide",
            content_css: theme === "dark" ? "dark" : "default",
            file_picker_types: "image",
            font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 96pt',
            toolbar:
              "undo redo | formatselect | fontsizeselect | fontselect  | " +
              " forecolor backcolor | bold italic underline strikethrough | superscript subscript codesample | alignleft aligncenter alignright alignjustify" +
              " | bullist numlist outdent indent | " +
              " charmap emoticons image | removeformat preview wordcount help" +
              ""
            ,
            font_formats:
              "Monserrat=Montserrat,sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            file_picker_callback: handleImageUpload,
            content_style: `
                              @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400&display=swap');
                              body {
                                font-family: 'Montserrat', sans-serif;
                              }
                              h1, h2, h3, h4, h5, h6 {
                                font-family: 'Montserrat', sans-serif;
                              }
                              import { parse } from 'html-react-parser';
  body { import { useInfo } from './../../context/infoContext';
                                  font-family: 'Montserrat', sans-serif;
                                  font-size: 14px;
                              }",
                          `,
          }}
        />
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className={`btn w-40 mx-2 my-4 ${guideId ? "" : "disabled-link"} ${spinnerEnable ? 'bg-rose-800 pointer-events-none' : ''}`}
            onClick={() => {
              avoidpage(false);
            }}
            disabled={!guideId}
          >
            {spinnerEnable
              ? (
                <span className='block mx-auto w-5 h-5 rounded-full spinner border-2 border-white border-l-2 border-l-blue-600 animate-spin' />
              )
              : ('Guardar Página'
              )}
          </button>
          <a
            href={`${process.env.PUBLIC_URL}/guides/${difficultyLevel?.name}/${guideId}/0`}
            target="_blank"
            rel="noreferrer"
            className={`btn w-40 mx-2 my-4 ${previewPage ? "" : "disabled-link"}`}
            disabled={!previewPage}
          >
            Ver contenido
          </a>
        </div>
      </>
    </form>
  );

}
