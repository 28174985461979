/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux'
import { addLanguage, deleteLanguage, editLanguage } from "../../../services/superAdminService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../common/loadingSpinner";
import { LANGUAGE_URL } from '../../../services/apiRoutes'
import ConstructionIcon from '@mui/icons-material/Construction';

function LanguageEditor() {

    const languageRef = useRef();
    const languageCodeRef = useRef();
    const updateLanguageRef = useRef();
    const updateLanguageCodeRef = useRef();
    const [languageId, setLanguageId] = useState();
    const token = useSelector((state) => state.user.token);
    const [languages, setLanguages] = useState([]);
    const [addLanguageModal, setAddLanguageModal] = useState(false);
    const [updateLanguageModal, setUpdateLanguageModal] = useState(false);
    const [deleteLanguageModal, setDeleteLanguageModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchLanguages = () => {

        fetch(`${LANGUAGE_URL}?cant=50`, {
            headers: {
                Authorization: token
            }
        })
            .then(response => response.json())
            .then(data => setLanguages(data.result))
            .catch(error => console.log(error))
    }

    useEffect(() => {
        fetchLanguages();
    }, [])

    const handleAddition = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await addLanguage(languageRef.current.value, languageCodeRef.current.value, token);
        setIsLoading(false);
        setAddLanguageModal(false);
        fetchLanguages();
    }

    const AddModal = () => {
        return (
            <>
                <div className="bg-slate-900 bg-opacity-50 flex fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 mx-auto">
                    <div className="dark:bg-slate-600 bg-white px-16 py-14 rounded-md text-center">
                        <h1 className="mb-10 font-bold dark:text-slate-100">Agrega un idioma</h1>

                        {isLoading ? (
                            <LoadingSpinner size={"300px"} />
                        ) : (
                            <form onSubmit={handleAddition}>
                                <input required placeholder="Idioma..." ref={languageRef} className="user-input" />
                                <input required placeholder="Código..." ref={languageCodeRef} className="user-input mb-5" />
                                <button
                                    className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                                    onClick={() => setAddLanguageModal(false)}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className="bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold"
                                    type="submit"
                                >
                                    Agregar
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </>
        )
    }

    const UpdateModal = () => {
        const id = languageId

        return (
            <>
                <div className="bg-slate-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 z-50">
                    <div className="bg-white px-16 py-14 rounded-md text-center">
                        <h1 className="mb-10 font-bold text-slate-600">Modifica el idioma </h1>
                        <form onSubmit={() => {
                            setIsLoading(true)
                            editLanguage(id, updateLanguageRef.current.value, updateLanguageCodeRef.current.value, token)
                            setIsLoading(false)
                            setUpdateLanguageModal(false)
                            fetchLanguages();
                        }}>
                            <input required placeholder="Idioma..." ref={updateLanguageRef} className="user-input" />
                            <input required placeholder="Código..." ref={updateLanguageCodeRef} className="user-input mb-5" />

                            {isLoading ? <LoadingSpinner size={"300px"} />
                                :
                                <>
                                    <button
                                        className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                                        onClick={() => setUpdateLanguageModal(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className="bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold"
                                        type="submit"
                                    >
                                        Modificar
                                    </button>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </>
        )
    }

    const DeleteModal = () => {
        const id = languageId
        const [isLoading, setIsLoading] = useState(false);
        return (
            <>
                <div className="bg-slate-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 z-50">
                    <div className="bg-white px-16 py-14 rounded-md text-center">
                        <h1 className="mb-10 font-bold text-slate-600">¿Estás seguro que deseas eliminar este idioma?</h1>

                        {isLoading && <LoadingSpinner size={"300px"} />}

                        <button
                            className="bg-red-500 px-4 py-2 rounded-md text-md text-white"
                            onClick={() => setDeleteLanguageModal(false)}
                        >
                            Cancelar
                        </button>
                        <button
                            className="bg-indigo-500 px-7 py-2 ml-2 rounded-md text-md text-white font-semibold"
                            onClick={() => {
                                setIsLoading(true)
                                deleteLanguage(id, token)
                                setIsLoading(false)
                                setDeleteLanguageModal(false)
                                toast.success('El idioma ha sido eliminado.')
                                fetchLanguages();
                            }}
                        >
                            Eliminar
                        </button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-[700px] w-full h-full max-h-[2800px] p-2">
            <p
                className="flex justify-center items-center gap-2 text-3xl font-bold text-center text-white"
            >
                <ConstructionIcon
                    fontSize="large"
                />
                Sección en construcción
            </p>
            {/* {addLanguageModal ? <AddModal /> : null}

            {languages.length === 0 &&
                <div className="flex justify-center items-center">
                    <LoadingSpinner size={"300px"} />
                </div>
            }
            <div className="overflow-x-auto">
                <div className="min-w-screen min-h-screen flex justify-center font-sans overflow-hidden">
                    <div className="w-full lg:w-4/6">
                        <div className="bg-white shadow-md rounded my-6">
                            <div className="flex justify-between mr-5">
                                <h3 className="text-2xl text-gray-800 font-bold ml-5 mt-6">Editor de idiomas</h3>
                                <button
                                    className="my-5 bg-rose-600 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => setAddLanguageModal(true)}
                                >
                                    + Agregar idioma
                                </button>
                            </div>
                            <table className="min-w-max w-full table-auto border border-gray-200">
                                <thead>
                                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                        <th className="py-3 px-6 text-center border border-gray-300">Idiomas</th>
                                        <th className="py-3 px-6 text-center border border-gray-300">Código</th>
                                        <th className="py-3 px-6 text-center border border-gray-300">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 text-sm font-light dark:bg-gray-500 dark:text-gray-200">
                                    {languages.map((language, index) => {
                                        return (

                                            <tr key={index}>
                                                <td className="py-3 px-6 whitespace-nowrap border border-gray-300">
                                                    <div className="flex items-center justify-center">
                                                        <div className="mr-2">
                                                            🌎
                                                        </div>
                                                        <span className="font-medium "> {language.name} </span>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-6 text-center border border-gray-300">
                                                    <div className="flex item-center justify-center">
                                                        <span className="font-medium ml-3 bg-gray-600 p-1 rounded-md text-slate-100">
                                                            {language.i18nCode}
                                                        </span>
                                                    </div>
                                                </td>
                                                {updateLanguageModal ? <UpdateModal /> : null}
                                                {deleteLanguageModal ? <DeleteModal /> : null}
                                                <td className="py-3 px-6 text-center border border-gray-300">
                                                    <div className="flex item-center justify-center">
                                                        <button
                                                            className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setUpdateLanguageModal(true)
                                                                setLanguageId(language.id)
                                                            }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                            onClick={() => {
                                                                setDeleteLanguageModal(true)
                                                                setLanguageId(language.id)
                                                            }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default LanguageEditor;
